import React from 'react'
import PropTypes from 'prop-types'

const Title = ({ title }) => title ? <span>{title}</span> : null

Title.propTypes = {
  title: PropTypes.string,
}
Title.defaultProps = {
  title: undefined
}

export default Title
