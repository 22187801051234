import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import DebugModal from '../../../components/DebugModal'
import UserName from 'modules/User/shared/UserName'
import UserTitle from 'modules/User/shared/UserTitle'

import './MainNavButton.scss'
import StatusBadge from '../../../../User/shared/StatusBadge'
import BadgeID from 'modules/User/shared/BadgeID'

const UserMainNavButton = ({ user }) => {
  const [showDebugModal, setShowDebugModal] = useState()

  let buttonPressTimer

  const handleButtonPress = () => {
    buttonPressTimer = setTimeout(() => setShowDebugModal(true), 800)
  }

  const handleButtonRelease = () => {
    clearTimeout(buttonPressTimer)
  }

  return (
    <>
      <NavLink
        to="/profile"
        onTouchStart={handleButtonPress}
        onTouchEnd={handleButtonRelease}
        onMouseDown={handleButtonPress}
        onMouseUp={handleButtonRelease}
        onMouseLeave={handleButtonRelease}
      >
        <li className="user-menu-nav">
          <div className="d-flex flex-column">
            <div>
              <strong>
                <UserName {...user} noCard noDutyStatus noLink noOnlineStatus />
              </strong>
            </div>

            <div className="font-small">
              <span className="text-muted">
                <BadgeID badgeNumber={user.badgeNumber} />
              </span>{' '}
              <UserTitle {...user} />
            </div>

            <div>
              <StatusBadge userId={user?.id} />
            </div>
          </div>
        </li>
      </NavLink>

      {showDebugModal && <DebugModal closeHandler={() => setShowDebugModal(false)} />}
    </>
  )
}

UserMainNavButton.propTypes = {
  user: PropTypes.object.isRequired,
}

export default connect(({ Auth: { user } }) => ({
  user,
}))(UserMainNavButton)
