import React, { lazy, Suspense, useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { logout } from '../ducks'
import useOrganization from '../shared/hooks/useOrganization'

import Conditional from '../shared/Conditional'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import './AccountIssue.scss'

const Subscription = lazy(() => import('../../Admin/screens/organization/Subscription'))
import Loading from '@therms/web-common/components/Loading'
import { systemToast } from '../../../utils/toaster'

const AccountIssue = () => {
  const dispatch = useDispatch()
  const [showBillingInformation, setShowBillingInformation] = useState()

  const org = useOrganization()

  const { subscriptionStatus } = org?.organizationMetadata || {}

  useEffect(() => {
    if (subscriptionStatus === 'trial-ended')
      systemToast("Your organization's trial period has ended", { info: true })
  }, [subscriptionStatus])

  if (showBillingInformation) {
    return (
      <div className="container">
        <Suspense fallback={<Loading />}>
          <Subscription />
        </Suspense>
      </div>
    )
  }

  return (
    <div className="account-issue-background flex-column-center h-100 w-100" id="account-issue">
      <div className="flex-column-center">
        <div className="alert alert-danger max-width-500 p-3">
          <Conditional
            permission="organization.canManageBilling"
            renderOnFail={() => (
              <span>
                <FAIcon name="exclamation-triangle" /> Your Organization's account needs attention.
                Contact your Organization's administrator.
              </span>
            )}
          >
            <div className="mb-3">
              <FAIcon name="exclamation-triangle" /> Your Organization's account needs attention.
              Either update your subscription billing information or please contact THERMS support{' '}
              <a href={`mailto:support@therms.io?subject=Account Issue (${org?.name})`}>
                support@therms.io
              </a>
            </div>

            <div className="d-flex justify-content-end">
              <button
                className="btn btn-secondary mr-2"
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(logout())
                  return false
                }}
                type="button"
              >
                <FAIcon name="sign-out-alt" className="mr-2" /> Logout
              </button>

              <button className="btn btn-primary" onClick={setShowBillingInformation} type="button">
                <FAIcon name="credit-card" className="mr-2" /> Update Billing Information
              </button>
            </div>
          </Conditional>
        </div>
      </div>
    </div>
  )
}

AccountIssue.propTypes = {}
AccountIssue.defaultProps = {}

export default AccountIssue
