import React from 'react'

import Conditional from '../../../../Auth/shared/Conditional'
import moduleIcons from '@config/moduleIcons'
import MainNavButton from 'modules/Layout/shared/MainNavButton/index'

const BulletinMainNavButton = () => (
  <Conditional module="location">
    <MainNavButton iconClass={`${moduleIcons.location} fa-2x`} to="/locations">
      Locations
    </MainNavButton>
  </Conditional>
)
export default BulletinMainNavButton
