import { createSelector } from 'reselect'
import get from 'lodash-es/get'

import Store from 'services/store'

// selectors
export const selectModuleSettings = (state, moduleName) =>
  get(state, `Auth.organizationMetadata.settings.module.${moduleName}`, undefined)

export const organizationSettingsSelector = createSelector(
  (state) => state.Auth.organizationMetadata.settings,
  settings => settings,
)

export const organizationModuleSettingsSelector = createSelector(
  selectModuleSettings,
  settings => settings,
)

// actions
export const updateOrganizationSettings = (settings) => (dispatch, getState) => {
  const {
    Auth: { organizationMetadata },
  } = getState()

  const currentSettings = organizationMetadata.settings

  return Store.OrganizationMetadata.update(organizationMetadata.id, {
    settings: {
      ...currentSettings,
      ...settings,
    },
  })
}

export const updateOrganizationModuleSettings = (moduleName, settingsValue) => (dispatch, getState) => {
  const {
    Auth: { organizationMetadata },
  } = getState()

  const currentSettings = organizationMetadata.settings

  return Store.OrganizationMetadata.update(organizationMetadata.id, {
    settings: {
      ...currentSettings,
      module: {
        ...currentSettings.module,
        [moduleName]: settingsValue,
      },
    },
  })
}
