import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '@therms/web-common/components/Modal'
import ViewMail from '../components/ViewMail'
import Compose from '../components/Compose'
import useMail from '../hooks/useMail'

const QuickMailModal = ({ closeHandler, mailId }) => {
  const [composeState, setComposeState] = useState()
  const [viewState, setViewState] = useState('view')
  const { destroy } = useMail(mailId)

  const handleDelete = () => {
    destroy()
    closeHandler()
  }

  const handleCompose = useCallback(
    state => {
      setComposeState(state)
      setViewState('compose')
    },
    [setComposeState],
  )

  if (!mailId) return null

  return (
    <Modal closeHandler={closeHandler} lg title="Mail Message">
      {viewState === 'view' && (
        <ViewMail
          mailId={mailId}
          onDelete={handleDelete}
          onReplyAll={handleCompose}
          onReply={handleCompose}
          onFwd={handleCompose}
        />
      )}

      {viewState === 'compose' && <Compose onComplete={closeHandler} state={composeState} />}
    </Modal>
  )
}

QuickMailModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  mailId: PropTypes.string.isRequired,
}
QuickMailModal.defaultProps = {}

export default QuickMailModal
