// see .md readme next to this file for details
import { uniqBy, upperFirst } from 'lodash-es'
import { createSelector } from 'reselect'
import { createAction } from 'redux-actions'

import { createHashByKey } from '@utils'
import Store, { registerStoreChangeHandler } from 'services/store'
import StateStore from '../index'
import { isProdEnv } from '../../config/env'
import * as Sentry from '@sentry/browser'

export default function duckBoilerplateFactory({ plural, reducerName, singular, storeMapperName }) {
  const capitalizedSingular = singular.toUpperCase()
  const capitalizedPlural = plural.toUpperCase()
  const upperFirstSingular = upperFirst(singular)

  const stateKeys = {
    loadingRecordById: `loading${upperFirst(singular)}ById`,
    loadingRecords: `loading${upperFirst(plural)}`,
    recordsById: `${plural}ById`,
    recordHasFetchedById: `${singular}HasFetchedById`,
  }

  const createActionName = (name) => `${reducerName}/${name}`

  const REMOVE_RECORD = `REMOVE_${capitalizedPlural}`
  const SET_HAS_FETCHED_RECORDS = `SET_HAS_FETCHED_${capitalizedPlural}`
  const SET_RECORD = `SET_${capitalizedSingular}`
  const SET_LOADING_RECORD = `SET_LOADING_${capitalizedSingular}`
  const SET_RECORDS = `SET_${capitalizedPlural}`
  const SET_LOADING_RECORDS = `SET_LOADING_${capitalizedPlural}`

  const _Store = Store[upperFirst(storeMapperName)]

  if (!_Store)
    throw new Error(
      `duckBoilerplateFactory for storeMapperName: ${storeMapperName} does not exist on Store`,
    )

  const actionCreators = {
    [REMOVE_RECORD]: createAction(REMOVE_RECORD),
    [SET_HAS_FETCHED_RECORDS]: createAction(SET_HAS_FETCHED_RECORDS),
    [SET_RECORD]: createAction(SET_RECORD),
    [SET_LOADING_RECORD]: createAction(SET_LOADING_RECORD),
    [SET_RECORDS]: createAction(SET_RECORDS),
    [SET_LOADING_RECORDS]: createAction(SET_LOADING_RECORDS),
  }

  const actions = {
    // fetchRecord(id)
    [`fetch${upperFirst(singular)}`]: (id) => (dispatch, getState) => {
      if (!id) return Promise.reject(new Error(`fetch${upperFirst(singular)}(id) requires id`))

      const record = _Store.get(id)
      let promise

      if (record) promise = Promise.resolve(record)
      else {
        if (!getState()[reducerName][stateKeys.loadingRecordById][id])
          dispatch(actionCreators[SET_LOADING_RECORD]({ id, loading: true }))

        promise = _Store.find(id)
      }

      return promise
        .then((result) => {
          // don't dispatch, the 'add' event dispatches 'registerStoreChangeHandler'
          // dispatch(actionCreators[SET_RECORD](result))

          const state = getState()

          if (state[reducerName][stateKeys.loadingRecordById][id])
            dispatch(actionCreators[SET_LOADING_RECORD]({ id, loading: false }))

          if (!state[reducerName][stateKeys.recordHasFetchedById][id])
            dispatch(actionCreators[SET_HAS_FETCHED_RECORDS]({ ids: [id] }))

          return result
        })
        .catch((err) => {
          if (!isProdEnv) console.error(`${storeMapperName}.find error`, err)
          // if we don't re-throw, .then()'s will resolve down the promise chain
          throw err
        })
    },

    // fetchRecords({ where: {...}, ... }, { ... })
    [`fetch${upperFirst(plural)}`]:
      (query = {}, opts = {}) =>
      (dispatch, getState) => {
        // we check this because it means the promise/query will immediately
        // resolve below - in cases where a nested UI component is calling
        // fetchRecords() after the parent, and the parent is conditionally
        // rendering based on "loadingRecords", we don't want an infinite loop
        if (
          !Store._completedQueries[storeMapperName][JSON.stringify(query)] &&
          !getState()[reducerName][stateKeys.loadingRecords]
        )
          dispatch(actionCreators[SET_LOADING_RECORDS](true))

        return _Store
          .findAll(query, opts)
          .then((results = []) => {
            // don't dispatch, the 'add' event dispatches in 'registerStoreChangeHandler'
            // dispatch(actionCreators[SET_RECORDS](results))

            if (!Array.isArray(results)) {
              Sentry.captureMessage(
                `state/utils/duckBoilerplateFactor.js "results" is not Array, received: ${typeof results}, ${JSON.stringify(
                  results,
                )}`,
                { level: Sentry.Severity.Error },
              )
            }

            const state = getState()

            const hasFetchedRecordIds = results?.filter(
              ({ id }) => !state[reducerName][stateKeys.recordHasFetchedById][id],
            )

            if (hasFetchedRecordIds.length)
              dispatch(
                actionCreators[SET_HAS_FETCHED_RECORDS]({
                  ids: hasFetchedRecordIds,
                }),
              )

            if (state[reducerName][stateKeys.loadingRecords])
              dispatch(actionCreators[SET_LOADING_RECORDS](false))

            return results
          })
          .catch((err) => {
            if (!isProdEnv)
              console.error(`${storeMapperName}.findAll error`, {
                err,
                query,
              })

            // if we don't re-throw, .then()'s will resolve down the promise chain
            throw err
          })
      },
  }

  // Object for use in redux-actions handleActions({ ... })
  const handleActionsBoilerplate = {
    [actionCreators[REMOVE_RECORD]]: (state, action) => {
      const id = action.payload
      const recordsById = state[stateKeys.recordsById]
      delete recordsById[id]

      return {
        ...state,
        [plural]: state[plural].filter((r) => r.id !== id),
        [stateKeys.recordsById]: recordsById,
      }
    },

    [actionCreators[SET_HAS_FETCHED_RECORDS]]: (state, action) => ({
      ...state,
      [stateKeys.recordHasFetchedById]: action.payload.ids.reduce(
        (acc, id) => (acc[id] = true) && acc,
        state[stateKeys.recordHasFetchedById],
      ),
    }),

    [actionCreators[SET_LOADING_RECORD]]: (state, action) => ({
      ...state,
      [stateKeys.loadingRecordById]: {
        ...state[stateKeys.loadingRecordById],
        [action.payload.id]: action.payload.loading,
      },
    }),

    [actionCreators[SET_LOADING_RECORDS]]: (state, action) => ({
      ...state,
      [stateKeys.loadingRecords]: action.payload,
    }),

    [actionCreators[SET_RECORD]]: (state, action) => ({
      ...state,
      [plural]: uniqBy([action.payload, ...state[plural]], 'id'),
      [stateKeys.recordsById]: {
        ...state[stateKeys.recordsById],
        [action.payload.id]: action.payload,
      },
      [stateKeys.recordHasFetchedById]: {
        ...state[stateKeys.recordHasFetchedById],
        [action.payload.id]: true,
      },
    }),

    [actionCreators[SET_RECORDS]]: (state, action) => ({
      ...state,
      [plural]: uniqBy([...action.payload, ...state[plural]], 'id'),
      [stateKeys.recordsById]: {
        ...state[stateKeys.recordsById],
        ...createHashByKey(action.payload, 'id'),
      },
      [stateKeys.recordHasFetchedById]: Object.values(action.payload).reduce(
        (acc, record) => (acc[record.id] = true) && acc,
        state[stateKeys.recordHasFetchedById],
      ),
    }),
  }

  const initialStateBoilerplate = {
    // loadingRecords
    [stateKeys.loadingRecords]: false,
    // records
    [plural]: [],
    // recordHasFetchedById
    [stateKeys.recordHasFetchedById]: {},
    // recordsById
    [stateKeys.recordsById]: {},
    // loadingRecordById
    [stateKeys.loadingRecordById]: {},
  }

  const selects = {
    // selectRecordById
    [`select${upperFirstSingular}ById`]: (state, id) => {
      if (!id) return undefined

      if (Array.isArray(id)) {
        return id.reduce((acc, _id) => {
          const record = state[reducerName][stateKeys.recordsById][_id]
          if (record) acc.push(record)
          return acc
        }, [])
      }

      return state[reducerName][stateKeys.recordsById][id]
    },

    // selectRecordLoadingById
    [`select${upperFirstSingular}LoadingById`]: (state, id) =>
      id ? state[reducerName][stateKeys.loadingRecordById][id] : undefined,
  }

  const selectors = {
    // recordByIdSelector
    [`${singular}ByIdSelector`]: createSelector(
      selects[`select${upperFirstSingular}ById`],
      (record) => record,
    ),

    // recordHasFetchedByIdSelector
    [`${singular}HasFetchedByIdSelector`]: (state, recordId) =>
      Boolean(state[reducerName][stateKeys.recordHasFetchedById][recordId]),

    // recordLoadingByIdSelector
    [`${singular}LoadingByIdSelector`]: createSelector(
      selects[`select${upperFirstSingular}LoadingById`],
      (record) => Boolean(record),
    ),
  }

  const registerStoreChangeListenerForBoilerplate = () => {
    /*
      @NOTE: this is useful and important because we use Store.create in our
      code/@components and never dispatch the created record to the state-store
      so the 'add' event is fired by Store and we dispatch here.
      Also, socket and any other async outside events that add to the Store
      are captured and dispatched.
    */
    registerStoreChangeHandler(storeMapperName, ({ event, payload }) => {
      if (event === 'add') StateStore.dispatch(actionCreators[SET_RECORDS](payload))
      else if (event === 'remove') {
        if (Array.isArray(payload)) {
          payload.forEach((record) =>
            StateStore.dispatch(actionCreators[REMOVE_RECORD](record.id || record)),
          )
        } else {
          StateStore.dispatch(actionCreators[REMOVE_RECORD](payload.id || payload))
        }
      }
    })
  }

  return {
    actions,
    actionCreators,
    createActionName,
    handleActionsBoilerplate,
    initialStateBoilerplate,
    registerStoreChangeListenerForBoilerplate,
    selects,
    selectors,
  }
}
