// https://zocada.com/compress-resize-images-javascript-browser/

/**
 * This func will resize images to be a max height or width of 1000px
 * @param file
 * @returns Promise<>
 */
export default async function resizeImage(file) {
  return new Promise((resolve) => {
    const fileName = file.name
    const reader = new FileReader()

    reader.onload = (event) => {
      const img = new Image()

      img.src = event.target.result

      img.onload = () => {
        const elem = document.createElement('canvas')

        let height
        let width

        // determine if height or width is greater
        if (img.width > img.height) {
          width = 1000
          const scaleFactor = width / img.width

          height = img.height * scaleFactor
        } else {
          height = 1000

          const scaleFactor = height / img.height
          width = img.width * scaleFactor
        }

        elem.height = height
        elem.width = width

        const ctx = elem.getContext('2d')

        ctx.drawImage(img, 0, 0, width, height)

        ctx.canvas.toBlob(
          (blob) => {
            const resizedFileAsBlob = new File([blob], fileName, {
              type: file.type,
              lastModified: Date.now(),
            })

            resolve(resizedFileAsBlob)
          },
          file.type,
          1,
        )
      }

      reader.onerror = (error) => {
        console.log('Resize error', error)
        resolve()
      }
    }

    reader.readAsDataURL(file)
  })
}
