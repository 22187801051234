/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import StateStore from 'state'
import { map } from 'lodash-es'
import * as Sentry from '@sentry/browser'

import { currentUserSelector } from 'modules/Auth/ducks'
import Store from 'services/store'
import { fetchMail } from '../../../state/ducks/mail'

const MAIL_PAGE_LIMIT = 15

const initialState = {
  mailLoading: false,
  mailSearchOptions: undefined,
  mailPage: 1,
  mailResultsPageCount: 1,
  mailResultsById: [],
  mailResultsCurrentFolderId: 'inbox',
  mailResultsCount: 0,
  showMailIdModal: undefined,
  unreadCount: 0,
}

const moduleName = 'Mail'

const moduleConstant = name => `${moduleName}/${name}`

// selectors
const selectMail = state => state.mail.mail

export const selectShowMailIdModal = state => state.Mail.showMailIdModal

// const selectMailByFolderId = (state, id) =>
//   state.mail.mail.filter(({ recieverFolderId }) => id === recieverFolderId)

// export const inboxMailSelector = createSelector(
//   selectMail,
//   selectCurrentUser,
//   (mail, user) => mail.filter(({ receiverId }) => user.id === receiverId),
// )

// export const sentMailSelector = createSelector(
//   selectMail,
//   selectCurrentUser,
//   (mail, user) => mail.filter(({ senderId }) => user.id === senderId),
// )

export const unreadMailCountSelector = state => state.Mail.unreadMailCount

export const unreadMailSelector = createSelector(selectMail, currentUserSelector, (mail, user) =>
  mail.filter(({ read, receiverId }) => user.id === receiverId && Boolean(!read)),
)

// types

// actions
export const setMailLoading = createAction('setMailLoading')
export const setMailPage = createAction('mailPage')
export const setMailResultsCurrentFolderId = createAction('setMailResultsCurrentFolderId')
export const setMailResultsPageCount = createAction('mailResultsPageCount')
export const setMailSearchOptions = createAction('setMailSearchOptions')
export const setMailResultsById = createAction(moduleConstant('setMailResultsById'))
export const setShowMailIdModal = createAction(moduleConstant('setShowMailIdModal'))
export const setUnreadMailCount = createAction(moduleConstant('setUnreadMailCount'))

export const fetchMailForFolder = ({ folderId = 'inbox' } = {}) => (dispatch, getState) => {

  const state = getState()
  const page = state.Mail.mailPage
  const previousFolder = state.Mail.mailResultsCurrentFolderId
  const where = {}

  dispatch(setMailLoading(true))
  dispatch(setMailResultsCurrentFolderId(folderId))

  if (previousFolder !== folderId) {
    dispatch(setMailPage(1))
    dispatch(setMailResultsPageCount(0))
  }

  if (folderId === 'inbox') {
    where.receiverId = state.Auth.user.id
  } else if (folderId === 'sent') {
    where.senderId = state.Auth.user.id
  } else {
    console.warn('fetchMailForFolder() no folderId specified')

    return Promise.reject()
  }

  return Store.Mail.findAll(
    {
      limit: MAIL_PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * MAIL_PAGE_LIMIT : 0,
      orderBy: [['createdAt', 'desc']],
      where,
    },
    { headers: { count: true }, raw: true },
  )
    .then(response => {
      let count
      let mail

      // when it's a cached response, it's an array of records
      if (Array.isArray(response)) {
        count = getState().Mail.mailResultsCount
        mail = response
      } else {
        count = response.headers.count
        mail = response.data
      }

      dispatch(setMailResultsById({ count, results: map(mail, 'id') }))
      dispatch(setMailResultsPageCount(count / MAIL_PAGE_LIMIT))
      dispatch(setMailLoading(false))

      return mail
    })
    .catch(err => {
      console.log('fetchMailForFolder() err', err)
      // todo show system toast err
      dispatch(setMailLoading(false))
    })
}

export const markMailRead = mailId => () =>
  Store.Mail.update(mailId, {
    read: true,
    readByReceiver: true,
    readByReceiverTime: new Date().toISOString(),
  })
    .then(console.log)
    .catch(err => {
    console.log('markMailRead() err', err)
  })

export const fetchUnreadMail = ({ bypassCache } = {}) => async (dispatch, getState) => {
  const unreadMailIds = unreadMailSelector(getState()).map(({ id }) => id)

  // WEB-CORE-7P logging
  Sentry.addBreadcrumb({
    data: {
      ...getState().Auth,
    },
  })

  // refetch current unread mail ids so record is up to date
  if (unreadMailIds.length)
    await dispatch(
      fetchMail(
        {
          where: { id: { isectNotEmpty: unreadMailIds } },
        },
        { force: bypassCache },
      ),
    )

  return dispatch(
    fetchMail(
      {
        where: { read: false, receiverId: getState().Auth?.user?.id },
      },
      { force: bypassCache },
    ),
  )
}

// reducer
const reducer = handleActions(
  {
    [setMailLoading]: (state, action = {}) => ({
      ...state,
      mailLoading: action.payload,
    }),

    [setMailPage]: (state, action = {}) => ({
      ...state,
      mailPage: action.payload,
    }),

    [setMailResultsCurrentFolderId]: (state, action = {}) => ({
      ...state,
      mailResultsCurrentFolderId: action.payload,
    }),

    [setMailResultsById]: (state, action = {}) => ({
      ...state,
      mailResultsById: action.payload.results,
      mailResultsCount: action.payload.count,
    }),

    [setMailResultsPageCount]: (state, action = {}) => ({
      ...state,
      mailResultsPageCount: Math.ceil(action.payload || 0),
    }),

    [setMailSearchOptions]: (state, action = {}) => ({
      ...state,
      mailSearchOptions: action.payload,
    }),

    [setShowMailIdModal]: (state, action = {}) => ({
      ...state,
      showMailIdModal: action.payload,
    }),

    [setUnreadMailCount]: (state, action = {}) => ({
      ...state,
      unreadMailCount: action.payload,
    }),
  },
  initialState,
)

StateStore.addReducer('Mail', reducer)

import('state/ducks/app.js').then(module => {
  module.registerRealtimeDisconnectedTask('fetchUnreadMail', () =>
    fetchUnreadMail({ bypassCache: true }),
  )
  module.registerRealtimeOnReconnectTask('fetchUnreadMail', () =>
    fetchUnreadMail({ bypassCache: true }),
  )
})
