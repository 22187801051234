import { handleActions } from 'redux-actions'
import reducerRegistry from '../reducerRegistry'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'

const reducerName = 'locationGroup'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'groups',
  reducerName,
  singular: 'group',
  storeMapperName: 'locationGroup',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const {
  groupByIdSelector,
  groupLoadingByIdSelector,
} = selectors

// actions
export const { fetchGroup, fetchGroups } = actions

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
