import React, { useCallback, useState } from 'react'
import coreApi from '../../../../../services/coreApi'
import { systemToast } from '../../../../../utils/toaster'
import PropTypes from 'prop-types'
import Store from 'services/store'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Modal from '@therms/web-common/components/Modal'
import LayoverRelative from '@therms/web-common/components/Layover/Relative'
import Loading from '@therms/web-common/components/Loading'

const PublishChangesControl = ({ hasUnpublishedScheduleShifts, weekDateToPublish }) => {
  const [loading, setLoading] = useState(false)
  const [showPublishOptionsModal, setShowPublishOptionsModal] = useState(false)

  const publishChanges = useCallback(async () => {
    setLoading(true)
    try {
      const { data: publishedScheduleShifts } = await coreApi.post('/scheduleShift/publish', {
        weekDateToPublish,
      })

      publishedScheduleShifts.forEach((scheduleShift) => Store.ScheduleShift.add(scheduleShift))

      setShowPublishOptionsModal(false)

      systemToast('Schedule published')
    } catch (e) {
      console.log('publishChanges() e', e)
      systemToast('There was a problem publishing changes', { error: true })
    } finally {
      setLoading(false)
    }
  }, [weekDateToPublish])

  if (!hasUnpublishedScheduleShifts) return null

  return (
    <div>
      <button
        className="btn btn-outline-success"
        disabled={loading}
        onClick={setShowPublishOptionsModal}
      >
        Publish Changes{' '}
        <FAIcon
          className={`ml-1 ${loading ? 'fa-spin' : ''}`}
          name={loading ? 'sync' : 'chevron-right'}
        />
      </button>

      {showPublishOptionsModal && (
        <Modal
          bodyClassName="position-relative"
          closeHandler={() => setShowPublishOptionsModal(false)}
          title="Publish Schedule Changes"
        >
          {loading && (
            <LayoverRelative>
              <Loading />
            </LayoverRelative>
          )}

          <div className="mb-3 text-center">
            Publishing schedule changes for the current week view will notify all Users that have
            new or changed shifts.
          </div>

          <button className="btn btn-block btn-success" onClick={publishChanges}>Publish</button>
        </Modal>
      )}
    </div>
  )
}

PublishChangesControl.propTypes = {
  hasUnpublishedScheduleShifts: PropTypes.bool.isRequired,
  weekDateToPublish: PropTypes.string.isRequired,
}
PublishChangesControl.defaultProps = {}

export default PublishChangesControl
