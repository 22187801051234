// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@therms/web-common/fonts/brand_logo.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:\"brand_logo\";font-style:normal;src:local(\"brand_logo\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.header{background-color:#1e262f;color:#fff;display:flex;font-size:16px;height:60px;justify-content:space-between;line-height:1;padding:3px}.header .brand{color:#bd2534;font-size:32px;font-family:\"brand_logo\";letter-spacing:.08em}.header .brand img{max-height:calc(60px - 5px)}.header .clock{font-size:46px}.header .organization-name{font-size:125%;margin-bottom:.25em}.header .region-name{font-size:125%}", ""]);
// Exports
module.exports = exports;
