import React  from 'react'
// import PropTypes from 'prop-types'

import Loading from '@components/Loading'

import './AuthLoading.scss'

const AuthLoading = () => (
  <div className="auth-loading-background flex-column-center h-100 w-100" id="auth-loading">
    <div className="w-100">
      <Loading centered lg text="Authorizing" />
    </div>
  </div>
  )

AuthLoading.propTypes = {}
AuthLoading.defaultProps = {}

export default AuthLoading
