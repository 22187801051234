import React, { useMemo } from 'react'
import FileStatus from './components/FileStatus'
import FileControls from './components/FileControls'
import Preview from './components/Preview'
import FileInfo from './components/FileInfo'
import PropTypes from 'prop-types'

import './index.scss'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

const FileCard = ({
  blob,
  contentType,
  disabled,
  isPrimaryImage,
  onDelete,
  onMakePrimaryImage,
  size,
  statusText,
  thumbnailUrl,
  uploading,
  uploadPercentage,
  url
}) => {
  const primaryImageInfo = useMemo(() => {
    if (isPrimaryImage)
      return (
        <div className="d-flex justify-content-center">
          <button className="btn btn-warning btn-sm btn-short font-xs" type="button" disabled>
            <FAIcon name="star" /> Primary Image
          </button>
        </div>
      )

    if (onMakePrimaryImage)
      return (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-outline-secondary btn-sm btn-short font-xs"
            onClick={onMakePrimaryImage}
            type="button"
          >
            <FAIcon name="star" /> Make Primary
          </button>
        </div>
      )

    return null
  }, [isPrimaryImage, onMakePrimaryImage])

  return (
    <div className="file-card position-relative">
      <div className="layer-container">
        <div className="layer-controls">
          <div className="w-100">
            <FileControls disabled={disabled} onDelete={onDelete} uploading={uploading} />
          </div>

          <div className="font-small">
            <FileInfo contentType={contentType} size={size} />
          </div>
        </div>

        <div className={`layer-preview ${uploading && 'file-card-uploading'}`}>
          <Preview blob={blob} contentType={contentType} thumbnailUrl={thumbnailUrl} url={url} />
        </div>
      </div>

      <div className="file-status-container">
        <FileStatus statusText={statusText} uploadPercentage={uploadPercentage} />
      </div>

      {primaryImageInfo}
    </div>
  )
}

FileCard.propTypes = {
  blob: PropTypes.any,
  contentType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isPrimaryImage: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onMakePrimaryImage: PropTypes.func,
  size: PropTypes.number.isRequired,
  statusText: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  uploading: PropTypes.bool,
  uploadPercentage: PropTypes.number,
  url: PropTypes.string,
}
FileCard.defaultProps = {
  blob: undefined,
  disabled: false,
  isPrimaryImage: false,
  onMakePrimaryImage: undefined,
  statusText: undefined,
  thumbnailUrl: undefined,
  uploading: false,
  uploadPercentage: undefined,
  url: undefined,
}

export default FileCard
