import React from 'react'
import { history } from 'services/browserHistory'
// import state from 'state'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import { toast } from 'react-toastify'
import { ROUTE_SCHEDULE_INDEX } from '../Routes'

const toastOptions = {
  className: 'alert alert-info',
  closeOnClick: true,
  hideProgressBar: true,
  onClick: () => history.push(ROUTE_SCHEDULE_INDEX),
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
}

let lateClockInToastId = 0
let lateClockOutToastId = 0

export const dismissLateClockInToast = () => toast.dismiss(lateClockInToastId)
export const dismissLateClockOutToast = () => toast.dismiss(lateClockOutToastId)

export const showLateForShiftClockInToast = ({ onClick, shiftId } = {}) => {
  const msg = (
    <div>
      <FAIcon name="exclamation-triangle" /> You are late for a shift clock-in
    </div>
  )

  toast.dismiss(lateClockInToastId)

  lateClockInToastId = toast(msg, {
    ...toastOptions,
    autoClose: false,
    className: 'alert alert-danger',
    onClick,
    toastId: shiftId,
  })
}

export const showLateForShiftClockOutToast = ({ onClick, shiftId } = {}) => {
  const msg = (
    <div>
      <FAIcon name="clock" /> Your shift has ended and you have not clocked out
    </div>
  )

  toast.dismiss(lateClockOutToastId)

  lateClockOutToastId = toast(msg, {
    ...toastOptions,
    autoClose: false,
    className: 'alert alert-warning',
    onClick,
    toastId: shiftId,
  })
}
