import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { fetchPendingReports, pendingReportsSelector } from 'state/ducks/report'

const ReportsPendingReviewCount = () => {
  const pendingReports = useSelector(pendingReportsSelector)
  const count = (pendingReports || []).length
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPendingReports())
  }, [dispatch])

  return (
    Boolean(count) && (
      <span>
        <span className="badge badge-success">{count >= 99 ? '99+' : count}</span>
      </span>
    )
  )
}

ReportsPendingReviewCount.propTypes = {}
ReportsPendingReviewCount.defaultProps = {}

export default ReportsPendingReviewCount
