import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import Field from 'components/Form/Field'
import { FORM_ERROR } from 'final-form'
import { formValidate } from '@therms/util-services'

import Store from 'services/store'
import { systemToast } from 'utils/toaster'

import LoadingStateText from '@components/LoadingStateText'
import RichTextEditor from 'components/RichText/RichTextEditor'
import Text from '@components/Inputs/Text'
import SelectUsers from '../../User/shared/Inputs/SelectUsers'
import Checkbox from '@therms/web-common/components/Inputs/Checkbox'
import PreviousMail from './PreviousMail'
import useOrganizationIsMultiRegion from '../../Auth/shared/hooks/useOrganizationIsMultiRegion'
import RFFFilePickerField from 'modules/Files/shared/FilePicker/RFFFilePickerField'

const Compose = ({ onComplete, state }) => {
  const [bodyMessage, setBodyMessage] = useState('')
  const [HTMLMessage, setHTMLMessage] = useState('')

  const isMultiRegion = useOrganizationIsMultiRegion()

  const autofocusEditor = useMemo(() => Boolean(state?.receiverIds || state?.receiverId), [state])

  const autofocusUser = useMemo(() => !state?.receiverIds, [state])

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = (values) => {
    if (!bodyMessage.length) return { [FORM_ERROR]: 'Mail must contain text' }

    if (!Array.isArray(values.receiverIds) && typeof values.receiverIds === 'string') {
      // eslint-disable-next-line no-param-reassign
      values.receiverIds = [values.receiverIds]
    }

    const payload = {
      ...values,
      body: bodyMessage,
      html: HTMLMessage,
      previsousMailId: state?.previsousMailId,
    }

    Store.Mail.create(payload)
      .then(() => {
        systemToast('Message Sent', { quick: true })
        onComplete()
      })
      .catch((err) => {
        console.error('err', err.errors || err)
        return {
          [FORM_ERROR]: 'There was a problem sending, try again',
        }
      })
  }

  const receiverIdsDefaultValue = state?.receiverIds
  const subjectDefaultValue = state?.subject

  return (
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            allRegionUsers
            autoFocus={autofocusUser}
            component={SelectUsers}
            defaultValue={receiverIdsDefaultValue}
            label=""
            multi
            name="receiverIds"
            showRegionName={isMultiRegion}
            validate={formValidate.required}
          />

          <div className="my-2">
            <Field
              component={Text}
              defaultValue={subjectDefaultValue}
              label=""
              name="subject"
              placeholder="Subject..."
              validate={formValidate.required}
            />
          </div>

          <div className="mb-3">
            <RichTextEditor
              autofocus={autofocusEditor}
              placeholderText="Your new mail here..."
              setBody={setBodyMessage}
              setHTML={setHTMLMessage}
            />
          </div>

          {state?.previsousMailId && (
            <div className="my-2">
              <PreviousMail collapsedOnRender previsousMailId={state?.previsousMailId} />
            </div>
          )}

          <div className="mb-2">
            <RFFFilePickerField />            
          </div>

          <Field component={Checkbox} label="Mark Message Urgent" name="urgent" type="checkbox" />

          {submitError && <div className="alert alert-danger">{submitError}</div>}

          <div className="d-flex justify-content-end w-100">
            <button className="btn btn-primary" disabled={submitting} type="submit">
              <LoadingStateText loadingText="Sending..." isLoading={submitting} text="Send" />
            </button>
          </div>
        </form>
      )}
    />
  )
}

Compose.propTypes = {
  onComplete: PropTypes.func.isRequired,
  state: PropTypes.shape({
    groupingMessageId: PropTypes.string,
    previsousMailId: PropTypes.string,
    receiverId: PropTypes.string,
    receiverIds: PropTypes.string,
    subject: PropTypes.string,
  }),
}

Compose.defaultProps = {
  state: {},
}

export default Compose
