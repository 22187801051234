import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { filter } from 'lodash-es'

import {
  fetchPendingDispatches,
  pendingDispatchesByLocationIdSelector,
  pendingDispatchesSelector,
} from 'state/ducks/dispatch'
import { getPendingServiceCallHighestPriorityBsClass } from '../utils'

function PendingDispatchCountBadges({ fetchPending, pending, showAssigned, showUnassigned }) {
  useEffect(() => {
    fetchPending()
  }, [])

  if (!pending || !pending.length) return null

  const assignedPending = filter(pending, ['fields.assigned', true])
  const assignedBsClassName = getPendingServiceCallHighestPriorityBsClass(assignedPending)
  const assignedCount = assignedPending.filter(({ fields }) => Boolean(fields.assigned)).length

  const assignedBadge = Boolean(assignedCount) && (
    <span className={`badge border border-${assignedBsClassName} text-${assignedBsClassName}`}>
      <strong>{assignedCount}</strong>
    </span>
  )

  const unassignedPending = filter(pending, ({ fields: { assigned } }) => !assigned)
  const unassignedBsClassName = getPendingServiceCallHighestPriorityBsClass(unassignedPending)
  const unassignedCount = pending.length - assignedCount

  const unassignedBadge = Boolean(unassignedCount) && (
    <>
      <span className={`animate-flash-slow animate-infinite badge badge-${unassignedBsClassName}`}>
        <strong>{unassignedCount}</strong>
      </span>
      {Boolean('unassignedCount') && ' '}
    </>
  )

  return (
    <span>
      {showUnassigned && unassignedBadge}
      {showAssigned && assignedBadge}
    </span>
  )
}

PendingDispatchCountBadges.propTypes = {
  showAssigned: PropTypes.bool,
  showUnassigned: PropTypes.bool,
  fetchPending: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  locationId: PropTypes.string,
  pending: PropTypes.array,
}

PendingDispatchCountBadges.defaultProps = {
  showAssigned: true,
  showUnassigned: true,
  locationId: undefined,
  pending: undefined,
}

const enhance = compose(
  connect(
    (state, props) => ({
      pending: props.locationId
        ? pendingDispatchesByLocationIdSelector(state, props.locationId)
        : pendingDispatchesSelector(state),
    }),
    { fetchPending: fetchPendingDispatches },
  ),
)

export default enhance(PendingDispatchCountBadges)
