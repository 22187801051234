import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import AuthLoading from '../screens/AuthLoading'

// initialize our "Auth" state
import '../state'
import { authenticate } from '../ducks'
import AccountIssue from '../screens/AccountIssue'
import { systemToast } from '../../../utils/toaster'
import { runSampleDataLoading } from '../../../utils/organiation-first-login-script'

class Auth extends Component {
  static propTypes = {
    authenticate: PropTypes.func.isRequired,
    authenticated: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    organizationMetadata: PropTypes.object,
    region: PropTypes.object,
  }

  static defaultProps = {
    organizationMetadata: undefined,
    region: undefined,
  }

  componentDidMount() {
    if (!this.props.authenticated) this.props.authenticate()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      try {
        const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone

        if (this.props.region.timezone !== browserTz) {
          systemToast('Your timezone does not match the current Region timezone', { warn: true })
        }
      } catch (e) {
        console.warn('timezone check error', e)
      }
    }

    if (prevProps?.organizationMetadata?.firstLogin) {
      runSampleDataLoading()
    }
  }

  componentWillUnmount() {}

  render() {
    if (!this.props.authenticated) return <AuthLoading />

    if (
      this.props.organizationMetadata &&
      !/active|trial-active/.test(this.props.organizationMetadata.subscriptionStatus)
    )
      return <AccountIssue />

    return this.props.children
  }
}

export { Auth }

export default connect(
  ({ Auth: { authenticated, organizationMetadata, region } }) => ({
    authenticated,
    organizationMetadata,
    region,
  }),
  { authenticate },
)(Auth)
