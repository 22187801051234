import { handleActions } from 'redux-actions'
// import { createSelector } from 'reselect'

import { NotFoundError } from '@utils/errors'
import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

import { fetchReportType } from './reportType'
import { fetchReportEntriesByReportId } from './reportEntry'
import {
  actionCreators as reportMetadataActionCreators,
  fetchReportMetadata,
} from './reportMetadata'
import { fetchReportEntryTypesForReportTypeAndEntries } from './reportEntryType'
import Store from '../../services/store'
import { createSelector } from 'reselect'

const reducerName = 'report'

const {
  actions,
  actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'reports',
  reducerName,
  singular: 'report',
  storeMapperName: 'report',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const {
  reportByIdSelector,
  reportHasFetchedByIdSelector,
  reportLoadingByIdSelector,
} = selectors

export const pendingReportsSelector = createSelector(
  state => state,
  state => state.report.reports.filter(({ statusCode }) => statusCode === 0),
)

// actions
export const { fetchReport, fetchReports } = actions

export const approveReport = ({ reportId }) => dispatch => Store.Report.updateStatus({
    notes: 'Reviewed & Approved',
    reportId,
    status: 'complete',
  }).then(({ report, reportMetadata }) => {
    dispatch(actionCreators.SET_REPORT(report))
    dispatch(reportMetadataActionCreators.SET_REPORTMETADATA(reportMetadata))
    return report
  })

export const fetchPendingReports = () => dispatch => dispatch(
    fetchReports({
      // UI can show "200+" if there are more pending status
      limit: 200,
      orderBy: [['updatedAt', 'desc']],
      where: { statusCode: { '==': 0 } },
    }),
  )

export const fetchReportWithDependencies = id => dispatch => {
  // we fetch records that are related to the report as well
  let report
  let reportType

  dispatch(actionCreators.SET_LOADING_REPORT({ id, loading: true }))

  return dispatch(fetchReport(id))
    .then(_report => {
      report = _report

      if (!_report) throw new NotFoundError(`no report found with id "${id}"`)

      return dispatch(fetchReportMetadata(report.reportMetadataId))
    })
    .then(_reportMetadata => {
      if (!_reportMetadata)
        throw new NotFoundError(`no reportMetadata found with id "${report.reportMetadataId}"`)

      return dispatch(fetchReportType(report.reportTypeId || report.builtInTypeName))
    })
    .then(_reportType => {
      if (!_reportType) throw new NotFoundError('no reportType found')
      reportType = _reportType

      return dispatch(fetchReportEntriesByReportId(report.id))
    })
    .then(reportEntries => dispatch(
        fetchReportEntryTypesForReportTypeAndEntries({
          reportType,
          reportEntries,
        }),
      ))
    .then(() => {
      dispatch(actionCreators.SET_LOADING_REPORT({ id, loading: false }))

      return report
    })
    .catch(err => {
      alert('err')
      console.log('', )
      console.log('err', err)
      console.log('', )
      if (err instanceof NotFoundError) return Promise.resolve(undefined)

      throw err
    })
}

export const fetchUserInProgressReports = userId => dispatch => {
  const _userId = userId

  return dispatch(
    fetchReports({
      limit: 150,
      orderBy: [['updatedAt', 'desc']],
      where: {
        statusCode: {
          '<': 0,
        },
        userIds: { isectNotEmpty: [_userId] },
      },
    }),
  )
}

export const rejectPendingReport = ({ reportId, rejectReason }) => dispatch => {
  if (!reportId) return Promise.reject(new Error('missing params "reportId"'))

  return Store.Report.updateStatus({ notes: rejectReason, reportId, status: 'rejected' }).then(
    ({ report, reportMetadata }) => {
      dispatch(actionCreators.SET_REPORT(report))
      dispatch(reportMetadataActionCreators.SET_REPORTMETADATA(reportMetadata))
      return report
    },
  )
}

export const updateReportStatus = ({ notes, reportId, status }) => dispatch => Store.Report.updateStatus({
    notes,
    reportId,
    status,
  }).then(({ report, reportMetadata }) => {
    dispatch(actionCreators.SET_REPORT(report))
    dispatch(reportMetadataActionCreators.SET_REPORTMETADATA(reportMetadata))
    return report
  })

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
