/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import StateStore from 'state'
import { get, map } from 'lodash-es'
import { models } from '@therms/models'

import { activeReportTypesSelector, fetchActiveReportTypes } from 'modules/Reports/ducks'
import { updateRegionModuleSettings } from '../../Auth/ducks/region'
import {
  organizationModuleSettingsSelector,
  updateOrganizationModuleSettings,
} from '../../Auth/ducks/organization'
import { selectAuth, currentUserSelector } from '../../Auth/ducks'
import {
  dispatchByIdSelector,
  fetchPendingDispatches,
  pendingDispatchesSelector,
} from 'state/ducks/dispatch'
import Store from 'services/store'
import coreApi from '../../../services/coreApi'

// import api from 'services/coreApi'
// import { createHashByKey } from '@utils'

const PAGE_LIMIT = 15

const initialState = {
  // this search state is used by more than 1 search/paginated result
  currentSearchId: undefined, // identifier for which component/module the results are for
  currentSearchQuery: {},
  dispatchSearchLoading: false,
  dispatchSearchPage: 1,
  dispatchResultsPageCount: 1,
  dispatchResultsIds: [],
  dispatchResultsCount: 0,
  dispatchSearchOptions: undefined,
}

const moduleName = 'Dispatch'

const mod = (name) => `${moduleName}/${name}`

// selectors
export const allowedDispatchTypeIdsSelector = createSelector(selectAuth, (Auth) =>
  Object.entries(
    get(Auth, 'organizationMetadata.settings.module.dispatch.allowedDispatchTypeIds', {}),
  )
    .map(([key, val]) => val && key)
    .filter((x) => Boolean(x))
    .map((name) => `builtin:${name.toLowerCase()}`),
)

export const currentUserAssignedDispatch = createSelector(
  currentUserSelector,
  pendingDispatchesSelector,
  (user, pendingDispatch) => pendingDispatch.filter(
      ({ fields: { assignmentToUserId } }) => assignmentToUserId === user.id,
    ),
)

export const serviceCallSettingsSelectors = {
  // check if any organization reportTypes allow reportEntryTypeId `dispatchservicecall`
  canClearWithReportEntry: createSelector(activeReportTypesSelector, (activeReportTypes) =>
    Boolean(
      activeReportTypes.find((reportType) =>
        reportType.allowedReportEntryTypeIds.includes(
          models.reportEntryType.fixtures.builtInsByName.dispatchservicecall.id,
        ),
      ),
    ),
  ),

  isServiceCallDispatchActive: createSelector(
    allowedDispatchTypeIdsSelector,
    (allowedDispatchTypes) =>
      allowedDispatchTypes.find((dispatchTypeId) => /servicecall/i.test(dispatchTypeId)),
  ),

  requireClearWithReportEntry: (state) =>
    get(
      organizationModuleSettingsSelector(state, 'dispatch'),
      'dispatchTypeSettings.serviceCall.requireClearedWithReportEntry',
      false,
    ),
}

// actions
export const clearDispatch = createAction(mod('clearDispatch'))
export const setCurrentFetchQuery = createAction(
  mod('setCurrentFetchQuery'),
  ({ currentSearchQuery = {}, currentSearchId = '' }) => ({ currentSearchQuery, currentSearchId }),
)
export const setDispatchLoading = createAction(mod('setDispatchLoading'))
export const setDispatchPage = createAction(mod('tasksPage'))
export const setDispatchResultsPageCount = createAction(mod('tasksResultsPageCount'))
export const setDispatchSearchOptions = createAction(mod('setDispatchSearchOptions'))
export const setDispatchResultsById = createAction(mod('setDispatchResultsById'))

export const acknowledgeServiceCallAssignment = ({ dispatchId, acknowledgedById }) => {
  const dispatchRecord = dispatchByIdSelector(StateStore.getState(), dispatchId)

  if (!dispatchRecord) return Promise.reject()

  return Store.Dispatch.update(dispatchId, {
    fields: {
      ...dispatchRecord.fields,
      acknowledged: true,
      acknowledgedTime: new Date().toISOString(),
      acknowledgedUserId: acknowledgedById,
    },
  })
}

export const assignDispatchServiceCall = ({ dispatchId, assignmentToUserId, assignmentToAdditionalUserIds }) => coreApi.post('/dispatch/assign-dispatch-to-user', { assignmentToUserId, assignmentToAdditionalUserIds, id: dispatchId })

export const fetchDispatchesForSearch = (currentSearchId, query = {}, opts = {}) => (
  dispatch,
  getState,
) => {
  if (!opts.noLoading) dispatch(setDispatchLoading(true))

  const state = getState()
  let currentSearchQuery

  if (!query && state.Dispatch.currentSearchId === currentSearchId) {
    currentSearchQuery = state.Dispatch.currentSearchQuery
  } else {
    const { page } = query || { page: 1 }

    const where = {
      ...(query.where || {
        'fields.actionNeeded': { '!=': true },
      }),
    }

    currentSearchQuery = {
      limit: query.limit || PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
      orderBy: query.orderBy || [['timestamp', 'desc']],
      where,
    }
  }

  dispatch(setCurrentFetchQuery({ currentSearchQuery, currentSearchId }))

  return Store.Dispatch.findAll(currentSearchQuery, {
    ...opts,
    // force: currentSearchQuery.offset > 1, // we have to force because of cache results filtering offset
    headers: { count: true },
    raw: true,
  })
    .then(async (response) => {
      let count
      let dispatches

      // when it's a cached response, it's an array of dispatches
      if (Array.isArray(response)) {
        count = await Store.Dispatch.count({ where: currentSearchQuery.where }).catch(() => 0)
        dispatches = response
      } else {
        count = +response.headers.count
        dispatches = response.data
      }

      dispatch(setDispatchResultsById({ count, results: map(dispatches, 'id') }))
      dispatch(setDispatchResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
      dispatch(setDispatchLoading(false))

      return dispatches
    })
    .catch((err) => {
      console.error(`Dispatches Dispatch.findAll error`, err)
      dispatch(setDispatchLoading(false))
    })
}

/**
 * Fetch the required data for clearing a Dispatch of type "service call"
 */
export const fetchClearDispatchServiceCallDependencies = () => async (dispatch, getState) => {
  await fetchActiveReportTypes()(dispatch, getState)
}

export const updateDispatchOrganizationModuleSettings = (settingsValue) =>
  updateOrganizationModuleSettings('dispatch', settingsValue)

export const updateDispatchRegionModuleSettings = (settingsValue) =>
  updateRegionModuleSettings('dispatch', settingsValue)

// reducer
const reducer = handleActions(
  {
    [clearDispatch]: () => ({
      ...initialState,
    }),

    [setCurrentFetchQuery]: (state, action = {}) => ({
      ...state,
      currentSearchQuery: action.payload.currentSearchQuery,
      currentSearchId: action.payload.currentSearchId,
    }),

    [setDispatchLoading]: (state, action = {}) => ({
      ...state,
      dispatchSearchLoading: action.payload,
    }),

    [setDispatchPage]: (state, action = {}) => ({
      ...state,
      dispatchSearchPage: action.payload,
    }),

    [setDispatchResultsById]: (state, action = {}) => ({
      ...state,
      dispatchResultsIds: action.payload.results,
      dispatchResultsCount: action.payload.count,
    }),

    [setDispatchResultsPageCount]: (state, action = {}) => ({
      ...state,
      dispatchResultsPageCount: action.payload,
    }),

    [setDispatchSearchOptions]: (state, action = {}) => ({
      ...state,
      dispatchSearchOptions: action.payload,
    }),
  },
  initialState,
)

StateStore.addReducer('Dispatch', reducer)

import('state/ducks/app.js').then((module) => {
  module.registerRealtimeDisconnectedTask('fetchPendingDispatches', () =>
    fetchPendingDispatches({ bypassCache: true }),
  )
  module.registerRealtimeOnReconnectTask('fetchPendingDispatches', () =>
    fetchPendingDispatches({ bypassCache: true }),
  )
})
