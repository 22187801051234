import React, { useContext } from 'react'
// import PropTypes from 'prop-types'

import Header from './Header/index'
import LayoutContext from '../shared/LayoutContext'
import LeftMenu from './LeftMenu/index'
import MainScene from './MainScene/index'

import './index.scss'

const Mobile = () => {
  const { showLeftMenu } = useContext(LayoutContext)

  return (
    <div className="mobile-layout-container">
      <Header />

      <LeftMenu showMenu={showLeftMenu} />

      <MainScene />
    </div>
  )
}

Mobile.propTypes = {}
Mobile.defaultProps = {}

export default Mobile
