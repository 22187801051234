import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router'
import ReactRouterPropTypes from 'react-router-prop-types'

import {
  fetchLocation,
  locationByIdSelector,
  locationLoadingByIdSelector,
} from 'state/ducks/location'
import LocationContext from '../../shared/LocationContext'

import { Container } from '@components/Card/index'
import AddressBlock from '@components/Address/AddressBlock'
import Conditional from 'modules/Auth/shared/Conditional'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Loading from '@components/Loading'
import LocationGroupNames from '../LocationGroup/Names'
import LocationId from './LocationId'
import Name from './Name'
import ServiceTypeNames from '../../../ServiceType/shared/ServiceTypes'

// eslint-disable-next-line import/no-cycle
import LocationName from '../../shared/LocationName'

import './index.scss'
import LocationActiveBoloCount from '../../shared/LocationActiveBoloCount'
import getPriorityBsClass from '@therms/web-common/components/Priority/getPriorityBsClass'
import Icon from '@therms/web-common/components/Icon'
import DateTime from '@therms/web-common/components/DateTime'
import LocationActivePassDowns from '../../shared/LocationActivePassDowns'
import Tooltip from '@therms/web-common/components/Tooltip'
import PatrolRouteName from '../../../Patrol/shared/PatrolRouteName'
import { getImageSmallUrl } from 'modules/Files/utils/imageSizeUrls'

class LocationCard extends Component {
  static propTypes = {
    fetchLocation: PropTypes.func.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    id: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object,
    showSubModuleInfo: PropTypes.bool,
  }

  static defaultProps = {
    location: undefined,
    showSubModuleInfo: true,
  }

  componentDidMount() {
    this.props.fetchLocation(this.props.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) this.props.fetchLocation(this.props.id)
  }

  renderContainerContent = () => {
    if (this.props.loading || !this.props.location) return <Loading centered />

    const { history, showSubModuleInfo } = this.props
    const {
      active,
      address,
      id,
      locationBolo,
      locationGroupIds,
      locationPassDowns,
      name,
      number,
      parentLocationId,
      patrolRouteIds,
      primaryImageUrl,
      serviceTypeIds,
      type,
    } = this.props.location

    return (
      <div className="position-relative">
        <div className="d-flex">
          <div className="flex-grow-1">
            {type === -1 && (
              <div className="sublocation-title-container">
                <i>Sub-Location of </i>{' '}
                <strong>
                  <LocationName id={parentLocationId} />
                </strong>
              </div>
            )}

            {!active && (
              <div className="pl-1 text-danger">
                <FAIcon name="ban" /> Inactive
              </div>
            )}

            <div className="d-flex flex-column justify-content-between w-100">
              <div className="pl-1 position-relative w-100">
                {number && <LocationId number={number} />}

                <strong>
                  <Name name={name} />
                </strong>

                {patrolRouteIds?.length > 0 && (
                  <Conditional settings="module.location.patrolRoute">
                    {patrolRouteIds.map((patrolRouteId) => (
                      <span
                        className="cursor mr-1 text-info"
                        onClick={() => this.props.history.push(`/locations/route/${patrolRouteId}`)}
                      >
                        <FAIcon name="route" /> <PatrolRouteName id={patrolRouteId} />
                      </span>
                    ))}
                  </Conditional>
                )}

                {address && (
                  <Tooltip title="Open Google Maps">
                    <div
                      className="cursor"
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${address?.streetAddress}%20${address?.city}%20${address?.state}%20`,
                        )
                      }
                    >
                      <AddressBlock {...address} />
                    </div>
                  </Tooltip>
                )}
              </div>

              <div className="p-1">
                <div>
                  {serviceTypeIds && (
                    <div className="d-none d-md-block">
                      <Conditional settings="module.location.serviceType">
                        <ServiceTypeNames badge serviceTypeIds={serviceTypeIds} />
                      </Conditional>
                    </div>
                  )}
                  {locationGroupIds && (
                    <div className="d-none d-md-block">
                      <Conditional settings="module.location.serviceType">
                        <LocationGroupNames badge link locationGroupIds={locationGroupIds} />
                      </Conditional>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            {primaryImageUrl ? (
              <img
                alt=""
                crossOrigin="anonymous"
                height="150"
                width="150"
                className="border-radius-right-card location-image"
                src={getImageSmallUrl(primaryImageUrl)}
              />
            ) : (
              <div className="flex-column-center min-height-150 min-width-150">
                <div className="font-400">
                  <FAIcon name="building" />
                </div>
              </div>
            )}
          </div>
        </div>

        {showSubModuleInfo && (
          <div className="d-flex w-100">
            {Boolean(locationPassDowns?.length) && (
              <div
                className="align-items-center bg-secondary cursor d-flex flex-fill justify-content-center py-2 text-dark"
                onClick={() => history.push(`/location/view/${id}/duty/passdown`)}
              >
                <LocationActivePassDowns
                  locationId={id}
                  render={({ date }) => (
                    <>
                      <Icon module="locationPassDown" />{' '}
                      <span className="font-weight-lighter mx-1">Pass Down</span>{' '}
                      <DateTime relativeTime timestamp={date} />
                    </>
                  )}
                />
              </div>
            )}

            {Boolean(locationBolo?.count) && (
              <div
                className={`align-items-center bg-${
                  getPriorityBsClass(locationBolo.priority) || 'secondary'
                } cursor d-flex flex-fill justify-content-center py-2 text-dark`}
                onClick={() => history.push(`/location/view/${id}/reporting/bolo`)}
              >
                <LocationActiveBoloCount
                  locationId={id}
                  render={({ count }) => (
                    <>
                      <FAIcon name="exclamation-triangle" />{' '}
                      <span className="font-weight-lighter mx-1">BOLO</span>{' '}
                      <strong>{count}</strong>
                    </>
                  )}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <LocationContext.Provider value={{ locationId: this.props.location.id }}>
        <div className="location-card-container">
          <Container>{this.renderContainerContent()}</Container>
        </div>
      </LocationContext.Provider>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    (state, props) => ({
      location: locationByIdSelector(state, props.id),
      loading: locationLoadingByIdSelector(state, props.id),
    }),
    { fetchLocation },
  ),
)

export default enhance(LocationCard)
