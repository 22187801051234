import React from 'react'
import PropTypes from 'prop-types'

import UserName from './UserName'

const UserNames = ({ ids, ...rest }) =>
  ids.map((id, i) => (
    <span key={id}>
      <UserName id={id} {...rest} />
      {i !== ids.length - 1 ? ', ' : ' '}
    </span>
  ))

UserNames.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
}
UserNames.defaultProps = {}

export default UserNames
