import StateStore from 'state'

import reducer from './ducks'
import './ducks/sagas'
import './ducks/enforce-user-access'

StateStore.addReducer(
  'Auth',
  reducer,
)
