import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { sleep } from '../../../../utils/promises'
import { Field } from 'react-final-form'

import FilePicker from './index'

/**
 * This component can only be used INSIDE (as a child) of a react-final-form <Form/> component.
 * It will prevent the form from being submitted until the files are done uploading by taking
 * advantage of the async field level validation logic built into react-final-form
 *
 */
const RFFFilePickerField = ({ canSelectMultiple, disabled, imageOnly, label, name }) => {
  const filesPickerRef = useRef()

  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <FilePicker
          canSelectMultiple={canSelectMultiple}
          disabled={disabled || meta.submitting}
          imageOnly={imageOnly}
          label={label}
          previouslyUploadedFileUrls={input.value || []}
          onFileUrlsChange={input.onChange}
            // in order for the 'validate' to fire the input.value passed needs to be new/different
          onUploadingStatusChange={() =>
              input.onChange(Array.isArray(input.value) ? [...input.value] : input.value)
            }
          ref={filesPickerRef}
        />
        )}
      validate={async () => {
        while (filesPickerRef.current && filesPickerRef.current.isUploading()) {
          // eslint-disable-next-line no-await-in-loop
          await sleep(100)
        }
      }}
    />
  )
}

RFFFilePickerField.propTypes = {
  canSelectMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  imageOnly: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string,
}

RFFFilePickerField.defaultProps = {
  canSelectMultiple: undefined,
  imageOnly: undefined,
  disabled: false,
  label: undefined,
  name: 'files',
}

export default RFFFilePickerField
