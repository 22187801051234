/* eslint-disable */
import { useCallback, useState } from 'react'

export default function useForceUpdate() {
  const [value, set] = useState(true) // boolean state

  const force = useCallback(() => {
    set(value => !value) // toggle the state to force render
  }, [set, value])

  return force
}
