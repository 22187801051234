/**
 * This component is a "home" for any components that always need to be mounted
 * in the web-app regardless of url or module navigation. Another way of
 * looking at this component is it's children are rendered outside of the
 * Layout module.
 *
 *
 * @note This exists because we use code-splitting and if a module needed to
 * always mount a component, it wouldn't be mounted until that module was loaded
 */
import React from 'react'

import MailMount from './Mail/Mount'
import ScheduleMount from './Schedule/Mount'

const Mount = () => (
  <>
    <MailMount />
    <ScheduleMount />
  </>
  )

Mount.propTypes = {}
Mount.defaultProps = {}

export default Mount
