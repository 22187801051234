/**
 * This file is imported at the entry point of the app to run before all other
 * modules/code.
 */

import dayjs from 'dayjs'
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat'
import calendarPlugin from 'dayjs/plugin/calendar'
import durationPlugin from 'dayjs/plugin/duration'
import isTodayPlugin from 'dayjs/plugin/isToday'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import toObjectPlugin from 'dayjs/plugin/toObject'
import weekOfYearPlugin from 'dayjs/plugin/weekOfYear'

dayjs.extend(advancedFormatPlugin)
dayjs.extend(calendarPlugin)
dayjs.extend(durationPlugin)
dayjs.extend(isTodayPlugin)
dayjs.extend(relativeTimePlugin)
dayjs.extend(toObjectPlugin)
dayjs.extend(weekOfYearPlugin)

import '../utils/browser-focus'
import '../services/browser'
