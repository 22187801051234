import React from 'react'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import { useHistory } from 'react-router'
import ScheduleModuleDropdownMenu from '../../components/ScheduleModuleDropdownMenu'
// import PropTypes from 'prop-types'
import PublishedWeekSchedule from './components/PublishedWeekSchedule/PublishedWeekSchedule'
import PublishedUserWeekSchedule from '../../shared/PublishedUserWeekSchedule'
import Conditional from '../../../Auth/shared/Conditional'
import classnames from 'classnames'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import OnDutyScheduleTimelogList from './components/OnDutyScheduleTimelogList'
import useScheduleTimelogs from '../../hooks/useScheduleTimelogs'

const ScheduleIndexScreen = () => {
  const [tab, setTab] = useLocalStorage('ScheduleIndexScreen_tab', 'user')
  const history = useHistory()

  const { total: onDutyTotal } = useScheduleTimelogs(
    {
      limit: 0,
      where: { clockOutTimestamp: null },
    },
    { includeTotal: true },
  )

  return (
    <div>
      <ScheduleModuleDropdownMenu />

      <Conditional permission="schedule.canCreateShifts">
        <div className="d-flex">
          <button className="btn btn-primary" onClick={() => history.push('/schedule/build')}>
            <FAIcon name="tools" /> Build Schedule
          </button>

          <div className="mx-1" />

          <button className="btn btn-primary" onClick={() => history.push('/schedule/timelogs')}>
            <FAIcon name="user-clock" /> Timelogs
          </button>
        </div>
      </Conditional>

      <div className="mt-3">
        <ul className="nav nav-pills">
          <div
            className={classnames('cursor nav-link', { active: tab === 'user' })}
            onClick={() => setTab('user')}
          >
            My Shifts
          </div>

          <Conditional permission="schedule.viewOtherUsersSchedule">
            <div
              className={classnames('cursor ml-1 nav-link', { active: tab === 'all' })}
              onClick={() => setTab('all')}
            >
              All Shifts
            </div>
          </Conditional>

          <div
            className={classnames('cursor ml-1 nav-link', { active: tab === 'onduty' })}
            onClick={() => setTab('onduty')}
          >
            On Duty {onDutyTotal > 0 && <span className="badge badge-info">{onDutyTotal}</span>}
          </div>
        </ul>

        <div className="mt-3">
          {tab === 'all' && <PublishedWeekSchedule />}
          {tab === 'user' && <PublishedUserWeekSchedule />}
          {tab === 'onduty' && <OnDutyScheduleTimelogList />}
        </div>
      </div>
    </div>
  )
}

ScheduleIndexScreen.propTypes = {}
ScheduleIndexScreen.defaultProps = {}

export default ScheduleIndexScreen
