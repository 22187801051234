import React from 'react'
// import PropTypes from 'prop-types'
import Conditional from '../../../Auth/shared/Conditional'
import ScheduleTimeLogWithShiftTable from '../../shared/ScheduleTimeLogWithShiftTable'

const ScheduleTimelogsScreen = () => (
    <Conditional permission="schedule.canCreateShifts">
      <ScheduleTimeLogWithShiftTable />
    </Conditional>
  )

ScheduleTimelogsScreen.propTypes = {}
ScheduleTimelogsScreen.defaultProps = {}

export default ScheduleTimelogsScreen
