// duck for current region
import { createSelector } from 'reselect'
import get from 'lodash-es/get'

import StateStore from 'state'
import Store from 'services/store'
import { types } from './index'

// selectors
export const selectModuleSettings = (state, moduleName) =>
  get(state, `Auth.organizationMetadata.settings.module.${moduleName}`, undefined)

export const selectOrgModules = (state) => state.Auth.organizationMetadata.modules

export const organizationModuleSettingsSelector = createSelector(
  selectModuleSettings,
  settings => settings,
)

// actions
export const fetchOrganization = () => {
  const organizationId = StateStore.getState().Auth.organization.id

  return Promise.all([
    Store.Organization.find(organizationId).then(organization => {
      StateStore.dispatch({ type: types.SET_ORGANIZATION, payload: organization })
      return organization
    }),
    Store.OrganizationMetadata.findOne({ organizationId }).then(metadata => {
      StateStore.dispatch({ type: types.SET_ORGANIZATION_METADATA, payload: metadata })
      return metadata
    }),
  ])
}

export const updateOrganizationModuleSettings = (moduleName, settingsValue) => {
  const {
    Auth: { organizationMetadata },
  } = StateStore.getState()

  const currentSettings = organizationMetadata.settings

  return Store.OrganizationMetadata.update(organizationMetadata.id, {
    settings: {
      ...currentSettings,
      module: {
        ...currentSettings.module,
        [moduleName]: settingsValue,
      },
    },
  })
}
