import React from 'react'
import FAIcon from '@components/Icon/FAIcon'

import './BuildScheduleMobileStyles.scss'

const BuildScheduleMobile = () => (
  <div>
    <div className="position-relative d-flex justify-content-center mt-5">
      <FAIcon name="mobile" className="mobile-icon" />
      <FAIcon name="ban" className="ban-icon" />
    </div>

    <p className="font-lg font-weight-bold mt-3 px-5 text-secondary text-center">
      Feature Unavailable on Mobile Devices
    </p>

    <p className="mt-4 px-3 text-secondary">
      Please logging using a desktop computer to use the Schedule Builder.
    </p>
  </div>
)

export default BuildScheduleMobile
