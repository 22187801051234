import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectShowMailIdModal, setShowMailIdModal } from './ducks'
import QuickMailModal from './screens/QuickMailModal'

const MailMount = () => {
  const dispatch = useDispatch()
  const showMailIdModal = useSelector(selectShowMailIdModal)

  const handleClose = useCallback(() => {
    dispatch(setShowMailIdModal())
  }, [dispatch])

  if (!showMailIdModal) return null

  return <QuickMailModal closeHandler={handleClose} mailId={showMailIdModal} />
}

MailMount.propTypes = {}
MailMount.defaultProps = {}

export default MailMount
