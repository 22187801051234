
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import humanSize from 'human-size'
import ellipsize from 'ellipsize'

const FileInfo = ({ contentType, size }) => {
  const formattedSize = useMemo(() => humanSize(size), [size])
  const formattedType = useMemo(() => {
    try {
      return ellipsize(contentType, 10)
    } catch (e) {
      console.error('unable to get contentType', contentType, e)

      return 'unknown file'
    }
  }, [contentType])

  return (
    <div className="bg-dark opacity-75 w-100">
      <span className="text-info">{formattedSize}</span> <span className="font-xs">{formattedType}</span>
    </div>
  )
}

FileInfo.propTypes = {
  contentType: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
}
FileInfo.defaultProps = {}

export default FileInfo
