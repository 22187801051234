import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { models, utils } from '@therms/models'
import { isMobile } from '@utils/viewport'

import { isDefined } from '@utils'

class Conditional extends PureComponent {
  accessLevel = (accessLevel) => models.user.utils.isAccessLevelGte(accessLevel, {
      userMetadata: this.props.userMetadata,
    })

  isAdmin = () => this.props.userMetadata.admin

  isCurrentUser = (userToCompare) => this.props.user.id === (userToCompare?.id || userToCompare)

  // we have this method on the class to accomodate the "or" prop
  isTrue = (predicate) => Boolean(predicate)

  module = (module) => models.organization.utils.organizationModuleValue(module, {
      organizationMetadata: this.props.organizationMetadata,
    })

  /**
   * If user has all permissions return true
   * @param permissions {String|Array<String>} permission get path
   * @return {boolean}
   */
  permission = (permissions) => models.user.utils.hasPermission(this.props.userMetadata, permissions)

  /**
   * If user has at least 1 permission will return true
   * @param permissions {Array<String>} a list of permission get paths
   * @return {boolean}
   */
  permissionOr = (permissions) => {
    for (const i in permissions) // eslint-disable-line
      if (models.user.utils.hasPermission(this.props.userMetadata, permissions[i])) return true

    return false
  }

  /**
   * Check organization settings. The prop must be an object where the key
   * is used as the _.get path to retreive the organization setting value.
   * The value of the key can be a boolean, string, or function to be invoked
   * with the organization setting value (function must return boolean)
   *
   * @param organizationSettings {object} { ['key']: boolean|string|function }
   * @returns {boolean}
   */
  organizationSettings = (organizationSettings) => models.organization.utils.organizationSettings(organizationSettings, {
      organizationMetadata: this.props.organizationMetadata,
    })

  passIfCurrentUser = (userId) => this.props.user.id === userId

  /* Hiearchy settings check:
      userMetadata.settings[path] ->
      regionMetadata.settings[path] ->
      organizationMetadata.settings[path]
   */
  settings = (settingsPath) => {
    const { organizationMetadata, regionMetadata, userMetadata } = this.props
    return utils.getSettings(settingsPath, {
      organizationMetadata,
      regionMetadata,
      userMetadata,
    })
  }

  /**
   * Check user settings. The prop must be an object where the key
   * is used as the _.get path to retreive the user setting value.
   * The value of the key can be a boolean, string, or function to be invoked
   * with the user setting value (function must return boolean)
   *
   * @param userSettings {object} { ['key']: boolean|string|function }
   * @returns {boolean}
   */
  userSettings = (userSettings) => models.user.utils.userSettings(userSettings, {
      userMetadata: this.props.userMetadata,
    })

  /**
   * Check userAccount settings. The prop must be an object where the key
   * is used as the _.get path to retreive the userAccount setting value.
   * The value of the key can be a boolean, string, or function to be invoked
   * with the userAccount setting value (function must return boolean)
   *
   * @param userAccountSettings {object} { ['key']: boolean|string|function }
   * @returns {boolean}
   */
  userAccountSettings = (userAccountSettings) => models.user.utils.userAccountSettings(userAccountSettings, {
      userAccount: this.props.userAccount,
    })

  // the prop "or" can contain rules ie: or={{ isAdmin: true, isCurrentUser: User }}
  or = (or) => {
    if (typeof or !== 'object') return false

    const rules = Object.entries(or)

    if (!rules.length) return true

    for (const [ruleName, ruleValue] of rules) {
      // eslint-disable-line
      this[ruleName](ruleValue)
      if (this[ruleName] && this[ruleName](ruleValue)) {
        return true
      }
    }

    return false
  }

  renderFailedCondition = () => {
    if (this.props.renderOnFail) return this.props.renderOnFail()

    return null
  }

  renderPassedConditional = () => {
    const { children, render } = this.props

    if (render) return render()
    if (Boolean(children)) return children

    return null
  }

  render() {
    const {
      accessLevel,
      isAdmin,
      isCurrentUser,
      isDesktop,
      isFalse,
      isTrue,
      module,
      or,
      organizationSettings,
      passIfCurrentUser,
      permission,
      permissionOr,
      settings,
      userSettings,
      userAccountSettings,
    } = this.props

    // first in the line to check
    if (passIfCurrentUser && this.passIfCurrentUser(passIfCurrentUser))
      return this.renderPassedConditional()

    if (isDesktop && isMobile()) {
      return this.renderFailedCondition('isMobile', false)
    }

    if (this.props.isMobile && !isMobile()) {
      return this.renderFailedCondition('isMobile', false)
    }

    if (or && !this.or(or)) return this.renderFailedCondition('or', or)
    if (accessLevel && !this.accessLevel(accessLevel))
      return this.renderFailedCondition('accessLevel', accessLevel)
    if (isAdmin && !this.isAdmin()) return this.renderFailedCondition('isAdmin', isAdmin)
    if (isCurrentUser && !this.isCurrentUser(isCurrentUser))
      return this.renderFailedCondition('isCurrentUser', isCurrentUser)
    if (module && !this.module(module)) return this.renderFailedCondition('module', module)
    if (organizationSettings && !this.organizationSettings(organizationSettings))
      return this.renderFailedCondition('organizationSettings', organizationSettings)
    if (permission && !this.permission(permission))
      return this.renderFailedCondition('permission', permission)
    if (permissionOr && !this.permissionOr(permissionOr))
      return this.renderFailedCondition('permissionOr', permissionOr)
    if (settings && !this.settings(settings))
      return this.renderFailedCondition('settings', settings)
    if (userSettings && !this.userSettings(userSettings))
      return this.renderFailedCondition('userSettings', userSettings)
    if (userAccountSettings && !this.userAccountSettings(userAccountSettings))
      return this.renderFailedCondition('userAccountSettings', userAccountSettings)
    if (isDefined(isFalse) && isFalse) return this.renderFailedCondition('isFalse', isFalse)
    if (isDefined(isTrue) && !isTrue) return this.renderFailedCondition('isTrue', isTrue)

    return this.renderPassedConditional()
  }
}

Conditional.propTypes = {
  // Redux State Props:
  organizationMetadata: PropTypes.object.isRequired,
  regionMetadata: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userAccount: PropTypes.object.isRequired,
  userMetadata: PropTypes.object.isRequired,

  // Validation/Rule Props:
  accessLevel: PropTypes.number,
  children: PropTypes.any,
  isAdmin: PropTypes.bool,
  isCurrentUser: PropTypes.string,
  isDesktop: PropTypes.bool,
  isFalse: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTrue: PropTypes.bool,
  module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // organization module name, checks if enabled or value (w/ object)
  or: PropTypes.object,
  organizationSettings: PropTypes.object,
  passIfCurrentUser: PropTypes.string,
  render: PropTypes.func,
  renderOnFail: PropTypes.func,
  // renderOnFail: PropTypes.func,
  permission: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  permissionOr: PropTypes.arrayOf(PropTypes.string),
  settings: PropTypes.string,
  userSettings: PropTypes.object,
  userAccountSettings: PropTypes.object,
}

Conditional.defaultProps = {
  accessLevel: undefined,
  children: undefined,
  isAdmin: undefined,
  isCurrentUser: undefined,
  isDesktop: undefined,
  isFalse: undefined,
  isMobile: undefined,
  isTrue: undefined,
  module: undefined,
  or: undefined,
  organizationSettings: undefined,
  passIfCurrentUser: undefined,
  render: undefined,
  renderOnFail: undefined,
  permission: undefined,
  permissionOr: undefined,
  settings: undefined,
  userSettings: undefined,
  userAccountSettings: undefined,
}

const authSelector = createSelector(
  (state) => state.Auth,
  ({ organizationMetadata, regionMetadata, user, userAccount, userMetadata }) => ({
    organizationMetadata,
    regionMetadata,
    user,
    userAccount,
    userMetadata,
  }),
)

export default connect((state) => authSelector(state))(Conditional)
