import React from 'react'

import ErrorBoundaryNoDisplay from '../../../../../components/ErrorBoundary/ErrorBoundaryNoDisplay'
import MainNavButton from 'modules/Layout/shared/MainNavButton'
import UnreadMailCountBadge from '../../../components/UnreadMailCountBadge'

const MailMainNavButton = () => (
  <MainNavButton iconClass="fa fa-envelope fa-2x" to="/mail">
    <div className="d-flex justify-content-between">
      Mail{' '}
      <ErrorBoundaryNoDisplay>
        <UnreadMailCountBadge />
      </ErrorBoundaryNoDisplay>
    </div>
  </MainNavButton>
)
export default MailMainNavButton
