import React from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.PureComponent {
  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/prop-types
    const { location } = this.props

    // eslint-disable-next-line react/prop-types
    if (location !== prevProps.location) {
      try {
        document.querySelector('.main-scene').scrollTo(0, 0)
      } catch (e) {
        console.error('unable to perform scroll on .main-scene', e)
      }
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    return <>{this.props.children}</>
  }
}

export default withRouter(ScrollToTop)
