import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import { downloadScheduleShifts } from '../util'
import Conditional from '../../Auth/shared/Conditional'
import { systemToast } from '../../../utils/toaster'

const WeekDate = ({ canDownload, downloadUserId, initialDate, onDateChange }) => {
  const [date, setDate] = useState(initialDate || new Date().toISOString())

  const changeWeek = useMemo(() => ({
    decr: () => setDate(dayjs(date).subtract(1, 'week').toISOString()),
    incr: () => setDate(dayjs(date).add(1, 'week').toISOString()),
  }))

  const { endOfWeek, startOfWeek } = useMemo(() => {
    const d = dayjs(date)

    return {
      endOfWeek: d.endOf('week').toISOString(),
      startOfWeek: d.startOf('week').toISOString(),
    }
  }, [date])

  const weekLabel = useMemo(
    () =>
      `${dayjs(startOfWeek).format(window.dateFormat)} - ${dayjs(endOfWeek).format(
        window.dateFormat,
      )}`,
    [startOfWeek],
  )

  const handleDownload = useCallback(() => {
    const body = { fromDate: startOfWeek, toDate: endOfWeek }

    if (downloadUserId) {
      body.userId = downloadUserId
    }

    systemToast('Downloading...')

    downloadScheduleShifts(body)
  }, [downloadUserId, endOfWeek, startOfWeek])

  useEffect(() => {
    onDateChange(date, {
      endOfWeek,
      startOfWeek,
    })
  }, [date])

  return (
    <>
      {onDateChange && (
        <button className="btn mr-1" onClick={changeWeek.decr}>
          <FAIcon name="caret-left" />
        </button>
      )}
      {weekLabel}
      {onDateChange && (
        <button className="btn ml-1" onClick={changeWeek.incr}>
          <FAIcon name="caret-right" />
        </button>
      )}

      {canDownload && (
        <button className="btn btn-short" onClick={handleDownload}>
          <FAIcon name="download" /> <Conditional isDesktop>Download</Conditional>
        </button>
      )}
    </>
  )
}

WeekDate.propTypes = {
  canDownload: PropTypes.bool,
  downloadUserId: PropTypes.string,
  initialDate: PropTypes.string,
  onDateChange: PropTypes.func,
}
WeekDate.defaultProps = {
  canDownload: undefined,
  downloadUserId: undefined,
  initialDate: undefined,
  onDateChange: undefined,
}

export default WeekDate
