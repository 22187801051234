import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { attentionReportsSelector, fetchUserInProgressReports } from 'modules/Reports/ducks'

const ReportsAttentionCount = () => {
  const attReports = useSelector(attentionReportsSelector)
  const count = (attReports || []).length
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserInProgressReports())
  }, [])

  return Boolean(count) && <div className="badge badge-warning">{count >= 50 ? '50+' : count}</div>
}

ReportsAttentionCount.propTypes = {}
ReportsAttentionCount.defaultProps = {}

export default ReportsAttentionCount
