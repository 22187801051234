import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import useOrganization from '../../Auth/shared/hooks/useOrganization'
import useScheduleShift from '../hooks/useScheduleShift'
import useScheduleTimelogs from '../hooks/useScheduleTimelogs'
import { useScheduleShiftAssignmentDetails } from '../hooks/useScheduleShiftAssignmentDetails'

import DateTime from '@therms/web-common/components/DateTime'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Modal from '@therms/web-common/components/Modal'
import PositionName from '../components/Position/PositionName'
import ShiftDetailsWithControls from './ShiftDetailsWithControls'
import UserName from '../../User/shared/UserName'

import { humanizeMinutes } from '../../../utils/date'
import { maxBy, minBy } from 'lodash-es'

const ShiftListItem = ({
  onClick,
  compactView,
  scheduleShiftId,
  showShiftDetailsOnClick,
  showTimelogDetails,
}) => {
  const [showShiftDetailsModal, setShowShiftDetailsModal] = useState(false)

  const { organizationMetadata } = useOrganization()
  const { data: scheduleShift } = useScheduleShift(scheduleShiftId)
  const { data: scheduleTimelogs } = useScheduleTimelogs(
    showTimelogDetails && { where: { scheduleShiftId } },
  )
  const moduleAssignmentDetails = useScheduleShiftAssignmentDetails(scheduleShift)

  const divider = useMemo(() => (
    <span className="mx-1 text-muted" key="divider">
      /
    </span>
  ))

  const endTime = useMemo(
    () =>
      scheduleShift &&
      dayjs(scheduleShift.dateEnd).format(organizationMetadata?.settings?.timeFormat),
    [scheduleShift?.dateEnd, organizationMetadata?.settings?.timeFormat],
  )

  const startTime = useMemo(
    () =>
      scheduleShift &&
      dayjs(scheduleShift.dateStart).format(organizationMetadata?.settings?.timeFormat),
    [scheduleShift?.dateStart, organizationMetadata?.settings?.timeFormat],
  )

  const shiftLength = useMemo(() => {
    if (!scheduleShift) return ''

    const duration = dayjs.duration(dayjs(scheduleShift.dateEnd).diff(scheduleShift.dateStart))

    return humanizeMinutes(duration.asMinutes())
  }, [scheduleShift])

  const handleClick = useCallback(() => {
    if (onClick) onClick(scheduleShiftId)
    else if (showShiftDetailsOnClick) setShowShiftDetailsModal(true)
  }, [onClick, scheduleShift, showShiftDetailsOnClick])

  if (!scheduleShift) return null

  const shiftTime = (
    <>
      <div className="font-small font-weight-light opacity-75">
        <DateTime time={false} timestamp={scheduleShift.dateStart} />
      </div>

      <div>
        <FAIcon className="text-info" name="clock" />
        <span className="font-weight-bold mx-1 text-info">
          {startTime} - {endTime}
        </span>
        <span className="font-small font-weight-lighter text-muted">({shiftLength})</span>
      </div>
    </>
  )

  const details = useMemo(
    () => (
      <div className="d-flex justify-content-between">
        {[
          moduleAssignmentDetails?.name,
          scheduleShift.schedulePositionId && (
            <PositionName id={scheduleShift.schedulePositionId} key={1} />
          ),
          scheduleShift?.userId && (
            <UserName id={scheduleShift.userId} key={2} noCard noLink noOnlineStatus />
          ),
        ]
          .filter((x) => !!x)
          .reduce((prev, curr, i) => {
            if (i === 0) return curr

            return [prev, divider, curr]
          }, null)}

        {scheduleShift.shiftNotes && (
          <FAIcon className="font-small ml-1 text-info" name="sticky-note" />
        )}
      </div>
    ),
    [moduleAssignmentDetails, scheduleShift],
  )

  const timelog = useMemo(() => {
    if (!scheduleTimelogs.length) return null

    // the record that is currently "on shift" and not yet clocked out
    const currentScheduleTimelog = scheduleTimelogs.filter(
      ({ clockOutTimestamp }) => !clockOutTimestamp,
    )[0]

    if (currentScheduleTimelog)
      return (
        <span className="badge badge-outline-info font-weight-light">
          On-Duty <DateTime date={false} timestamp={currentScheduleTimelog.clockInTimestamp} />
        </span>
      )

    const clockIn = minBy(scheduleTimelogs, 'clockInTimestamp').clockInTimestamp
    const clockOut = maxBy(scheduleTimelogs, 'clockOutTimestamp').clockOutTimestamp

    return (
      <span className="font-small font-weight-light text-info">
        Timelog <DateTime date={false} timestamp={clockIn} /> -{' '}
        <DateTime date={false} timestamp={clockOut} />{' '}
      </span>
    )
  }, [scheduleTimelogs])

  return (
    <div
      className={`card d-flex p-1 ${onClick || showShiftDetailsOnClick ? 'cursor' : ''}`}
      onClick={handleClick}
    >
      {compactView ? (
        <>
          <div className="d-flex flex-wrap justify-content-between">
            <div>{shiftTime}</div>
            <div>{details}</div>
          </div>

          <div>{timelog}</div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div>
              <div>{shiftTime}</div>

              <div className="d-flex flex-wrap">{details}</div>
            </div>

            <div className="m-2">
              <button className="btn btn-outline-secondary h-100">
                <FAIcon name="info-circle" /> Shift Details
              </button>
            </div>
          </div>

          <div>{timelog}</div>
        </>
      )}

      {showShiftDetailsModal && (
        <Modal closeHandler={() => setShowShiftDetailsModal(false)} title="Shift">
          <ShiftDetailsWithControls scheduleShiftId={scheduleShiftId} />
        </Modal>
      )}
    </div>
  )
}

ShiftListItem.propTypes = {
  compactView: PropTypes.bool,
  onClick: PropTypes.func,
  scheduleShiftId: PropTypes.string.isRequired,
  showShiftDetailsOnClick: PropTypes.bool,
  showTimelogDetails: PropTypes.bool,
}
ShiftListItem.defaultProps = {
  compactView: false,
  onClick: undefined,
  showShiftDetailsOnClick: true,
  showTimelogDetails: true,
}

export default ShiftListItem
