export const getImageThumbnailUrl = (url) => {
  if (!url) throw new Error('getImageThumbnailUrl() failed, an url is required')

  return `${url}.thumbnail`
}

export const getImageSmallUrl = (url) => {
  if (!url) throw new Error('getImageSmallUrl() failed, an url is required')

  return `${url}.small`
}
