import { sagaMiddleware } from './middleware'

const _registeredSagas = {}

export const addSaga = function addSaga(name, iterator) {
  // stop dev env hot-reloading from registering multiple times :-(
  if (_registeredSagas[name]) return

  _registeredSagas[name] = iterator

  sagaMiddleware.run(iterator)
}
