import coreApi from '../../../services/coreApi'
import store from 'services/store'

export const scheduleTimelogApi = {
  clockIn: async ({ scheduleShiftId }) => {
    const { data } = await coreApi.post('/scheduleTimelog/clockin', { scheduleShiftId })
    store.ScheduleTimelog.add(data)
    store.ScheduleShift.find(data?.scheduleShiftId, { force: true })
  },

  clockOut: async ({ scheduleTimelogId }) => {
    const { data } = await coreApi.post('/scheduleTimelog/clockout', { scheduleTimelogId })
    store.ScheduleTimelog.add(data)
    store.ScheduleShift.find(data?.scheduleShiftId, { force: true })
  },

  endBreak: async ({ scheduleTimelogId }) => {
    const { data } = await coreApi.post('/scheduleTimelog/breakend', { scheduleTimelogId })
    store.ScheduleTimelog.add(data)
  },

  /**
   * The returned array is a list of ScheduleTimelog records with the .scheduleShift prop populated
   * @param fromDate
   * @param locationId
   * @param toDate
   * @param userId
   * @returns {Promise<ScheduleTimelog[]>}
   */
  getTimeLogWithShifts: async ({ fromDate, locationIds, toDate, userIds }) => {
    const { data } = await coreApi.post('/scheduleTimelog/get-timelog-with-shifts', {
      fromDate,
      locationIds,
      toDate,
      userIds,
    })

    return data
  },

  startBreak: async ({ scheduleTimelogId }) => {
    const { data } = await coreApi.post('/scheduleTimelog/breakstart', { scheduleTimelogId })
    store.ScheduleTimelog.add(data)
  },
}
