import dayjs from 'dayjs'

const MONTH_MIN = (365 / 12) * 24 * 60
const WEEK_MIN = 7 * 24 * 60

export function getNumberWithOrdinal(n) {
  // eslint-disable-next-line no-restricted-globals
  if (!n || isNaN(n)) return ''

  const s = ['th', 'st', 'nd', 'rd']
  const v = +n % 100
  return +n + (s[(v - 20) % 10] || s[v] || s[0])
}

export function humanizeMinutes(_min) {
  let min = Math.abs(_min)
  let months = ''
  let weeks = ''
  let days = ''
  let hrs = ''
  let minutes = ''

  if (min >= MONTH_MIN) {
    months = Math.floor(min / MONTH_MIN)
    min %= MONTH_MIN

    if (months > 1) months = `${months} months`
    else months = `${months} month`
  }

  if (min >= WEEK_MIN) {
    weeks = Math.floor(min / WEEK_MIN)
    min %= WEEK_MIN

    if (weeks > 1) weeks = `${weeks} weeks`
    else weeks = `${weeks} week`
  }

  if (min >= 1440) {
    days = Math.floor(min / 1440)
    min %= 1440

    if (days > 1) days = `${days} days`
    else days = `${days} day`
  }

  if (min >= 60) {
    hrs = Math.floor(min / 60)
    min %= 60

    if (hrs > 1) hrs = `${hrs} hrs`
    else hrs = `${hrs} hr`
  }

  if (min >= 1) {
    minutes = Math.floor(min)

    if (minutes > 1) minutes = `${minutes} mins`
    else minutes = `${minutes} min`
  } else if (!months && !weeks && !days && !hrs) minutes = '< 1 min'

  // conditionally display incrementals
  if (months) {
    return `${months} ${weeks}`.trim()
  }

  if (weeks) {
    return `${weeks} ${days}`.trim()
  }

  return `${days} ${hrs} ${minutes}`.trim()
}

export const humanizeMillis = (millis) => humanizeMinutes(millis / 1000 / 60)

export const roundToNearestMin = (date) => {
  const coeff = 1000 * 60 * 1
  const d = new Date(date || new Date())
  return new Date(Math.round(d.getTime() / coeff) * coeff).toISOString()
}

export const setLocaleFirstDayOfWeek = (day = 0) => {
  dayjs.Ls.en.weekStart = day
}
