import React, { useEffect, useMemo, useState } from 'react'
import WeekDate from '../../../shared/WeekDate'
import PropTypes from 'prop-types'
import Loading from '@components/Loading'
import { DateTimeDiff } from '../../../../../components/DateTimeDiff'
import DateTime from '@therms/web-common/components/Inputs/DateTime'
import SelectUsers from '../../../../User/shared/Inputs/SelectUsers'
import ShiftListItem from '../../../shared/ShiftListItem'
import useScheduleShifts from '../../../hooks/useScheduleShifts'
import dayjs from 'dayjs'
import ShiftDetails from '../../Shift/ShiftDetails'
import useScheduleShift from '../../../hooks/useScheduleShift'
import Store from '../../../../../services/store'
import DeleteCancelSave from '@therms/web-common/components/Buttons/DeleteCancelSave'
import { systemToast } from '../../../../../utils/toaster'

const AddTimelogForm = ({ onComplete }) => {
  const [loading, setLoading] = useState(false)
  const [scheduleShiftId, setScheduleShiftId] = useState()
  const [date, setDate] = useState(new Date().toISOString())
  const [clockOutTime, setClockOutTime] = useState()
  const [clockInTime, setClockInTime] = useState()
  const [user, setUser] = useState()

  const { endOfWeek, startOfWeek } = useMemo(() => {
    const d = dayjs(date)

    return {
      endOfWeek: d.endOf('week').toISOString(),
      startOfWeek: d.startOf('week').toISOString(),
    }
  }, [date])

  const { data: shifts, validating: loadingShifts } = useScheduleShifts({
    where: {
      dateEnd: { '>=': startOfWeek },
      dateStart: { '<=': endOfWeek },
      published: true,
    },
  })

  const { data: scheduleShift, validating: scheduleShiftLoading } =
    useScheduleShift(scheduleShiftId)

  useEffect(() => {
    setUser(scheduleShift?.userId)
    setClockInTime(scheduleShift?.dateStart)
    setClockOutTime(scheduleShift?.dateEnd)
  }, [scheduleShift])

  const save = async () => {
    try {
      setLoading(true)
      await Store.scheduleTimelog.create({
        clockInTimestamp: clockInTime,
        clockOutTimestamp: clockOutTime,
        comment: 'Timelog entry added manually by an authorized User',
        userId: user,
        metadata: {
          assignmentModuleId: scheduleShift.assignmentModuleId,
          assignmentModuleName: scheduleShift.assignmentModuleName,
          schedulePositionId: scheduleShift.schedulePositionId,
        },
        scheduleShiftId,
      })

      systemToast(`Timelog created`, { quick: true })

      onComplete()
    } catch (e) {
      systemToast(`Error creating timelog, try again`, { error: true })
    } finally {
      setLoading(false)
    }
  }

  if (!scheduleShiftId) {
    return (
      <div>
        <div className="flex-column-center">
          <strong>Pick Schedule Shift</strong>
        </div>

        <div className="flex-column-center">
          <div>
            <WeekDate onDateChange={setDate} />
          </div>
        </div>

        {loadingShifts ? (
          <div className="flex-column-center">
            <Loading />
          </div>
        ) : (
          <div className="max-height-300 mt-1 overflow-auto">
            {shifts.map((shift) => (
              <div onClick={() => setScheduleShiftId(shift.id)}>
                <ShiftListItem compactView scheduleShiftId={shift.id} />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  if (scheduleShiftLoading) {
    return <Loading />
  }

  return (
    <div>
      <div className="flex-column-center">
        <strong>Adding Timelog for Shift:</strong>
      </div>

      <div className="bg-info-opacity-25 card p-1">
        <ShiftDetails id={scheduleShiftId} />
      </div>

      <hr />

      <SelectUsers
        label="User Timelog"
        multi={false}
        onChange={(e) => setUser(e.target.value)}
        value={user}
      />

      <div className="text-center">
        Total: <DateTimeDiff time1={clockInTime} time2={clockOutTime} />
      </div>

      <DateTime
        label="Clock In"
        noDefaultValue={false}
        onChange={setClockInTime}
        value={clockInTime}
        viewMode="time"
      />

      <DateTime
        component={DateTime}
        label="Clock Out"
        noDefaultValue={false}
        onChange={setClockOutTime}
        value={clockOutTime}
        viewMode="time"
      />

      <DeleteCancelSave disabled={loading} isSaving={loading} onSave={save} showDelete={false} />
    </div>
  )
}

AddTimelogForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
}

export { AddTimelogForm }
