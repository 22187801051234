import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import coreApi from '../../services/coreApi'
import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

const reducerName = 'user'

const {
  actions,
  actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'users',
  reducerName,
  singular: 'user',
  storeMapperName: 'user',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const { userByIdSelector, userHasFetchedByIdSelector, userLoadingByIdSelector } = selectors
export const selectUserById = (state, id) => state.user.usersById[id]
export const selectAllUsers = (state) => state.user.users
export const selectUsers = (state, regionId) => {
  if (regionId) return state.user.users.filter(({ regionIds }) => regionIds.includes(regionId))

  return state.user.users
}

export const usersSelector = createSelector(selectUsers, (users) =>
  users.filter(({ active }) => Boolean(active)),
)

export const activeUsersAllRegionsSelector = createSelector(selectAllUsers, (allUsers) =>
  allUsers.filter(({ active }) => Boolean(active)),
)

export const inactiveUsersSelector = createSelector(selectUsers, (activeUsersList) =>
  activeUsersList.filter(({ active }) => Boolean(!active)),
)

// actions
export const { SET_USERS } = actionCreators
export const { fetchUser } = actions

export const fetchUsers =
  ({ active = true, bypassCache, regionId } = {}) =>
  (dispatch, getState) => {
    if (!regionId) regionId = getState().Auth.region.id // eslint-disable-line

    const regionIds = { isectNotEmpty: [regionId] }

    return dispatch(
      actions.fetchUsers(
        {
          limit: 1000,
          where: { active, regionIds },
        },
        {
          force: bypassCache,
        },
      ),
    )
  }

export const updateEmail = ({ userAccountId, userId, email }) =>
  coreApi.put('/userAccount/updateEmail', {
    email,
    userAccountId,
    userId,
  })

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
