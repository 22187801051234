/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import { map } from 'lodash-es'

import { selectCurrentRegion, updateRegionModuleSettings } from '../../Auth/ducks/region'
import { fetchCurrentRegionUserRecent, currentUserSelector } from '../../Auth/ducks'
import { selectReportEntryTypes } from 'state/ducks/reportEntryType'
import { fetchReportTypes, reportTypeByIdSelector } from 'state/ducks/reportType'
import { fetchUserInProgressReports as fetchUserInProgress, fetchReports } from 'state/ducks/report'
import { userRecentByUserIdSelector } from 'state/ducks/userRecent'
import coreApi from 'services/coreApi'
import StateStore from 'state'
import Store from 'services/store'

const PAGE_LIMIT = 10

const initialState = {
  // this search state is used by more than 1 search/paginated result
  currentSearchId: undefined, // identifier for which component the results are for
  currentSearchQuery: {},
  reportsLoading: false,
  reportsSearchOptions: undefined,
  reportsPage: 1,
  reportsResultsPageCount: 1,
  reportsResultsById: [],
  reportsResultsCount: 0,
}

const moduleName = 'Report'

const mod = name => `${moduleName}/${name}`

// selectors
export const selectReportsSearchOptions = state => state.Report.reportsSearchOptions

const selectReportTypes = state => state.reportType.reportTypes

const selectReportsInProgressWithLocationId = (state, locationId) => state.report.reports
    .filter(({ locationIds }) => locationIds.includes(locationId))
    .filter(({ statusCode }) => statusCode < 0)

const selectReportsWithLocationId = (state, locationId) => state.report.reports.filter(({ locationIds }) => locationIds.includes(locationId))

export const reportEntryTypesSelector = createSelector(
  selectCurrentRegion,
  selectReportEntryTypes,
  (currentRegion, reportEntryTypes) =>
    reportEntryTypes.filter(
      ({ id, regionId }) => regionId === currentRegion.id || /builtin/.test(id),
    ),
)

export const reportTypesSelector = createSelector(
  selectCurrentRegion,
  selectReportTypes,
  (currentRegion, reportTypes) =>
    reportTypes.filter(({ id, regionId }) => regionId === currentRegion.id || /builtin/.test(id)),
)

// todo: separate selector for selecting creating/viewing reportTypes
export const activeReportTypesSelector = createSelector(
  selectCurrentRegion,
  selectReportTypes,
  currentUserSelector,
  (currentRegion, reportTypes, user) =>
    reportTypes
      .filter(({ active }) => Boolean(active))
      .filter(({ regionId }) => regionId === currentRegion.id)
      .filter(({ accessLevelCreate, restrictByAccessLevel }) => {
        if (restrictByAccessLevel)
          return user.userMetadata.admin || user.userMetadata.accessLevel >= accessLevelCreate

        return true
      }),
)

/*
  "Attention Reports" are reports that the user is a contributor of and are either
  marked as "rejected" status (rejected the report review step by a user)
  or have not been updated in more than 12hrs
 */
export const attentionReportsSelector = createSelector(
  state => state.report.reports,
  state => state.Auth.user,
  (reports, currentUser) =>
    reports
      .filter(
        report =>
          report.status === 'rejected' || report.inactive,
      )
      .filter(({ contributorUserIds }) => contributorUserIds.includes(currentUser.id)),
)

export const currentUserActiveInProgressReportsSelector = createSelector(
  state => state.report.reports,
  state => state.Auth.user.id,
  (reports, currentUserId) =>
    reports
      .filter(
        report =>
          report.statusCode < 0 && report.status !== 'rejected' && !report.inactive,
      )
      .filter(({ contributorUserIds }) => contributorUserIds.includes(currentUserId)),
)

export const currentUserInProgressReportsSelector = createSelector(
  state => state.report.reports,
  state => state.Auth.user.id,
  (reports, currentUserId) =>
    reports
      .filter(report => report.statusCode < 0 && report.status !== 'rejected')
      .filter(({ contributorUserIds }) => contributorUserIds.includes(currentUserId)),
)

export const currentUserRecentReportsSelector = createSelector(
  state => state.Auth.userRecent?.reportIds || [],
  state => state.report.reportsById,
  (recentReportIds, reportsById) =>
    recentReportIds.reduce((acc, reportId) => {
      if (reportsById[reportId]) acc.push(reportsById[reportId])
      return acc
    }, []),
)

export const allInProgressReportsSelector = createSelector(
  state => state.report.reports,
  reports => reports.filter(report => report.statusCode < 0),
)

export const allExceptUserInProgressReportsSelector = createSelector(
  state => state.report.reports,
  currentUserSelector,
  (reports, currentUser) =>
    reports
      .filter(report => report.statusCode < 0)
      .filter(({ contributorUserIds }) => !contributorUserIds.includes(currentUser.id)),
)

export const locationReportsByLocationIdSelector = createSelector(
  selectReportsWithLocationId,
  reports => reports,
)

export const locationReportsInProgressByLocationIdSelector = createSelector(
  selectReportsInProgressWithLocationId,
  reports => reports,
)

// actions
export const clearReports = createAction(mod('clearReports'))
export const setCurrentFetchQuery = createAction(
  mod('setCurrentFetchQuery'),
  ({ currentSearchQuery = {}, currentSearchId = '' }) => ({ currentSearchQuery, currentSearchId }),
)
export const setReportsLoading = createAction(mod('setReportsLoading'))
export const setReportsPage = createAction(mod('reportsPage'))
export const setReportResultsPageCount = createAction(mod('reportsResultsPageCount'))
export const setReportSearchOptions = createAction(mod('setReportSearchOptions'))
export const setReportResultsById = createAction(mod('setReportResultsById'))

export const fetchActiveReportTypes = () => (dispatch, getState) => 
  // eslint-disable-next-line no-use-before-define
   fetchReportTypes({ where: { active: true } })(dispatch, getState)


export const fetchInProgressReports = ({ bypassCache = false } = {}) => dispatch => dispatch(
    fetchReports(
      {
        where: { statusCode: { '<': 0 } },
      },
      { force: bypassCache },
    ),
  )

export const fetchInProgressReportsForLocation = locationId => dispatch => dispatch(
    fetchReports({
      where: { locationIds: { isectNotEmpty: [locationId] }, statusCode: { '<': 0 } },
    }),
  )

/**
 * Fetch reports with pagination results and store results & metadata in state.
 *
 * @note This method and the state is used by multiple components, ie: Reports Search & All In-Progress
 *       so in order to maintain a user's search state for the correct component we use an ID field
 *       in state, `currentSearchId` which is arbitrary and determined by the component using the state
 *
 * @param currentSearchId {string} An identifier for which component is using the search/query state
 * @param [query] {object} to refetch current query, pass no query arg
 * @param [query.page] {number} the page number to fetch
 * @param [query.where] {object} the js-data where query
 * @param [opts] {object} optional
 * @returns {function(*, *): Promise<T | never>}
 */
export const fetchReportsForSearch = (currentSearchId, query, opts) => (dispatch, getState) => {
  dispatch(setReportsLoading(true))

  const state = getState()
  let currentSearchQuery

  if (!query && state.Report.currentSearchId === currentSearchId) {
    currentSearchQuery = state.Report.currentSearchQuery
  } else {
    const { page } = query || { page: 1 }

    const where = {
      ...(query.where || {}),
    }

    currentSearchQuery = {
      limit: PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
      orderBy: query.orderBy || [['updatedAt', 'desc']],
      where,
    }
  }

  dispatch(setCurrentFetchQuery({ currentSearchQuery, currentSearchId }))

  return Store.Report.findAll(currentSearchQuery, {
    ...opts,
    force: currentSearchQuery.offset > 1, // we have to force because of cache results filtering offset
    headers: { count: true },
    raw: true,
  })
    .then(async response => {
      let count
      let reports

      // when it's a cached response, it's an array of reports
      if (Array.isArray(response)) {
        // todo: this can be async in the background?
        count = await Store.Report.count({ where: currentSearchQuery.where }).catch(() => 0)
        reports = response
      } else {
        count = +response.headers.count
        reports = response.data
      }

      dispatch(setReportResultsById({ count, results: map(reports, 'id') }))
      dispatch(setReportResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
      dispatch(setReportsLoading(false))

      return reports
    })
    .catch(err => {
      console.error(`Reports Report.findAll error`, err)
      // todo show system toast err
      dispatch(setReportsLoading(false))
    })
}

/**
 * This action will specifically call the Report api's "search" endpoint which
 * returns a "search" response schema and is different than other REST endpoints
 *
 * @param page {Number}
 * @param searchId {String}
 * @param query {Object}
 * @returns {function(*, *): Promise<unknown>}
 */
export const fetchReportsSearch = ({ page = 1, searchId = '', query } = {}) => (
  dispatch,
  getState,
) => {
  dispatch(setReportsLoading(true))

  const state = getState()
  let currentSearchQuery

  if (!query && state.Report.currentSearchId === searchId) {
    currentSearchQuery = state.Report.currentSearchQuery
  } else {
    currentSearchQuery = {
      limit: PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
      query,
    }
  }

  dispatch(setCurrentFetchQuery({ currentSearchQuery, searchId }))

  return coreApi
    .post('/report/search', { ...currentSearchQuery })
    .then(async response => {
      const count = response.data.hits
      const reports = response.data.results

      dispatch(setReportResultsById({ count, results: map(reports, 'id') }))
      dispatch(setReportResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
      dispatch(setReportsLoading(false))

      Store.Report.add(reports)

      return reports
    })
    .catch(err => {
      console.error(`Reports search error`, err)
      // todo show system toast err
      dispatch(setReportsLoading(false))
    })
}

export const fetchUniqueReportTypesForLocation = ({ locationId }) => async (dispatch, getState) => {
  const { data: uniqueReportTypeIds } = await coreApi.post(
    '/report/uniqueReportTypeIdsForLocation',
    { locationId },
  )

  const state = getState()
  // filter out previously fetched reportTypes
  const reportTypeIdsToFetch = uniqueReportTypeIds.filter(
    reportTypeId => !state.reportType.reportTypeHasFetchedById[reportTypeId],
  )

  if (reportTypeIdsToFetch.length)
    await dispatch(fetchReportTypes({ where: { id: { isectNotEmpty: reportTypeIdsToFetch } } }))

  return Promise.resolve(reportTypeByIdSelector(getState(), uniqueReportTypeIds))
}

export const fetchUserInProgressReports = () => (dispatch, getState) => {
  const {
    Auth: { user },
  } = getState()

  dispatch(fetchUserInProgress(user.id)).then(reports => 
    // todo: what is this for?
    // let attentionCount = 0
    // let inProgressCount = 0
    //
    // reports.forEach(report => {
    //   const { status } = report
    //
    //   if (status === 'rejected' || isReportInactive(report)) return attentionCount++
    //
    //   if (status === 'inprogress' || (status === 'reopened' && !isReportInactive(report)))
    //     return inProgressCount++
    //
    //   return null
    // })

     reports
  )
}

export const fetchUserRecentReports = _userId => async (dispatch, getState) => {
  const userId = _userId || getState().Auth.user.id
  let userRecent = userRecentByUserIdSelector(getState(), userId)

  if (!userRecent) userRecent = await fetchCurrentRegionUserRecent()

  const recentReportIds = userRecent.reportIds

  return dispatch(fetchReports({ where: { id: { isectNotEmpty: recentReportIds } } }))
}

export const updateRegionalReportSettings = settingsValue =>
  updateRegionModuleSettings('report', settingsValue)

// reducer
const reducer = handleActions(
  {
    [clearReports]: () => ({
      ...initialState,
    }),

    [setCurrentFetchQuery]: (state, action = {}) => ({
      ...state,
      currentSearchQuery: action.payload.currentSearchQuery,
      currentSearchId: action.payload.currentSearchId,
    }),

    [setReportsLoading]: (state, action = {}) => ({
      ...state,
      reportsLoading: action.payload,
    }),

    [setReportsPage]: (state, action = {}) => ({
      ...state,
      // eslint-disable-next-line no-restricted-globals
      reportsPage: !isNaN(action.payload) && action.payload > 0 ? +action.payload : 1,
    }),

    [setReportResultsById]: (state, action = {}) => ({
      ...state,
      reportsResultsById: action.payload.results || [],
      reportsResultsCount: action.payload.count || 0,
    }),

    [setReportResultsPageCount]: (state, action = {}) => ({
      ...state,
      reportsResultsPageCount: action.payload,
    }),

    [setReportSearchOptions]: (state, action = {}) => ({
      ...state,
      reportsSearchOptions: action.payload,
    }),
  },
  initialState,
)

export default reducer

StateStore.addReducer('Report', reducer)

import('state/ducks/app.js').then(module => {
  module.registerRealtimeDisconnectedTask('fetchInProgressReports', () =>
    fetchInProgressReports({ bypassCache: true }),
  )
  module.registerRealtimeOnReconnectTask('fetchInProgressReports', () =>
    fetchInProgressReports({ bypassCache: true }),
  )
})
