/*
    This file called in Auth/ducks/sagas after user authenticated (and before any components render)

 */
import state from 'state'

export default () => {
  // set date/time format for @therms/web-common <DateTime /> component to access
  window.dateFormat = state.getState()?.Auth?.organizationMetadata?.settings?.dateFormat
  window.timeFormat = state.getState()?.Auth?.organizationMetadata?.settings?.timeFormat

  import('./Dispatch/startup')
  import('./Mail/startup')
  import('./Online/startup')
  import('./Schedule/startup')
  import('./User/startup')
}
