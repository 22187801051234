import React from 'react'
import Modal from '@therms/web-common/components/Modal'
import PropTypes from 'prop-types'
import { AddTimelogForm } from './index'
import Conditional from '../../../../Auth/shared/Conditional'

const AddTimelogFormModal = ({ closeHandler }) => (
  <Modal closeHandler={closeHandler} title="Add Shift Timelog">
    <Conditional
      permission="schedule.canAdjustShiftClockInOutTimes"
      renderOnFail={() => (
        <div className="text-danger">
          Invalid Permission - You need the permission, "Can adjust other User's clock in/out
          times".
        </div>
      )}
    >
      <AddTimelogForm onCancel={closeHandler} onComplete={closeHandler} />
    </Conditional>
  </Modal>
)

AddTimelogFormModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export { AddTimelogFormModal }
