import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import UserName from '../../../../../User/shared/UserName'
import { systemToast } from '../../../../../../utils/toaster'
import { scheduleTimelogApi } from '../../../../services/apiCore'
import FAIcon from '@components/Icon/FAIcon'
import InlineForm from '@therms/web-common/components/Forms/InlineForm'
import Textarea from '@therms/web-common/components/Inputs/Textarea'
import Field from '../../../../../../components/Form/Field'
import store from 'services/store'

const TimeLogControlsOnDuty = ({ scheduleTimelog }) => {
  const [loading, setLoading] = useState(false)

  const { canClockOut, canEndBreak, canStartBreak } = useMemo(() => {
    if (!scheduleTimelog) return {}

    const lastBreaklogAction =
      scheduleTimelog.breaklog[scheduleTimelog.breaklog?.length - 1]?.action

    return {
      canClockOut: Boolean(scheduleTimelog.clockInTimestamp) && lastBreaklogAction !== 'breakstart',
      canEndBreak: lastBreaklogAction === 'breakstart',
      canStartBreak: lastBreaklogAction === 'breakend' || !lastBreaklogAction,
    }
  }, [scheduleTimelog])

  const handleClockOut = useCallback(async () => {
    setLoading(true)

    try {
      await scheduleTimelogApi.clockOut({ scheduleTimelogId: scheduleTimelog?.id })
    } catch (e) {
      console.log('error', e)
      systemToast('There was a problem clocking-out', { error: true })
    } finally {
      setLoading(false)
    }
  }, [scheduleTimelog])

  const handleEndBreak = useCallback(async () => {
    setLoading(true)

    try {
      await scheduleTimelogApi.endBreak({ scheduleTimelogId: scheduleTimelog?.id })
    } catch (e) {
      console.log('error', e)
      systemToast('There was a problem ending break', { error: true })
    } finally {
      setLoading(false)
    }
  }, [scheduleTimelog])

  const handleStartBreak = useCallback(async () => {
    setLoading(true)

    try {
      await scheduleTimelogApi.startBreak({ scheduleTimelogId: scheduleTimelog?.id })
    } catch (e) {
      console.log('error', e)
      systemToast('There was a problem starting break', { error: true })
    } finally {
      setLoading(false)
    }
  }, [scheduleTimelog])

  if (!scheduleTimelog) return null

  return (
    <div>
      <div className="alert alert-info text-center">
        <FAIcon name="info-circle" className="mr-1" />
        <UserName id={scheduleTimelog?.userId} noDutyStatus noOnlineStatus /> is{' '}
        <strong>On-Duty</strong>
      </div>

      <div className="py-3">
        <div className="d-flex justify-content-around mb-3">
          <button
            className="btn btn-outline-success"
            disabled={loading || !canStartBreak}
            onClick={handleStartBreak}
            type="button"
          >
            Start Break
          </button>

          <button
            className="btn btn-outline-secondary"
            disabled={loading || !canEndBreak}
            onClick={handleEndBreak}
            type="button"
          >
            End Break
          </button>
        </div>

        <div className="pb-3">
          <InlineForm
            autoSave
            onSubmit={(values) => {
              store.ScheduleTimelog.update(scheduleTimelog.id, values)
            }}
            initialValues={{ comment: scheduleTimelog.comment || '' }}
            debouncedFields={['comment']}
            debouncedMs={1500}
            field={() => (
              <Field
                component={Textarea}
                label="Timelog Comment"
                maxLength={280}
                name="comment"
                subText="This comment is visible to Scheduling Admin/Managers"
              />
            )}
          />
        </div>

        <div>
          <button
            className="btn btn-block btn-secondary"
            disabled={loading || !canClockOut}
            onClick={handleClockOut}
            type="button"
          >
            <FAIcon name="sign-out-alt" /> Clock-Out
          </button>
        </div>
      </div>
    </div>
  )
}

TimeLogControlsOnDuty.propTypes = {
  scheduleTimelog: PropTypes.object.isRequired,
}
TimeLogControlsOnDuty.defaultProps = {}

export default TimeLogControlsOnDuty
