import pkg from '../../package.json'

// todo: when app mounts run script to delete old namespace keys from LS
const NAMESPACE = `THERMS-${pkg.version}`

function makeNamespace(name) {
  return `${NAMESPACE}.${name}`
}

// Polyfill - ie: iOS localStorage isn't turned on
if (!('localStorage' in window)) {
  window.localStorage = {
    _data: {},

    setItem(id, val) {
      return (this._data[id] = String(val))
    },
    getItem(id) {
      return Object.hasOwnProperty.apply(this._data, id) ? this._data[id] : undefined
    },
    removeItem(id) {
      return delete this._data[id]
    },
    clear() {
      return (this._data = {})
    },
  }
}

export const removeItem = name => {
  window.localStorage.removeItem(makeNamespace(name))
}

export const getItem = name => {
  try {
    const val = window.localStorage.getItem(makeNamespace(name))

    try {
      return JSON.parse(val)
    } catch (e) {
      return val
    }
  } catch (e) {
    return undefined
  }
}

export const setItem = (name, value) => {
  try {
    return window.localStorage.setItem(
      makeNamespace(name),
      typeof value !== 'string' ? JSON.stringify(value) : value,
    )
  } catch (e) {
    return undefined
  }
}

export default {
  getItem,
  removeItem,
  setItem,
}
