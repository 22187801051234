import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { escapeRegExp } from 'lodash-es'

import Select from '@components/Inputs/Select'

import { fetchLocations } from 'state/ducks/location'
import { activeRegionLocationsSelector } from '../../ducks'
import { components } from 'react-select'
import ServiceTypes from '../../../ServiceType/shared/ServiceTypes'
import LocationGroupNames from '../../components/LocationGroup/Names'

import './SelectLocations.scss'

class SelectLocations extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    disabled: PropTypes.bool,
    fetchLocations: PropTypes.func.isRequired,
    input: PropTypes.object,
    isClearable: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.elementType,
      PropTypes.node,
      PropTypes.string,
    ]),
    loadingLocations: PropTypes.bool.isRequired,
    meta: PropTypes.object,
    multi: PropTypes.bool,
    omitLocationsIds: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    regionId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    defaultValue: undefined,
    disabled: undefined,
    input: {},
    isClearable: true,
    label: '',
    meta: {},
    multi: false,
    omitLocationsIds: [],
    onChange: undefined,
    placeholder: undefined,
  }

  componentDidMount() {
    this.props.fetchLocations({ active: true })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.regionId !== this.props.regionId) this.props.fetchLocations({ active: true })
  }

  filterOption = ({ data }, input) => {
    const _input = input.replace(/[^a-zA-Z0-9] /gi, '')

    const rgx = new RegExp(escapeRegExp(_input), 'gi')

    if (_input) return rgx.test(JSON.stringify(data))

    return true
  }

  getPlaceholder = () => {
    if (this.props.multi) {
      return 'Locations'
    }

    return 'Location'
  }

  onChange = e => {
    const v = e.currentTarget.value

    if (this.props.onChange) {
      if (this.props.multi && !v) this.props.onChange([])
      else this.props.onChange(v)
    }
  }

  renderLabel = () => {
    const { label, multi } = this.props
    let customLabel

    if (!label) return null
    if (React.isValidElement(label)) return label
    if (typeof label === 'string') customLabel = label
    else if (multi) customLabel = 'Locations'
    else customLabel = 'Location'

    return <label>{customLabel}</label>
  }

  renderLocationSubText = ({ locationGroupIds, serviceTypeIds }) => (
    <span className="font-small">
      {serviceTypeIds?.length > 0 && <ServiceTypes badge serviceTypeIds={serviceTypeIds} />}

      {locationGroupIds?.length > 0 && (
      <LocationGroupNames badge locationGroupIds={locationGroupIds} />
        )}
    </span>
    )

  render() {
    const {
      defaultValue,
      disabled,
      input,
      isClearable,
      loadingLocations,
      meta,
      multi,
      placeholder,
      locations,
      ...rest
    } = this.props

    const options = locations
      .filter(({ id }) => !this.props.omitLocationsIds.includes(id))
      .map(({ address, id, locationGroupIds, name, number, serviceTypeIds }) => ({
        address,
        value: id,
        label: name,
        locationGroupIds,
        name,
        number,
        serviceTypeIds,
      }))

    return (
      <div className="min-width-200 select-locations-container">
        <Select
          disabled={disabled}
          defaultValue={defaultValue}
          filterOption={this.filterOption}
          isClearable={isClearable}
          isLoading={loadingLocations}
          label={this.renderLabel()}
          multi={multi}
          name={multi ? 'locationIds' : 'locationId'}
          options={options}
          placeholder={placeholder || this.getPlaceholder()}
          input={input}
          meta={meta}
          {...rest}
          onChange={this.onChange}
          reactSelectCustomComponents={{
            MultiValueLabel: props => (
              <components.MultiValueLabel {...props}>
                <div>
                  {props.data.number ? (
                    <span className="font-small text-secondary">{props.data.number} </span>
                  ) : (
                    ''
                  )}
                  <span className="font-weight-bold">{props.data.label}</span>
                </div>

                <div>{this.renderLocationSubText(props.data)}</div>
              </components.MultiValueLabel>
            ),
            Option: _props => {
              const {
                data: { label, number },
                innerProps,
                innerRef,
              } = _props

              return (
                <article
                  ref={innerRef}
                  {...innerProps}
                  className={`${_props.isFocused && 'bg-primary'}`}
                >
                  <div className="cursor p-1">
                    {number ? <span className="font-small text-secondary">{number} </span> : ''}
                    <span className="font-weight-bold">{label}</span>
                    <div className="short">{this.renderLocationSubText(_props.data)}</div>
                  </div>
                </article>
              )
            },

            SingleValue: props => (
              <components.SingleValue {...props}>
                <div>
                  {props.data.number ? (
                    <span className="font-small text-secondary">{props.data.number} </span>
                  ) : (
                    ''
                  )}
                  <span className="font-weight-bold">{props.data.label}</span>
                  <div className="short">{this.renderLocationSubText(props.data)}</div>
                </div>
              </components.SingleValue>
            ),
          }}
        />
      </div>
    )
  }
}

export default connect(
  (state, props) => ({
    loadingLocations: state.location.loadingLocations,
    regionId: props.regionId || state.Auth.region.id,
    locations: activeRegionLocationsSelector(state),
  }),
  { fetchLocations },
)(SelectLocations)
