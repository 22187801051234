import React from 'react'
// import PropTypes from 'prop-types'
import RegionName from '../../../Region/shared/RegionName'
import Conditional from '../../../Auth/shared/Conditional'
import SchedulePositions from './components/SchedulePositions'
import ScheduleRegionSettingsForm from './components/ScheduleRegionSettingsForm'

const ScheduleSettingsScreen = () => (
  <div>
    <Conditional module="region">
      <h4>
        Region - <RegionName />
      </h4>
    </Conditional>

    <ScheduleRegionSettingsForm />

    <SchedulePositions />
  </div>
  )

ScheduleSettingsScreen.propTypes = {}
ScheduleSettingsScreen.defaultProps = {}

export default ScheduleSettingsScreen
