import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import useScheduleTimelogs from '../../../../hooks/useScheduleTimelogs'
import UserName from '../../../../../User/shared/UserName'
import ShiftAssignment from '../../../../components/Shift/ShiftAssignment'
import DateTime from '@therms/web-common/components/DateTime'
import Loading from '@therms/web-common/components/Loading'
import ShiftPositionName from '../../../../components/Shift/ShiftPositionName'
import ScheduleTimelogModal from './components/ScheduleTimelogModal'
import TimeDiff from '../../../../../../components/TimeDiff'

const OnDutyScheduleTimelogList = () => {
  const [showScheduleTimelogModal, setShowScheduleTimelogModal] = useState(false)

  const { data: scheduleTimelogs, validating } = useScheduleTimelogs({
    orderBy: [['clockInTimestamp', 'desc']],
    where: { clockOutTimestamp: null },
  })

  if (validating && !scheduleTimelogs.length) return <Loading />

  return (
    <div>
      {scheduleTimelogs.map((scheduleTimelog) => (
        <div
          className="card cursor mb-1 p-1"
          key={scheduleTimelog.id}
          onClick={() => setShowScheduleTimelogModal(scheduleTimelog.id)}
        >
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div>
              <UserName id={scheduleTimelog.userId} />{' '}
              <span className="font-weight-light text-muted">
                On-Duty for{' '}
                <TimeDiff timestamp={scheduleTimelog.clockInTimestamp} />
              </span>
            </div>

            <ShiftPositionName scheduleShiftOrId={scheduleTimelog.scheduleShiftId} showNameOnly />
          </div>

          <div className="d-flex justify-content-between text-muted">
            <div className="font-italic font-small">
              Clocked-In @ <DateTime date={false} timestamp={scheduleTimelog.clockInTimestamp} />
            </div>

            {scheduleTimelog.scheduleShiftId && (
            <ShiftAssignment showNameOnly scheduleShiftOrId={scheduleTimelog.scheduleShiftId} />
              )}
          </div>
        </div>
        ))}

      {!scheduleTimelogs.length && (
        <div className="font-italic text-center text-muted">Nobody is on-duty</div>
      )}

      {showScheduleTimelogModal && (
        <ScheduleTimelogModal
          closeHandler={() => setShowScheduleTimelogModal(false)}
          scheduleTimelogId={showScheduleTimelogModal}
        />
      )}
    </div>
  )
}

OnDutyScheduleTimelogList.propTypes = {}
OnDutyScheduleTimelogList.defaultProps = {}

export default OnDutyScheduleTimelogList
