/* eslint-disable no-multi-assign */
import { globalVent } from '@utils/vent'
import { BROWSER_FOCUS_CHANGE } from './events'

let visible = document.hasFocus()

// https://stackoverflow.com/a/38710376/4946857
function onBrowserFocusChange(callback) {
  if (!callback) {
    throw new Error('no callback given')
  }

  function focused() {
      callback((visible = true))
  }

  function unfocused() {
      callback((visible = false))
  }

  // Standards:
  if ('hidden' in document) {
    document.addEventListener('visibilitychange', () => {
      ;(document.hidden ? unfocused : focused)()
    })
  }
  if ('mozHidden' in document) {
    document.addEventListener('mozvisibilitychange', () => {
      ;(document.mozHidden ? unfocused : focused)()
    })
  }
  if ('webkitHidden' in document) {
    document.addEventListener('webkitvisibilitychange', () => {
      ;(document.webkitHidden ? unfocused : focused)()
    })
  }
  if ('msHidden' in document) {
    document.addEventListener('msvisibilitychange', () => {
      ;(document.msHidden ? unfocused : focused)()
    })
  }
  // IE 9 and lower:
  if ('onfocusin' in document) {
    document.onfocusin = focused
    document.onfocusout = unfocused
  }
  // All others:
  window.onpageshow = window.onfocus = focused
  window.onpagehide = window.onblur = unfocused
}

onBrowserFocusChange((focused) => globalVent.publish(BROWSER_FOCUS_CHANGE, { focused }))

export const checkIsVisible = () => visible
