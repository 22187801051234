import React from 'react'
import { Route, Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import ScrollMemory from 'react-router-scroll-memory'
import { QueryParamProvider } from 'use-query-params'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { history } from 'services/browserHistory'
import coreApi from './services/coreApi'
import StateStore from 'state'

import Auth from 'modules/Auth/shared/AuthWrapper'
import ErrorBoundaryWithDetails from './components/ErrorBoundary/ErrorBoundaryWithDetails'
import Layout from 'modules/Layout'
import ScrollToTop from './components/ScrollToTop'

import './index.scss'
import Mount from './modules/Mount'

const App = () => (
  <Provider store={StateStore}>
    <>
      <SWRConfig
        value={{
          refreshInterval: 60000,
          fetcher: coreApi,
        }}
      >
        <ErrorBoundaryWithDetails>
          <Auth>
            <Router history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <ScrollToTop>
                  <ScrollMemory elementID="main-scene" />

                  <Layout />
                </ScrollToTop>

                <Mount />
              </QueryParamProvider>
            </Router>
          </Auth>
        </ErrorBoundaryWithDetails>
      </SWRConfig>
      <ToastContainer />
    </>
  </Provider>
)

export default App
