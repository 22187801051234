import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MimeTypeFAIcon from '@therms/web-common/components/Icon/MimeTypeFAIcon'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import {
  REGEX_VALID_IMAGE_FORMATS,
  REGEX_VALID_RASTER_IMAGE_FORMATS,
} from 'modules/Files/constants'

const Preview = ({ blob, contentType, thumbnailUrl, url }) => {
  const [delaying, setDelaying] = useState(false)
  const [imageDataUrl, setImageDataUrl] = useState()

  useEffect(() => {
    if (REGEX_VALID_IMAGE_FORMATS.test(contentType) && !imageDataUrl) {
      if (blob) {
        try {
          setImageDataUrl(URL.createObjectURL(blob))
        } catch (e) {
          console.error('error creating object url for preview', e)
        }
      } else if (!REGEX_VALID_RASTER_IMAGE_FORMATS.test(contentType)) {
        setImageDataUrl(url)
      } else if (thumbnailUrl) {
        setImageDataUrl(thumbnailUrl)
      }
    }
  }, [blob, imageDataUrl, contentType, thumbnailUrl, url])

  useEffect(() => {
    if (delaying) {
      const t = setTimeout(() => {
        setDelaying(false)
      }, 2000)

      return () => clearTimeout(t)
    }

    return undefined
  }, [delaying])

  if (delaying)
    return (
      <div>
        <i className="fa fa-circle-notch fa-spin" />
        <div className="flex-column-center">
          <FAIcon className="text-info" name="fa fa-image fa-3x" />
        </div>
      </div>
    )

  if (imageDataUrl)
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        className="border-radius"
        crossOrigin="anonymous"
        onError={() => {
          // in different scenarios the image might not have loaded, we delay and try again
          setDelaying(true)
        }}
        onLoad={() => {
          URL.revokeObjectURL(imageDataUrl)
        }}
        src={imageDataUrl}
        style={{ height: '100px', objectFit: 'contain', width: '100px' }}
      />
    )

  return (
    <div className="flex-column-center" style={{ height: '100px', width: '100px' }}>
      <MimeTypeFAIcon lg mimeType={contentType?.split('/')[1]} />
    </div>
  )
}

Preview.propTypes = {
  blob: PropTypes.object,
  contentType: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  url: PropTypes.string,
}
Preview.defaultProps = {
  blob: undefined,
  thumbnailUrl: undefined,
  url: undefined,
}

export default Preview
