import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import usePatrolRoutes from '../../Patrol/shared/usePatrolRoutes'
import Checkbox from '@therms/web-common/components/Inputs/Checkbox'
import TextSearch from '@therms/web-common/components/Inputs/TextSearch'
import { filterCbObjectFields } from '@therms/web-common/utils'

const SelectPatrolRoutesList = ({ onChange, value }) => {
  const [filteredPatrolRoutes, setFilteredPatrolRoutes] = useState([])
  const [searchText, setSearchText] = useState('')
  const { data: patrolRoutes } = usePatrolRoutes()

  const handleCheckboxOnChange = useCallback(
    (patrolRouteId, checked) => {
      if (checked) {
        onChange([...(value || []), patrolRouteId])
      } else {
        onChange((value || []).filter((lid) => lid !== patrolRouteId))
      }
    },
    [onChange, value],
  )

  const selectedByPatrolRouteId = useMemo(() => {
    const map = {}

    if (Array.isArray(value)) value.forEach((patrolRouteId) => (map[patrolRouteId] = true))

    return map
  }, [value])

  useEffect(() => {
    if (searchText)
      setFilteredPatrolRoutes(
        patrolRoutes.filter(
          filterCbObjectFields(searchText, {
            fields: ['description', 'name'],
          }),
        ),
      )
    else setFilteredPatrolRoutes(patrolRoutes)
  }, [patrolRoutes, searchText, value])

  if (!patrolRoutes?.length) return null

  return (
    <div>
      {patrolRoutes.length > 15 && <TextSearch onChange={setSearchText} small value={searchText} />}

      <div className="max-height-500 overflow-auto">
        {filteredPatrolRoutes.map((l) => (
          <div key={l.id}>
            <Checkbox
              checked={selectedByPatrolRouteId[l.id]}
              onChange={(checked) => handleCheckboxOnChange(l.id, checked)}
              label={(
                <span>
                  <span className="font-small text-muted">{l.number}</span> {l.name}
                </span>
              )}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

SelectPatrolRoutesList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
}
SelectPatrolRoutesList.defaultProps = {}

export default SelectPatrolRoutesList
