import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@therms/web-common/components/Modal'
import ShiftDetailsForm from './ShiftDetailsForm'

const ShiftDetailsFormModal = ({
  closeHandler,
  date,
  id,
  assignmentModuleId,
  assignmentModuleName,
}) => (
  <Modal closeHandler={closeHandler} title={id ? 'Edit Shift Details' : 'Create New Shift'}>
    <ShiftDetailsForm
      date={date}
      id={id}
      onCancel={closeHandler}
      onComplete={closeHandler}
      assignmentModuleId={assignmentModuleId}
      assignmentModuleName={assignmentModuleName}
    />
  </Modal>
  )

ShiftDetailsFormModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  date: PropTypes.string,
  id: PropTypes.string,
  assignmentModuleId: PropTypes.string,
  assignmentModuleName: PropTypes.string,
}
ShiftDetailsFormModal.defaultProps = {
  date: undefined,
  id: undefined,
  assignmentModuleId: undefined,
  assignmentModuleName: undefined,
}

export default ShiftDetailsFormModal
