import React from 'react'
import PropTypes from 'prop-types'

import ENV from 'config/env'
import __debug from '../../../debug'

import Modal from '@therms/web-common/components/Modal'
import {
  getDataStoreMemoryInfo,
  getStateMemoryInfo,
  getDeviceMemoryInfo,
} from '../../../services/debugging'

const DebugModal = ({ closeHandler }) => {
  const debugValues = __debug(true)

  return (
    <Modal closeHandler={closeHandler} title="Debug">
      <div className="d-flex flex-column justify-content-center">
        <h4>App Version</h4>
        <div className="mb-3">
          <b>{ENV.RELEASE}</b>
        </div>

        <div className="font-weight-bold my-3">Dependencies</div>

        <pre className="pl-2 mb-3">
          <div>@therms/models {debugValues['@therms/models']}</div>
          <div>@therms/utils-services {debugValues['@therms/utils-services']}</div>
          <div>@therms/web-common {debugValues['@therms/web-common']}</div>
          <div>@therms/webpack-config {debugValues['@therms/webpack-config']}</div>
        </pre>

        <hr />

        <h4>Performance Debugging</h4>

        <div>Memory ({getDeviceMemoryInfo().memoryUsed}% used)</div>

        <div>
          <pre>{JSON.stringify(getDeviceMemoryInfo().memory, null, 2)}</pre>
        </div>

        <div>State Object Counts in Memory</div>

        <pre>{JSON.stringify(getStateMemoryInfo(), null, 2)}</pre>

        <div>DataStore Object Counts in Memory</div>
        <pre>{JSON.stringify(getDataStoreMemoryInfo(), null, 2)}</pre>
      </div>
    </Modal>
  )
}

DebugModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}
DebugModal.defaultProps = {}

export default DebugModal
