import React, { useCallback } from 'react'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import PropTypes from 'prop-types'
import isPlural from '../../../../../utils/isPlural'

const FileInput = ({ imageOnly, multiple, onFilesSelected }) => {
  const onFilesChange = useCallback(
    (e) => {
      const files = e.target.files

      if (files?.length) onFilesSelected(files)
    },
    [onFilesSelected],
  )
  return (
    <div className="position-relative w-100">
      <input
        accept={imageOnly ? 'image/*' : '*;capture=camera'}
        className="btn btn-block cursor position-absolute"
        multiple={multiple}
        onChange={onFilesChange}
        style={{ opacity: 0 }}
        type="file"
      />

      <button className={`btn btn-secondary ${multiple ? 'btn-block' : ''}`}>
        <FAIcon name="plus" /> Attach {isPlural(multiple, 'File')}
      </button>
    </div>
  )
}

FileInput.propTypes = {
  imageOnly: PropTypes.bool,
  multiple: PropTypes.bool.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
}

FileInput.defaultProps = {
  imageOnly: false,
}

export default FileInput
