import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from '@therms/web-common/components/Inputs/Select'
import { components } from 'react-select'
// import { escapeRegExp } from 'lodash-es'
import { useScheduleAssignableModules } from '../../hooks/useScheduleAssignableModules'
import LocationName from '../../../../../Location/shared/LocationName'
import PatrolRouteName from '../../../../../Patrol/shared/PatrolRouteName'

const SelectAssignedModule = ({
  disabled,
  isClearable,
  label,
  onChange,
  placeholder,
  selectedId,
  selectedModel,
}) => {
  const [options, setOptions] = useState([])

  const { loading, locations, patrolRoutes } = useScheduleAssignableModules()

  const Group = useCallback(
    (props) => (
      <div>
        <components.Group {...props} />
      </div>
    ),
    [],
  )

  useEffect(() => {
    const _options = []

    if (patrolRoutes?.length) {
      _options.push({
        label: 'Patrol Routes',
        options: patrolRoutes.map(({ description, id, name }) => ({
          description,
          label: name,
          model: 'patrolRoute',
          id,
        })),
      })
    }

    if (locations?.length) {
      _options.push({
        label: 'Locations',
        options: locations.map(({ address, id, name, number }) => ({
          address,
          label: `${number} ${name}`,
          model: 'location',
          id,
        })),
      })
    }

    setOptions(_options)
  }, [locations, patrolRoutes])

  return (
    <div className="min-width-200">
      <Select
        disabled={disabled}
        isClearable={isClearable}
        isLoading={loading}
        label={label}
        options={options}
        placeholder={placeholder || 'Select module assignment...'}
        onChange={onChange}
        value={selectedId && selectedModel ? { model: selectedModel, id: selectedId } : undefined}
        reactSelectCustomComponents={{
          Group,

          SingleValue: (props) => {
            // eslint-disable-next-line react/prop-types
            const { model, id } = props?.data?.value || {}

            return (
              <components.SingleValue {...props}>
                {model === 'location' && <LocationName id={id} />}

                {model === 'patrolRoute' && <PatrolRouteName id={id} />}
              </components.SingleValue>
            )
          },
        }}
      />
    </div>
  )
}

SelectAssignedModule.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  disabled: PropTypes.bool,
  input: PropTypes.object,
  isClearable: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.elementType,
    PropTypes.node,
    PropTypes.string,
  ]),
  meta: PropTypes.object,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectedId:PropTypes.string,
  selectedModel:PropTypes.string,
}
SelectAssignedModule.defaultProps = {
  defaultValue: undefined,
  disabled: undefined,
  input: {},
  isClearable: true,
  label: '',
  meta: {},
  multi: false,
  onChange: undefined,
  placeholder: undefined,
  selectedId: undefined,
  selectedModel: undefined,
}

export default SelectAssignedModule
