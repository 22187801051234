import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@therms/web-common/components/Modal'
import useScheduleTimelog from '../../../../../hooks/useScheduleTimelog'
import ShiftDetailsWithControls from '../../../../../shared/ShiftDetailsWithControls'

const ScheduleTimelogModal = ({ closeHandler, scheduleTimelogId }) => {
  const { data } = useScheduleTimelog(scheduleTimelogId)

  return (
    <Modal closeHandler={closeHandler} title="Timelog">
      {data.scheduleShiftId && <ShiftDetailsWithControls scheduleShiftId={data.scheduleShiftId} />}
    </Modal>
  )
}

ScheduleTimelogModal.propTypes = {
  scheduleTimelogId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
}
ScheduleTimelogModal.defaultProps = {}

export default ScheduleTimelogModal
