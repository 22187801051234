import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form as RFForm } from 'react-final-form'
import { formValidate } from '@therms/util-services'
import { FORM_ERROR } from 'final-form'

import { systemToast } from 'utils/toaster'
import Store from 'services/store'

import DateTime from '@therms/web-common/components/Inputs/DateTime'
import Text from '@therms/web-common/components/Inputs/Text'
import Textarea from '@therms/web-common/components/Inputs/Textarea'
import Loading from '@therms/web-common/components/Loading'
import DeleteCancelSave from '@therms/web-common/components/Buttons/DeleteCancelSave'
import useScheduleShift from '../../../hooks/useScheduleShift'
import SelectSchedulePosition from '../../Position/SelectSchedulePosition'
import SelectUsers from '../../../../User/shared/Inputs/SelectUsers'
import SelectAssignedModule from './AssignedModule/SelectAssignedModule'
import dayjs from 'dayjs'

const ShiftDetailsForm = ({
  assignmentModuleId,
  assignmentModuleName,
  date,
  id,
  onCancel,
  onComplete,
}) => {
  const { data: scheduleShift, destroy, validating: loading } = useScheduleShift(id)

  const [deletingRecord, setDeletingRecord] = useState()

  async function handleDelete() {
    setDeletingRecord(true)

    try {
      await destroy()

      systemToast('Shift Deleted', { quick: true })
      onComplete()
    } catch (err) {
      console.error('err', err)

      systemToast('There was a problem deleting', { error: true })
    } finally {
      setDeletingRecord(false)
    }
  }

  async function handleOnSubmit(values) {
    let opDisplayName = 'Created'

    try {
      let storeRecord

      if (scheduleShift?.id) {
        storeRecord = await Store.ScheduleShift.update(scheduleShift.id, {
          ...scheduleShift,
          draft: {
            ...(scheduleShift.draft || {}),
            ...values,
            id: undefined,
            draft: undefined,
            shiftNotes: values.shiftNotes || '',
          },
        })
        opDisplayName = 'Updated'
      } else {
        storeRecord = await Store.ScheduleShift.create(values)
        opDisplayName = 'Created'
      }

      systemToast(`Shift ${opDisplayName}`, { quick: true })

      onComplete(storeRecord)

      return undefined
    } catch (err) {
      console.error('err', err)

      return {
        [FORM_ERROR]: 'There was a problem saving, please try again',
      }
    }
  }

  if (deletingRecord || loading) return <Loading />

  return (
    <RFForm
      initialValues={{
        assignmentModuleId,
        assignmentModuleName,
        ...scheduleShift,
        ...(scheduleShift?.draft || {}),
      }}
      onSubmit={handleOnSubmit}
      validate={(values) => {
        if (values.dateStart >= values.dateEnd) {
          return {
            dateStart: 'Start date must be before end',
          }
        }

        return undefined
      }}
      render={({ form, handleSubmit, submitError, submitting, values }) => {
        const shiftEndLabel = useMemo(() => {
          let d = ''

          if (values.dateEnd !== values.dateStart) {
            d =
              Math.round(dayjs(values.dateEnd).diff(dayjs(values.dateStart), 'hours', true) * 10) /
              10

            d = `${d}hr shift`
          }

          return (
            <span>
              Shift End <small className="text-muted">{d}</small>
            </span>
          )
        }, [values.dateEnd, values.dateStart])

        return (
          <form onSubmit={handleSubmit}>
            <div className="card">
              {scheduleShift?.metadata?.isClockedIn && (
                <div className="alert alert-warning">
                  A User is clocked in for this Shift - editing is limited.
                </div>
              )}

              <Field component={Text} label="Shift Name" name="name" subText="Optional" />

              <div className="d-flex">
                <Field
                  component={DateTime}
                  defaultValue={date && new Date(date).toISOString()}
                  disabled={scheduleShift?.metadata?.isClockedIn}
                  label="Shift Start"
                  name="dateStart"
                  noDefaultValue={false}
                  validate={formValidate.isoDate}
                  viewMode="time"
                />

                <Field
                  component={DateTime}
                  dateMode={false}
                  defaultValue={date && new Date(date).toISOString()}
                  disabled={scheduleShift?.metadata?.isClockedIn}
                  label={shiftEndLabel}
                  name="dateEnd"
                  noDefaultValue
                  parse={(value) => {
                    try {
                      const dayJsValue = dayjs(value)
                      const newValueHr = dayJsValue.get('hour')
                      const newValueMin = dayJsValue.get('minute')

                      const isHourLowerThanStartDateHour =
                        dayjs(values.dateStart || date).get('hour') >= newValueHr

                      let newValue

                      if (isHourLowerThanStartDateHour) {
                        newValue = dayjs(values.dateStart || date)
                          .add(1, 'day')
                          .set('hour', newValueHr)
                          .set('minute', newValueMin)
                      } else {
                        newValue = dayjs(values.dateStart || date)
                          .set('hour', newValueHr)
                          .set('minute', newValueMin)
                      }

                      return newValue.toISOString()
                    } catch (e) {
                      return value
                    }
                  }}
                  validate={formValidate.isoDate}
                  viewMode="time"
                />
              </div>

              <div className="d-flex">
                <div className="flex-grow-1">
                  <SelectAssignedModule
                    disabled={scheduleShift?.metadata?.isClockedIn}
                    label="Module Assignment"
                    multi={false}
                    onChange={({ target }) => {
                      form.change('assignmentModuleName', target.model)
                      form.change('assignmentModuleId', target.id)
                    }}
                    placeholder="Select assignment..."
                    selectedId={values?.assignmentModuleId}
                    selectedModel={values?.assignmentModuleName}
                  />
                </div>

                <div className="flex-grow-1">
                  <Field
                    component={SelectSchedulePosition}
                    disabled={scheduleShift?.metadata?.isClockedIn}
                    label="Position"
                    multi={false}
                    name="schedulePositionId"
                  />
                </div>
              </div>
            </div>

            <Field
              component={SelectUsers}
              disabled={scheduleShift?.metadata?.isClockedIn}
              // defaultValue={userId}
              label="Shift Assignment"
              name="userId"
              multi={false}
            />

            <Field autoResize component={Textarea} label="Shift Notes" name="shiftNotes" />

            {submitError && <div className="alert alert-danger">{submitError}</div>}

            <DeleteCancelSave
              disabled={submitting}
              isSaving={submitting}
              onCancel={onCancel}
              onDelete={handleDelete}
              onSave={handleSubmit}
              showDelete={!scheduleShift?.metadata?.isClockedIn && id}
            />
          </form>
        )
      }}
    />
  )
}

ShiftDetailsForm.propTypes = {
  assignmentModuleId: PropTypes.string,
  assignmentModuleName: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  // userId: PropTypes.string,
}
ShiftDetailsForm.defaultProps = {
  assignmentModuleId: undefined,
  assignmentModuleName: undefined,
  date: undefined,
  id: undefined,
  onComplete: undefined,
  // userId: undefined,
}

export default ShiftDetailsForm
