import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectUserById } from '../../../state/ducks/user'

const UserTitle = ({ title, userId }) => {
  const user = useSelector(state => selectUserById(state, userId))

  if (!title && !userId) return null

  if (title || user) return <span>{title || user.title}</span>

  return null
}

UserTitle.propTypes = {
  title: PropTypes.string,
  userId: PropTypes.string,
}
UserTitle.defaultProps = {
  title: undefined,
  userId: undefined,
}

export default UserTitle
