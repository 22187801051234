import { isProdEnv } from '../config/env'

export class ReducerRegistry {
  constructor() {
    this.emitChange = null
    this.reducers = {
      __init: () => ({}), // used to avoid initial warning of no reducers
    }

    this.getReducers = this.getReducers.bind(this)
    this.register = this.register.bind(this)
    this.setChangeListener = this.setChangeListener.bind(this)
  }

  getReducers() {
    return { ...this.reducers }
  }

  register(name, reducer) {
    if (this.reducers[name] && process.env.NODE_ENV === 'production') {
      if (!isProdEnv)
        console.warn(
          'ReducerRegistry.register reducer name already exists:',
          name,
        )

      return
    }

    if (typeof name !== 'string' || typeof reducer !== 'function') {
      console.log('name', name)
      console.log('reducer', reducer)
      throw new Error('reducerRegistry.js register() param validation failed')
    }

    this.reducers = { ...this.reducers, [name]: reducer }

    if (this.emitChange) {
      this.emitChange(this.getReducers())
    }
  }

  setChangeListener(listener) {
    this.emitChange = listener
  }
}

const reducerRegistry = new ReducerRegistry()

export default reducerRegistry
