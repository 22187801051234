import React from 'react'

import Conditional from '../../../../Auth/shared/Conditional'
import MainNavButton from '../../../../Layout/shared/MainNavButton'

const RecordsMainNavButton = () => (
  <Conditional module="record">
    <MainNavButton iconClass="fa fa-archive fa-2x" to="/records">
      Records
    </MainNavButton>
  </Conditional>
)

export default RecordsMainNavButton
