/* eslint-disable no-use-before-define */
// import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import { map } from 'lodash-es'

import Store from 'services/store'

const PAGE_LIMIT = 10

const initialState = {
  currentSearchQuery: {},
  filesLoading: false,
  filesSearchOptions: undefined,
  filesPage: 1,
  filesResultsPageCount: 1,
  filesResultsById: [],
  filesResultsCount: 0,
}

const moduleName = 'Location.file'

const moduleConstant = name => `${moduleName}/${name}`

// selectors
// export const selectAuth = state => state.Auth
// export const regionsByIdSelector = createSelector(selectRegions, regionList =>
//   createHashByKey(regionList, 'id'),
// )

// types

// actions
export const clearFiles = createAction('clearFiles')
export const setCurrentFetchQuery = createAction('setCurrentFetchQuery')
export const setFilesLoading = createAction('setFilesLoading')
export const setFilesPage = createAction('filesPage')
export const setFileResultsPageCount = createAction('filesResultsPageCount')
export const setFileSearchOptions = createAction('setFileSearchOptions')
export const setFileResultsById = createAction(
  moduleConstant('setFileResultsById'),
)

// note: to refetch current query, pass no args
export const fetchFiles = (opts) => (
  dispatch,
  getState,
) => {
  dispatch(setFilesLoading(true))

  let currentSearchQuery

  if (!opts) {
    currentSearchQuery = getState().Location.file.currentSearchQuery
  } else {
    const { locationId, page } = opts

    const where = {
      linkModelId: locationId,
      linkModelName: 'location',
    }

    currentSearchQuery = {
      limit: PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
      orderBy: [['createdAt', 'desc']],
      where,
    }
  }

  dispatch(setCurrentFetchQuery(currentSearchQuery))

  return Store.File.findAll(currentSearchQuery, {
    headers: { count: true },
    raw: true,
  })
    .then(response => {
      let count
      let files

      // when it's a cached response, it's an array of records
      if (Array.isArray(response)) {
        count = getState().Location.file.filesResultsCount
        files = response
      } else {
        count = response.headers.count
        files = response.data
      }

      dispatch(setFileResultsById({ count, results: map(files, 'id') }))
      dispatch(setFileResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
      dispatch(setFilesLoading(false))

      return files
    })
    .catch(err => {
      console.error(`Location.files File.findAll error`, err)
      // todo show system toast err
      dispatch(setFilesLoading(false))
    })
}

// reducer
const reducer = handleActions(
  {
    [clearFiles]: () => ({
      ...initialState,
    }),

    [setCurrentFetchQuery]: (state, action = {}) => ({
      ...state,
      currentSearchQuery: action.payload,
    }),

    [setFilesLoading]: (state, action = {}) => ({
      ...state,
      filesLoading: action.payload,
    }),

    [setFilesPage]: (state, action = {}) => ({
      ...state,
      filesPage: action.payload,
    }),

    [setFileResultsById]: (state, action = {}) => ({
      ...state,
      filesResultsById: action.payload.results,
      filesResultsCount: action.payload.count,
    }),

    [setFileResultsPageCount]: (state, action = {}) => ({
      ...state,
      filesResultsPageCount: action.payload,
    }),

    [setFileSearchOptions]: (state, action = {}) => ({
      ...state,
      filesSearchOptions: action.payload,
    }),
  },
  initialState,
)

export default reducer
