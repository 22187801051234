import React from 'react'

import Conditional from '../../../../Auth/shared/Conditional'
import MainNavButton from '../../../../Layout/shared/MainNavButton/index'

const AdminMainNavButton = () => (
  <Conditional
    permissionOr={[
      'organization.canChangeSettings',
      'organization.canEdit',
      'organization.canEditModules',
      'organization.canManageBilling',
      'region.canEdit',
      'region.canChangeSettings',
    ]}
  >
    <MainNavButton iconClass="fa fa-cogs fa-2x" to="/admin">
      Admin
    </MainNavButton>
  </Conditional>
)

export default AdminMainNavButton
