import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DateTime from '@therms/web-common/components/DateTime'
import UserName from '../../User/shared/UserName'
import useScheduleTimelogs from '../hooks/useScheduleTimelogs'
import { orderBy } from 'lodash-es'
import { formatTimelogAction } from '../util'
import FAIcon from '@components/Icon/FAIcon'

const ScheduleTimeLog = ({ scheduleShiftId, timelogDesc }) => {
  const [log, setLog] = useState([])

  const { data: scheduleTimelogs } = useScheduleTimelogs({
    orderBy: [['clockInTimestamp', 'desc']],
    where: { scheduleShiftId },
  })

  useEffect(() => {
    const _log = []

    scheduleTimelogs.forEach(({ clockInTimestamp, clockOutTimestamp, breaklog, userId }) => {
      _log.push({ action: formatTimelogAction('clockin'), timestamp: clockInTimestamp, userId })

      if (clockOutTimestamp)
        _log.push({ action: formatTimelogAction('clockout'), timestamp: clockOutTimestamp, userId })

      breaklog.forEach((breakAction) => {
        _log.push({
          action: formatTimelogAction(breakAction.action),
          timestamp: breakAction.timestamp,
          userId,
        })
      })
    })

    setLog(orderBy(_log, ['timestamp'], timelogDesc ? ['desc'] : ['asc']))
  }, [scheduleTimelogs, timelogDesc])

  return (
    <div>
      {log.map(({ action, timestamp, userId }) => {
        const isClockIn = /clock-in/gi.test(action)
        const isClockOut = /clock-out/gi.test(action)
        const className = isClockIn ? 'text-success' : ''
        let iconName = `fa fa-`

        if (isClockIn) {
          iconName += 'sign-in-alt'
        } else if (isClockOut) {
          iconName += 'sign-out-alt'
        }

        return (
          <div className="" key={timestamp}>
            <small className="font-weight-light">
              <UserName id={userId} />
            </small>

            <div className={`d-flex justify-content-between ${className}`}>
              <div>
                {iconName && <FAIcon className="mr-1" name={iconName} />}
                <span className="label">{action}</span>{' '}
                <small className="font-weight-light text-muted">
                  <DateTime relativeTime timestamp={timestamp} />
                </small>
              </div>

              <DateTime timestamp={timestamp} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

ScheduleTimeLog.propTypes = {
  scheduleShiftId: PropTypes.string.isRequired,
  timelogDesc: PropTypes.bool,
}
ScheduleTimeLog.defaultProps = {
  timelogDesc: true,
}

export default ScheduleTimeLog
