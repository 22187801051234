// import React from 'react'
import PropTypes from 'prop-types'
import Email from '@therms/web-common/components/Email'

const UserEmail = Email

UserEmail.propTypes = {
  email: PropTypes.string,
}
UserEmail.defaultProps = {
  email: '',
}

export default UserEmail
