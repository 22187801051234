import React, { useCallback } from 'react'
import * as Sentry from '@sentry/browser'

import useSchedulePositions from '../../../hooks/useSchedulePositions'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import ModalController from '@therms/web-common/components/Modal/ModalController'
import NewPositionModal from '../../../components/Position/PositionFormModal'
import PositionChip from '../../../components/Position/PositionChip'

const SchedulePositions = () => {
  const { data: positions, destroyById } = useSchedulePositions()

  const handlePositionDestroy = useCallback(async (id) => {
    try {
      await destroyById(id)
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [])

  return (
    <div>
      <h5>
        Schedule Positions
        <small className="d-block text-muted">Officer roles to be assigned on their shifts</small>
      </h5>

      <div className="d-flex flex-wrap mx-n1 mt-2">
        {positions?.map(({ id, name }) => (
          <div className="m-1" key={id}>
            <PositionChip name={name} onDestroy={() => handlePositionDestroy(id)} />
          </div>
        ))}
      </div>

      <ModalController modal={NewPositionModal}>
        {({ show }) => (
          <button className="btn btn-sm btn-secondary mt-1" type="button" onClick={show}>
            <FAIcon name="plus" /> <span className="ml-2">New Position</span>
          </button>
        )}
      </ModalController>
    </div>
  )
}

export default SchedulePositions
