import React from 'react'
import PropTypes from 'prop-types'
import useSchedulePosition from '../../hooks/useSchedulePosition'

const PositionName = ({ id }) => {
  const { data } = useSchedulePosition(id)

  if (!id) return null

  return data?.name || <span className="loading" />
}

PositionName.propTypes = {
  id: PropTypes.string.isRequired,
}
PositionName.defaultProps = {}

export default PositionName
