import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ShiftBadge from './ShiftBadge'
import ItemTypes from './DnD/ItemTypes'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { systemToast } from '../../../../../utils/toaster'

import './ShiftDraggable.scss'
import LayoverRelative from '@therms/web-common/components/Layover/Relative'
import { useKeyPress } from '../../../../../hooks/useKeyPress'

const ShiftDraggable = ({ scheduleShift }) => {
  const isAltPressed = useKeyPress('Alt')

  const canDrag = useMemo(() => !scheduleShift?.metadata?.isClockedIn, [scheduleShift])

  const [{ isDragging }, drag, preview] = useDrag({
    canDrag,
    item: { type: ItemTypes.SHIFT, scheduleShift },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const onDragStart = useCallback(() => {
    if (!canDrag) systemToast(`You can't move this shift`, { quick: true, warn: true })
  }, [canDrag])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <div
      className={`d-inline-block ${
        isDragging ? 'animate-flash animate-infinite' : ''
      } shift-draggable-container`}
      onDragStart={onDragStart}
      style={{ opacity: isDragging ? 0.4 : 1 }}
    >
      <div className="position-relative" ref={drag}>
        <ShiftBadge scheduleShiftId={scheduleShift.id} />
        {isAltPressed && <LayoverRelative>Clone Shift</LayoverRelative>}
      </div>
    </div>
  )
}

ShiftDraggable.propTypes = {
  scheduleShift: PropTypes.object.isRequired,
}
ShiftDraggable.defaultProps = {}

export default ShiftDraggable
