// duck for current region
import { createSelector } from 'reselect'
import get from 'lodash-es/get'
import StateStore from 'state'
import Store from 'services/store'

// selectors
export const selectCurrentRegion = state => state.Auth.region

export const selectModuleSettings = (state, moduleName) =>
  get(state, `Auth.regionMetadata.settings.module.${moduleName}`, undefined)

export const regionModuleSettingsSelector = createSelector(
  selectModuleSettings,
  settings => settings,
)

// actions
export const updateRegionModuleSettings = (moduleName, settingsValue) => {
  const {
    Auth: { regionMetadata },
  } = StateStore.getState()

  const currentSettings = regionMetadata.settings

  return Store.RegionMetadata.update(regionMetadata.id, {
    settings: {
      ...currentSettings,
      module: {
        ...currentSettings.module,
        [moduleName]: settingsValue,
      },
    },
  })
}
