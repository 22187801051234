import React from 'react'
// import PropTypes from 'prop-types'

import Routes from 'modules/Routes'

import './index.scss'

const MainScene = () => (
  <div className="main-scene" id="main-scene">
    <Routes />
  </div>
)

MainScene.propTypes = {}
MainScene.defaultProps = {}

export default MainScene
