import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchRegion, regionByIdSelector } from 'state/ducks/region'

const RegionName = ({ id }) => {
  const dispatch = useDispatch()

  const currentRegion = useSelector(state => state.Auth.region)
  const region = useSelector(state => regionByIdSelector(state, id))

  const [loading, setLoading] = useState(Boolean(id) && !Boolean(region))

  useEffect(() => {
    if (!region && id) {
      setLoading(true)

      dispatch(fetchRegion(id)).then(() => setLoading(false))
    }
  }, [id, region,])

  if (loading) return <span className="loading" />

  const { name } = region || currentRegion

  return <span>{name}</span>
}

RegionName.propTypes = {
  id: PropTypes.string,
}
RegionName.defaultProps = {
  id: undefined,
}

export default RegionName
