import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'
import StateStore from '..'
import Store, { registerStoreChangeHandler } from '../../services/store'

const reducerName = 'region'

const {
  actions,
  // actionCreators,
  createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'regions',
  reducerName,
  singular: 'region',
  storeMapperName: 'region',
})

const initialState = {
  ...initialStateBoilerplate,
  regionMetadataByRegionId: {},
}

// selects
const selectRegionMetadataByRegionId = (state, regionId) =>
  state.region.regionMetadataByRegionId[regionId]

// selectors
export const { regionByIdSelector, regionLoadingByIdSelector } = selectors

export const regionMetadataByRegionIdSelector = createSelector(
  selectRegionMetadataByRegionId,
  regionMetadata => regionMetadata,
)

// actionCreators
const SET_REGIONMETADATA = createAction(createActionName(`SET_REGIONMETADATA`))

// actions
export const { fetchRegions } = actions

export const fetchRegion = id => dispatch => Store.RegionMetadata.findOne({ regionId: id }).then(regionMetadata => {
    dispatch(SET_REGIONMETADATA(regionMetadata))

    return dispatch(actions.fetchRegion(id))
  })

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,

    [SET_REGIONMETADATA]: (state, action) => ({
      ...state,
      regionMetadataByRegionId: {
        ...state.regionMetadataByRegionId,
        [action.payload.regionId]: action.payload,
      },
    }),
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
registerStoreChangeHandler('regionMetadata', ({ event, payload }) => {
  if (event !== 'add') return

  payload.forEach(regionMetadata =>
    StateStore.dispatch(SET_REGIONMETADATA(regionMetadata)),
  )
})
