import { isProdEnv } from 'config/env'

function retry(fn, retriesLeft = 5, interval = 1000) {
  if (!isProdEnv) return fn()

  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        console.log('')
        console.error('lazy import error', error)
        console.log('')
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
          } else {
            // Passing on "reject" is the important part
            retry(fn, retriesLeft - 1, interval).then(resolve, reject)
          }
        }, interval)
      })
  })
}

export default retry
