import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Build from './screens/Build'
import ErrorBoundaryWithLogo from '../../components/ErrorBoundary/ErrorBoundaryWithLogo'
import Index from './screens/Index'
import RouteBreadcrumbs from './components/RouteBreadcrumbs'
import ScheduleSettingsScreen from './screens/Settings'
import ScheduleTimelogsScreen from './screens/Timelogs'

export const ROUTE_SCHEDULE_BUILD = '/schedule/build'
export const ROUTE_SCHEDULE_TIMELOGS = '/schedule/timelogs'
export const ROUTE_SCHEDULE_INDEX = '/schedule'
export const ROUTE_SCHEDULE_SETTINGS = '/schedule/settings'

const RecordsRoutes = () => (
  <div>
    <RouteBreadcrumbs />

    <div className="module-content">
      <ErrorBoundaryWithLogo>
        <Switch>
          <Route path={ROUTE_SCHEDULE_BUILD} component={Build} />

          <Route path={ROUTE_SCHEDULE_SETTINGS} component={ScheduleSettingsScreen} />

          <Route path={ROUTE_SCHEDULE_TIMELOGS} component={ScheduleTimelogsScreen} />

          <Route path={ROUTE_SCHEDULE_INDEX} component={Index} />
        </Switch>
      </ErrorBoundaryWithLogo>
    </div>
  </div>
)

export default withRouter(RecordsRoutes)
