/* eslint-disable no-use-before-define */
import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'
import reducerRegistry from '../reducerRegistry'
import { uniqBy } from 'lodash-es'

import { createHashByKey } from '@utils'
import StateStore from '../index'
import Store, { registerStoreChangeHandler } from '../../services/store'

// import StateStore from '..'

const initialState = {
  loadingMail: false,
  mailFolders: [],
  mailFoldersById: {},
  mail: [],
  mailById: {},
  mailLoadingById: {},
}

const reducerName = 'mail'

const createActionName = name => `${reducerName}/${name}`

// selectors
export const selectMailById = (state, id) => {
  if (Array.isArray(id))
    return id.reduce(
      (acc, _id) => acc.push(state.mail.mailById[_id]) && acc,
      [],
    )

  return state.mail.mailById[id]
}

const selectMailLoadingById = (state, id) => state.mail.mailLoadingById[id]

export const mailByIdSelector = createSelector(
  selectMailById,
  mail => mail,
)

export const mailLoadingByIdSelector = createSelector(
  selectMailLoadingById,
  loading => Boolean(loading),
)

// action creators
export const SET_MAIL = createAction(createActionName('SET_MAIL'))
export const SET_MAILFOLDERS = createAction(createActionName('SET_MAILFOLDERS'))
export const SET_MAIL_ID = createAction(createActionName('SET_MAIL_ID'))
export const SET_LOADING_MAIL = createAction(
  createActionName('SET_LOADING_MAIL'),
)
export const SET_LOADING_MAIL_ID = createAction(
  createActionName('SET_LOADING_MAIL_ID'),
)

// actions
export const fetchOneMail = id => dispatch => {
  const record = Store.Mail.get(id)
  let promise

  if (record) promise = Promise.resolve(record)
  else {
    dispatch(SET_LOADING_MAIL_ID({ id, loading: true }))
    promise = Store.Mail.find(id)
  }

  return promise
    .then(mail => {
      if (!mail) throw new Error('mail not found')

      dispatch(SET_MAIL_ID(mail))
      dispatch(SET_LOADING_MAIL_ID({ id, loading: false }))
    })
    .catch(() => {
      // todo: show system error
      dispatch(SET_LOADING_MAIL_ID({ id, loading: false }))
    })
}

export const fetchMailFolders = () => dispatch => {
  Store.MailFolder.findAll({
    orderBy: ['name'],
  })
    .then(mailFolders => {
      dispatch(SET_MAILFOLDERS(mailFolders))

      return mailFolders
    })
    .catch(() => {
      // todo: show system error
    })
}

export const fetchMail = ({
  limit = 20,
  offset = 0,
  where = {},
} = {}, opts) => dispatch => {
  dispatch(SET_LOADING_MAIL(true))

  return Store.Mail.findAll({
    limit,
    offset,
    orderBy: [['createdAt', 'desc']],
    where,
  }, opts)
    .then(mail => {
      dispatch(SET_MAIL(mail))
      dispatch(SET_LOADING_MAIL(false))

      return mail
    })
    .catch(() => {
      // todo: show system error
      dispatch(SET_LOADING_MAIL(false))
    })
}

// reducer
const reducer = handleActions(
  {
    [SET_LOADING_MAIL_ID]: (state, action) => ({
      ...state,
      mailLoadingById: {
        ...state.mailLoadingById,
        [action.payload.id]: action.payload.loading,
      },
    }),
    [SET_LOADING_MAIL]: (state, action) => ({
      ...state,
      loadingMail: action.payload,
    }),

    [SET_MAIL_ID]: (state, action) => ({
      ...state,
      mail: uniqBy([action.payload, ...state.mail], 'id'),
      mailById: {
        ...state.mailById,
        [action.payload.id]: action.payload,
      },
    }),

    [SET_MAILFOLDERS]: (state, action) => ({
      ...state,
      mailFolders: uniqBy([...action.payload, ...state.mailFolders], 'id'),
      mailFoldersById: {
        ...state.mailById,
        [action.payload.id]: action.payload,
      },
    }),

    [SET_MAIL]: (state, action) => ({
      ...state,
      mail: uniqBy([...action.payload, ...state.mail], 'id'),
      mailById: {
        ...state.mailById,
        ...createHashByKey(action.payload, 'id'),
      },
    }),
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeHandler('mail', ({ event, payload }) => {
  if (event === 'add') StateStore.dispatch(SET_MAIL(payload))
  // else if (event === 'remove')
  //   StateStore.dispatch(REMOVE_DISPATCHCALLS(payload)) // todo: no action implemented
})
