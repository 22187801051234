import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import reducerRegistry from '../reducerRegistry'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import Store from '../../services/store'
import { sortBy } from 'lodash-es'
// import Store from 'store'

const reducerName = 'dispatch'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'dispatches',
  reducerName,
  singular: 'dispatch',
  storeMapperName: 'dispatch',
})

const initialState = {
  ...initialStateBoilerplate,
  activeRecordsByLocationId: {},
}

// selectors
export const { dispatchByIdSelector, dispatchLoadingByIdSelector } = selectors

const selectDispatches = state => state.dispatch.dispatches

const selectDispatchesByLocationId = (state, locationId) =>
  state.dispatch.dispatches.filter(d => d.locationId === locationId)

export const dispatchesByLocationIdSelector = createSelector(
  selectDispatchesByLocationId,
  dispatches => dispatches,
)

export const pendingDispatchSelector = createSelector(
  selectDispatches,
  dispatches => dispatches.filter(({ fields: { actionNeeded } }) => Boolean(actionNeeded)),
  // .reduce((acc, { fields: { priority } }) => (priority > acc ? priority : acc), 0),
)

export const pendingDispatchesByLocationIdSelector = createSelector(
  selectDispatchesByLocationId,
  dispatches => dispatches.filter(({ fields: { actionNeeded } }) => Boolean(actionNeeded)),
)

export const pendingDispatchesSelector = createSelector(
  selectDispatches,
  dispatches => dispatches.filter(({ fields: { actionNeeded } }) => Boolean(actionNeeded)),
)

export const recentDispatchesSelector = createSelector(
  selectDispatches,
  dispatches =>
    sortBy(dispatches.filter(({ fields: { actionNeeded } }) => Boolean(!actionNeeded)), [
      'updatedAt',
    ]).slice(0, 10),
)

// actions
export const { fetchDispatch, fetchDispatches } = actions

export const fetchDispatchTextSearch = () => 
  // todo: need to hit search endpoint instead of findAll
   Store.Dispatch.findAll({})


export const fetchPendingDispatches = ({ bypassCache = false } = {}) => async (
  dispatch,
  getState,
) => {
  const pendingDispatchIds = pendingDispatchesSelector(getState()).map(({ id }) => id)

  // refetch current pending dispatch ids so records are up to date
  if (pendingDispatchIds.length)
    await dispatch(
      actions.fetchDispatches(
        {
          where: { id: { isectNotEmpty: pendingDispatchIds } },
        },
        { force: bypassCache },
      ),
    )

  return dispatch(
    fetchDispatches(
      {
        where: {
          'fields.actionNeeded': true,
        },
      },
      { force: bypassCache },
    ),
  )
}

export const fetchRecentDispatches = () => dispatch => dispatch(
    fetchDispatches({
      orderBy: [['timestamp', 'DESC']],
      where: {
        'fields.actionNeeded': { '!=': true },
      },
      limit: 10,
    }),
  )

// action creators
// export const fetchRegion = () => dispatch => {
//   dispatch(SET_DISPATCHES([]))
// }

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
