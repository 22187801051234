import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import UserName from '../../../User/shared/UserName'
import useScheduleShift from '../../hooks/useScheduleShift'
import Loading from '@therms/web-common/components/Loading'
import DateTime from '@therms/web-common/components/DateTime'
import PositionName from '../Position/PositionName'
import ShiftAssignment from './ShiftAssignment'
import dayjs from 'dayjs'
import { humanizeMinutes } from '../../../../utils/date'

const ShiftDetails = ({ id, showDraft }) => {
  const { data, validating } = useScheduleShift(id)

  const scheduleShift = useMemo(() => {
    if (!data) return undefined

    if (showDraft) {
      return { ...data, ...(data?.draft || {}) }
    }

    return data
  }, [data])

  const shiftLength = useMemo(() => {
    const duration = dayjs.duration(dayjs(scheduleShift.dateEnd).diff(scheduleShift.dateStart))

     return humanizeMinutes(duration.asMinutes())
  }, [scheduleShift])

  if (!scheduleShift && validating) return <Loading />

  return (
    <div>
      <div className="alert mb-2 text-center">
        <span className="font-weight-light mr-1">
          <DateTime time={false} timestamp={scheduleShift.dateStart} />
        </span>

        <div>
          <DateTime date={false} timestamp={scheduleShift.dateStart} /> -{' '}
          <DateTime date={false} timestamp={scheduleShift.dateEnd} />
        </div>

        <div>
          <span className="font-weight-light text-dark">({shiftLength})</span>
        </div>
      </div>

      {scheduleShift.name && (
        <div className="mb-2">
          <div className="text-muted">Shift Name</div>
          {scheduleShift.name}
        </div>
      )}

      <div className="d-flex justify-content-between">
        {scheduleShift.assignmentModuleName && (
          <div>
            <ShiftAssignment scheduleShift={scheduleShift} />
          </div>
        )}

        {scheduleShift.schedulePositionId && (
          <div>
            <div className="text-muted">Position</div>
            <PositionName id={scheduleShift.schedulePositionId} />
          </div>
        )}
      </div>

      {scheduleShift?.userId && (
        <div className="mb-2">
          <div className="text-muted">Assigned to</div> <UserName id={scheduleShift.userId} />
        </div>
      )}

      {scheduleShift.shiftNotes && (
        <div className="mt-2 with-whitespace">
          <div className="text-muted">Shift Notes</div>
          {scheduleShift.shiftNotes}
        </div>
      )}
    </div>
  )
}

ShiftDetails.propTypes = {
  id: PropTypes.string.isRequired,
  showDraft: PropTypes.bool,
}
ShiftDetails.defaultProps = {
  showDraft: false
}

export default ShiftDetails
