import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { Field, useForm, useFormState } from 'react-final-form'
import Checkbox from '@therms/web-common/components/Inputs/Checkbox'
import Number from '@therms/web-common/components/Inputs/Number'

const ClockInFields = () => {
  const form = useForm()
  const { values } = useFormState()

  const [canClockInEarly, setCanClockInEarly] = useState(+values?.earlyClockInThresholdMin > 0)

  return (
    <>
      <h5>Timelog (Clock in/out & breaks)</h5>

      <div className="my-2">
        {/* <Field */}
        {/*  component={Checkbox} */}
        {/*  label="Users can clock-in without an assigned Shift" */}
        {/*  name="allowClockInWithoutShift" */}
        {/*  type="checkbox" */}
        {/* /> */}

        <Checkbox
          checked={canClockInEarly}
          label="Users can clock-in early"
          onChange={(checked) => {
            if (!checked) {
              form.change('earlyClockInThresholdMin', undefined)
            }

            setCanClockInEarly(checked)
          }}
        />

        {canClockInEarly && (
          <div className="ml-4">
            <Field
              component={Number}
              defaultValue={5}
              inputClassName="max-width-100"
              label="Early Clock-in Threshold"
              max={60}
              min={0}
              name="earlyClockInThresholdMin"
              subText="Number of minutes a User can clock-in early"
            />
          </div>
        )}
      </div>
    </>
  )
}

ClockInFields.propTypes = {}
ClockInFields.defaultProps = {}

export default ClockInFields
