let envOverride = {}

try {
  envOverride = JSON.parse(localStorage.getItem('ENV_OVERRIDE')) || {}

  if (Object.keys(envOverride).length)
    console.warn('ENV OVERRIDES SET:', envOverride)
} catch (e) {
  console.log('ENV_OVERRIDE parsing errror, must be JSON object', e)
}

export const isProdEnv = process.env.NODE_ENV === 'production'

export default {
  NODE_ENV: envOverride.NODE_ENV || process.env.NODE_ENV,
  RELEASE: process.env.RELEASE || 'dev',

  // browser configs
  AUTH_COOKIE_DOMAIN: envOverride.AUTH_COOKIE_DOMAIN || process.env.AUTH_COOKIE_DOMAIN,
  AUTH_COOKIE_NAME: envOverride.AUTH_COOKIE_NAME || process.env.AUTH_COOKIE_NAME,
  JWT_HEADER_KEY: envOverride.JWT_HEADER_KEY || process.env.JWT_HEADER_KEY,

  // URI's
  DEPLOY_ENV: envOverride.DEPLOY_ENV || process.env.DEPLOY_ENV,
  ADMIN_SERVER: envOverride.ADMIN_SERVER || process.env.ADMIN_SERVER,
  AUTH_SERVER: envOverride.AUTH_SERVER || process.env.AUTH_SERVER,
  AUTH_UI: envOverride.AUTH_UI || process.env.AUTH_UI,
  CORE_SERVER: envOverride.CORE_SERVER || process.env.CORE_SERVER,
  EMAIL_SERVER: envOverride.EMAIL_SERVER || process.env.EMAIL_SERVER,
  FILE_SERVER: envOverride.FILE_SERVER || process.env.FILE_SERVER,
  WS_REALTIME_URL: envOverride.WS_REALTIME_URL || process.env.WS_REALTIME_URL,
}
