/*
  This is a utility for communicating across browser tabs/windows that are open on the same device.
*/
import Vent from '@utils/vent'

class BrowserToBrowserCommunications {
  #vent

  static browserTempId = Math.random()

  static Keys = {
    __B2B_COMMS_CH: '__B2B_COMMS_CH',
  }

  constructor() {
    this.vent = new Vent()

    window.addEventListener('storage', (event) => {
      if (
        event.storageArea !== localStorage ||
        event.key !== BrowserToBrowserCommunications.Keys.__B2B_COMMS_CH
      )
        return

      try {
        const { key, value } = JSON.parse(event.newValue)
        this.vent.publish(key, value)
      } catch (e) {
        console.log('BrowserToBrowserCommunications#ctor listener error', e, event.newValue)
      }
    })
  }

  onMsg(key, cb) {
    const { remove } = this.vent.subscribe(key, cb)

    return { remove }
  }

  publishMsg = (key, value) => {
    try {
      window.localStorage.setItem(
        BrowserToBrowserCommunications.Keys.__B2B_COMMS_CH,
        JSON.stringify({ key, value }),
      )
    } catch (e) {
      console.log('BrowserToBrowserCommunications#publishMsg (key, error)', key, e)
    }
  }
}

export default new BrowserToBrowserCommunications()
