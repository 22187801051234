import React from 'react'
import PropTypes from 'prop-types'

import Conditional from 'modules/Auth/shared/Conditional'
import FAIcon from '@components/Icon/FAIcon'
import DateTime from '@therms/web-common/components/DateTime'

const Read = ({ mail: { readByReceiver, readByReceiverTime, receiverId }, showReadTime }) => {
  const Component = () => (
    <span className="font-small text-info">
      <FAIcon name="eye" /> {showReadTime && <DateTime timestamp={readByReceiverTime} />}
    </span>
  )

  return (
    <Conditional
      isCurrentUser={receiverId}
      render={() => (readByReceiver ? <Component /> : null)}
      renderOnFail={() => (readByReceiver ? <Component /> : null)}
    />
  )
}

Read.propTypes = {
  mail: PropTypes.object.isRequired,
  showReadTime: PropTypes.bool,
}

Read.defaultProps = {
  showReadTime: undefined,
}

export default Read
