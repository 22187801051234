import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useMail from '../hooks/useMail'
import useCurrentUser from '../../Auth/shared/hooks/useCurrentUser'

import Files from 'modules/Files/shared/Files'
import { isUserSenderAndReceiver } from '../utils'
import Loading from '@therms/web-common/components/Loading'
import MailHeaders from './MailHeaders'
import MailControls from './MailControls'
import MailBody from './MailBody'
import PreviousMail from './PreviousMail'

import './ViewMail.scss'

const ViewMail = ({ mailId, onDelete, onFwd, onReply, onReplyAll }) => {
  const initialRender = useRef(true)
  const { data: mail, markAsRead, validating } = useMail(mailId)
  const { id: currentUserId } = useCurrentUser()

  useEffect(() => {
    if (!mail) return
    // only do this check once - if the user marks msg as unread we don't want it to mark it back to read
    if (!initialRender.current) return
    if (mail.read) return
    if (currentUserId === mail.senderId && !isUserSenderAndReceiver(currentUserId, mail)) return

    markAsRead()
  }, [currentUserId, mail])

  useEffect(() => {
    initialRender.current = false
  }, [])

  if (validating) return <Loading centered />

  if (!mail) return null

  return (
    <div>
      <div>
        <div className="main-mail-top-container">
          <div className="main-mail-headers-container">
            <MailHeaders mail={mail} />
          </div>

          <MailControls
            mailId={mailId}
            onDelete={onDelete}
            onFwd={onFwd}
            onReply={onReply}
            onReplyAll={onReplyAll}
          />
        </div>
        <hr />

        <div>
          <div className="p-3">
            <MailBody mail={mail} />
          </div>

          <Files fileUrls={mail.files} />
        </div>
      </div>

      <div className="mt-2">
        <PreviousMail previsousMailId={mail.previsousMailId} />
      </div>
    </div>
  )
}

ViewMail.propTypes = {
  mailId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFwd: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  onReplyAll: PropTypes.func.isRequired,
}

ViewMail.defaultProps = {}

export default ViewMail
