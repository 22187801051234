import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

const reducerName = 'userAccount'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'userAccounts',
  reducerName,
  singular: 'userAccount',
  storeMapperName: 'userAccount',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const { userAccountByIdSelector, userAccountLoadingByIdSelector } = selectors

export const selectUserAccountsById = state => state.userAccount.userAccountsById

export const userAccountByUserIdSelector = createSelector(
  state => state.userAccount.userAccountsById,
  (state, userId) => state.user.usersById[userId],
  (userAccountsById, user) => user && userAccountsById[user.userAccountId],
)

// actions
export const { fetchUserAccount, fetchUserAccounts } = actions

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
