import React from 'react'

const UserScheduledHoursContext = React.createContext(undefined)

// eslint-disable-next-line react/prop-types
export function UserScheduledHoursContextProvider({ hoursByUserId, children, totalHoursScheduled }) {
  return (
    <UserScheduledHoursContext.Provider value={{ hoursByUserId, totalHoursScheduled }}>
      {children}
    </UserScheduledHoursContext.Provider>
  )
}

export function useUserScheduledHoursContext(contextRequired = false) {
  const ctx = React.useContext(UserScheduledHoursContext)

  if (contextRequired && ctx === undefined) {
    throw new Error(
      'UserScheduledHoursContext must be a child below UserScheduledHoursContextProvider',
    )
  }

  return {
    hoursByUserId: ctx.hoursByUserId,
    totalHoursScheduled: ctx.totalHoursScheduled,
  }
}
