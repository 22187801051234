/* eslint-disable no-use-before-define */
import reducerRegistry from 'state/reducerRegistry'
import { createAction as raCreateAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import { inactiveUsersSelector, selectUsers, SET_USERS } from '../../../state/ducks/user'
import { selectCurrentRegion } from '../../Auth/ducks/region'
import coreApi from '../../../services/coreApi'

const initialState = {}

const moduleName = 'Roster'
const createActionName = (name) => `${moduleName}/${name}`
const createAction = (name) => raCreateAction(createActionName(name))

// selectors
export const allUsersSelector = createSelector(selectUsers, (users) =>
  users.filter(({ active }) => active),
)

export const activeDefaultRegionUsersSelector = createSelector(
  selectCurrentRegion,
  selectUsers,
  (region, users) =>
    users.filter(({ active, defaultRegionId }) => active && defaultRegionId === region.id),
)

export const activeRegionUsersSelector = createSelector(
  selectCurrentRegion,
  selectUsers,
  (region, users) =>
    users.filter(({ active }) => active).filter(({ regionIds }) => regionIds.includes(region.id)),
)

export const inactiveRegionUsersSelector = createSelector(
  selectCurrentRegion,
  inactiveUsersSelector,
  (region, users) => users.filter(({ regionIds }) => regionIds.includes(region.id)),
)

// types
export const fetchUsersSuccess = createAction('fetch users success')

// actions
export const fetchUsersForAllRegionIds = () => async (dispatch) => {
  try {
    const { data: users } = await coreApi.get('/user/allRegions')

    dispatch(SET_USERS(users))

    return users
  } catch (e) {
    console.log('fetchUsersForAllRegionIds()', e)
    return []
  }
}

// reducer
const reducer = handleActions(
  {
    // [setLoadingUsers]: (state, action) => ({
    //   ...state,
    //   loadingUsers: action.payload,
    // }),
  },
  initialState,
)

export default reducer

reducerRegistry.register(moduleName, reducer)
