import React from 'react'
// import PropTypes from 'prop-types'

import './NoConnectionIcon.scss'

const NoConnectionIcon = () => (
  <div className="d-inline-block no-connection-icon-container position-relative">
    <span
      className="animate-flash-slow animate-infinite fa-stack"
      style={{ left: '-10px', position: 'absolute' }}
    >
      <i className="fas fa-bolt fa-stack-1x text-danger" style={{ fontSize: '65%' }} />
      <i className="fas fa-ban fa-stack-1x text-muted" style={{ opacity: 0.6 }} />
    </span>
  </div>
)

NoConnectionIcon.propTypes = {}
NoConnectionIcon.defaultProps = {}

export default NoConnectionIcon
