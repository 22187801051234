/* eslint-disable no-use-before-define */
// import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import { map } from 'lodash-es'

import Store from 'services/store'

const PAGE_LIMIT = 20

const initialState = {
  accessLogLoading: false,
  accessLogSearchOptions: undefined,
  accessLogPage: 1,
  accessLogResultsPageCount: 1,
  accessLogResultsById: [],
  accessLogResultsCount: 0,
}

const moduleName = 'LocationAccessLog'

const moduleConstant = name => `${moduleName}/${name}`

// selectors
// export const selectAuth = state => state.Auth
// export const regionsByIdSelector = createSelector(selectRegions, regionList =>
//   createHashByKey(regionList, 'id'),
// )

// types

// actions
export const clearAccessLog = createAction('clearAccessLog')
export const setAccessLogLoading = createAction('setAccessLogLoading')
export const setAccessLogPage = createAction('accessLogPage')
export const setAccessLogResultsPageCount = createAction(
  'accessLogResultsPageCount',
)
export const setAccessLogSearchOptions = createAction(
  'setAccessLogSearchOptions',
)
export const setAccessLogResultsById = createAction(
  moduleConstant('setAccessLogResultsById'),
)

export const fetchAccessLogs = ({ locationId, page = 1 } = {}) => (dispatch, getState) => {
  dispatch(setAccessLogLoading(true))

  const where = {
    locationId
  }

  return Store.LocationAccessLog.findAll(
    {
      limit: PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
      orderBy: [['timeIn', 'desc']],
      where,
    },
    { headers: { count: true }, raw: true },
  )
    .then(response => {
      let count
      let accesss

      // when it's a cached response, it's an array of records
      if (Array.isArray(response)) {
        count = getState().Location.accessLog.accessLogResultsCount
        accesss = response
      } else {
        count = response.headers.count
        accesss = response.data
      }

      dispatch(setAccessLogResultsById({ count, results: map(accesss, 'id') }))
      dispatch(setAccessLogResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
      dispatch(setAccessLogLoading(false))

      return accesss
    })
    .catch(err => {
      console.error(`LocationAccessLog.findAll error`, err)
      // todo show system toast err
      dispatch(setAccessLogLoading(false))
    })
}

// reducer
const reducer = handleActions(
  {
    [clearAccessLog]: () => ({
      ...initialState,
    }),
    [setAccessLogLoading]: (state, action = {}) => ({
      ...state,
      accessLogLoading: action.payload,
    }),

    [setAccessLogPage]: (state, action = {}) => ({
      ...state,
      accessLogPage: action.payload,
    }),

    [setAccessLogResultsById]: (state, action = {}) => ({
      ...state,
      accessLogResultsById: action.payload.results,
      accessLogResultsCount: action.payload.count,
    }),

    [setAccessLogResultsPageCount]: (state, action = {}) => ({
      ...state,
      accessLogResultsPageCount: action.payload,
    }),

    [setAccessLogSearchOptions]: (state, action = {}) => ({
      ...state,
      accessLogSearchOptions: action.payload,
    }),
  },
  initialState,
)

export default reducer
