import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import Viewport from 'viewport-dimensions'
import * as Sentry from '@sentry/browser'

import Desktop from './Desktop/index'
import LayoutContext from './shared/LayoutContext'
import Mobile from './Mobile/index'

import LAYOUT from '@config/layout'

// a component that negotiates whether to display the desktop or mobile layout
class Layout extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)

    this.state = {
      isMobile: this.isMobile(),
      showLeftMenu: false,
      showRightMenu: false,
    }

    this.handleWindowResizeEvent = debounce(this.windowResizeListener, 250)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResizeEvent)

    this.setMainContainerHeight()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResizeEvent)
  }

  getContext = () => ({
      ...this.state,
      setShowLeftMenu: this.setShowLeftMenu,
      setShowRightMenu: this.setShowRightMenu,
    })

  isMobile = () => {
    const width = Viewport.width()

    if (width > LAYOUT.breakpointDesktop) {
      return false
    }
    if (width <= LAYOUT.breakpointDesktop) {
      return true
    }

    return this.state.isMobile
  }

  setMainContainerHeight = () => {
    // https://americansoftware.atlassian.net/browse/WAPP-161
    try {
      const $container = document.querySelector('.layout-main-container')

      if (!$container) return

      $container.style.height = `${document.querySelector(
        '#root',
      ).offsetHeight -
      document.querySelector('.header').offsetHeight -
      5}px`
    } catch (e) {
      Sentry.addBreadcrumb('calling setMainContainerHeight()')
      Sentry.captureException(e)
    }
  }

  setShowLeftMenu = (showLeftMenu = false) =>
    this.setState({
      showLeftMenu,
    })

  setShowRightMenu = (showRightMenu = false) =>
    this.setState({
      showRightMenu,
    })

  windowResizeListener = () => {
    const isMobile = this.isMobile()

    this.setMainContainerHeight()

    if (isMobile === this.state.isMobile) return

    this.setState({
      isMobile,
    })
  }

  render() {
    const LayoutComponent = this.state.isMobile ? Mobile : Desktop

    return (
      <LayoutContext.Provider value={this.getContext()}>
        <LayoutComponent />
      </LayoutContext.Provider>
    )
  }
}

export { Layout }

export default Layout
