import { createContext } from 'react'

// DO NOT SET ANY DEFAULT VALUE
export default createContext()

// location: {}, // don't use as default because of truthy checks on `location`
// locationId: undefined,

// if more than 1 location then the id's will be listed,
//    ie: a Report with multiple Locations selected
// locationIds: [],
