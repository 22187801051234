import { combineReducers, createStore } from 'redux'

import middleware from './middleware'
import reducerRegistry from './reducerRegistry'

const initialState = {}

// Preserve initial state for not-yet-loaded reducers
const combine = reducers => {
  const newReducers = { ...reducers }

  const reducerNames = Object.keys(reducers)

  Object.keys(initialState).forEach(item => {
    if (reducerNames.indexOf(item) === -1) {
      newReducers[item] = (state = null) => state
    }
  })

  return combineReducers(newReducers)
}

const reducer = combine(reducerRegistry.getReducers())
const store = createStore(reducer, initialState, middleware)

// Replace the store's reducer whenever a new reducer is registered.
reducerRegistry.setChangeListener(reducers => {
  store.replaceReducer(combine(reducers))
})

// add our reducerRegistry.register method to the store
if (store.reducerRegistry) {
  throw new Error('Redux store already contains a reducerRegistry method')
} else {
  store.reducerRegistry = reducerRegistry
}

// add our reducerRegistry.register method to the store
if (store.addReducer) {
  throw new Error('Redux store already contains a addReducer method')
} else {
  store.addReducer = reducerRegistry.register
}

export default store
