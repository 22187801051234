import React from 'react'

import Conditional from '../../../../Auth/shared/Conditional'
import MainNavButton from '../../../../Layout/shared/MainNavButton'

const ScheduleMainNavButton = () => (
  <Conditional module="schedule">
    <MainNavButton iconClass="fa fa-calendar-alt fa-2x" to="/schedule">
      Schedule
    </MainNavButton>
  </Conditional>
)

export default ScheduleMainNavButton
