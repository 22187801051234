import useCoreModelsFindAll from 'hooks/useCoreModelsFindAll'

export default (query = {}, opts) =>
  useCoreModelsFindAll(
    'scheduleShift',
    {
      limit: query?.where ? 2000 : 0,
      ...query,
      where: { ...(query?.where || {}), _deleted: null },
    },
    opts,
  )
