import { REGEX_VALID_RASTER_IMAGE_FORMATS } from '../../constants'

export const buildFilesFromUrls = (fileUrls) => Promise.all(
    fileUrls.map((fileUrl) =>
      fetch(fileUrl, { method: 'HEAD' }).then((res) => {
        const date = res.headers.get('last-modified')
        const type = res.headers.get('content-type')
        const size = res.headers.get('content-length')

        const thumbnailUrl = REGEX_VALID_RASTER_IMAGE_FORMATS.test(type)
          ? `${fileUrl}.thumbnail`
          : undefined

        // eslint-disable-next-line no-use-before-define
        return new FileTemplate({
          size,
          statusText: new Date(date).toString(),
          thumbnailUrl,
          type,
          url: fileUrl,
        })
      }),
    ),
  )

/**
 * A template object for use in the FilePicker component
 *
 * @param size
 * @param statusText
 * @param thumbnailUrl
 * @param type
 * @param url
 * @return {{size: *, statusText: *, type: *, url: *, thumbnailUrl: *}}
 * @constructor
 */
export function FileTemplate({ size, statusText, thumbnailUrl, type, url }) {
  return {
    size,
    statusText,
    thumbnailUrl,
    type,
    url,
  }
}
