import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ellipsize from 'ellipsize'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import ImageThumbnail from './ImageThumbnail'
import MimeTypeFAIcon from '@therms/web-common/components/Icon/MimeTypeFAIcon'

import { REGEX_VALID_IMAGE_FORMATS } from 'modules/Files/constants'
import './FileCard.scss'

const FileCard = ({ ellipsed, downloading, file, onClick }) => {
  const [delaying, setDelaying] = useState(false)
  const { contentType, name, thumbnailUrl, url } = file

  useEffect(() => {
    if (delaying) {
      const t = setTimeout(() => {
        setDelaying(false)
      }, 2000)

      return () => clearTimeout(t)
    }

    return undefined
  }, [delaying])

  if (delaying || downloading)
    return (
      <div className="file-card-container bg-secondary-opacity-25 onhover-highlight">
        <FAIcon className="fa-spin" name="circle-notch" />
      </div>
    )

  if (REGEX_VALID_IMAGE_FORMATS.test(contentType))
    return (
      <ImageThumbnail
        contentType={contentType}
        url={url}
        thumbnailUrl={thumbnailUrl}
        onError={() => setDelaying(true)}
        onClick={onClick}
      />
    )

  return (
    <div
      className="d-flex file-card-container flex-row bg-secondary-opacity-25 onhover-highlight p-3"
      onClick={onClick}
    >
      <MimeTypeFAIcon lg mimeType={contentType} />

      {(!!contentType || !!name) && (
        <div className="ml-3">
          {!!contentType && (
            <p className="mb-0 text-secondary font-weight-bold font-small">{contentType}</p>
          )}
          {!!name && <p className="mb-0 font-small">{ellipsed ? ellipsize(name, 25) : name}</p>}
        </div>
      )}
    </div>
  )
}

FileCard.propTypes = {
  ellipsed: PropTypes.bool,
  downloading: PropTypes.bool,
  file: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

FileCard.defaultProps = {
  ellipsed: true,
  downloading: false,
  onClick: undefined,
}

export default FileCard
