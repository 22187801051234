import useCoreModelsFindAll from '../../../../hooks/useCoreModelsFindAll'
import useCurrentUser from '../../../Auth/shared/hooks/useCurrentUser'

export default function useUserStatus(userId, opts) {
  const user = useCurrentUser()

  const userStatus = useCoreModelsFindAll(
    'userStatus',
    { where: { userId: userId || user.id } },
    opts,
  )

  return userStatus.data[0]
}
