import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash-es'

import { fetchRegions } from 'state/ducks/region'
import { uniqueUserOnlineSelector } from '../../../state/ducks/online'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Loading from '@therms/web-common/components/Loading'
import RegionName from '../shared/RegionName'

const SelectableRegionList = ({ activeRegionId, onSelectRegion, regionIds }) => {
  const [loading, setLoading] = useState(true)
  const [regions, setRegions] = useState([])

  const dispatch = useDispatch()

  const onlineRecords = useSelector(uniqueUserOnlineSelector)

  useEffect(() => {
    dispatch(fetchRegions({ where: { id: { isectNotEmpty: regionIds } } }))
      .then(_regions => {
        setRegions(sortBy(_regions, ['name']))
      })
      .finally(() => setLoading())
  }, [dispatch, regionIds])

  if (loading) return <Loading />

  return (
    <div>
      <ul className="list-group">
        {regions.map(({ id }) => {
          const current = activeRegionId === id
          const onlineCount = onlineRecords.filter(online => online.regionIds.includes(id)).length

          return (
            <li
              className={classnames('cursor list-group-item onhover-bg-secondary', {
                active: current,
              })}
              key={id}
              onClick={() => onSelectRegion(id)}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <span
                    className={classnames({
                      'font-weight-bold': current,
                    })}
                  >
                    <RegionName id={id} />
                  </span>
                  {current && <span className="font-small"> (Current Region)</span>}
                </div>

                {onlineCount > 0 && (
                  <span className="font-small text-info">
                    <FAIcon name="users" /> {onlineCount} online
                  </span>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

SelectableRegionList.propTypes = {
  activeRegionId: PropTypes.string.isRequired,
  onSelectRegion: PropTypes.func.isRequired,
  regionIds: PropTypes.arrayOf(PropTypes.object).isRequired,
}
SelectableRegionList.defaultProps = {}

export default SelectableRegionList
