import { useCallback } from 'react'
import useCoreModelsFind from 'hooks/useCoreModelsFind'

export default mailId => {
  const mail = useCoreModelsFind('mail', mailId)

  const markAsRead = useCallback(async () => {
    const updatedMail = { read: true, readByReceiver: true }

    // don't update "readByReceiverTime" if it was previously read
    if (!mail?.data?.readByReceiverTime)
      updatedMail.readByReceiverTime = new Date().toISOString()

    await mail.update(updatedMail)
  }, [mail])

  const markAsUnread = useCallback(async () => {
    await mail.update({ read: false })
  }, [])

  return { ...mail, markAsRead, markAsUnread }
}
