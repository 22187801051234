import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import useScheduleShifts from '../hooks/useScheduleShifts'
import Loading from '@therms/web-common/components/Loading'
import LayoverRelative from '@therms/web-common/components/Layover/Relative'
import useCurrentUser from '../../Auth/shared/hooks/useCurrentUser'
import WeekDate from './WeekDate'
import ShiftListItem from './ShiftListItem'
import { roundToNearestMin } from '../../../utils/date'

const PublishedUserWeekSchedule = ({ initialDate, userId }) => {
  const [date, setDate] = useState(initialDate || roundToNearestMin())
  const { id } = useCurrentUser()

  const { endOfWeek, startOfWeek } = useMemo(() => {
    const d = dayjs(date)

    return {
      endOfWeek: d.endOf('week').toISOString(),
      startOfWeek: d.startOf('week').toISOString(),
    }
  }, [date])

  const { data, validating } = useScheduleShifts({
    where: {
      dateEnd: { '>=': startOfWeek },
      dateStart: { '<=': endOfWeek },
      published: true,
      userId: { '==': userId || id },
    },
  })

  const scheduleShifts = useMemo(
    () =>
      data.map((scheduleShift) => ({
        ...scheduleShift,
        weekdayLabel: dayjs(scheduleShift.dateStart).format('dddd'),
      })),
    [data],
  )

  return (
    <div>
      {validating && (
        <LayoverRelative>
          <Loading />
        </LayoverRelative>
      )}

      <div>
        <WeekDate
          canDownload
          downloadUserId={userId || id}
          initialDate={initialDate}
          onDateChange={setDate}
        />
      </div>

      <div className="">
        {scheduleShifts.map((scheduleShift, i) => (
          <div key={scheduleShift.id}>
            {scheduleShifts[i - 1]?.weekdayLabel !== scheduleShift.weekdayLabel && (
              <div className="font-weight-bold text-primary">{scheduleShift.weekdayLabel}</div>
            )}

            <div className="mb-1 ml-1" key={scheduleShift.id}>
              <div className="cursor onhover-highlight">
                <ShiftListItem scheduleShiftId={scheduleShift.id} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

PublishedUserWeekSchedule.propTypes = {
  initialDate: PropTypes.string,
  userId: PropTypes.string,
}
PublishedUserWeekSchedule.defaultProps = {
  initialDate: undefined,
  userId: undefined,
}

export default PublishedUserWeekSchedule
