import React from 'react'

import MainNavButton from 'modules/Layout/shared/MainNavButton/index'
import Conditional from '../../../../Auth/shared/Conditional'
import usePatrolRouteRuns from '../../usePatrolRouteRuns'

const DashboardMainNavButton = () => {
  const { total } = usePatrolRouteRuns(
    {
      limit: 0,
      where: { status: 'running' },
    },
    { includeTotal: true },
  )

  return (
    <Conditional module="patrol">
      <MainNavButton iconClass="fa fa-route fa-2x" to="/patrol">
        <div className="d-flex justify-content-between">
          Patrol
          {!!total && (
            <div>
              <span className="badge badge-info">{total}</span>
            </div>
          )}
        </div>
      </MainNavButton>
    </Conditional>
  )
}
export default DashboardMainNavButton
