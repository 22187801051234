import { types } from './index'
import StateStore from 'state'
import uniqBy from 'lodash-es/uniqBy'

import { isProdEnv } from 'config/env'
import { handleAuthFailed } from 'services/auth'
import realtime from 'services/realtime'
import { registerStoreChangeHandler } from 'services/store'

export function turnOnListeners() {
  realtime.registerListener('error', (err) => {
    // ws-realtime sends a status props
    if (+err.status === 401) {
      if (!isProdEnv || window.__DEBUG) alert('socket error.status === 401')

      handleAuthFailed('ws-realtime 401')
    }
  })

  registerStoreChangeHandler('organization', ({ event, payload }) => {
    if (event !== 'add') return

    payload.forEach((organization) => {
      StateStore.dispatch({
        type: types.SET_ORGANIZATION,
        payload: organization,
      })
    })
  })
  registerStoreChangeHandler('organizationMetadata', ({ event, payload }) => {
    if (event !== 'add') return

    payload.forEach((organizationMetadata) => {
      StateStore.dispatch({
        type: types.SET_ORGANIZATION_METADATA,
        payload: organizationMetadata,
      })
    })
  })
  registerStoreChangeHandler('region', ({ event, payload }) => {
    if (event !== 'add') return

    const state = StateStore.getState()

    payload.forEach((region) => {
      if (region.id === state.Auth.region.id)
        StateStore.dispatch({ type: types.SET_REGION, payload: region })
      else if (state.Auth.user.regionIds.includes(region.id)) {
        // only update the user's regions in Auth.regions (current user's region access)
        StateStore.dispatch({
          type: types.SET_REGIONS,
          payload: uniqBy([...state.Auth.regions, ...payload], 'id'),
        })
      }
    })
  })
  registerStoreChangeHandler('regionMetadata', ({ event, payload }) => {
    if (event !== 'add') return

    const state = StateStore.getState()

    payload.forEach((regionMetadata) => {
      if (regionMetadata.id === state.Auth.regionMetadata.id)
        StateStore.dispatch({
          type: types.SET_REGION_METADATA,
          payload: regionMetadata,
        })
    })
  })
  registerStoreChangeHandler('user', ({ event, payload }) => {
    if (event !== 'add') return

    const state = StateStore.getState()

    const user = payload.find(({ id }) => state.Auth.user.id === id)

    if (!user) return

    StateStore.dispatch({ type: types.SET_USER, payload: user })
  })
  registerStoreChangeHandler('userAccount', ({ event, payload }) => {
    if (event !== 'add') return

    const state = StateStore.getState()

    const userAccount = payload.find(({ id }) => state.Auth.userAccount.id === id)

    if (!userAccount) return

    StateStore.dispatch({ type: types.SET_USER_ACCOUNT, payload: userAccount })
  })
  registerStoreChangeHandler('userMetadata', ({ event, payload }) => {
    if (event !== 'add') return

    const state = StateStore.getState()

    const userMetadata = payload.find(({ id }) => state.Auth.userMetadata.id === id)

    if (!userMetadata) return

    StateStore.dispatch({
      type: types.SET_USER_METADATA,
      payload: userMetadata,
    })
  })
  registerStoreChangeHandler('userRecent', ({ event, payload }) => {
    if (event !== 'add') return

    const state = StateStore.getState()

    const userRecent = payload.find(({ id }) => state.Auth.userRecent.id === id)

    if (!userRecent) return

    StateStore.dispatch({
      type: types.SET_USERRECENT,
      payload: userRecent,
    })
  })
  registerStoreChangeHandler('userStatus', ({ event, payload }) => {
    if (event !== 'add') return

    const state = StateStore.getState()

    const userStatus = payload.find(({ id }) => state.Auth?.userStatus?.id === id)

    if (!userStatus) return

    StateStore.dispatch({
      type: types.SET_USERSTATUS,
      payload: userStatus,
    })
  })
}
