import React from 'react'
import PropTypes from 'prop-types'
import useScheduleShift from '../../hooks/useScheduleShift'
import PositionName from '../Position/PositionName'

const ShiftPositionName = ({ scheduleShiftOrId, showNameOnly }) => {
  const { data: scheduleShift } = useScheduleShift(scheduleShiftOrId?.id ?? scheduleShiftOrId)

  if (!scheduleShift?.schedulePositionId) return null

  if (showNameOnly) return <PositionName id={scheduleShift.schedulePositionId} />

  return (
    <div>
      <div className="display-label">Location</div>
      <PositionName id={scheduleShift.positionId} />
    </div>
  )
}

ShiftPositionName.propTypes = {
  scheduleShiftOrId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  showNameOnly: PropTypes.bool,
}
ShiftPositionName.defaultProps = {
  showNameOnly: false,
}

export default ShiftPositionName
