import store from './store'
import state from '../state'

function getStateObjectsCounts(stateTree) {
  const stateObjectCounts = {}
  Object.entries(stateTree).forEach(([key, value]) => {
    stateObjectCounts[key] = 0

    Object.values(value).forEach(_value => {
      if (Array.isArray(_value)) stateObjectCounts[key]++
      else if (typeof _value === 'object') stateObjectCounts[key] += Object.keys(_value).length
    })
  })

  return stateObjectCounts
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

function niceBytes(x) {
  let l = 0
  let n = parseInt(x, 10) || 0

  while (n >= 1024 && ++l) {
    n /= 1024
  }
  // include a decimal point and a tenths-place digit if presenting
  // less than ten of KB or greater units
  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`
}

export function getDataStoreMemoryInfo() {
  const mapperRecordsCounts = Object.keys(store._collections).reduce((acc, mapper) => {
    acc[mapper] = store[mapper].getAll().length
    return acc
  }, {})

  return mapperRecordsCounts
}

export function getStateMemoryInfo() {
  return getStateObjectsCounts(state.getState())
}

export function getDeviceMemoryInfo() {
  let memory = {}
  let memoryUsed = 0

  try {
    memoryUsed =
      (
        window.performance.memory.usedJSHeapSize / window.performance.memory.jsHeapSizeLimit
      ).toFixed(2) * 100
    memory = {
      totalJSHeapSize: niceBytes(window.performance.memory.totalJSHeapSize),
      usedJSHeapSize: niceBytes(window.performance.memory.usedJSHeapSize),
      jsHeapSizeLimit: niceBytes(window.performance.memory.jsHeapSizeLimit),
    }
  } catch (e) {
    console.warn('window.performance.memory not available')
  }

  return {
    memoryUsed,
    memory,
  }
}
