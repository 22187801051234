import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ModalController from '@components/Modal/ModalController'

import ChangeRegionModal from 'modules/Region/shared/ChangeRegionModal'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import useRegions from '../../../../Auth/shared/hooks/useRegions'

const RegionName = ({ region, user }) => {
  const [hasMultiRegion, setHasMultiRegion] = useState(false)

  const { data } = useRegions(user.regionIds)

  useEffect(() => {
    setHasMultiRegion(data.filter(({ active }) => active).length > 1)
  }, [data])

  return (
    <ModalController modal={ChangeRegionModal}>
      {({ show }) => (
        <span
          className={hasMultiRegion ? `badge badge-secondary cursor onhover-highlight` : ''}
          onClick={() => user.regionIds.length > 1 && show()}
        >
          {region.name} {hasMultiRegion && <FAIcon name="caret-square-down" />}
        </span>
      )}
    </ModalController>
  )
}

RegionName.propTypes = {
  region: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}
RegionName.defaultProps = {}

export default connect(({ Auth: { region, user } }) => ({
  region,
  user,
}))(RegionName)
