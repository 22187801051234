import React from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from './index'

const ErrorBoundaryNoDisplay = ({ children }) => (
  <ErrorBoundary FallbackComponent={null}>
    {children}
  </ErrorBoundary>
)

ErrorBoundaryNoDisplay.propTypes = {
  children: PropTypes.any.isRequired,
}

ErrorBoundaryNoDisplay.defaultProps = {}

export default ErrorBoundaryNoDisplay
