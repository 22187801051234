import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { locationByIdSelector } from '../../../state/ducks/location'
import { currentUserSelector } from '../../Auth/ducks'
import LocationContext from './LocationContext'

function LocationActivePassDowns({ badge, locationId, render }) {
  const { locationId: locationIdFromContext } = useContext(LocationContext) || {}
  const [count, setCount] = useState(0)
  const [mostRecentDate, setMostRecentDate] = useState()

  const location = useSelector(state =>
    locationByIdSelector(state, locationId || locationIdFromContext),
  )
  const { userMetadata } = useSelector(currentUserSelector)

  const locationPassDowns = location.locationPassDowns

  useEffect(() => {
    if (Array.isArray(locationPassDowns)) {
      // filter count and most recent date based on user's accessLevel
      const current = locationPassDowns.reduce(
        (acc, { accessLevel, date }) => {
          const hasAccessLevel = userMetadata.admin || userMetadata.accessLevel >= accessLevel

          if (hasAccessLevel) {
            acc.count++
            acc.date = date > acc.date ? date : acc.date
          }

          return acc
        },
        { count: 0, date: '' },
      )

      setCount(current.count)
      setMostRecentDate(current.date)
    }
  }, [userMetadata, locationPassDowns])

  if (!count) return null

  if (render) return render({ count, date: mostRecentDate })

  return (
    <span
      className={classnames({
        'badge badge-secondary': badge,
      })}
    >
      {count}
    </span>
  )
}

LocationActivePassDowns.propTypes = {
  badge: PropTypes.bool,
  locationId: PropTypes.string,
  render: PropTypes.func,
}

LocationActivePassDowns.defaultProps = {
  badge: false,
  locationId: undefined,
  render: undefined,
}

export default LocationActivePassDowns
