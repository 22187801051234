import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ellipsize from 'ellipsize'

import { realtimeConnectedSelector } from '../../../../state/ducks/app'
import logo from '@images/logo_icon_red_153x154.png'
import { selectAuth } from '../../../Auth/ducks'

import FAIcon from '@components/Icon/FAIcon'
import LayoutContext from '../../shared/LayoutContext'
import NoConnectionIcon from '../../components/NoConnectionIcon'
import RegionName from 'modules/Region/shared/modules/Layout/RegionName'

import './index.scss'

const Header = () => {
  const { organization } = useSelector(selectAuth)
  const realtimeConnected = useSelector(realtimeConnectedSelector)
  const { setShowLeftMenu } = useContext(LayoutContext)

  return (
    <div className="header">
      <div className="brand d-flex" onClick={() => setShowLeftMenu(true)}>
        <FAIcon className={realtimeConnected ? '' : 'text-muted'} name="bars" />

        {!realtimeConnected && <NoConnectionIcon />}
      </div>

      <div className="header-organization-container">
        <div className="organization-name">
          {ellipsize(organization.name, 25, {
            chars: [],
          })}
        </div>

        <div className="region-name">
          <RegionName />
        </div>
      </div>

      <Link to="/" className="brand">
        <img src={logo} alt="" />
      </Link>
    </div>
  )
}

Header.propTypes = {}

export default Header
