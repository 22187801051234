import useLocation from '../../Location/shared/hooks/useLocation'
import usePatrolRoute from '../../Patrol/shared/usePatrolRoute'

export function useScheduleShiftAssignmentDetails(scheduleShift) {
  const location = useLocation(
    scheduleShift?.assignmentModuleName === 'location'
      ? scheduleShift.assignmentModuleId
      : undefined,
  )
  const { data } = usePatrolRoute(
    scheduleShift?.assignmentModuleName === 'patrolRoute'
      ? scheduleShift.assignmentModuleId
      : undefined,
  )

  if (location) {
    return {
      module: 'Location',
      name: location.name,
    }
  }

  if (data) {
    return {
      module: 'Patrol Route',
      name: data.name,
    }
  }

  return undefined
}
