import React from 'react'
import PropTypes from 'prop-types'
import useUser from 'modules/User/shared/hooks/useUser'

const BadgeID = ({ badgeNumber, userId }) => {
  const { data: user } = useUser(userId)

  if (badgeNumber) return <span>#{badgeNumber}</span>
  if (user) return <span>#{user.badgeNumber}</span>
  return null
}

BadgeID.propTypes = {
  badgeNumber: PropTypes.string,
  userId: PropTypes.string,
}
BadgeID.defaultProps = {
  badgeNumber: undefined,
  userId: undefined,
}

export default BadgeID
