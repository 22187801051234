import React from 'react'

import moduleIcons from '@config/moduleIcons'

import Conditional from '../../../../Auth/shared/Conditional'
import ErrorBoundaryNoDisplay from '../../../../../components/ErrorBoundary/ErrorBoundaryNoDisplay'
import MainNavButton from 'modules/Layout/shared/MainNavButton'
import ReportsPendingReviewCount from '../../../components/ReportsPendingReviewCount'
import ReportsAttentionCount from '../../../components/ReportsAttentionCount'
import ReportsInProgressCount from '../../../components/ReportsInProgressCount'

const ReportsMainNavButton = () => (
  <Conditional module="report">
    <MainNavButton iconClass={`${moduleIcons.report} fa-2x`} to="/reports">
      <div className="d-flex justify-content-between">
        Reports{' '}
        <ErrorBoundaryNoDisplay>
          <div>
            <Conditional
              permissionOr={['report.canReviewApprove', 'report.canEditReportTypes']}
            >
              <ReportsPendingReviewCount />{' '}
            </Conditional>
            <ReportsInProgressCount /> <ReportsAttentionCount />
          </div>
        </ErrorBoundaryNoDisplay>
      </div>
    </MainNavButton>
  </Conditional>
)

export default ReportsMainNavButton
