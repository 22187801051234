import ENV, { isProdEnv } from './config/env'

import models from '@therms/models/package'
import utilServices from '@therms/util-services/package'
import webCommon from '@therms/web-common/package'
import webpackConfig from '@therms/webpack-config/package'

import realtime from './services/realtime'
import state from './state/store'
import store from './services/store'

const __debug = (returnValues = false) => {
  if (returnValues)
    return {
      ENV,
      '@therms/web-common': webCommon.version,
      '@therms/models': models.version,
      '@therms/utils-services': utilServices.version,
      '@therms/webpack-config': webpackConfig.version,
    }

  // turn on the __DEBUG flag for other code to listen for
  window.__DEBUG = !(window.__DEBUG)

  console.log(`Release: ${window.__release}`)

  console.log('Environment: ', ENV)

  console.log('@therms/models', models.version)
  console.log('@therms/utils-services', utilServices.version)
  console.log('@therms/web-common', webCommon.version)
  console.log('@therms/webpack-config', webpackConfig.version)
  console.log(' *-*-* "__io", "__state" & "__store" now available on window obj')

  window.__io = realtime.io
  window.__state = state
  window.__store = store

  return null
}

if (!isProdEnv) {
  window.__io = realtime.io
  window.__state = state
  window.__store = store
}

window.__debug = __debug

export default __debug
