import { useEffect, useMemo, useState } from 'react'
import useRegionMetadata from '../../../../Auth/shared/hooks/useRegionMetadata'
import useCoreModelsFindAll from '../../../../../hooks/useCoreModelsFindAll'
import { systemToast } from '../../../../../utils/toaster'

export function useScheduleAssignableModules() {
  const [retryCount, setRetryCount] = useState(2)
  const [locationsWhereQuery, setLocationsWhereQuery] = useState()
  const [patrolRoutesWhereQuery, setPatrolRoutesWhereQuery] = useState()

  const { data: regionMetadata } = useRegionMetadata()

  const scheduleSettings = useMemo(
    () => regionMetadata?.settings?.module?.schedule,
    [regionMetadata],
  )

  const {
    data: locations,
    error,
    revalidate,
    validating: validatingLocations,
  } = useCoreModelsFindAll('location', {
    limit: 2000,
    where: locationsWhereQuery,
    orderBy: 'name',
  })
  const { data: patrolRoutes, validating: validatingPatrolRoutes } = useCoreModelsFindAll(
    'patrolRoute',
    {
      limit: 2000,
      where: patrolRoutesWhereQuery,
      orderBy: 'name',
    },
  )

  useEffect(() => {
    if (error && retryCount) {
      systemToast('Refetching schedule metadata, please wait', { quick: true })

      const t = setTimeout(() => {
        setRetryCount(retryCount - 1)
        revalidate()
      }, 1500)

      return () => clearTimeout(t)
    }

    if (error && !retryCount) {
      alert('There was a problem fetching schedule metadata, please try reloading.')
    }

    return undefined
  }, [error])

  useEffect(() => {
    if (!scheduleSettings?.assignableModules) return

    if (scheduleSettings.assignableModules.locations) {
      const _locationsWhereQuery = { active: true, type: 1 }

      if (Array.isArray(scheduleSettings.assignableModules.locationIds)) {
        _locationsWhereQuery.id = {
          isectNotEmpty: scheduleSettings.assignableModules.locationIds,
        }
      }

      setLocationsWhereQuery(_locationsWhereQuery)
    }

    if (scheduleSettings.assignableModules.patrolRoutes) {
      const _patrolRoutesWhereQuery = {}

      if (Array.isArray(scheduleSettings.assignableModules.patrolRouteIds)) {
        _patrolRoutesWhereQuery.id = {
          isectNotEmpty: scheduleSettings.assignableModules.patrolRouteIds,
        }
      }

      setPatrolRoutesWhereQuery(_patrolRoutesWhereQuery)
    }
  }, [scheduleSettings])

  return {
    error,
    loading: validatingLocations || validatingPatrolRoutes,
    locations,
    patrolRoutes,
    revalidate,
  }
}
