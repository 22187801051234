import React, { useState } from 'react'

const UserScheduleContext = React.createContext(undefined)

// eslint-disable-next-line react/prop-types
export function UserScheduleContextProvider({ children }) {
  const [highlightedUserId, setHighlightedUserId] = useState(undefined)
  const [isMouseOverShift, setIsMouseOverShift] = useState(false)

  return (
    <UserScheduleContext.Provider
      value={{ highlightedUserId, isMouseOverShift, setHighlightedUserId, setIsMouseOverShift }}
    >
      {children}
    </UserScheduleContext.Provider>
  )
}

export function useUserScheduleContext(contextRequired = false) {
  const ctx = React.useContext(UserScheduleContext)

  if (contextRequired && ctx === undefined) {
    throw new Error('UserScheduleContext must be a child below UserScheduleContextProvider')
  }

  const { highlightedUserId, isMouseOverShift, setHighlightedUserId, setIsMouseOverShift } =
    ctx || {}

  return {
    highlightedUserId,
    isMouseOverShift,
    setHighlightedUserId,
    setIsMouseOverShift,
  }
}
