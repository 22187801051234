import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useForceUpdate from './hooks/useForceUpdate'
import { humanizeMinutes } from '../utils/date'
import dayjs from 'dayjs'

const TimeDiff = ({ timestamp }) => {
  const forceUpdate = useForceUpdate()

  useEffect(() => {
    const i = setInterval(() => {
      forceUpdate()
    }, 30000)

    return () => clearInterval(i)
  }, [forceUpdate])

  if (!timestamp) return null

  return <>{humanizeMinutes(dayjs(timestamp).diff(dayjs(), 'minute'))}</>
}

TimeDiff.propTypes = {
  timestamp: PropTypes.string.isRequired,
}
TimeDiff.defaultProps = {}

export default TimeDiff
