import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'

import { realtimeConnectedSelector } from '../../../../state/ducks/app'
import NoConnectionIcon from '../../components/NoConnectionIcon'
import RegionName from '../../../Region/shared/modules/Layout/RegionName'

import logo from '@images/logo_icon_red_153x154.png'
import './index.scss'
import Clock from '../../components/Clock'

const Header = ({ organization }) => {
  const realtimeConnected = useSelector(realtimeConnectedSelector)

  return (
    <div className="align-items-center header d-flex justify-content-between px-2 w-100">
      <div>
        <Link to="/">
          <div className="brand float-left">
            <img src={logo} alt="" className="mr-1 p-2" />
            <span className={`d-none d-sm-none d-md-inline ${!realtimeConnected && 'text-muted'}`}>
              THERMS
            </span>
          </div>
        </Link>

        {!realtimeConnected && <NoConnectionIcon />}
      </div>

      <div>
        <Clock />
      </div>

      <div className="float-right h-100 pr-3">
        <div className="align-items-end d-flex flex-column header-module-icons">
          <div className="organization-name">{organization.name}</div>

          <div className="region-name">
            <RegionName />
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default connect(({ Auth: { organization } }) => ({
  organization,
}))(Header)
