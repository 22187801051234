import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { changeActiveRegion } from 'modules/Auth/ducks'

import Modal from '@components/Modal'
import SelectableRegionList from '../components/SelectableRegionList'

class ChangeRegionModal extends Component {
  static propTypes = {
    closeHandler: PropTypes.func,
    region: PropTypes.object.isRequired,
    setActiveRegion: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  }

  static defaultProps = {
    closeHandler: () => {},
  }

  state = {}

  handleSelectRegion = regionId => {
    if (regionId === this.props.region.id) return this.props.closeHandler()

    this.props.setActiveRegion(regionId)
    return this.props.closeHandler()
  }

  render() {
    return (
      <Modal closeHandler={this.props.closeHandler} title="Change Region">
        <SelectableRegionList
          onSelectRegion={this.handleSelectRegion}
          activeRegionId={this.props.region.id}
          regionIds={this.props.user.regionIds}
        />
      </Modal>
    )
  }
}

const enhance = compose(
  connect(
    ({ Auth: { region, user } }) => ({ region, user }),
    { setActiveRegion: changeActiveRegion },
  )
)

export default enhance(ChangeRegionModal)
