import { useCallback, useEffect, useReducer, useRef } from 'react'
import Store from 'services/store'
import QuickLRU from 'quick-lru'

import { globalVent } from '@utils/vent'
import { REALTIME_SERVICE_CONNECTION_CHANGE } from 'utils/events'

const initialState = {
  data: [],
  error: undefined,
  total: 0,
  validating: true,
}

function reducer(state, payload) {
  if (payload) return { ...state, ...payload }

  return state
}

const lru = new QuickLRU({ maxSize: 500 })

/**
 *
 * @param mapperName {String}
 * @param query {Object} Js-data query
 * @param opts.includeTotal {Boolean} Fetch the total records for a query
 * @param opts.force {Boolean} Bypass cached query
 * @param opts.noLimit {Boolean} removes the default response limit
 * @returns {{ data: Array<Object>, error: Boolean, revalidate: Function<Promise>, total: Number,  validating: Boolean }}
 */
export default function useCoreModelsFindAll(
  mapperName,
  query,
  { debug = false, includeTotal = false, force = false, noLimit = false } = {},
) {
  if (!mapperName) throw new Error('useCoreModelsFindAll() requires mapperName')

  const _local = useRef({ firstRender: true })

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  _local.current.fetchTotal = query?.limit === 0 || includeTotal
  _local.current.force = force
  _local.current.mapperName = mapperName
  _local.current.noLimit = noLimit
  _local.current.state = state
  _local.current.query = query

  if (_local.current.firstRender && query && query?.where) {
    state.data = lru.get(mapperName + JSON.stringify(query)) || []

    if (debug) {
      console.log(
        mapperName,
        'First Render',
        state.data.length
          ? `has data: ${mapperName}${JSON.stringify(query)}`
          : ` NO DATA lru:${mapperName}${JSON.stringify(query)}`,
      )
    }

    if (state.data.length) state.validating = false

    _local.current.firstRender = false
  }

  // if (_local.current.firstRender)
  //   console.log(mapperName, 'state.data', state.data?.length, 'query && query?.where', query && query?.where)
  // else console.log(mapperName, 'query && query?.where', query && query?.where)

  const runQuery = useCallback(
    async ({ force: _force = false, skipChecksAndRunQuery = false } = {}) => {
      if (!skipChecksAndRunQuery) {
        if (_local.current.wait || _local.current.queryInProgress) {
          // simulate a debounced call so that a query is ran AFTER the last wait
          _local.current.runAfterWait = true
          return
        }

        if (
          !_local.current.query ||
          JSON.stringify(_local.current.query) === _local.current.lastQueryJSON
        )
          return
      }

      _local.current.lastQueryJSON = JSON.stringify(_local.current.query)
      _local.current.wait = true
      _local.current.waitTimeout = setTimeout(() => {
        _local.current.wait = false

        if (_local.current.runAfterWait) runQuery()
      }, 100)

      try {
        // ignore empty queries to prevent unexpected "blind fetching"
        if (_local.current.query?.where) {
          if (debug) {
            console.log(
              'runQuery() ',
              '_force: ',
              _force,
              ' skipChecksAndRunQuery: ',
              skipChecksAndRunQuery,
            )
          }

          _local.current.queryInProgress = true

          if (_local.current.fetchTotal)
            Store.count(_local.current.mapperName, {
              where: _local.current.query.where || {},
            }).then((total) => dispatch({ total }))

          if (_local.current.query?.limit !== 0) {
            if (_local.current.state.error) dispatch({ error: false })

            let records = []
            let _stopValidating

            if (!_local.current.prevData?.length) {
              _stopValidating = true
              dispatch({ validating: true })
            }

            records = await Store.findAll(
              _local.current.mapperName,
              {
                ..._local.current.query,
                limit: _local.current.query.limit || (_local.current.noLimit ? undefined : 25),
              },
              { force: _local.current.force || _force },
            )

            if (debug) {
              console.log('query finished, simulating 2sec network delay...')
              await new Promise((r) => setTimeout(r, 2000))
              const dataSame = JSON.stringify(_local.current.prevData) !== JSON.stringify(records)
              console.log('  is new data different from the last returned data: ', dataSame)
              if (!dataSame) {
                console.log('  prevData', _local.current.prevData)
                console.log('  new data', records)
              }
            }

            lru.set(_local.current.mapperName + JSON.stringify(_local.current.query), records)

            if (JSON.stringify(_local.current.prevData) !== JSON.stringify(records)) {
              dispatch({ data: records, validating: false })

              _local.current.prevData = records
            } else if (_stopValidating) {
              dispatch({ validating: false })
            }
          }
        } else if (_local.current.state.data?.length) {
          dispatch({ data: [], error: false, validating: false })
        } else if (_local.current.state.validating) {
          dispatch({ error: false, validating: false })
        }
      } catch (e) {
        console.log(`useDataStoreFindAll#${mapperName} e`, e)

        dispatch({ error: true })
      } finally {
        _local.current.queryInProgress = false
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const destroyById = useCallback((id) => {
    if (!id) return null
    return Store[mapperName].destroy(id)
  }, [])

  const revalidate = useCallback((_force = false) => {
    if (debug) {
      console.log('revalidate()')
    }

    return runQuery({ force: _force, skipChecksAndRunQuery: true })
  }, [])

  useEffect(() => {
    function storeEventHandler(_mapperName) {
      if (debug) {
        if (_mapperName === _local.current.mapperName) {
          console.log('storeEventHandler() cb, will runQuery(): ', !_local.current.queryInProgress)
        }
      }
      // don't trigger query when query is in progress
      if (_local.current.queryInProgress) return

      if (_mapperName === _local.current.mapperName) runQuery({ skipChecksAndRunQuery: true })
    }

    Store.on(`add`, storeEventHandler)
    Store.on(`remove`, storeEventHandler)

    const { remove } = globalVent.subscribe(REALTIME_SERVICE_CONNECTION_CHANGE, (connected) => {
      // don't trigger query when query is inprogress
      if (_local.current.queryInProgress) return

      if (connected) runQuery({ skipChecksAndRunQuery: true })
    })

    return () => {
      Store.off(`add`, storeEventHandler)
      Store.off(`remove`, storeEventHandler)
      remove()
      clearTimeout(_local.current.waitTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (query) runQuery()
    else dispatch({ data: [], error: undefined, total: 0, validating: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [force, mapperName, query])

  state.destroyById = destroyById
  state.revalidate = revalidate

  return state
}
