import axios from 'axios'
import { handleAuthFailed } from './auth'
import cookies from 'js-cookie'
import url from 'url'
import ENV from 'config/env'

const parsedUrl = url.parse(ENV.CORE_SERVER)
const CORE_SERVER_HOST = parsedUrl.host || window.location.host
const CORE_SERVER_PATH = parsedUrl.path

const axiosConfig = {
  baseURL: url.format({ host: CORE_SERVER_HOST, pathname: CORE_SERVER_PATH }),

  timeout: 20000, // 3g connections can be slow
}

const coreApi = axios.create(axiosConfig)

// not working with axios v0.19
// https://github.com/axios/axios/issues/2203
// axiosRetry(coreApi, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

coreApi.interceptors.request.use(
  (config) => {
    // add jwt to req header
    // eslint-disable-next-line no-param-reassign
    config.headers[process.env.JWT_HEADER_KEY] = cookies.get(process.env.AUTH_COOKIE_NAME)

    return config
  },
  (error) => 
    // Do something with request error
     Promise.reject(error)
  ,
)

// Add a response interceptor
coreApi.interceptors.response.use(
  response => response,
  error => {
    console.log('error', error.response)

    const status = error?.response?.status

    if (status === 401) return handleAuthFailed('coreApi 401')

    return Promise.reject(error)
  },
)

export default coreApi
