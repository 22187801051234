import React from 'react'
import { useDispatch } from 'react-redux'

import { logout } from '../../../ducks'
import MainNavButton from 'modules/Layout/shared/MainNavButton'

const BulletinMainNavButton = () => {
  const dispatch = useDispatch()

  return (
    <MainNavButton
      iconClass="fa fa-flip-horizontal fa-sign-out-alt"
      onClick={(e) => {
        e.preventDefault()
        dispatch(logout())
        return false
      }}
      // only have a "to" so the .active class isn't applied to <a> tag
      to="/logout"
    >
      Logout
    </MainNavButton>
  )
}
export default BulletinMainNavButton
