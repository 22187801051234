import { useEffect, useState } from 'react'
import useScheduleShift from '../hooks/useScheduleShift'
import useScheduleSettings from '../hooks/useScheduleSettings'
import useUserHasPermission from '../../Auth/shared/hooks/useUserHasPermission'
import useScheduleTimelogs from '../hooks/useScheduleTimelogs'
import useCurrentUser from '../../Auth/shared/hooks/useCurrentUser'
import dayjs from 'dayjs'

const ConditionalShowScheduleTimelogControls = ({ children, scheduleShiftId }) => {
  const user = useCurrentUser()
  const canClockInOutForOtherUsers = useUserHasPermission('schedule.canClockInOutForOtherUsers')
  const [showControls, setShowControls] = useState(false)

  const { data: scheduleTimelogs } = useScheduleTimelogs({
    where: { clockOutTimestamp: null, scheduleShiftId },
  })

  const scheduleSettings = useScheduleSettings()
  const { data: scheduleShift } = useScheduleShift(scheduleShiftId)

  useEffect(() => {
    if (!scheduleShift) return

    let _showControls = true
    const now = dayjs()

    if (user.id === scheduleShift.userId || canClockInOutForOtherUsers) {
      if (scheduleTimelogs[0]) {
        // if there is already a timelog record, show it
        _showControls = true
      } else if (
        scheduleShift.dateStart >= now.toISOString() &&
        scheduleSettings?.allowClockInEarly
      ) {
        // early clock-in check
        const minutesUntilNextShiftStarts = Math.abs(+now.diff(scheduleShift.dateStart, 'minute'))

        _showControls = +scheduleSettings?.earlyClockInThresholdMin >= minutesUntilNextShiftStarts
      } else if (scheduleShift.dateEnd <= now.toISOString()) {
        // shift has ended check
        _showControls = false
      }
    } else {
      _showControls = false
    }

    setShowControls(_showControls)
  }, [canClockInOutForOtherUsers, scheduleShift, scheduleSettings, scheduleTimelogs, user])

  if (!scheduleShift || !showControls) return null

  return children
}

ConditionalShowScheduleTimelogControls.propTypes = {}
ConditionalShowScheduleTimelogControls.defaultProps = {}

export default ConditionalShowScheduleTimelogControls
