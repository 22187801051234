import React from 'react'

import Conditional from '../../../../Auth/shared/Conditional'
import ErrorBoundaryNoDisplay from '../../../../../components/ErrorBoundary/ErrorBoundaryNoDisplay'
import MainNavButton from '../../../../Layout/shared/MainNavButton'
import PendingDispatchCountBadge from 'modules/Dispatch/components/PendingDispatchCountBadges'

const UserMainNavButton = () => (
  <Conditional module="dispatch">
    <MainNavButton iconClass="fa fa-phone-square fa-2x" to="/dispatch">
      <div className="d-flex justify-content-between">
        Dispatch{' '}
        <ErrorBoundaryNoDisplay>
          <PendingDispatchCountBadge />
        </ErrorBoundaryNoDisplay>
      </div>
    </MainNavButton>
  </Conditional>
)

export default UserMainNavButton
