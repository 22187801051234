import { applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

export const sagaMiddleware = createSagaMiddleware()

const middleware = [thunk, sagaMiddleware]

const enhancer = composeEnhancers(applyMiddleware(...middleware))

export default enhancer
