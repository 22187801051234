import { useState } from 'react'

import localStorage from '../services/localStorage'

export default function useLocalStorage(key, initialValue) {
  if (!key) throw new Error('useLocalStorage(key, initialValue) "key" is required')

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(key)
    return item !== null ? item : initialValue
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      if (valueToStore === undefined || valueToStore === null) localStorage.removeItem(key)
      else localStorage.setItem(key, valueToStore)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  // set the localStorage value without an update/re-render
  const setValueWithoutUpdate = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value
      // Save to local storage
      if (valueToStore === undefined || valueToStore === null) localStorage.removeItem(key)
      else localStorage.setItem(key, valueToStore)
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue, setValueWithoutUpdate]
}
