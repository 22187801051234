import Store from 'services/store'
import StateStore from 'state'
import { intersection } from 'lodash-es'
import { registerStoreChangeHandler } from '../../../services/store'

// enforce the allowed Location records for this User
registerStoreChangeHandler('location', async ({ event, payload }) => {
  if (event !== 'add') return

  const { allowedLocationIds } = StateStore.getState()?.Auth

  if (Array.isArray(allowedLocationIds)) {
    if (Array.isArray(payload)) {
      setTimeout(() => {
        Store.Location.removeAll({
          where: { id: { notIn: allowedLocationIds }, type: { '==': 1 } },
        })
      }, 1)

      // console.info('removing Locations, user does not have access', allowedLocationIds)
    } else if (!allowedLocationIds.includes(payload.id)) {
      setTimeout(() => {
        Store.Location.remove(payload.id)
      }, 1)

      // console.info('removing Location, user does not have access', payload)
    }
  }
})

registerStoreChangeHandler('dispatch', async ({ event, payload }) => {
  if (event !== 'add') return

  const { allowedLocationIds } = StateStore.getState()?.Auth

  if (Array.isArray(allowedLocationIds)) {
    if (Array.isArray(payload)) {
      setTimeout(() => {
        Store.Dispatch.removeAll({ where: { locationId: { notIn: allowedLocationIds } } })
      }, 1)

      // console.info('removing Dispatchs, user does not have access', idsToRemove)
    } else if (payload.locationId && !allowedLocationIds.includes(payload.locationId)) {
      setTimeout(() => {
        Store.Dispatch.remove(payload.id)
      }, 1)

      // console.info('removing Dispatch, user does not have access', payload)
    }
  }
})

function reportCheckAndRemove(allowedLocationIds, report) {
  if (report?.locationIds?.length && !intersection(allowedLocationIds, report?.locationIds).length) {
    setTimeout(() => {
      Store.Report.remove(report.id)
    }, 1)
  }
}

registerStoreChangeHandler('report', async ({ event, payload }) => {
  if (event !== 'add') return

  const { allowedLocationIds } = StateStore.getState()?.Auth

  if (Array.isArray(allowedLocationIds)) {
    if (Array.isArray(payload)) {
      payload.forEach(report => reportCheckAndRemove(allowedLocationIds, report))
    } else {
      reportCheckAndRemove(allowedLocationIds, payload)
    }
  }
})
