import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Conditional from '../../../../Auth/shared/Conditional'
import useScheduleShift from '../../../hooks/useScheduleShift'
import ShiftTimeLogControlsOnDuty from './components/OnDuty'
import ShiftTimeLogControlsClockIn from './components/ClockIn'
import useScheduleTimelogs from '../../../hooks/useScheduleTimelogs'

const STEPS = {
  START: 1,
  CLOCKIN: 2,
  ONDUTY: 3,
}

const ShiftTimelogControls = ({ scheduleShiftId }) => {
  const [step, setStep] = useState(null)

  const { data: scheduleShift } = useScheduleShift(scheduleShiftId)

  const { data: onDutyScheduleTimelogs } = useScheduleTimelogs({
    where: { clockOutTimestamp: null, scheduleShiftId },
  })

  useEffect(() => {
    if (!scheduleShift) return

    if (onDutyScheduleTimelogs.length) setStep(STEPS.ONDUTY)
    else setStep(STEPS.CLOCKIN)
  }, [onDutyScheduleTimelogs, scheduleShift])

  switch (step) {
    case STEPS.START:
      return (
        <Conditional
          passIfCurrentUser={scheduleShift?.userId}
          permission="schedule.canClockInOutForOtherUsers"
        >
          <button className="btn btn-block btn-primary" onClick={() => setStep(STEPS.CLOCKIN)}>
            Start Shift
          </button>
        </Conditional>
      )
    case STEPS.CLOCKIN:
      return scheduleShift && (
        <ShiftTimeLogControlsClockIn
          onCancel={() => setStep(STEPS.START)}
          scheduleShift={scheduleShift}
        />
      )
    case STEPS.ONDUTY:
      return (
        <ShiftTimeLogControlsOnDuty
          scheduleShift={scheduleShift}
          scheduleTimelog={onDutyScheduleTimelogs[0]}
        />
      )
    default:
      return null
  }
}

ShiftTimelogControls.propTypes = {
  scheduleShiftId: PropTypes.string.isRequired,
}
ShiftTimelogControls.defaultProps = {}

export default ShiftTimelogControls
