import { createAction, handleActions } from 'redux-actions'
import reducerRegistry from '../reducerRegistry'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import Store from '../../services/store'
import { createSelector } from 'reselect'

const reducerName = 'locationBolo'

const {
  actions,
  // actionCreators,
  createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'bolos',
  reducerName,
  singular: 'bolo',
  storeMapperName: 'locationBolo',
})

const initialState = {
  ...initialStateBoilerplate,
  activeBolosByLocationId: {},
}

// selectors
export const selectActiveBolosByLocationId = (state, locationId) =>
  state.locationBolo.activeBolosByLocationId[locationId]

export const selectBolos = state => state.locationBolo.bolos

export const activeBolosSelector = createSelector(selectBolos, bolos =>
  bolos.filter(({ expireDate }) => new Date().toISOString() <= expireDate),
)

export const { boloByIdSelector, boloLoadingByIdSelector } = selectors

export const highestPriorityBoloByLocationIdSelector = createSelector(
  selectActiveBolosByLocationId,
  (bolos = []) => bolos.reduce((acc, { priority }) => (priority > acc ? priority : acc), 0),
)

// actions
export const setActiveBolosByLocationId = createAction(
  createActionName('setActiveBolosByLocationId'),
  ({ locationId, bolos }) => ({ locationId, bolos }),
)

export const { fetchBolo, fetchBolos } = actions

export const fetchActiveBolosByLocationId = locationId => dispatch => {
  let today = new Date()
  today.setHours(0, 0, 0, 0) // we want to take advantage of cached responses
  today = today.toISOString()

  Store.LocationBolo.findAll({
    where: { expireDate: { '>=': today }, locationId },
  }).then(bolos => {
    dispatch(setActiveBolosByLocationId({ locationId, bolos }))
  })
}

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
    [setActiveBolosByLocationId]: (state, action) => ({
      ...state,
      activeBolosByLocationId: {
        ...state.activeBolosByLocationId,
        [action.payload.locationId]: action.payload.bolos,
      },
    }),
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
