import React from 'react'
import PropTypes from 'prop-types'
import ItemTypes from './DnD/ItemTypes'
import { useDrop } from 'react-dnd'
import Store from 'services/store'
import dayjs from 'dayjs'

import './TableCellDroppable.scss'
import { pick } from 'lodash-es'
import { systemToast } from '../../../../../utils/toaster'
import { useKeyPress } from '../../../../../hooks/useKeyPress'

const TableCellDroppable = ({
  children,
  className,
  date,
  onClick,
  onDropComplete,
  assignmentModuleId,
  assignmentModuleName,
}) => {
  const isAltPressed = useKeyPress('Alt')

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.SHIFT,
    canDrop: () => true,
    drop: (item) => {
      if (!item?.scheduleShift) {
        console.warn(`draggable scheduleShift item does not have item.scheduleShift property`)

        return
      }

      const droppedShift = {
        ...item.scheduleShift,
        ...(item.scheduleShift.draft || {})
      }


      const cloneShift = isAltPressed

      const newDateStart = dayjs(date)
        .set('hours', dayjs(droppedShift.dateStart).get('hours'))
        .set('minutes', dayjs(droppedShift.dateStart).get('minutes'))
        .set('seconds', 0)

      const addHours = dayjs(droppedShift.dateEnd).diff(
        dayjs(droppedShift.dateStart),
        'minutes',
        true,
      )

      const updatedScheduleShift = {
        draft: {
          id: undefined,
          assignmentModuleId,
          assignmentModuleName,
          dateEnd: dayjs(newDateStart).add(addHours, 'minutes').toISOString(),
          dateStart: newDateStart.toISOString(),
        },
      }

      if (onDropComplete) onDropComplete()

      if (cloneShift) {
        const clonedShiftValues = {
          ...updatedScheduleShift.draft,
          ...pick(droppedShift, ['name', 'schedulePositionId', 'shiftNotes', 'userId']),
          id: undefined,
        }

        console.info(
          'TableCellDroppable - Cloning Shift, original Shift:',
          droppedShift,
          ' cloned Shift:',
          clonedShiftValues,
        )

        Store.ScheduleShift.create(clonedShiftValues)
          .then((res) => {
            console.info('TableCellDroppable - Cloning Shift, created Shift success:', res)
          })
          .catch((err) => {
            console.log('TableCellDroppable Store.ScheduleShift.create() err', err)

            systemToast('There was a problem cloning shift', { error: true, quick: true })
          })
      } else {
        console.info(
          'TableCellDroppable - Updating Shift, original Shift:',
          droppedShift,
          ' updated Shift:',
          updatedScheduleShift,
        )

        Store.ScheduleShift.update(droppedShift.id, updatedScheduleShift)
          .then((res) => {
            console.info('TableCellDroppable - new Shift, updated Shift success:', res)
          })
          .catch((err) => {
            console.log('TableCellDroppable Store.ScheduleShift.update() err', err)

            systemToast('There was a problem changing shift', { error: true, quick: true })
          })
      }

      // console.log(`drop... move scheduleShift: ${droppedShiftId} to user:${userId} for date: ${date}`)
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return (
    <td
      className={`max-width-150 min-width-150 overflow-hidden table-cell-droppable-shift ${className}`}
      data-date={date}
      data-assignmentmoduleid={assignmentModuleId}
      data-assignmentmodulename={assignmentModuleName}
      onClick={onClick}
      ref={drop}
      style={{
        backgroundColor: isOver ? 'grey' : '',
        position: 'relative',
      }}
    >
      {children}
    </td>
  )
}

TableCellDroppable.propTypes = {
  assignmentModuleId: PropTypes.string.isRequired,
  assignmentModuleName: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDropComplete: PropTypes.func,
}
TableCellDroppable.defaultProps = {
  children: null,
  className: '',
  onDropComplete: undefined,
}

export default TableCellDroppable
