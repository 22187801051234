import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import './index.scss'

const MainNavButton = ({ children, iconClass, onClick, to }) => (
  <NavLink onClick={onClick} to={to}>
    <li className="d-flex align-items-center">
      <div className="module-menu-icon">
        <i className={iconClass} />
      </div>

      <div className="module-menu-text w-100">
        {children}
      </div>
    </li>
  </NavLink>
)

MainNavButton.propTypes = {
  children: PropTypes.node.isRequired,
  iconClass: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
}
MainNavButton.defaultProps = {
  onClick: undefined,
  to: '',
}
export default MainNavButton
