import { handleActions } from 'redux-actions'
import { models } from '@therms/models'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

const builtInReportEntryTypeDefaultsByName = models.reportEntryType.fixtures.builtInsByName

const reducerName = 'reportEntryType'

const {
  actions,
  actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'reportEntryTypes',
  reducerName,
  singular: 'reportEntryType',
  storeMapperName: 'reportEntryType',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const { reportEntryTypeByIdSelector, reportEntryTypeLoadingByIdSelector } = selectors

export const selectReportEntryTypes = state => state.reportEntryType.reportEntryTypes
export const selectReportEntryTypesById = state => state.reportEntryType.reportEntryTypesById

// actions
export const fetchReportEntryType = idOrBuiltInName => dispatch => {
  // Override the default because we have to get builtin's locally
  if (!idOrBuiltInName) return Promise.reject(new Error('fetchReportEntryType(idOrBuiltInName)'))

  const isBuiltIn = /builtin/.test(idOrBuiltInName)

  if (!isBuiltIn) return dispatch(actions.fetchReportEntryType(idOrBuiltInName))

  const builtInName = idOrBuiltInName.substr(idOrBuiltInName.indexOf(':') + 1)

  const builtInReportEntryType = builtInReportEntryTypeDefaultsByName[builtInName]

  if (!builtInReportEntryType)
    return Promise.reject(
      new Error(`fetchReportEntryType() builtin "${builtInName}" does not exist`),
    )

  dispatch(actionCreators.SET_REPORTENTRYTYPE(builtInReportEntryType))

  return Promise.resolve(builtInReportEntryType)
}

export const fetchReportEntryTypes = (...args) => async dispatch => {
  // Override the default becuse we need to merge built-in defaults to results

  dispatch(actionCreators.SET_LOADING_REPORTENTRYTYPES(true))

  let reportEntryTypes

  try {
    reportEntryTypes = await dispatch(actions.fetchReportEntryTypes(...args))
  } catch (e) {
    dispatch(actionCreators.SET_LOADING_REPORTENTRYTYPES(false))

    return Promise.reject(e)
  }

  const mergedReportEntryTypes = [
    ...reportEntryTypes,
    ...Object.values(builtInReportEntryTypeDefaultsByName),
  ]

  dispatch(actionCreators.SET_LOADING_REPORTENTRYTYPES(false))
  dispatch(actionCreators.SET_REPORTENTRYTYPES(mergedReportEntryTypes))

  return Promise.resolve(mergedReportEntryTypes)
}

export const fetchReportEntryTypesByById = (reportEntryTypeIds = []) => (dispatch, getState) => {
  const {
    reportEntryType: { reportEntryTypeHasFetchedById },
  } = getState()

  // make sure the builtin's are dispatched
  dispatch(actionCreators.SET_REPORTENTRYTYPES(Object.values(builtInReportEntryTypeDefaultsByName)))

  let uniqueReportEntryTypeIds = new Set()

  reportEntryTypeIds.forEach(id => uniqueReportEntryTypeIds.add(id))

  // filter out built-in id's
  uniqueReportEntryTypeIds = Array.from(uniqueReportEntryTypeIds).filter(
    id => id.indexOf('builtin:') < 0,
  )

  // filter out already fetched reportEntryTypes
  uniqueReportEntryTypeIds = Array.from(uniqueReportEntryTypeIds).filter(
    id => !Boolean(reportEntryTypeHasFetchedById[id]),
  )

  if (!uniqueReportEntryTypeIds.length) return Promise.resolve([])

  return dispatch(
    fetchReportEntryTypes({
      where: { id: { isectNotEmpty: Array.from(uniqueReportEntryTypeIds) } },
    }),
  )
}

export const fetchReportEntryTypesForReportTypeAndEntries = ({
  reportEntries,
  reportType,
}) => dispatch => {
  if (!reportType)
    return Promise.reject(
      new Error('fetchReportEntryTypesForReportEntries({ reportEntries }) arg is required'),
    )

  const reportEntryTypeIds = [
    ...reportType.allowedReportEntryTypeIds,
    ...reportEntries.map(({ reportEntryTypeId }) => reportEntryTypeId),
  ]

  if (!reportEntryTypeIds.length) return Promise.resolve([])

  return dispatch(fetchReportEntryTypesByById(reportEntryTypeIds))
}

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
