// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@therms/web-common/fonts/brand_logo.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:\"brand_logo\";font-style:normal;src:local(\"brand_logo\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.mobile-layout-container .header{background-color:#1e262f;display:flex;font-size:18px;height:45px;justify-content:space-between;line-height:1;padding:.2em .5em;position:fixed;width:100%;z-index:1030}.mobile-layout-container .header .brand{color:#bd2534;font-size:40px;font-family:\"brand_logo\"}.mobile-layout-container .header .brand img{height:35px}.mobile-layout-container .header .organization-name{font-size:100%;font-weight:bold;margin-bottom:0}.mobile-layout-container .header .region-name{font-size:100%}.mobile-layout-container .header .header-organization-container{align-items:center;display:flex;flex-direction:column;justify-content:center}", ""]);
// Exports
module.exports = exports;
