import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import LoadingStateText from '@therms/web-common/components/LoadingStateText'

const PositionChip = ({ name, onDestroy }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDestroyClick = useCallback(async () => {
    setIsDeleting(true)
    try {
      await onDestroy()
    } catch {
      setIsDeleting(false)
    }
  }, [onDestroy, setIsDeleting])

  return (
    <div className="d-inline-flex flex-row align-items-center card">
      <div className="py-1 px-3">
        <p className="mb-0 text-secondary font-weight-bold">
          <LoadingStateText isLoading={isDeleting} loadingText="Deleting" text={name} />
        </p>
      </div>

      {!!onDestroy && (
        <button className="btn btn-secondary" type="button" onClick={handleDestroyClick}>
          <FAIcon name="times" />
        </button>
      )}
    </div>
  )
}

PositionChip.propTypes = {
  name: PropTypes.string.isRequired,
  onDestroy: PropTypes.func,
}

PositionChip.defaultProps = {
  onDestroy: null,
}

export default PositionChip
