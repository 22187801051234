import React from 'react'
import PropTypes from 'prop-types'

const MailBody = ({ mail }) => {
  if (Boolean(mail.html)) return <div dangerouslySetInnerHTML={{ __html: mail.html }} />

  return <p className="with-whitespace">{mail.body}</p>
}

MailBody.propTypes = {
  mail: PropTypes.object.isRequired,
}
MailBody.defaultProps = {}

export default MailBody
