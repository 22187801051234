import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { userOnlineRecordsByUserId } from '../../../state/ducks/online'
import useUserStatus from './hooks/useUserStatus'

const UserOnlineLatestStatus = ({ userId }) => {
  const userStatus = useUserStatus(userId)
  const userOnlineRecords = useSelector(userOnlineRecordsByUserId(userId))

  const [timeString, setTimeString] = useState()

  useEffect(() => {
    if (!userId || !userStatus) return

    let _timeString

    if (userOnlineRecords.length) {
      // get the oldest connection time
      const connectedSince = userOnlineRecords.reduce((acc, o) => {
        // eslint-disable-next-line no-param-reassign
        if (acc > o.createdAt) acc = o.createdAt

        return acc
      }, new Date().toISOString())

      _timeString = `Online for ${dayjs.duration(dayjs().diff(connectedSince)).humanize()}`
    } else {
      _timeString = userStatus.lastOnlineDate
        ? `Offline for ${dayjs.duration(dayjs().diff(userStatus.lastOnlineDate)).humanize()}`
        : '* User has not been online'
    }

    setTimeString(_timeString)
  }, [userStatus, userOnlineRecords])

  if (!userStatus) return null

  return (
    <span className={`badge badge-outline-${userStatus.online ? 'info' : 'secondary'}`}>
      {timeString}
    </span>
  )
}

UserOnlineLatestStatus.propTypes = {
  userId: PropTypes.string.isRequired,
}
UserOnlineLatestStatus.defaultProps = {}

export default UserOnlineLatestStatus
