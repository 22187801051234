import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { filter, findIndex, includes, omit, without } from 'lodash-es'

import FileCard from './components/FileCard'
import FullScreenLayoverPlayer from '@components/Video/FullScreenLayoverPlayer'
import ImageLightBox from '@components/ImageLightBox'

import { downloadFile } from '../../utils/files'
import { getImageThumbnailUrl } from 'modules/Files/utils/imageSizeUrls'
import {
  REGEX_VALID_IMAGE_FORMATS,
  REGEX_VALID_RASTER_IMAGE_FORMATS,
} from 'modules/Files/constants'

const isImage = (contentType) => REGEX_VALID_IMAGE_FORMATS.test(contentType)
const isRasterImage = (contentType) => REGEX_VALID_RASTER_IMAGE_FORMATS.test(contentType)

const setInitialState = (fileUrls) => {
  const state = {}
  fileUrls.forEach((url) => {
    state[url] = { url }
  })

  return state
}

const filesReducer = (files, payload) => {
  const { type, fileData } = payload

  if (type === 'remove') return omit(files, fileData.url)

  if (type === 'add') {
    const file = { ...files[fileData.url], ...fileData }
    if (isRasterImage(fileData.contentType)) file.thumbnailUrl = getImageThumbnailUrl(fileData.url)
    return { ...files, [fileData.url]: file }
  }

  if (type === 'setProcessed') {
    const file = files[fileData.url]

    if (!file) return files

    file.isProcessed = true

    return { ...files, [fileData.url]: file }
  }

  return files
}

const downloadReducer = (urlsDownloading, { type, url } = {}) => {
  if (type === 'add') {
    if (includes(urlsDownloading, url)) return urlsDownloading
    return [...urlsDownloading, url]
  }
  if (type === 'remove') return without(urlsDownloading, url)
  return urlsDownloading
}

const Files = ({ ellipsed, fileUrls }) => {
  const [files, dispatchFiles] = useReducer(filesReducer, setInitialState(fileUrls))
  const [urlsDownloading, dispatchUrlsDownloading] = useReducer(downloadReducer, [])
  const [previewTypeOpen, setPreviewTypeOpen] = useState('')
  const [galleryIndex, setGalleryIndex] = useState()
  const [videoUrl, setVideoUrl] = useState()

  const imageUrls = useMemo(() => {
    const imageFiles = filter(files, ({ contentType }) => isImage(contentType))
    return imageFiles.map(({ url }) => url)
  }, [files])

  const fetchFileHead = useCallback((url) => {
    axios
      // CORE-1152 Math.random() prevents bug on Chrome where it makes the request with no Origin header.
      .head(`${url}?random=${Math.random()}`)
      .then((response) => {
        const { 'content-type': contentType, 'x-amz-meta-name': name } = response.headers

        dispatchFiles({
          type: 'add',
          fileData: { contentType, isProcessed: true, name, url },
        })
      })
      .catch((err) => {
        if (axios.isCancel(err)) console.log('Request cancelled', err)
        else console.error(`There was a problem fetching the MIME type of URL: ${url}`, err)
      })
  }, [])

  useEffect(() => {
    if (fileUrls.length < Object.keys(files).length) {
      const removedFiles = omit(files, fileUrls)
      Object.keys(removedFiles).forEach((url) =>
        dispatchFiles({ type: 'remove', fileData: { url } }),
      )
    } else {
      fileUrls.forEach((url) => {
        if (!files[url]?.isProcessed) {
          dispatchFiles({ type: 'setProcessed', fileData: { url } })
          fetchFileHead(url)
        }
      })
    }
  }, [files, fileUrls])

  const handleFileCardClick = useCallback(
    async ({ contentType, url }) => {
      if (isImage(contentType)) {
        const fileIndex = findIndex(imageUrls, (imageUrl) => imageUrl === url)
        setGalleryIndex(fileIndex)
        setPreviewTypeOpen('image')
      } else if (/video/i.test(contentType)) {
        setVideoUrl(url)
        setPreviewTypeOpen('video')
      } else {
        dispatchUrlsDownloading({ type: 'add', url })
        await downloadFile(url)
        dispatchUrlsDownloading({ type: 'remove', url })
      }
    },
    [imageUrls],
  )

  if (!fileUrls.length) return null

  return (
    <div className="d-flex flex-wrap m-n1">
      {Object.keys(files).map((fileKey) => {
        const file = files[fileKey]
        return (
          <div className="m-1" key={file.url}>
            <FileCard
              ellipsed={ellipsed}
              downloading={includes(urlsDownloading, file.url)}
              file={file}
              onClick={() => handleFileCardClick(file)}
            />
          </div>
        )
      })}

      {previewTypeOpen === 'image' && (
        <ImageLightBox
          images={imageUrls}
          initialIndex={galleryIndex}
          onClose={() => {
            setPreviewTypeOpen(null)
            setGalleryIndex(0)
          }}
        />
      )}

      {previewTypeOpen === 'video' && (
        <FullScreenLayoverPlayer
          closeHandler={() => {
            setPreviewTypeOpen(null)
            setVideoUrl(null)
          }}
          url={videoUrl}
        />
      )}
    </div>
  )
}

Files.propTypes = {
  ellipsed: PropTypes.bool,
  fileUrls: PropTypes.array,
}

Files.defaultProps = {
  ellipsed: undefined,
  fileUrls: [],
}

export default Files
