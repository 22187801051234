import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'
import BuildSchedule from '../../components/BuildSchedule'
import ScheduleModuleDropdownMenu from '../../components/ScheduleModuleDropdownMenu'
import Conditional from '../../../Auth/shared/Conditional'
import useDetectDevice from 'use-mobile-detect-hook'
import BuildScheduleMobile from 'modules/Schedule/components/BuildSchedule/BuildScheduleMobile'

const BuildScheduleScreen = () => {
  const detectDevice = useDetectDevice()
  const isMobile = useMemo(() => detectDevice.isMobile(), [])

  return (
    <Conditional permission="schedule.canCreateShifts">
      {isMobile ? (
        <BuildScheduleMobile />
      ) : (
        <div>
          <ScheduleModuleDropdownMenu />

          <BuildSchedule />
        </div>
      )}
    </Conditional>
  )
}

BuildScheduleScreen.propTypes = {}
BuildScheduleScreen.defaultProps = {}

export default BuildScheduleScreen
