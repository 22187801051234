/*
 * wrap react-final-form because of this issue:
 *
 *   https://github.com/final-form/react-final-form/issues/130
 *
 * When submitting a form with fields that have values and the user clears a field, that field
 * is then removed from the form values object and never gets sent up to the back-end to updated
 * it as an emptied field, it simply continues as the same value.
 *
 * Our wrapper adds a custom parser prop to the <Field /> component to allow
 * empty string fields in our forms.
 */
import React from 'react'
import { Field as RFFField } from 'react-final-form'

export default function Field(props) {
  return <RFFField parse={v => v} {...props} />
}
