import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '@therms/web-common/components/Modal'
import PositionForm from './PositionForm'

const PositionFormModal = ({ closeHandler, id, onCancel, onComplete }) => {
  const handleFormCancel = useCallback(() => {
    onCancel()
    closeHandler()
  }, [closeHandler, onCancel])

  const handleFormComplete = useCallback(() => {
    onComplete()
    closeHandler()
  }, [onComplete, onCancel])
  

  return (
    <Modal
      closeHandler={closeHandler}
      title={id ? 'Edit Schedule Position' : 'New Schedule Position'}
    >
      <PositionForm id={id} onCancel={handleFormCancel} onComplete={handleFormComplete} />
    </Modal>
  )
}

PositionFormModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
}

PositionFormModal.defaultProps = {
  id: undefined,
  onCancel: () => {},
  onComplete: () => {},
}

export default PositionFormModal
