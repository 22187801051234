// babel polyfills
import 'core-js'
import 'regenerator-runtime/runtime'

// must run at the top level of app startup
import './config/app-setup'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'

import 'services/realtime'

import { updateAppReloadToast } from './utils/toaster'
import App from './App'
import debug from './debug'
import pkg from '../package'

import './index.scss'

const release = process.env.RELEASE || `v${pkg.version}-NO_RELEASE_ENV-${new Date().getTime()}`

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  // const whyDidYouRender = require('@welldone-software/why-did-you-render')
  // whyDidYouRender(React, {
  //   // exclude: [/./],
  //   include: [/^Report/],
  //   collapseGroups: true,
  //   // trackAllPureComponents: true
  // })
}

if (typeof $ === 'undefined' || !window.$) {
  // eslint-disable-next-line global-require
  window.$ = require('jquery')
  this.$ = window.$
}

// for debug.js
window.__release = release

if (process.env.NODE_ENV === 'production' && process.env.DEPLOY_ENV) {
  Sentry.init({
    dsn: 'https://890b227cde5c4b6da48192d7321643a7@sentry.io/1516161',
    environment: process.env.DEPLOY_ENV,
    // process.env.RELEASE set in webpack bundling
    release,
    ignoreErrors: [
      /ChunkLoadError/, // this can happen for many reasons but mostly network issues
      // network connection and app in background related errors
      /^Network Error$/,
      /status code 404/,
      /status code 401/,
      /status code 422/,
      /object has no keys/,
      /Request aborted/,
      /timeout of 0ms exceeded/, // axios throws this when network/connx error
      /timeout of 20000ms exceeded/,
      // Sentry WEB-CORE-AY iOS Firefox bug
      /webkit\.messageHandlers\.contextMenuMessageHandler\.postMessage/,
    ],
  })

  Sentry.configureScope(scope => {
    Object.entries(debug(true)).forEach(([key, val]) => {
      scope.setExtra(key, val)
    })
    scope.setExtra('__debug')
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

OfflinePluginRuntime.install({
  onUpdateReady: () => {
    let interval

    // https://github.com/NekR/offline-plugin/issues/400
    // https://sentry.io/organizations/therms/issues/1224176237
    try {
      // window.isFocused global set in index.html
      if (window.isFocused) OfflinePluginRuntime.applyUpdate()
      else
        interval = setInterval(() => {
          if (window.isFocused) {
            clearInterval(interval)
            OfflinePluginRuntime.applyUpdate()
          }
        }, 15000)
    } catch (error) {
      console.log('is window focused? ', window.isFocused)
      console.log('OfflinePluginRuntime.applyUpdate() error', error)

      setTimeout(() => {
        OfflinePluginRuntime.applyUpdate()
      }, 60000 * 5) // try again in 5min
    }
  },

  onUpdating: () => {
    console.log('SW Event:', 'onUpdating')
  },

  onUpdated: () => {
    window.serviceWorkerUpdate = true

    setTimeout(() => {
      updateAppReloadToast()
    }, 2000)
  },

  onUpdateFailed: () => {
    console.log('SW Event:', 'onUpdateFailed')
  },
})

// Dev HMR
if (module.hot) {
  module.hot.accept()
}
