/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import { get, map } from 'lodash-es'

import { fetchTask } from 'state/ducks/task'
import { fetchInProgressReportsForLocation } from '../../Reports/ducks'
import StateStore from 'state'
import Store from 'services/store'

const PAGE_LIMIT = 10

const initialState = {
  // this search state is used by more than 1 search/paginated result
  currentSearchId: undefined, // identifier for which component/module the results are for
  currentSearchQuery: {},
  tasksLoading: false,
  tasksSearchOptions: undefined,
  tasksPage: 1,
  tasksResultsPageCount: 1,
  tasksResultsById: [],
  tasksResultsCount: 0,
}

const moduleName = 'Task'

const mod = name => `${moduleName}/${name}`

// selectors
// const selectTasksWithUserId = (state, userId) => {
//   return state.task.tasks.filter(({ userIds }) => userIds.includes(userId))
// }

const selectTasksWithLocationId = (state, locationId) => state.task.tasks
    .filter(({ locationIds }) => locationIds.includes(locationId))
    .filter(c => !c._deleted)

export const locationTasksByLocationIdSelector = createSelector(
  selectTasksWithLocationId,
  tasks => tasks,
)

// actions
export const clearTasks = createAction(mod('clearTasks'))
export const setCurrentFetchQuery = createAction(
  mod('setCurrentFetchQuery'),
  ({ currentSearchQuery = {}, currentSearchId = '' }) => ({ currentSearchQuery, currentSearchId }),
)
export const setTasksLoading = createAction(mod('setTasksLoading'))
export const setTasksPage = createAction(mod('tasksPage'))
export const setTaskResultsPageCount = createAction(mod('tasksResultsPageCount'))
export const setTaskSearchOptions = createAction(mod('setTaskSearchOptions'))
export const setTaskResultsById = createAction(mod('setTaskResultsById'))

/**
 * Fetches a task and any dependencies it might have based on `linkModuleName` &
 * `moduleSpecificMetadata`.
 *
 * Tasks can be linked to different modules that have different settings and dependencies.
 * For example, a Location Task can have a setting:
 *    `Task.moduleSpecificMetadata.location.showInReports = true`
 * Where the dependencies in this case are in-progress Location Reports.
 *
 * @note the business logic for negotiating what dependencies to fetch live in the body
 *      of this function call
 *
 * @param taskId {string}
 * @returns {Promise<Task>}
 */
export const fetchTaskWithLinkModuleDetails = taskId => async (dispatch, getState) => {
  if (!taskId) return Promise.reject(new Error('fetchTaskWithLinkModuleDetails() taskId required'))

  let task = getState().task.tasksById[taskId]

  if (!task) task = await dispatch(fetchTask(taskId))

  if (
    task.linkModelName === 'location' &&
    get(task, 'moduleSpecificMetadata.location.showInReports')
  ) {
    await dispatch(fetchInProgressReportsForLocation(task.linkModelId))
    return task
  }

  return Promise.resolve(task)
}

export const fetchTasksForLocationReport = ({ locationId, reportId } = {}) => (
  dispatch,
  getState,
) => {
  if (!locationId || !reportId)
    return Promise.reject(new Error('fetchTasksForLocationReport() locationId & reportId required'))

  const currentSearchId = `locationreport${reportId}`

  if (getState().Task.currentSearchId !== currentSearchId) dispatch(clearTasks())

  const query = {
    where: {
      active: true,
      linkModelId: locationId,
      linkModelName: 'location',
      'moduleSpecificMetadata.location.showInReports': true,
    },
  }

  return dispatch(fetchTasksForSearch(`locationreport${reportId}`, query, { limit: 25 }))
}

/**
 * Fetch tasks with pagination results and store results & metadata in state.
 *
 * @note This method and the state is used by multiple components, ie: Tasks Search & All In-Progress
 *       so in order to maintain a user's search state for the correct component we use an ID field
 *       in state, `currentSearchId` which is arbitrary and determined by the component using the state
 *
 * @param currentSearchId {string} An unique identifier for which component is using the search/query state
 * @param [query] {object} to refetch current query, pass no query arg
 * @param [query.page] {number} the page number to fetch
 * @param [query.where] {object} the js-data where query
 * @param [opts] {object} optional
 * @param [opts.noLoading] {boolean} whether to dispatch tasksLoading state
 * @returns {function(*, *): Promise<T | never>}
 */
export const fetchTasksForSearch = (currentSearchId, query, opts = {}) => (dispatch, getState) => {
  if (!opts.noLoading) dispatch(setTasksLoading(true))

  const state = getState()
  let currentSearchQuery

  if (!query && state.Task.currentSearchId === currentSearchId) {
    currentSearchQuery = state.Task.currentSearchQuery
  } else {
    const { page } = query || { page: 1 }

    const where = {
      ...(query.where || {}),
    }

    currentSearchQuery = {
      limit: opts.limit || PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
      orderBy: query.orderBy || [['updatedAt', 'desc']],
      where,
    }
  }

  dispatch(setCurrentFetchQuery({ currentSearchQuery, currentSearchId }))

  return Store.Task.findAll(currentSearchQuery, {
    ...opts,
    // force: currentSearchQuery.offset > 1, // we have to force because of cache results filtering offset
    headers: { count: true },
    raw: true,
  })
    .then(async response => {
      let count
      let tasks

      // when it's a cached response, it's an array of tasks
      if (Array.isArray(response)) {
        count = await Store.Task.count({ where: currentSearchQuery.where }).catch(() => 0)
        tasks = response
      } else {
        count = +response.headers.count
        tasks = response.data
      }

      dispatch(setTaskResultsById({ count, results: map(tasks, 'id') }))
      dispatch(setTaskResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
      dispatch(setTasksLoading(false))

      return tasks
    })
    .catch(err => {
      console.error(`Tasks Task.findAll error`, err)
      dispatch(setTasksLoading(false))
    })
}

// reducer
const reducer = handleActions(
  {
    [clearTasks]: () => ({
      ...initialState,
    }),

    [setCurrentFetchQuery]: (state, action = {}) => ({
      ...state,
      currentSearchQuery: action.payload.currentSearchQuery,
      currentSearchId: action.payload.currentSearchId,
    }),

    [setTasksLoading]: (state, action = {}) => ({
      ...state,
      tasksLoading: action.payload,
    }),

    [setTasksPage]: (state, action = {}) => ({
      ...state,
      tasksPage: action.payload,
    }),

    [setTaskResultsById]: (state, action = {}) => ({
      ...state,
      tasksResultsById: action.payload.results,
      tasksResultsCount: action.payload.count,
    }),

    [setTaskResultsPageCount]: (state, action = {}) => ({
      ...state,
      tasksResultsPageCount: action.payload,
    }),

    [setTaskSearchOptions]: (state, action = {}) => ({
      ...state,
      tasksSearchOptions: action.payload,
    }),
  },
  initialState,
)

export default reducer

StateStore.addReducer('Task', reducer)
