/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import { map, sortBy } from 'lodash-es'

import Store from 'services/store'
import { selectCurrentRegion } from '../../Auth/ducks/region'
import moment from 'moment'
import * as locationBoloDuck from '../../../state/ducks/locationBolo'

const PAGE_LIMIT = 10

const initialState = {
  boloListLoading: false,
  boloListSearchOptions: undefined,
  boloListPage: 1,
  boloListResultsPageCount: 1,
  boloListResultsById: [],
  boloListResultsCount: 0,
}

const moduleName = 'LocationBolo'

const moduleConstant = (name) => `${moduleName}/${name}`

// selectors
export const currentRegionRecentBoloSelector = createSelector(
  locationBoloDuck.activeBolosSelector,
  selectCurrentRegion,
  (bolos, region) => {
    const sevenDaysAgo = moment()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .subtract(7, 'days')
      .toISOString()

    return sortBy(bolos, 'updatedAt')
      .filter(({ regionId }) => regionId === region.id)
      .filter(({ updatedAt }) => sevenDaysAgo <= updatedAt)
      .reverse()
      .slice(0, 15)
  },
)

export const currentRegionActiveBoloSelector = createSelector(
  locationBoloDuck.activeBolosSelector,
  selectCurrentRegion,
  (bolos, region) =>
    sortBy(bolos, 'updatedAt')
      .filter(({ regionId }) => regionId === region.id)
      .reverse()
      .slice(0, 100),
)

// types

// actions

export const clearBolos = createAction('clearBolos')
export const setBoloListLoading = createAction('setBoloListLoading')
export const setBoloListPage = createAction('boloListPage')
export const setBoloListResultsPageCount = createAction('boloListResultsPageCount')
export const setBoloListSearchOptions = createAction('setBoloListSearchOptions')
export const setBoloListResultsById = createAction(moduleConstant('setBoloListResultsById'))

export const fetchBolos =
  ({ expired = false, force = false, locationId, page = 1 } = {}) =>
  (dispatch, getState) => {
    if (!locationId) throw new Error('fetchBolos() requires locationId param')

    dispatch(setBoloListLoading(true))

    let today = new Date()
    today.setHours(0, 0, 0, 0) // we want to take advantage of cached responses
    today = today.toISOString()

    const where = {
      expireDate: { [expired ? '<=' : '>=']: today },
      locationId,
    }

    return Store.LocationBolo.findAll(
      {
        limit: PAGE_LIMIT,
        offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
        orderBy: [['expireDate', 'desc']],
        where,
      },
      { force, headers: { count: true }, raw: true },
    )
      .then((response) => {
        let count
        let bolo

        // when it's a cached response, it's an array of records
        if (Array.isArray(response)) {
          count = getState().Location.bolo.boloListResultsCount
          bolo = response
        } else {
          count = response.headers.count
          bolo = response.data
        }

        dispatch(setBoloListResultsById({ count, results: map(bolo, 'id') }))
        dispatch(setBoloListResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
        dispatch(setBoloListLoading(false))

        return bolo
      })
      .catch((err) => {
        console.error(`LocationBolo.findAll error`, err)
        // todo show system toast err
        dispatch(setBoloListLoading(false))
      })
  }

export const fetchActiveBolos = () => (dispatch, getState) => {
  const currentRegion = selectCurrentRegion(getState())

  return dispatch(
    locationBoloDuck.fetchBolos({
      limit: 100,
      where: {
        active: true,
        regionId: currentRegion.id,
      },
    }),
  )
}

export const fetchRecentBolos = () => (dispatch, getState) => {
  const currentRegion = selectCurrentRegion(getState())

  const sevenDaysAgo = moment()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .subtract(7, 'days')
    .toISOString()

  return dispatch(
    locationBoloDuck.fetchBolos({
      limit: 15,
      where: {
        regionId: currentRegion.id,
        updatedAt: {
          '>=': sevenDaysAgo,
        },
      },
    }),
  )
}

// reducer
const reducer = handleActions(
  {
    [clearBolos]: () => ({
      ...initialState,
    }),
    [setBoloListLoading]: (state, action = {}) => ({
      ...state,
      boloListLoading: action.payload,
    }),

    [setBoloListPage]: (state, action = {}) => ({
      ...state,
      boloListPage: action.payload,
    }),

    [setBoloListResultsById]: (state, action = {}) => ({
      ...state,
      boloListResultsById: action.payload.results,
      boloListResultsCount: action.payload.count,
    }),

    [setBoloListResultsPageCount]: (state, action = {}) => ({
      ...state,
      boloListResultsPageCount: action.payload,
    }),

    [setBoloListSearchOptions]: (state, action = {}) => ({
      ...state,
      boloListSearchOptions: action.payload,
    }),
  },
  initialState,
)

export default reducer
