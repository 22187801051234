import React from 'react'

import MainNavButton from 'modules/Layout/shared/MainNavButton/index'

const BulletinMainNavButton = () => (
  <MainNavButton iconClass="fa fa-question-circle" to="/support">
    Help & Support
  </MainNavButton>
)
export default BulletinMainNavButton
