import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import useScheduleShift from '../hooks/useScheduleShift'
import useOrganization from '../../Auth/shared/hooks/useOrganization'
import PositionName from '../components/Position/PositionName'
import UserName from '../../User/shared/UserName'
import { humanizeMinutes } from '../../../utils/date'
import ShiftAssignment from '../components/Shift/ShiftAssignment'
import DateTime from '@therms/web-common/components/DateTime'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import './ShiftDetails.scss'

const ShiftDetails = ({ scheduleShiftId }) => {
  const { organizationMetadata } = useOrganization()
  const { data: scheduleShift } = useScheduleShift(scheduleShiftId)

  const endTime = useMemo(
    () =>
      scheduleShift &&
      dayjs(scheduleShift.dateEnd).format(organizationMetadata?.settings?.timeFormat),
    [scheduleShift?.dateEnd, organizationMetadata?.settings?.timeFormat],
  )

  const startTime = useMemo(
    () =>
      scheduleShift &&
      dayjs(scheduleShift.dateStart).format(organizationMetadata?.settings?.timeFormat),
    [scheduleShift?.dateStart, organizationMetadata?.settings?.timeFormat],
  )

  const shiftLength = useMemo(() => {
    if (!scheduleShift) return ''

    const duration = dayjs.duration(dayjs(scheduleShift.dateEnd).diff(scheduleShift.dateStart))

    return humanizeMinutes(duration.asMinutes())
  }, [scheduleShift])

  if (!scheduleShift) return null

  return (
    <div className="shiftdetails-container">
      <div className="time">
        <div className="font-small font-weight-light opacity-75">
          <DateTime time={false} timestamp={scheduleShift.dateStart} />
        </div>

        <div>
          <FAIcon className="text-info" name="clock" />
          <span className="font-weight-bold mx-1 text-info">
            {startTime} - {endTime}
          </span>
          <span className="font-small font-weight-lighter text-muted">({shiftLength})</span>
        </div>
      </div>

      <div className="details">
        {scheduleShift.name && (
          <div className="">
            <div className="display-label">Shift Name</div>
            {scheduleShift.name}
          </div>
        )}

        <div className="d-flex justify-content-between">
          {scheduleShift.assignmentModuleName && (
            <div className="w-50">
              <ShiftAssignment scheduleShiftOrId={scheduleShift} />
            </div>
          )}

          {scheduleShift.schedulePositionId && (
            <div className="w-50">
              <div className="display-label">Position</div>
              <PositionName id={scheduleShift.schedulePositionId} />
            </div>
          )}
        </div>

        {scheduleShift?.userId && (
          <div className="">
            <div className="display-label">Assigned to</div> <UserName id={scheduleShift.userId} />
          </div>
        )}

        {scheduleShift.shiftNotes && (
          <div className="with-whitespace">
            <div className="display-label">Shift Notes</div>
            {scheduleShift.shiftNotes}
          </div>
        )}
      </div>
    </div>
  )
}

ShiftDetails.propTypes = {
  scheduleShiftId: PropTypes.string.isRequired,
}
ShiftDetails.defaultProps = {}

export default ShiftDetails
