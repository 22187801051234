import { createSelector } from 'reselect'
import { groupBy, keyBy, sortBy } from 'lodash-es'

import { fetchOnlines, resetOnlineState, selectOnline } from 'state/ducks/online'
import { selectCurrentRegion } from '../../Auth/ducks/region'
import { currentUserSelector } from '../../Auth/ducks'
import store from 'services/store'

// selectors

/**
 * Group online records by userId
 *
 * @returns Object<{ [userId]: Array<Object> }>
 */
export const currentRegionOnlineGroupedByUserIdSelector = createSelector(
  selectOnline,
  selectCurrentRegion,
  (online, region) =>
    groupBy(
      sortBy(
        online.filter(({ regionIds }) => regionIds.includes(region.id)),
        'createdAt',
      ),
      'userId',
    ),
)

export const currentRegionOnlineCount = createSelector(
  selectOnline,
  selectCurrentRegion,
  (online, region) => online.filter(({ regionIds }) => regionIds.includes(region.id)).length,
)

export const onlineRecordsByUserIdSelector = createSelector(selectOnline, (online) =>
  keyBy(online, 'userId'),
)

// actions
export const fetchOnlineForUsersRegions =
  ({ bypassCache = false } = {}) =>
  async (dispatch, getState) => {
    const state = getState()
    const user = currentUserSelector(state)

    if (bypassCache) {
      store.Online.removeAll()
      dispatch(resetOnlineState())
    }

    return dispatch(
      fetchOnlines(
        { where: { regionIds: { isectNotEmpty: user.regionIds } } },
        { force: bypassCache },
      ),
    )
  }

import('state/ducks/app.js').then((module) => {
  module.registerRealtimeDisconnectedTask('fetchOnlineForUsersRegions', () =>
    fetchOnlineForUsersRegions({ bypassCache: true }),
  )
  module.registerRealtimeOnReconnectTask('fetchOnlineForUsersRegions', () =>
    fetchOnlineForUsersRegions({ bypassCache: true }),
  )
})
