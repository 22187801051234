import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import useScheduleShifts from '../../../hooks/useScheduleShifts'
import { systemToast } from '../../../../../utils/toaster'

export function useBuildScheduleData(dateForWeek) {
  const [retryCount, setRetryCount] = useState(2)

  const date = useMemo(() => dateForWeek || new Date().toISOString(), [dateForWeek])

  const { endOfWeek, startOfWeek, todayFormatted } = useMemo(() => {
    const d = dayjs(date)

    return {
      endOfWeek: d.endOf('week').toISOString(),
      startOfWeek: d.startOf('week').toISOString(),
      todayFormatted: dayjs().format(window.dateFormat),
    }
  }, [date])

  const {
    data: scheduleShiftsData,
    error,
    revalidate: revalidateScheduleShifts,
    validating,
  } = useScheduleShifts({
    where: { dateStart: { '>=': startOfWeek, '<=': endOfWeek } },
  })

  useEffect(() => {
    if (error && retryCount) {
      systemToast('Refetching schedule shifts, please wait', { quick: true })

      const t = setTimeout(() => {
        setRetryCount(retryCount - 1)
        revalidateScheduleShifts()
      }, 1500)

      return () => clearTimeout(t)
    }

    if (error && !retryCount) {
      alert('There was a problem fet}ching shifts, please try reloading.')
    }

    return undefined
  }, [error])

  const { hasUnpublishedScheduleShifts, scheduleShifts } = useMemo(() => {
    let _hasUnpublishedScheduleShifts = false

    const shifts = scheduleShiftsData.map((scheduleShift) => {
      // if a scheduleShift record has `draft` property, spread it over the record
      if (scheduleShift.draft && Object.keys(scheduleShift).length) {
        _hasUnpublishedScheduleShifts = true

        return {
          ...scheduleShift,
          ...scheduleShift.draft,
        }
      }
      if (!scheduleShift.published) {
        _hasUnpublishedScheduleShifts = true
      }

      return scheduleShift
    })

    return { hasUnpublishedScheduleShifts: _hasUnpublishedScheduleShifts, scheduleShifts: shifts }
  }, [scheduleShiftsData])

  const scheduleShiftsUnassigned = useMemo(
    () => scheduleShifts.filter((ss) => !ss.assignmentModuleId || !ss.assignmentModuleName),
    [scheduleShifts],
  )

  const [hoursByUserId, totalHours] = useMemo(() => {
    const map = {}
    let aggTotalHrs = 0

    scheduleShifts.forEach((shift) => {
      if (!shift.userId) return

      if (!map[shift.userId]) map[shift.userId] = 0

      const duration = Number(
        dayjs.duration(dayjs(shift.dateEnd).diff(shift.dateStart)).asHours().toFixed(2),
      )

      map[shift.userId] += duration
      aggTotalHrs += duration
    })

    return [map, Math.round(aggTotalHrs)]
  }, [scheduleShifts])

  /*
      Schema

      {
        location: {
          '5dd87d0672193f40b689b9d1': {
            '2020-08-19T00:00:00.000Z': Array<ScheduleShift>
          }
        }
      }

   */
  const scheduleShiftsByModuleNameByIdByDate = useMemo(() => {
    const map = {}

    for (let i = 0; i < scheduleShifts.length; i++) {
      const scheduleShift = scheduleShifts[i]

      if (!map[scheduleShift.assignmentModuleName]) map[scheduleShift.assignmentModuleName] = {}

      if (!map[scheduleShift.assignmentModuleName][scheduleShift.assignmentModuleId])
        map[scheduleShift.assignmentModuleName][scheduleShift.assignmentModuleId] = {}

      const d = dayjs(scheduleShift.dateStart).startOf('day').toISOString()

      if (!map[scheduleShift.assignmentModuleName][scheduleShift.assignmentModuleId][d])
        map[scheduleShift.assignmentModuleName][scheduleShift.assignmentModuleId][d] = []

      map[scheduleShift.assignmentModuleName][scheduleShift.assignmentModuleId][d].push(
        scheduleShift,
      )
    }

    return map
  }, [scheduleShifts])

  const dayOfWeekDateFormatted = useMemo(
    () => ({
      0: dayjs(startOfWeek).format(window.dateFormat),
      1: dayjs(startOfWeek).add(1, 'day').format(window.dateFormat),
      2: dayjs(startOfWeek).add(2, 'day').format(window.dateFormat),
      3: dayjs(startOfWeek).add(3, 'day').format(window.dateFormat),
      4: dayjs(startOfWeek).add(4, 'day').format(window.dateFormat),
      5: dayjs(startOfWeek).add(5, 'day').format(window.dateFormat),
      6: dayjs(startOfWeek).add(6, 'day').format(window.dateFormat),
    }),
    [startOfWeek],
  )

  const weekDatesList = useMemo(
    () =>
      new Array(7)
        .fill(startOfWeek)
        .map((_, i) => dayjs(startOfWeek).add(i, 'day').startOf('day').toISOString()),
    [startOfWeek],
  )

  return {
    dayOfWeekDateFormatted,
    endOfWeek,
    hoursByUserId,
    startOfWeek,
    hasUnpublishedScheduleShifts,
    revalidateScheduleShifts,
    scheduleShiftsUnassigned,
    scheduleShiftsByModuleNameByIdByDate,
    todayFormatted,
    totalHours,
    validating,
    weekDatesList,
  }
}
