import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import reducerRegistry from '../reducerRegistry'
import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'

const reducerName = 'location'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'locations',
  reducerName,
  singular: 'location',
  storeMapperName: 'location',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const { locationByIdSelector, locationLoadingByIdSelector } = selectors

export const inactiveLocationsSelector = createSelector(
  (state) => state.location.locations,
  (locations) => locations.filter((l) => !l.active).filter((l) => l.type === 1),
)

export const locationsSelector = createSelector(
  (state) => state.location.locations,
  (locations) => locations.filter((l) => l.active).filter((l) => l.type === 1),
)

const selectLocationsByGroupId = (state, groupId) =>
  state.location.locations.filter(
    (l) => l.active && l.locationGroupIds && l.locationGroupIds.includes(groupId),
  )
const selectLocationsByRegionZoneId = (state, zoneId) =>
  state.location.locations.filter((l) => l.active && l.regionZoneId === zoneId)

export const locationsByGroupIdSelector = createSelector(
  selectLocationsByGroupId,
  (locations) => locations,
)
export const locationByRegionZoneIdSelector = createSelector(
  selectLocationsByRegionZoneId,
  (locations) => locations,
)

export const subLocationsSelectorByParentLocationId = createSelector(
  (state, parentLocationId) => parentLocationId,
  (state) => state.location.locations,
  (parentLocationId, locations) =>
    locations
      .filter(({ type }) => type === -1)
      .filter(({ active }) => active)
      .filter((l) => l.parentLocationId === parentLocationId),
)

// actions
export const { fetchLocation } = actions

export const fetchLocations = (query = {}, opts = {}, ...args) =>
  actions.fetchLocations(
    {
      ...query,
      limit: query.limit ?? 750,
    },
    opts,
    ...args,
  )

export const fetchSubLocations = (locationId) =>
  actions.fetchLocations({
    active: true,
    limit: 5000,
    where: {
      parentLocationId: locationId,
      type: -1,
    },
  })

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
