/* eslint-disable no-use-before-define */
import * as Sentry from '@sentry/browser'
import cookies from 'js-cookie'

import ENV from '../config/env'

const LAST_URLPATH_COOKIE_NAME = 'auth-failed-at-url'

export function getLastAuthUrlPath() {
  return cookies.get(LAST_URLPATH_COOKIE_NAME)
}

export function handleAuthFailed(debugReason = null) {
  Sentry.addBreadcrumb({
    data: { authCookie: cookies.get(LAST_URLPATH_COOKIE_NAME), url: window.location.pathname },
  })
  Sentry.captureMessage(`auth failed, ${debugReason}`, { level: Sentry.Severity.Debug })

  removeAuthCookie()

  // store current url in "lastUrl" cookie (so we can expire it after 5 min)
  const inFiveMin = new Date(new Date().getTime() + 5 * 60 * 1000)

  cookies.set(LAST_URLPATH_COOKIE_NAME, window.location.pathname + (window.location.search || ''), {
    domain: ENV.AUTH_COOKIE_DOMAIN,
    expires: inFiveMin,
  })

  window.location = ENV.AUTH_UI + (window.location.search || '')
}

export function handleLogout() {
  removeAuthCookie()
  window.location = ENV.AUTH_UI
}

export function removeAuthCookie() {
  cookies.remove(ENV.AUTH_COOKIE_NAME, { domain: ENV.AUTH_COOKIE_DOMAIN })
}

export function resetLastAuthUrlPath() {
  cookies.remove(LAST_URLPATH_COOKIE_NAME, {
    domain: ENV.AUTH_COOKIE_DOMAIN,
  })
}

export function setAuthCookie(data) {
  cookies.set(ENV.AUTH_COOKIE_NAME, data, { domain: ENV.AUTH_COOKIE_DOMAIN })
}
