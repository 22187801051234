import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useUserStatus from '../hooks/useUserStatus'
import { capitalize } from 'lodash-es'
import { getColorContrast } from '../../../../utils/color'

const StatusBadge = ({ className, userId }) => {
  const userStatus = useUserStatus(userId)

  const status = userStatus?.status

  const [statusContrastColor, setStatusContrastColor] = useState('')

  useEffect(() => {
    if (status?.color) {
      setStatusContrastColor(getColorContrast(status.color))
    }
  }, [status])

  if (!status) return null

  return (
    <span
      className={`badge ${className}`}
      style={{ backgroundColor: status.color, color: statusContrastColor }}
    >
      {capitalize(status.title)}
    </span>
  )
}

StatusBadge.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.string.isRequired,
}
StatusBadge.defaultProps = {
  className: '',
}

export default StatusBadge
