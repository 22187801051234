import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
  allExceptUserInProgressReportsSelector,
  currentUserActiveInProgressReportsSelector,
  fetchUserInProgressReports,
  fetchInProgressReports,
} from 'modules/Reports/ducks'

const ReportsInProgressCount = ({ totalCount, onlyCurrentUser }) => {
  const allReports = useSelector(allExceptUserInProgressReportsSelector)
  const userReports = useSelector(currentUserActiveInProgressReportsSelector)
  const count = totalCount
    ? (allReports || []).length + (userReports || []).length
    : (onlyCurrentUser ? userReports : allReports || []).length
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUserInProgressReports())
    dispatch(fetchInProgressReports())
  }, [dispatch])

  return Boolean(count) && <div className="badge badge-info">{count >= 99 ? '99+' : count}</div>
}

ReportsInProgressCount.propTypes = {
  totalCount: PropTypes.bool,
  onlyCurrentUser: PropTypes.bool,
}
ReportsInProgressCount.defaultProps = {
  totalCount: false,
  onlyCurrentUser: true,
}

export default ReportsInProgressCount
