import { useEffect, useState } from 'react'
import useCurrentUser from './useCurrentUser'
import { get } from 'lodash-es'

export default function useUserHasPermission(permissionGetPath = '') {
  const user = useCurrentUser()
  const [hasPermission, setHasPermission] = useState(
    user.userMetadata.admin || get(user.userMetadata.permissions, permissionGetPath),
  )

  useEffect(() => {
    setHasPermission(
      user.userMetadata.admin || get(user.userMetadata.permissions, permissionGetPath),
    )
  }, [permissionGetPath, user])

  return hasPermission
}
