import { useEffect, useState } from 'react'
import Store from 'services/store'

export default function useLocations({ limit, orderBy, locationIds, skip } = {}) {
  const [locations, setLocations] = useState([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)

  const query = Array.isArray(locationIds)
    ? {
        id: { isectNotEmpty: locationIds },
      }
    : { active: true, type: 1 }

  useEffect(() => {
    setError(false)
    setLoading(true)

    const _limit = limit
    const _skip = skip || 0

    Store.Location.findAll({
      limit: _limit,
      offset: _skip,
      orderBy: orderBy || ['name'],
      where: query,
    })
      .then(setLocations)
      .catch(err => {
        console.error(`Location.findAll error`, err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }, [limit, locationIds, skip])

  useEffect(() => {
    Store.Location.count({ where: query }).then(setTotal)
  }, [locationIds])

  return {
    locations,
    error,
    loading,
    total,
  }
}
