import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { fetchUnreadMail, unreadMailCountSelector } from '../ducks'

class UnreadMailCountBadge extends Component {
  static propTypes = {
    fetchUnreadMail: PropTypes.func.isRequired,
    unreadCount: PropTypes.number,
  }

  static defaultProps = {
    unreadCount: undefined,
  }

  componentDidMount() {
    this.props.fetchUnreadMail()
  }

  render() {
    const { unreadCount } = this.props

    return (
      Boolean(unreadCount) && (
        <span>
          <span className="animate-flash-slow animate-infinite badge badge-secondary">
            <strong>{unreadCount}</strong>
          </span>
        </span>
      )
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      unreadCount: unreadMailCountSelector(state),
    }),
    { fetchUnreadMail },
  ),
)

export default enhance(UnreadMailCountBadge)
