import { useSelector } from 'react-redux'

import { selectOrganization, selectOrganizationMetadata } from '../../ducks'

export default function useOrganization() {
  const organization = useSelector(selectOrganization)

  organization.organizationMetadata = useSelector(selectOrganizationMetadata)

  return organization
}
