import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import useDebounce from '../../hooks/useDebounce'

import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './RichTextEditor.scss'

const RichTextEditor = ({ autofocus, initialHTML, placeholderText, setBody, setHTML }) => {
  const mountState = useMemo(() => {
    if (Boolean(initialHTML)) {
      const contentBlock = htmlToDraft(initialHTML)
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)

      return editorState
    }

    return EditorState.createEmpty()
  }, [])

  const [editorRef, setEditorRef] = useState()

  useEffect(() => {
    if (autofocus && editorRef) {
      editorRef.focus()
    }
  }, [autofocus, editorRef])

  const [editorState, setEditorState] = useState(mountState)

  const debouncedEditorState = useCallback(useDebounce(editorState, 500), [editorState])

  useEffect(() => {
    setBody(editorState.getCurrentContent().getPlainText())
    setHTML(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [debouncedEditorState])

  return (
    <Editor
      editorRef={setEditorRef}
      editorState={editorState}
      toolbarClassName="rich-text-editor-toolbar"
      wrapperClassName="rich-text-editor-wrapper"
      editorClassName="bg-dark rich-text-editor"
      placeholder={placeholderText}
      onEditorStateChange={setEditorState}
    />
  )
}

RichTextEditor.propTypes = {
  autofocus: PropTypes.bool,
  initialHTML: PropTypes.string,
  placeholderText: PropTypes.string,
  setBody: PropTypes.func,
  setHTML: PropTypes.func,
}

RichTextEditor.defaultProps = {
  autofocus: false,
  initialHTML: undefined,
  placeholderText: 'Your Message Here...',
  setBody: () => {},
  setHTML: () => {},
}

export default RichTextEditor
