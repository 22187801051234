import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import useScheduleShifts from './useScheduleShifts'
import useScheduleShift from './useScheduleShift'
import useUserStatus from '../../User/shared/hooks/useUserStatus'
import { roundToNearestMin } from '../../../utils/date'
import useScheduleTimelog from './useScheduleTimelog'
import { globalVent } from '@utils/vent'
import { BROWSER_FOCUS_CHANGE } from '../../../utils/events'

export function useUserSchedule({ userId } = {}) {
  const [forceUpdate, setForceUpdate] = useState(0)
  const userStatus = useUserStatus(userId)

  useEffect(() => {
    const i = setInterval(() => {
      setForceUpdate(Math.random())
    }, 1000 * 60)

    const { remove } = globalVent.subscribe(BROWSER_FOCUS_CHANGE, ({ focused }) => {
      if (focused) setForceUpdate(Math.random())
    })

    return () => {
      clearInterval(i)
      remove()
    }
  }, [])

  const scheduleShiftsQuery = useMemo(() => {
    const now = roundToNearestMin()
    const oneWeekFromNow = dayjs(now).add(7, 'days').toISOString()

    if (!userStatus?.userId) return undefined

    return {
      limit: 1,
      orderBy: 'dateStart',
      where: {
        dateEnd: { '>=': now },
        dateStart: { '<=': oneWeekFromNow },
        published: true,
        userId: userStatus?.userId,
      },
    }
  }, [forceUpdate, userStatus?.userId])

  const { data: scheduleShifts, validating: validatingScheduleShifts } =
    useScheduleShifts(scheduleShiftsQuery)

  const { data: currentScheduleTimelog, validating: validatingCurrentScheduleTimelog } =
    useScheduleTimelog(userStatus?.schedule?.scheduleTimelogId)

  const { data: currentScheduleShift, validating: validatingCurrentScheduleShift } =
    useScheduleShift(userStatus?.schedule?.scheduleShiftId)

  const nextScheduleShift = scheduleShifts[0] || undefined

  const lateForShiftClockin =
    !validatingCurrentScheduleTimelog &&
    nextScheduleShift &&
    nextScheduleShift?.dateStart <= new Date().toISOString() &&
    nextScheduleShift?.dateEnd > new Date().toISOString() &&
    currentScheduleTimelog?.scheduleShiftId !== nextScheduleShift?.id

  const lateForShiftClockout = currentScheduleShift?.dateEnd <= new Date().toISOString()

  return {
    // scheduleShift if current user is clocked-in for a shift
    currentScheduleShift,

    // scheduleTimelog if current user is clocked-in
    currentScheduleTimelog,

    lateForShiftClockin,
    lateForShiftClockout,

    loading:
      validatingScheduleShifts ||
      validatingCurrentScheduleTimelog ||
      validatingCurrentScheduleShift,

    // scheduleShiftId of the next shift starting
    nextScheduleShift,
  }
}
