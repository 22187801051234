import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'
import { sortByDateKey } from '@utils'
import { selectCurrentUserAccessLevel } from '../../modules/Auth/ducks'

const reducerName = 'locationPassDown'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'passDowns',
  reducerName,
  singular: 'passDown',
  storeMapperName: 'locationPassDown',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const selectLocationPassDowns = state => state.locationPassDown.passDowns

const selectPassDownsByLocationId = (state, locationId) =>
  state.locationPassDown.passDowns.filter(c => c.locationId === locationId)

export const { passDownByIdSelector, passDownLoadingByIdSelector } = selectors

export const passDownsByLocationIdSelector = createSelector(
  selectPassDownsByLocationId,
  passDowns => passDowns,
)

/**
 * Select all active passdowns for a locationId
 * @return Array<Object>
 */
export const locationPassDownsByLocationIdSelector = createSelector(
  selectPassDownsByLocationId,
  passDowns => sortByDateKey(passDowns).filter(({ active }) => Boolean(active)),
)

// actions
export const { fetchPassDown, fetchPassDowns } = actions

export const fetchLocationPassDowns = locationId => async (dispatch, getState) => {
  const accessLevel = selectCurrentUserAccessLevel(getState())

  return dispatch(
    fetchPassDowns({ where: { accessLevel: { '<=': accessLevel }, active: true, locationId } }),
  )
}

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
