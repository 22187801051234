import React from 'react'

import Conditional from '../../../../Auth/shared/Conditional'
import MainNavButton from 'modules/Layout/shared/MainNavButton/index'

const BulletinMainNavButton = () => (
  <Conditional module="bulletin">
    <MainNavButton iconClass="fa fa-newspaper fa-2x" to="/bulletins">
      Bulletin Board
    </MainNavButton>
  </Conditional>
)
export default BulletinMainNavButton
