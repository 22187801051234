import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import Select from '@components/Inputs/Select'
import { isFunc } from '@therms/web-common/utils'
import useSchedulePositions from '../../hooks/useSchedulePositions'

const SelectSchedulePosition = ({
  defaultValue,
  input,
  label,
  onChange,
  meta,
  multi,
  placeholder,
}) => {
  const { data: schedulePositions, validating: loadingSchedulePositions } = useSchedulePositions()

  const _placeholder = useMemo(() => {
    if (multi) return 'Positions'

    return 'Position'
  }, [multi])

  const _onChange = useCallback(
    e => {
      if (isFunc(onChange)) onChange(e.target.value)
    },
    [onChange],
  )

  const _label = useMemo(() => {
    let customLabel

    if (!label) return null
    if (React.isValidElement(label)) return label
    if (typeof label === 'string') customLabel = label
    else if (multi) customLabel = 'ActivityTypes'
    else customLabel = 'Location'

    return <label>{customLabel}</label>
  }, [label, multi])

  const options = useMemo(
    () =>
      schedulePositions.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [schedulePositions],
  )

  return (
    <div className="min-width-200">
      <Select
        defaultValue={defaultValue}
        isClearable
        isLoading={loadingSchedulePositions}
        label={_label}
        multi={multi}
        name={`schedulePositionId${multi ? 's' : ''}`}
        options={options}
        placeholder={placeholder || _placeholder}
        input={input}
        onChange={_onChange}
        meta={meta}
      />
    </div>
  )
}

SelectSchedulePosition.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  input: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.elementType,
    PropTypes.node,
    PropTypes.string,
  ]),
  meta: PropTypes.object,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

SelectSchedulePosition.defaultProps = {
  defaultValue: undefined,
  input: {},
  label: '',
  meta: {},
  multi: true,
  onChange: undefined,
  placeholder: undefined,
}

export default SelectSchedulePosition
