import React from 'react'
import PropTypes from 'prop-types'
import isRequiredIf from 'react-proptype-conditional-require'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Portal } from 'react-portal'

import {
  locationByIdSelector,
  locationLoadingByIdSelector,
  fetchLocation,
} from 'state/ducks/location'
import { get, truncate } from 'lodash-es'
// eslint-disable-next-line import/no-cycle
import LocationCard from '../components/LocationCard'

import './LocationName.scss'
import Viewport from 'viewport-dimensions'
import LAYOUT from '@therms/web-common/config/layout'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import LocationActiveBoloCount from './LocationActiveBoloCount'
import LocationActivePassDowns from './LocationActivePassDowns'
import getPriorityBsClass from '@therms/web-common/components/Priority/getPriorityBsClass'

class LocationName extends React.Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    id: isRequiredIf(PropTypes.string, (props) => !props.location),
    location: isRequiredIf(PropTypes.object, (props) => !props.id),
    fetchLocation: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    noCard: PropTypes.bool,
    noLink: PropTypes.bool,
    showActiveIcons: PropTypes.bool,
    showLocationNumber: PropTypes.bool,
    truncate: PropTypes.number,
  }

  static defaultProps = {
    id: undefined,
    location: undefined,
    noCard: false,
    noLink: false,
    showActiveIcons: false,
    showLocationNumber: false,
    truncate: undefined,
  }

  constructor(props) {
    super(props)

    this.state = {
      isMobile: Viewport.width() <= LAYOUT.breakpointTablet,
      showCard: false,
    }

    this.locationCardRef = React.createRef()
    this.locationNameRef = React.createRef()
    this.mouseEnterTimer = null
    this.mouseOutTimer = null
  }

  componentDidMount() {
    if (!this.props.location && this.props.id) this.props.fetchLocation(this.props.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) this.props.fetchLocation(this.props.id)
  }

  handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.history.push(`/location/view/${this.props.location.id}`)
  }

  onMouseEnter = (e) => {
    if ((this.state.isMobile && this.props.noLink) || this.props.noCard) return
    if (this.state.showCard) return

    let x = e.nativeEvent.clientX
    const y = e.nativeEvent.clientY

    this.mouseEnterTimer = setTimeout(() => {
      this.setState(
        {
          showCard: true,
        },
        () => {
          if (x + this.locationCardRef.current.clientWidth > window.innerWidth)
            x = window.innerWidth - this.locationCardRef.current.clientWidth

          let top = y
          try {
            const locationNameRects = get(this.locationNameRef.current.getClientRects(), '0')

            if (locationNameRects) top = locationNameRects.top + locationNameRects.height
          } catch (err) {
            console.log('<LocationName /> hover card err', err)
          }

          this.locationCardRef.current.style.left = `${x}px`
          this.locationCardRef.current.style.top = `${top}px`
        },
      )
    }, 400)
  }

  onMouseLeave = () => {
    if (this.state.isMobile && this.props.noLink) return
    if (this.mouseEnterTimer) clearTimeout(this.mouseEnterTimer)
    if (!this.state.showCard) return

    this.mouseOutTimer = setTimeout(() => {
      this.setState({
        showCard: false,
      })
    }, 100)
  }

  render() {
    const { id, location, loading, noLink, showActiveIcons } = this.props

    if (!id) return null

    if (loading) return <span className="loading" />

    if (!location) return null

    return (
      <span
        className="locationname-container position-relative"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={this.locationNameRef}
      >
        <span
          className={noLink ? '' : 'cursor onhover-underline'}
          onClick={noLink ? undefined : this.handleClick}
        >
          {this.props.showLocationNumber && location.number && (
            <span className="mr-1 text-muted">{location.number}</span>
          )}
          {truncate(location.name, { length: this.props.truncate || location.name.length })}
          {!location.active && <span className="font-weight-lighter text-danger">*</span>}
          {showActiveIcons && (
            <span className="d-inline-block font-xs ml-1">
              <LocationActivePassDowns
                locationId={location.id}
                render={() => (
                  <>
                    <FAIcon className="text-secondary" name="level-down-alt" />{' '}
                  </>
                )}
              />

              <LocationActiveBoloCount
                locationId={location.id}
                render={({ priority }) => (
                  <FAIcon
                    className={`text-${getPriorityBsClass(priority)}`}
                    name="exclamation-triangle"
                  />
                )}
              />
            </span>
          )}
        </span>

        {this.state.showCard && (
          <Portal>
            <div
              className="locationname-locationcard-container position-absolute"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onMouseEnter={() => {
                if (this.mouseOutTimer) clearTimeout(this.mouseOutTimer)
              }}
              ref={this.locationCardRef}
            >
              <LocationCard id={this.props.id} />
            </div>
          </Portal>
        )}
      </span>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    (state, props) => ({
      location: locationByIdSelector(state, props.id),
      loading: locationLoadingByIdSelector(state, props.id),
    }),
    { fetchLocation },
  ),
)

export default enhance(LocationName)
