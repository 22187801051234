import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import classnames from 'classnames'
import { withRouter } from 'react-router'
import ReactRouterPropTypes from 'react-router-prop-types'

import { fetchGroups } from 'state/ducks/locationGroup'

class LocationGroupNames extends Component {
  static propTypes = {
    badge: PropTypes.bool, // wrap name span in .badge class
    fetchGroups: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    link: PropTypes.bool,
    locationGroupIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    loading: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    badge: false,
    link: false,
  }

  componentDidMount() {
    if (!this.props.groups.length) this.props.fetchGroups()
  }

  render() {
    if (this.props.loading || !this.props.groups) return <span className="loading" />

    return (
      <span>
        {this.props.groups
          .filter(({ id }) => this.props.locationGroupIds.includes(id))
          .map(g => (
            <span
              className={classnames(
                'mr-1',
                {
                  'badge badge-pill badge-primary': this.props.badge,
                },
                {
                  cursor: this.props.link,
                  'onhover-underline': this.props.link,
                },
              )}
              key={g.id}
              onClick={
                this.props.link
                  ? () => this.props.history.push(`/locations/group/${g.id}`)
                  : undefined
              }
            >
              {g.name}
            </span>
          ))}
      </span>
    )
  }
}

const enhance = compose(
  withRouter,
  connect(
    state => ({
      loading: state.locationGroup.loadingGroups,
      groups: state.locationGroup.groups,
    }),
    { fetchGroups },
  ),
)

export default enhance(LocationGroupNames)
