import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { formValidate } from '@therms/util-services'
import { FORM_ERROR } from 'final-form'

import { systemToast } from 'utils/toaster'
import Store from 'services/store'
import useCoreModelsFind from 'hooks/useCoreModelsFind'

import DateTime from '@therms/web-common/components/Inputs/DateTime'
import DeleteCancelSave from '@therms/web-common/components/Buttons/DeleteCancelSave'
import Loading from '@therms/web-common/components/Loading'
import ShiftDetails from '../../shared/ShiftDetails'
import UserName from '../../../User/shared/UserName'
import { DateTimeDiff } from '../../../../components/DateTimeDiff'

const ScheduleShiftTimelog = ({ id, onCancel, onComplete }) => {
  const { data, destroy, error, validating } = useCoreModelsFind('scheduleTimelog', id)

  async function handleOnSubmit(values) {
    let opDisplayName = 'Created'

    try {
      let storeRecord

      if (id) {
        storeRecord = await Store.scheduleTimelog.update(id, values)
        opDisplayName = 'Updated'
      } else {
        storeRecord = await Store.scheduleTimelog.create(values)
        opDisplayName = 'Created'
      }

      systemToast(`Timelog ${opDisplayName}`, { quick: true })

      if (onComplete) onComplete(storeRecord)

      return undefined
    } catch (err) {
      console.error('err', err)

      return {
        [FORM_ERROR]: 'There was a problem saving, please try again',
      }
    }
  }

  if (validating) return <Loading />
  if (error)
    return <div className="alert alert-danger">There was a problem loading, please try again</div>

  return (
    <Form
      initialValues={{ ...data }}
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitError, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          {data?.scheduleShiftId && (
            <div className="mb-3">
              <ShiftDetails scheduleShiftId={data.scheduleShiftId} />
            </div>
          )}

          {data.userId && (
            <div className="mb-2">
              Timelog: <UserName id={data.userId} />
            </div>
          )}

          <hr />

          <div className="text-center">
            Total: <DateTimeDiff time1={values.clockInTimestamp} time2={values.clockOutTimestamp} />
          </div>

          <Field
            component={DateTime}
            label="Clock In"
            name="clockInTimestamp"
            noDefaultValue={false}
            validate={formValidate.isoDate}
            viewMode="time"
          />

          <Field
            component={DateTime}
            label="Clock Out"
            name="clockOutTimestamp"
            noDefaultValue={false}
            validate={formValidate.isoDate}
            viewMode="time"
          />

          {submitError && <div className="alert alert-danger">{submitError}</div>}

          <DeleteCancelSave
            disabled={submitting}
            isSaving={submitting}
            onCancel={onCancel}
            onDelete={() => destroy().finally(onComplete)}
            onSave={handleSubmit}
            showDelete={id}
          />
        </form>
      )}
    />
  )
}

ScheduleShiftTimelog.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
}
ScheduleShiftTimelog.defaultProps = {
  id: undefined,
  onComplete: undefined,
}

export default ScheduleShiftTimelog
