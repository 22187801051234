import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loading from '@therms/web-common/components/Loading'

import retry from 'utils/retry-async'

const Admin = lazy(() => retry(() => import('./Admin')))
const Bulletin = lazy(() => retry(() => import('./Bulletin')))
const Dashboard = lazy(() => retry(() => import('./Dashboard')))
const Dispatch = lazy(() => retry(() => import('./Dispatch')))
const Integrations = lazy(() => retry(() => import('./Integrations')))
const Locations = lazy(() => retry(() => import('./Location')))
const Mail = lazy(() => retry(() => import('./Mail')))
const Patrol = lazy(() => retry(() => import('./Patrol')))
const Profile = lazy(() => retry(() => import('./Profile')))
const Records = lazy(() => retry(() => import('./Records')))
const Reports = lazy(() => retry(() => import('./Reports')))
const Roster = lazy(() => retry(() => import('./Roster')))
const Schedule = lazy(() => retry(() => import('./Schedule')))
const Stats = lazy(() => retry(() => import('./Stats')))
const Subscribers = lazy(() => retry(() => import('./Subscribers')))
const Support = lazy(() => retry(() => import('./Support')))

// imports for runtime invokation
import './Admin/ducks'
import './Dispatch/ducks'
import './Location/ducks'
import './Mail/ducks'
import './Online/ducks'
import './Reports/ducks'
import './Roster/ducks'
import './Task/ducks'

const Routes = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/dashboard" component={Dashboard} />

      <Route path="/admin" component={Admin} />
      <Route path="/bulletins" component={Bulletin} />
      <Route path="/dispatch" component={Dispatch} />
      <Route path="/integrations" component={Integrations} />
      <Route path={['/locations', '/location']} component={Locations} />
      <Route path="/mail" component={Mail} />
      <Route path="/patrol" component={Patrol} />
      <Route path="/profile" component={Profile} />
      <Route path="/records" component={Records} />
      <Route path="/reports" component={Reports} />
      <Route path="/roster" component={Roster} />
      <Route path="/schedule" component={Schedule} />
      <Route path="/stats" component={Stats} />
      <Route path="/subscribers" component={Subscribers} />
      <Route path="/support" component={Support} />

      <Route render={() => <h4 className="p-5 text-warning">Route Not Found...</h4>} />
    </Switch>
  </Suspense>
)

export default Routes
