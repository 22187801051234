import cookies from 'js-cookie'
import socketIO from 'socket.io-client'
import url from 'url'
import { globalVent } from '@utils/vent'

import ENV from 'config/env'
import { handleAuthFailed } from './auth'
import { REALTIME_SERVICE_CONNECTION_CHANGE } from '../utils/events'
import { isMobile } from '@utils/viewport'

const authToken = cookies.get(ENV.AUTH_COOKIE_NAME)
const parsedUrl = url.parse(ENV.WS_REALTIME_URL)
const REALTIME_HOST = parsedUrl.host || window.location.host
const REALTIME_PATH = parsedUrl.path

const io = socketIO(REALTIME_HOST, {
  query: {
    authToken,
    webCoreVersion: ENV.RELEASE,
  },
  path: REALTIME_PATH,
  // ws conn. only - pm2 clustered backend doesnt support polling (sticky session)
  transports: ['websocket', 'polling'],
})

const connectionChangeHandlers = []

function notifyConnectionChange(connected) {
  globalVent.publish(REALTIME_SERVICE_CONNECTION_CHANGE, connected)
  connectionChangeHandlers.forEach(handler => handler(connected))
}

io.on('connect', () => {
  console.info('Realtime socket connnected')
  notifyConnectionChange(true)
})

io.on('connect_error', () => {
  console.error('Realtime socket connection error')
  notifyConnectionChange(false)
})

io.on('connect_timeout', () => {
  console.error('Realtime socket connection timeout')
  notifyConnectionChange(false)
})

io.on('error', err => {
  console.error('Realtime socket error', err)
  notifyConnectionChange(false)
})

io.on('disconnect', () => {
  console.info('Realtime socket disconnnected')
  notifyConnectionChange(false)
})

io.on('reconnect', () => {
  console.info('Realtime socket reconnected')
  notifyConnectionChange(true)
})

// emitted from ws-realtime
io.on('sessionexpire', () => {
  console.info('Realtime socket sessionexpire')
  handleAuthFailed('realtime "sessionexpire" event received')
})

function registerConnectionChangeListener(handler) {
  connectionChangeHandlers.push(handler)
}

function registerListener(msg, handler) {
  io.on(msg, handler)

  return () => io.off(msg, handler)
}

export default {
  io,
  registerListener,
  registerConnectionChangeListener,
}

// browser hack
// https://github.com/socketio/socket.io/issues/3259
// try and prevent background browser/tabs "napping" and disconnecting our
//  socket connection.
setInterval(() => {
  if (!isMobile()) {
    io.emit('ping')
  }
}, 15000)
