/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import classnames from 'classnames'

import { fetchServiceTypes } from 'state/ducks/serviceType'

class ServiceTypes extends Component {
  static propTypes = {
    badge: PropTypes.bool, // wrap name span in .badge class
    fetchServiceTypes: PropTypes.func.isRequired,
    types: PropTypes.arrayOf(PropTypes.object).isRequired,
    serviceTypeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    loading: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    badge: false,
  }

  componentDidMount() {
    if (!this.props.types.length)
      this.props.fetchServiceTypes()
  }

  render() {
    const { badge, types } = this.props

    // todo: populate the number of badges while loading with .loading class
    // this is so the block space doesn't jump around on the page when the
    // badges finally load
    if (this.props.loading || !types)
      return <span className="loading" />

    const typeList = types
      .filter(({ id }) => this.props.serviceTypeIds.includes(id))

    return (
      <span>
        {typeList
          .map((t, i) => (
            <span
              className={classnames({
                'badge badge-secondary badge-pill mr-1': badge,
              })}
              key={t.id}
            >
              {t.name}{!badge ? (i + 1 === typeList.length ? '' : ', ') : ''}
            </span>
          ))}
      </span>
    )
  }
}

const enhance = compose(
  connect(
    state => ({
      loading: state.serviceType.loadingServiceTypes,
      types: state.serviceType.serviceTypes,
    }),
    { fetchServiceTypes },
  ),
)

export default enhance(ServiceTypes)

