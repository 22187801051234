import arrayDiff from 'simple-array-diff'
import { combineReducers } from 'redux'

import StateStore from 'state'
import reducerRegistry from 'state/reducerRegistry'
import Store from 'services/store'
import { selectCurrentRegion, updateRegionModuleSettings } from 'modules/Auth/ducks/region'
import {
  inactiveLocationsSelector,
  locationByRegionZoneIdSelector,
  locationsSelector,
} from 'state/ducks/location'

import accessLogReducer from './locationAccessLog'
import boloReducer from './locationBolo'
import filesReducer from './files'
import passDownReducer from './locationPassDown'
import { updateOrganizationModuleSettings } from '../../Auth/ducks/organization'
import { createSelector } from 'reselect'
import { sortBy } from 'lodash-es'

const reducerName = 'Location'

// selectors
export const activeRegionLocationsSelector = createSelector(
  selectCurrentRegion,
  locationsSelector,
  (region, locations) => sortBy(locations.filter(l => l.regionId === region.id), ['name']),
)

export const inactiveRegionLocationsSelector = createSelector(
  selectCurrentRegion,
  inactiveLocationsSelector,
  (region, locations) => sortBy(locations.filter(l => l.regionId === region.id), ['name']),
)

/**
 * This standalone util function is used to update the locations that belong
 * to a regionZone. It passes a list of location id's that belong to the zone
 * after the update. The body of this util will get the location id's before
 * the update and do a diff so that it knows what locations to update that
 * were either removed or added.
 *
 * @param {string} zoneId - regionZone id
 * @param {Array<String>} updatedLocationIds - list of updated location id's
 * @returns {Promise<void>}
 */
export const updateLocationZoneIdForRegion = async ({ zoneId, updatedLocationIds }) => {
  const state = StateStore.getState()
  const locationsBeforeUpdate = locationByRegionZoneIdSelector(state, zoneId) || []
  const locationIdsBeforeUpdate = locationsBeforeUpdate.map(({ id }) => id)

  const arrayDiffResult = arrayDiff(locationIdsBeforeUpdate, updatedLocationIds)

  const promises = []

  // Update locations that were removed
  arrayDiffResult.removed.forEach(locationId => {
    promises.push(
      Store.Location.update(locationId, {
        regionZoneId: '',
      }),
    )
  })

  // Update locations that were added
  arrayDiffResult.added.forEach(locationId => {
    promises.push(
      Store.Location.update(locationId, {
        regionZoneId: zoneId,
      }),
    )
  })

  try {
    await Promise.all(promises)
  } catch (err) {
    console.error('Locations/ducks err updateLocationZoneIdForRegionZone()', err)
    throw err
  }
}

export const updateLocationSettings = ({ locationId, settingsValue }) => (dispatch, getState) => {
  const {
    location: { locationsById },
  } = getState()

  const location = locationsById[locationId]

  return Store.Location.update(locationId, {
    settings: {
      ...location.settings,
      ...settingsValue,
    },
  })
}

export const updateLocationOrganizationModuleSettings = settingsValue =>
  updateOrganizationModuleSettings('location', settingsValue)

export const updateLocationRegionModuleSettings = settingsValue =>
  updateRegionModuleSettings('location', settingsValue)

reducerRegistry.register(
  reducerName,
  combineReducers({
    accessLog: accessLogReducer,
    bolo: boloReducer,
    file: filesReducer,
    passDown: passDownReducer,
  }),
)
