import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import coreApi from '../../services/coreApi'
import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

const reducerName = 'task'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'tasks',
  reducerName,
  singular: 'task',
  storeMapperName: 'task',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
const selectTasksByLinkModelNameAndId = (state, modelName, modelId) =>
  state.task.tasks
    .filter(c => c.linkModelName === modelName && c.linkModelId === modelId)
    .filter(c => !c._deleted)

export const { taskByIdSelector, taskLoadingByIdSelector } = selectors

export const tasksByLinkModelNameAndIdSelector = createSelector(
  selectTasksByLinkModelNameAndId,
  tasks => tasks,
)

// actions
export const { fetchTask, fetchTasks } = actions

export const fetchTaskTitles = linkModelName =>
  coreApi
    .get(`/task/titles?linkModelName=${linkModelName}`, { useCache: false })
    .then(({ data }) => data)

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
