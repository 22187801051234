import { useCallback, useEffect, useState } from 'react'
import { scheduleTimelogApi } from '../services/apiCore'

export default function useScheduleTimelogsWithShift({ fromDate, locationIds, toDate, userIds }) {
  const [data, setData] = useState([])
  const [validating, setValidating] = useState(true)

  const fetchData = useCallback(() => {
    setValidating(true)

    scheduleTimelogApi
      .getTimeLogWithShifts({
        fromDate,
        locationIds,
        toDate,
        userIds,
      })
      .then(setData)
      .catch(() => setData([]))
      .finally(setValidating)
  }, [fromDate, locationIds, toDate, userIds])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    data,
    revalidate: fetchData,
    validating,
  }
}
