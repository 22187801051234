import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useLocations from './hooks/useLocations'
import Checkbox from '@therms/web-common/components/Inputs/Checkbox'
import TextSearch from '@therms/web-common/components/Inputs/TextSearch'
import { filterCbObjectFields } from '@therms/web-common/utils'
import isPlural from '../../../utils/isPlural'

const SelectLocationsList = ({ onChange, value }) => {
  const [filteredLocations, setFilteredLocations] = useState([])
  const [searchText, setSearchText] = useState('')
  const { locations } = useLocations({ limit: 5000 })

  const locationsById = useMemo(() => {
    const map = Object.fromEntries(locations.map((location) => [location.id, false]))

    value.forEach((selectedLocationId) => {
      if (Object.hasOwn(map, selectedLocationId)) {
        map[selectedLocationId] = true
      }
    })

    return map
  }, [locations, value])
  
  useEffect(() => {
    if (searchText) {
      setFilteredLocations(
        locations.filter(
          filterCbObjectFields(searchText, {
            fields: ['address.streetAddress', 'address.city', 'name', 'number'],
          }),
        ),
      )
    } else {
      setFilteredLocations(locations)
    }
  }, [locations, searchText, value])

  const handleCheckboxOnChange = useCallback(
    (locationId, checked) => {
      const locationIdsSet = new Set(value.reduce((acc, lid) => {
        if (Object.hasOwn(locationsById, lid)) {
          acc.push(lid)
        }
        
        return acc
      }, []))
      
      if (checked) {
        locationIdsSet.add(locationId)
      } else {
        locationIdsSet.delete(locationId)
      }
      
      onChange(Array.from(locationIdsSet))
    },
    [locationsById, onChange, value],
  )

  if (!locations?.length) return null

  return (
    <div>
      {locations.length > 25 && <TextSearch onChange={setSearchText} small value={searchText} />}

      {value?.length > 0 && (
        <div className="font-italic font-small text-muted">
          {value.length} {isPlural(value, 'Location')} selected
        </div>
      )}

      <div className="max-height-300 overflow-auto">
        {filteredLocations.map((l) => (
          <div key={l.id}>
            <Checkbox
              checked={locationsById[l.id]}
              onChange={(checked) => handleCheckboxOnChange(l.id, checked)}
              label={
                <span>
                  <span className="font-small text-muted">{l.number}</span> {l.name}
                </span>
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

SelectLocationsList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
}
SelectLocationsList.defaultProps = {}

export default SelectLocationsList
