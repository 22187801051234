import React, { useCallback } from 'react'
import Field from 'components/Form/Field'

import Select from '@components/Inputs/Select'
import InlineForm from '@components/Forms/InlineForm'
import { systemToast } from '../../../../../utils/toaster'
import { updateRegionModuleSettings } from '../../../../Auth/ducks/region'
import useRegionMetadata from '../../../../Auth/shared/hooks/useRegionMetadata'
import SelectAssignableModuleFields from './SelectAssignableModuleFields'
import ClockInFields from './ClockInFields'
import { setLocaleFirstDayOfWeek } from '../../../../../utils/date'

function ScheduleRegionSettingsForm() {
  const { data } = useRegionMetadata()

  const settings = data?.settings?.module?.schedule || {}

  const handleSave = useCallback((values) => updateRegionModuleSettings('schedule', values).then(() => {
      // we only set this on ap startup so if it chanages we need to call our date util
      setLocaleFirstDayOfWeek(values?.firstDayOfWeek)
      systemToast('Settings Saved', { quick: true })
    }), [])

  return (
    <div>
      <InlineForm
        autoSave
        debouncedFields={['earlyClockInThresholdMin']}
        initialValues={settings}
        field={(
          <>
            <div className="max-width-300">
              <Field
                component={Select}
                defaultValue={0}
                isClearable={false}
                label="First Day of Week"
                name="firstDayOfWeek"
                options={[
                  { label: 'Monday', value: 1 },
                  { label: 'Sunday', value: 0 },
                ]}
              />
            </div>

            <SelectAssignableModuleFields />

            <ClockInFields />
          </>
        )}
        onSubmit={handleSave}
        onlyShowSaveOnChanged
      />
    </div>
  )
}

export default ScheduleRegionSettingsForm
