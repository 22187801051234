/**
 * Format User name based on a specific setting, @therms/models/organizationMetadata
 * settings.userNameFormat
 *
 * @param user {Object}
 * @param userNameFormat {Number} 1: Doe, John; 2: John Doe; 3: J.Doe; 4: John D.
 * @returns {string}
 */
export default function formatUserName(user, userNameFormat = 1) {
  if (!user) return ''

  const { firstName = '', lastName = '' } = user

  if (userNameFormat === 1) return `${lastName}, ${firstName}`

  if (userNameFormat === 2) return `${firstName} ${lastName}`

  if (userNameFormat === 3) return `${firstName.substr(0, 1).toUpperCase()}.${lastName}`

  if (userNameFormat === 4) return `${firstName} ${lastName.substr(0, 1).toUpperCase()}.`

  return `${lastName}, ${firstName}`
}
