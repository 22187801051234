import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'
import { uniqBy } from 'lodash-es'

const reducerName = 'online'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'onlines',
  reducerName,
  singular: 'online',
  storeMapperName: 'online',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const selectOnline = state => state.online.onlines

export const userOnlineRecordsByUserId = userId =>
  createSelector(selectOnline, online => online.filter(o => o.userId === userId))

export const uniqueUserOnlineSelector = createSelector(selectOnline, online =>
  uniqBy(online, 'userId'),
)

export const { onlineByIdSelector, onlineLoadingByIdSelector } = selectors

// actions
export const { fetchOnline, fetchOnlines } = actions

export const resetOnlineState = createAction('resetOnlineState')

// reducer
const reducer = handleActions(
  {
    [resetOnlineState]: () => ({
      ...initialState,
    }),
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
