import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { useUserSchedule } from '../hooks/useUserSchedule'
import {
  dismissLateClockInToast,
  dismissLateClockOutToast,
  showLateForShiftClockInToast,
  showLateForShiftClockOutToast,
} from './Toasts'
import Modal from '@therms/web-common/components/Modal'
import ShiftDetailsWithControls from '../shared/ShiftDetailsWithControls'

const LateShiftNotifications = () => {
  const [showScheduleShiftModalId, setShowScheduleShiftModalId] = useState()

  const { currentScheduleShift, lateForShiftClockin, lateForShiftClockout, nextScheduleShift } =
    useUserSchedule()

  useEffect(() => {
    if (lateForShiftClockin) {
      showLateForShiftClockInToast({
        onClick: () => setShowScheduleShiftModalId(nextScheduleShift.id),
      })
    } else {
      dismissLateClockInToast()
    }
  }, [lateForShiftClockin, nextScheduleShift])

  useEffect(() => {
    if (lateForShiftClockout) {
      showLateForShiftClockOutToast({
        onClick: () => setShowScheduleShiftModalId(currentScheduleShift.id),
      })
    } else {
      dismissLateClockOutToast()
    }
  }, [currentScheduleShift, lateForShiftClockout])

  if (showScheduleShiftModalId)
    return (
      <Modal closeHandler={() => setShowScheduleShiftModalId()} title="Shift">
        <ShiftDetailsWithControls scheduleShiftId={showScheduleShiftModalId} />
      </Modal>
    )

  return null
}

LateShiftNotifications.propTypes = {}
LateShiftNotifications.defaultProps = {}

export default LateShiftNotifications
