import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

const reducerName = 'reportEntry'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'reportEntries',
  reducerName,
  singular: 'reportEntry',
  storeMapperName: 'reportEntry',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
const selectReportEntryByReportId = (state, reportId) =>
  state.reportEntry.reportEntries.filter(reportEntry => reportEntry.reportId === reportId)

export const { reportEntryByIdSelector, reportEntryLoadingByIdSelector } = selectors

export const reportEntriesByReportIdSelector = createSelector(
  selectReportEntryByReportId,
  reportEntries => reportEntries,
)

// actions
export const { fetchReportEntry, fetchReportEntries } = actions

export const fetchReportEntriesByReportId = reportId => (dispatch) => dispatch(fetchReportEntries({ limit: 500, where: { reportId } }))

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
