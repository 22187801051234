import { handleActions } from 'redux-actions'
import { models } from '@therms/models'

import { NotFoundError } from '@utils/errors'
import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

import Store from '../../services/store'
import { createSelector } from 'reselect'

const builtInReportTypeDefaultsById = models.reportType.fixtures.builtInsById

const reducerName = 'reportType'

const {
  actions,
  actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'reportTypes',
  reducerName,
  singular: 'reportType',
  storeMapperName: 'reportType',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const {
  reportTypeByIdSelector,
  reportTypeHasFetchedByIdSelector,
  reportTypeLoadingByIdSelector,
} = selectors

export const selectReportTypes = state => state.reportType.reportTypes

export const activeReportTypesSelectory = createSelector(selectReportTypes, reportTypes =>
  reportTypes.filter(({ active }) => active),
)

// actions
export const fetchReportType = id => async dispatch => {
  // Override the default because  we might not be fetching by id but also builtInTypeName
  if (!id) return Promise.reject(new Error('fetchReportType(id)'))

  const isBuiltIn = /builtin/.test(id)
  let reportType

  dispatch(
    actionCreators.SET_LOADING_REPORTTYPE({
      id,
      loading: true,
    }),
  )

  if (!isBuiltIn) reportType = await Store.ReportType.find(id)
  else reportType = builtInReportTypeDefaultsById[id]

  if (!reportType) return Promise.reject(new NotFoundError(`reportType "${id}" not found`))

  dispatch(actionCreators.SET_REPORTTYPE(reportType))
  dispatch(
    actionCreators.SET_LOADING_REPORTTYPE({
      id,
      loading: false,
    }),
  )

  return Promise.resolve(reportType)
}

export function fetchReportTypes(...args) {
  return async dispatch => {
    // Override the default because we need to merge built-in defaults
    let reportTypes

    dispatch(actionCreators.SET_LOADING_REPORTTYPES(true))

    try {
      reportTypes = await dispatch(actions.fetchReportTypes(...args))
    } catch (e) {
      dispatch(actionCreators.SET_LOADING_REPORTTYPES(false))

      return Promise.reject(e)
    }

    // add any built-in defaults
    Object.keys(models.reportType.fixtures.builtInsByName).forEach(builtInName => {
      if (!reportTypes.find(rt => rt.builtInTypeName === builtInName))
        reportTypes.push(models.reportType.fixtures.builtInsByName[builtInName])
    })

    dispatch(actionCreators.SET_LOADING_REPORTTYPES(false))
    dispatch(actionCreators.SET_REPORTTYPES(reportTypes))

    return Promise.resolve(reportTypes)
  }
}

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
