import React from 'react'
// import PropTypes from 'prop-types'
import { NavLink, Route, Switch } from 'react-router-dom'

const RouteBreadcrumbs = () => (
  <nav className="breadcrumb-container">
    <ol>
      <li>
        <NavLink to="/schedule">Schedule</NavLink>
      </li>

      <Switch>
        <Route path="/schedule/build">
          <li>Build</li>
        </Route>

        <Route path="/schedule/settings">
          <li>Settings</li>
        </Route>

        <Route path="/schedule/timelogs">
          <li>Timelogs</li>
        </Route>

        {/* <Route path="/schedule"> */}
        {/*  <li>Current Schedule</li> */}
        {/* </Route> */}
      </Switch>
    </ol>
  </nav>
)

RouteBreadcrumbs.propTypes = {}
RouteBreadcrumbs.defaultProps = {}

export default RouteBreadcrumbs
