import { useCallback, useEffect, useReducer, useRef } from 'react'
import Store from 'services/store'

import { globalVent } from '@utils/vent'
import { REALTIME_SERVICE_CONNECTION_CHANGE } from 'utils/events'

const initialState = {
  data: undefined,
  error: undefined,
  validating: true,
}

function reducer(state, payload) {
  if (payload) return { ...state, ...payload }

  return state
}

/**
 *
 * @param mapperName {String} Name of the @therms/models mapper
 * @param id {String} If no ID provided all params returned are undefined values
 * @param opts.force {Boolean} Bypass cache
 *
 * @returns {{create: Function<Promise>, createOrUpdate: Function<Promise>, data: *, destroy: Function<Promise>, error: *, update: Function<Promise>, validating: Boolean,}}
 */
export default function useCoreModelsFind(mapperName, id, { debug = false } = {}) {
  const _local = useRef({ firstRender: true })

  const [state, dispatch] = useReducer(reducer, { ...initialState })

  _local.current.id = id
  _local.current.mapperName = mapperName
  _local.current.state = state

  if (_local.current.firstRender && id) {
    if (debug) {
      console.log(
        mapperName,
        'First Render',
        state?.data?.length ? `has data: ${mapperName} ${id}` : ` NO DATA lru:${mapperName}${id}`,
      )
    }

    _local.current.state.data = id ? Store.get(mapperName, id) : undefined
    _local.current.state.validating = !_local.current.state.data
    _local.current.firstRender = false
  } else if (!id) {
    _local.current.state.validating = false
  }

  const runQuery = useCallback(async ({ bypassCachedRecord = false } = {}) => {
    if (!_local.current.id || _local.current.wait || _local.current.queryInProgress) return

    _local.current.queryInProgress = true
    _local.current.wait = true
    _local.current.waitTimeout = setTimeout(() => (_local.current.wait = false), 100)

    try {
      let record = Store.get(_local.current.mapperName, _local.current.id)
      let stopValidating = _local.current.state.validating

      if (!record || bypassCachedRecord) {
        record = await Store.find(mapperName, _local.current.id)
        stopValidating = true
      }

      if (JSON.stringify(record) !== JSON.stringify(_local.current.state.data) || stopValidating)
        dispatch({ data: record, validating: false })
    } catch (e) {
      console.log(`useDataStoreFind#${mapperName} e`, e?.message)

      dispatch({ error: true, validating: false })
    } finally {
      _local.current.queryInProgress = false
    }
  }, [])

  useEffect(() => {
    function storeEventHandler(_mapperName, record) {
      if (debug) {
        if (_mapperName === _local.current.mapperName) {
          console.log(
            'storeEventHandler() cb, will runQuery(): ',
            !_local.current.queryInProgress &&
              _mapperName === _local.current.mapperName &&
              _local.current?.id === (record[0]?.id || record?.id),
          )
        }
      }

      // don't trigger query when query is inprogress
      if (_local.current.queryInProgress) return

      if (
        _mapperName === _local.current.mapperName &&
        _local.current?.id === (record[0]?.id || record?.id)
      ) {
        if (!_local.current.queryInProgress) runQuery()
      }
    }

    Store.on(`add`, storeEventHandler)
    Store.on(`remove`, storeEventHandler)

    const { remove } = globalVent.subscribe(REALTIME_SERVICE_CONNECTION_CHANGE, (connected) => {
      if (connected) {
        if (!_local.current.queryInProgress) runQuery({ bypassCachedRecord: true })
      }
    })

    return () => {
      Store.off(`add`, storeEventHandler)
      Store.off(`remove`, storeEventHandler)
      remove()
      clearTimeout(_local.current.waitTimeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (id) runQuery()
    else dispatch({ data: undefined, error: undefined, validating: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, mapperName])

  const create = useCallback((values) => Store[mapperName].create(values), [id, mapperName])
  const createOrUpdate = useCallback(
    (values) => (id ? Store[mapperName].update(id, values) : Store[mapperName].create(values)),
    [id, mapperName],
  )
  const destroy = useCallback(() => id && Store[mapperName].destroy(id), [id, mapperName])
  const update = useCallback((values) => id && Store[mapperName].update(id, values), [
    id,
    mapperName,
  ])

  state.create = create
  state.createOrUpdate = createOrUpdate
  state.destroy = destroy
  state.update = update
  state.revalidate = runQuery

  return state
}
