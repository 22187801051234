import React from 'react'
import PropTypes from 'prop-types'

import logo from '@images/logo_icon_red_153x154.png'

import ErrorBoundary from './index'

const FallbackComponent = () => (
  <div className="flex-column-center h-100 w-100">
    <div className="my-3">
      <img alt=" " src={logo} width={100} />
    </div>

    <h4 className="text-danger">There was an Error</h4>
  </div>
)

const ErrorBoundaryWithLogo = ({ children }) => (
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    {children}
  </ErrorBoundary>
)

ErrorBoundaryWithLogo.propTypes = {
  children: PropTypes.any.isRequired,
}

ErrorBoundaryWithLogo.defaultProps = {}

export default ErrorBoundaryWithLogo
