import memo from 'fast-memoize'

/**
 * Returns the contrasting dark or light color based on the passed hex color
 *
 * http://24ways.org/2010/calculating-color-contrast/
 *
 * @param hex {string}
 * @param [darkColor] {string}
 * @param [lightColor]
 * @returns {string}
 */
export function getColorContrast(hex = '', { darkColor = '#000', lightColor = '#fff' } = {}) {
  if (!hex) return darkColor

  const r = parseInt(hex.substr(1, 2), 16)
  const g = parseInt(hex.substr(3, 2), 16)
  const b = parseInt(hex.substr(5, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return yiq >= 128 ? darkColor : lightColor
}

export const getColorContrastMemoized = memo(getColorContrast)
