/*
  This service is used to set/elect a primary browser tab/window when multiple are open on the same
  device.


  Use-case: Some of our users will have multiple tabs open at once. We don't want a notification
  to play a sound across all tabs.

 */
import b2bComms from 'utils/browser-to-browser-communications'
import { globalVent } from '@utils/vent'
import { BROWSER_FOCUS_CHANGE } from '../utils/events'
import { Howl, Howler } from 'howler'

import { isMobile } from '@utils/viewport'

let isPrimary
const browserTempId = Math.random()
let isPlayingSound = false
let currentSoundId

const NEW_PRIMARY_BROWSER_ELECTED = 'NEW_PRIMARY_BROWSER_ELECTED'
const PRIMARY_BROWSER_CLOSING = 'PRIMARY_BROWSER_CLOSING'

function setThisBrowserToPrimary(primary = false) {
  isPrimary = primary
}

b2bComms.onMsg(NEW_PRIMARY_BROWSER_ELECTED, (_browserTempId) => {
  if (browserTempId !== _browserTempId) {
    setThisBrowserToPrimary(false)
  }
})
b2bComms.onMsg(PRIMARY_BROWSER_CLOSING, () => {
  b2bComms.publishMsg(NEW_PRIMARY_BROWSER_ELECTED, browserTempId)
  setThisBrowserToPrimary(true)
})

globalVent.subscribe(BROWSER_FOCUS_CHANGE, ({ focused }) => {
  if (focused) {
    b2bComms.publishMsg(NEW_PRIMARY_BROWSER_ELECTED, browserTempId)
    setThisBrowserToPrimary(true)
  }
})

// set this browser as primary on launch because the user could open the tab then immediately blur
b2bComms.publishMsg(NEW_PRIMARY_BROWSER_ELECTED, browserTempId)
setThisBrowserToPrimary(true)

window.addEventListener('beforeunload', () => {
  b2bComms.publishMsg(PRIMARY_BROWSER_CLOSING)
})

/*  ******************************
    ******** Exported API ********
    ******************************
*/

export const checkIsPrimary = () => isPrimary

export function playSound(soundUri) {
  // we don't play sounds on mobile
  if (isMobile()) return false

  if (!soundUri) return console.warn('No sound uri passed to playSound()')

  if (isPlayingSound && currentSoundId) Howler.stop(currentSoundId)

  if (isPrimary) {
    const sound = new Howl({
      onend() {
        isPlayingSound = false
      },
      onplayerror() {
        isPlayingSound = false

        sound.once('unlock', () => {
          isPlayingSound = true
          sound.play()
        })
      },
      src: [soundUri],
    })

    isPlayingSound = true
    currentSoundId = sound.play()
  }

  return true
}

