import coreApi from '../../../services/coreApi'

export const downloadScheduleShifts = async ({ fromDate, toDate, userId } = {}) => {
  const response = await coreApi
    .post('/scheduleShift/csv-download', { fromDate, toDate, userId })
    .catch((e) => console.error(e))

  if (!response.data.csvString) {
    return alert('There are no shifts to download')
  }

  const blobUrl = window.URL.createObjectURL(
    new Blob([response.data.csvString], { type: 'text/csv;charset=utf-8;' }),
  )
  const a = document.createElement('a')

  a.href = blobUrl
  a.download = `Schedule ${response.data.filename}`
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)

  return null
}

export const downloadScheduleTimelogsWithShift = async ({ fromDate, locationIds, toDate, userIds } = {}) => {
  const response = await coreApi
    .post('/scheduleTimelog/csv-download', { fromDate, locationIds, toDate, userIds })
    .catch((e) => console.error(e))

  if (!response.data.csvString) {
    return alert('There are no shifts to download')
  }

  const blobUrl = window.URL.createObjectURL(
    new Blob([response.data.csvString], { type: 'text/csv;charset=utf-8;' }),
  )
  const a = document.createElement('a')

  a.href = blobUrl
  a.download = `Timelog ${response.data.filename}`
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)

  return null
}

export const formatTimelogAction = (action) => {
  switch (action) {
    case 'breakend':
      return 'Break End'
    case 'breakstart':
      return 'Break Start'
    case 'clockin':
      return 'Clock-In'
    case 'clockout':
      return 'Clock-Out'
    default:
      return action
  }
}
