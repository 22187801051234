import React from 'react'
// import PropTypes from 'prop-types'

import Admin from 'modules/Admin/shared/modules/Layout/MainNavButton'
import Auth from 'modules/Auth/shared/modules/Layout/MainNavButton'
import Bulletin from 'modules/Bulletin/shared/modules/Layout/MainNavButton'
import Dispatch from 'modules/Dispatch/shared/modules/Layout/MainNavButton'
import Dashboard from 'modules/Dashboard/shared/modules/Layout/MainNavButton'
import Mail from 'modules/Mail/shared/modules/Layout/MainNavButton'
import Locations from 'modules/Location/shared/modules/Layout/MainNavButton'
import Patrol from 'modules/Patrol/shared/modules/Layout/MainNavButton'
import Profile from 'modules/Profile/shared/modules/Layout/MainNavButton'
import Records from 'modules/Records/shared/modules/Layout/MainNavButton'
import Reports from 'modules/Reports/shared/modules/Layout/MainNavButton'
import Roster from 'modules/Roster/shared/modules/Layout/MainNavButton'
import Schedule from 'modules/Schedule/shared/modules/Layout/MainNavButton'
import Support from 'modules/Support/shared/modules/Layout/MainNavButton'

import './index.scss'

const LeftMenu = () => (
  <div className="left-menu">
    <ul>
      <Profile />

      <Dashboard />

      <Mail />

      <Bulletin />

      <Dispatch />

      <Reports />

      <Locations />

      <Patrol />

      <Records />

      <Roster />

      <Schedule />


      <Admin />
    </ul>

    <ul className="secondary-nav">
      <Support />

      <Auth />
    </ul>
  </div>
)

LeftMenu.propTypes = {}
LeftMenu.defaultProps = {}

export default LeftMenu
