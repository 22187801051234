import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import useScheduleShifts from '../../../../hooks/useScheduleShifts'
import Loading from '@therms/web-common/components/Loading'
import LayoverRelative from '@therms/web-common/components/Layover/Relative'
import WeekDate from '../../../../shared/WeekDate'
import ShiftListItem from '../../../../shared/ShiftListItem'
import { roundToNearestMin } from '../../../../../../utils/date'

const PublishedWeekSchedule = ({ initialDate }) => {
  const [date, setDate] = useState(initialDate || roundToNearestMin())

  const { endOfWeek, startOfWeek } = useMemo(() => {
    const d = dayjs(date)

    return {
      endOfWeek: d.endOf('week').toISOString(),
      startOfWeek: d.startOf('week').toISOString(),
    }
  }, [date])

  const { data, validating } = useScheduleShifts({
    orderBy: ['dateStart'],
    where: {
      dateEnd: { '>=': startOfWeek },
      dateStart: { '<=': endOfWeek },
      published: true,
    },
  })

  const scheduleShifts = useMemo(
    () =>
      data.map((scheduleShift) => ({
        ...scheduleShift,
        weekdayLabel: dayjs(scheduleShift.dateStart).format('dddd'),
      })),
    [data],
  )

  return (
    <div>
      {validating && (
        <LayoverRelative>
          <Loading />
        </LayoverRelative>
      )}

      <div>
        <WeekDate canDownload initialDate={initialDate} onDateChange={setDate} />
      </div>

      <div className="">
        {scheduleShifts.map((scheduleShift, i) => (
          <div key={scheduleShift.id}>
            {scheduleShifts[i - 1]?.weekdayLabel !== scheduleShift.weekdayLabel && (
              <div className="font-weight-bold text-primary">{scheduleShift.weekdayLabel}</div>
            )}

            <div className="mb-1 ml-1" key={scheduleShift.id}>
              <div className="cursor onhover-highlight">
                <ShiftListItem compactView scheduleShiftId={scheduleShift.id} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

PublishedWeekSchedule.propTypes = {
  initialDate: PropTypes.string,
}
PublishedWeekSchedule.defaultProps = {
  initialDate: undefined,
}

export default PublishedWeekSchedule
