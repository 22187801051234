import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form as RFForm } from 'react-final-form'
import { formValidate } from '@therms/util-services'
import { FORM_ERROR } from 'final-form'

import { systemToast } from 'utils/toaster'
import Store from 'services/store'

import Text from '@therms/web-common/components/Inputs/Text'
import Loading from '@therms/web-common/components/Loading'
import DeleteCancelSave from '@therms/web-common/components/Buttons/DeleteCancelSave'
import useSchedulePosition from '../../hooks/useSchedulePosition'

const PositionForm = ({ id, onCancel, onComplete }) => {
  const { data: position, destroy, validating: loading } = useSchedulePosition(id)

  const [deletingRecord, setDeletingRecord] = useState(Boolean(id))

  async function handleDelete() {
    setDeletingRecord(true)

    try {
      await destroy()

      systemToast('Position Deleted', { quick: true })
      onComplete()
    } catch (err) {
      console.error('err', err)

      systemToast('There was a problem deleting', { error: true })
    } finally {
      setDeletingRecord(false)
    }
  }

  async function handleOnSubmit(values) {
    let opDisplayName = 'Created'

    try {
      let storeRecord

      if (position?.id) {
        storeRecord = await Store.SchedulePosition.update(position.id, values)
        opDisplayName = 'Updated'
      } else {
        storeRecord = await Store.SchedulePosition.create(values)
        opDisplayName = 'Created'
      }

      systemToast(`Position ${opDisplayName}`, { quick: true })

      onComplete(storeRecord)

      return undefined
    } catch (err) {
      console.error('err', err)

      return {
        [FORM_ERROR]: 'There was a problem saving, please try again',
      }
    }
  }

  if (deletingRecord || loading) return <Loading />

  return (
    <RFForm
      initialValues={{ ...position }}
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={Text}
            label="Position Name"
            name="name"
            placeholder="Patrol Officer, Static Guard, Watch Commander, etc."
            validate={formValidate.required}
          />

          {submitError && <div className="alert alert-danger">{submitError}</div>}

          <div className="d-flex justify-content-end w-100">
            <DeleteCancelSave
              disabled={submitting}
              isSaving={submitting}
              onCancel={onCancel}
              onDelete={handleDelete}
              onSave={handleSubmit}
              showDelete={id}
            />
          </div>
        </form>
      )}
    />
  )
}

PositionForm.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
}
PositionForm.defaultProps = {
  id: undefined,
  onComplete: undefined,
}

export default PositionForm
