import React, { useEffect, useState } from 'react'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import PropTypes from 'prop-types'

const FileControls = ({ disabled, onDelete, uploading }) => {
  const [confirming, setConfirming] = useState(false)

  useEffect(() => {
    if (confirming) {
      const t = setTimeout(() => {
        setConfirming(false)
      }, 3000)

      return () => clearTimeout(t)
    }

    return () => null
  }, [confirming])

  if (disabled) return null

  const iconName = uploading ? 'ban' : 'trash'

  return (
    <div className="d-flex justify-content-end p-1">
      {!confirming && (
        <button className="btn btn-danger btn-sm" onClick={setConfirming} type="button">
          <FAIcon name={iconName} />
        </button>
      )}

      {confirming && (
        <button className="btn btn-danger btn-sm" onClick={onDelete} type="button">
          <FAIcon name={iconName} /> Confirm
        </button>
      )}
    </div>
  )
}

FileControls.propTypes = {
  disabled: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  uploading: PropTypes.bool,
}
FileControls.defaultProps = {
  disabled: false,
  uploading: false,
}

export default FileControls
