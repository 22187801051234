import useCoreModelsFindAll from '../../../hooks/useCoreModelsFindAll'

export default function usePatrolRoutes(routeIds) {
  const where = {}

  if (Array.isArray(routeIds)) {
    where.id = { isectNotEmpty: routeIds }
  } else if (typeof routeIds === 'string') {
    where.id = routeIds
  }

  return useCoreModelsFindAll('patrolRoute', { orderBy: ['name'], where })
}
