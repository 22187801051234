import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import useScheduleShift from '../../../hooks/useScheduleShift'
import useOrganization from '../../../../Auth/shared/hooks/useOrganization'
import PositionName from '../../Position/PositionName'
import UserName from '../../../../User/shared/UserName'
import dayjs from 'dayjs'
import { useUserScheduleContext } from './UserScheduleContext'

const ShiftBadge = React.forwardRef(({ scheduleShiftId }, ref) => {
  const { highlightedUserId, setHighlightedUserId, setIsMouseOverShift } = useUserScheduleContext()
  const { organizationMetadata } = useOrganization()
  const { data } = useScheduleShift(scheduleShiftId)

  const scheduleShift = useMemo(() => ({ ...(data || {}), ...(data?.draft || {}) }), [data])

  const { handleOnMouseOver, handleOnMouseOut } = useMemo(
    () => ({
      handleOnMouseOver: () => {
        setHighlightedUserId(scheduleShift.userId)
        setIsMouseOverShift(true)
      },
      handleOnMouseOut: () => {
        setHighlightedUserId(undefined)
        setIsMouseOverShift(false)
      },
    }),
    [highlightedUserId, scheduleShift, setHighlightedUserId, setIsMouseOverShift],
  )

  const { className, thisShiftIsHighlighted } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    let className = ''

    if (highlightedUserId && highlightedUserId !== scheduleShift.userId) {
      className = 'bg-secondary-opacity-25'
    }

    return {
      className,
      thisShiftIsHighlighted: highlightedUserId && highlightedUserId === scheduleShift.userId,
    }
  }, [highlightedUserId, scheduleShift])

  const [startTime, endTime, duration] = useMemo(() => {
    if (!scheduleShift) return []

    const end = dayjs(scheduleShift.dateEnd).format(organizationMetadata?.settings?.timeFormat)
    const start = dayjs(scheduleShift.dateStart).format(organizationMetadata?.settings?.timeFormat)
    // ie: 8.5 or 4.2
    const d =
      Math.round(
        dayjs(scheduleShift.dateEnd).diff(dayjs(scheduleShift.dateStart), 'hours', true) * 10,
      ) / 10

    return [start, end, `${d}hr`]
  }, [scheduleShift?.dateEnd, organizationMetadata?.settings?.timeFormat])

  const unpublished = scheduleShift?.draft || !scheduleShift?.published

  if (!scheduleShift) return null

  return (
    <span
      className={`badge badge-${
        unpublished ? 'info' : 'success'
      } d-inline-block max-width-150 text-left text-truncate ${className}`}
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
      ref={ref}
    >
      {scheduleShift.name && (
        <div className="font-weight-lighter text-truncate">{scheduleShift.name}</div>
      )}

      <div className="text-center">
        {startTime} - {endTime} ({duration})
      </div>

      {scheduleShift?.schedulePositionId && (
        <div className="font-weight-light">
          <PositionName id={scheduleShift?.schedulePositionId} />
        </div>
      )}

      {scheduleShift?.userId && (
        <div
          className={`font-weight-light ${
            thisShiftIsHighlighted ? 'bg-dark border-radius text-light' : ''
          }`}
        >
          <UserName id={scheduleShift.userId} noLink noDutyStatus noOnlineStatus noCard />
        </div>
      )}
    </span>
  )
})

ShiftBadge.propTypes = {
  scheduleShiftId: PropTypes.string.isRequired,
}
ShiftBadge.defaultProps = {}

export default ShiftBadge
