/* eslint-disable no-use-before-define */
// import { createSelector } from 'reselect'
import { createAction, handleActions } from 'redux-actions'
import { map, sortBy } from 'lodash-es'
import { createSelector } from 'reselect'

import Store from 'services/store'
import {
  passDownsByLocationIdSelector,
  selectLocationPassDowns,
} from 'state/ducks/locationPassDown'
import { selectCurrentRegion } from '../../Auth/ducks/region'

const PAGE_LIMIT = 20

const initialState = {
  passDownLoading: false,
  passDownSearchOptions: undefined,
  passDownPage: 1,
  passDownResultsPageCount: 1,
  passDownResultsById: [],
  passDownResultsCount: 0,
}

const moduleName = 'LocationPassDown'

const moduleConstant = name => `${moduleName}/${name}`

// selectors
export const currentRegionActivePassDownSelector = createSelector(
  selectLocationPassDowns,
  selectCurrentRegion,
  (passDowns, region) => sortBy(passDowns, 'updatedAt')
      .filter(({ regionId }) => regionId === region.id)
      .filter(({ active }) => active)
      .reverse(),
)

export const locationActivePassDownByLocationIdSelector = createSelector(
  passDownsByLocationIdSelector,
  passDowns => sortBy(passDowns, 'updatedAt')
      .filter(({ active }) => active)
      .reverse(),
)

// types

// actions
export const clearPassDown = createAction('clearPassDown')
export const setPassDownLoading = createAction('setPassDownLoading')
export const setPassDownPage = createAction('passDownPage')
export const setPassDownResultsPageCount = createAction('passDownResultsPageCount')
export const setPassDownSearchOptions = createAction('setPassDownSearchOptions')
export const setPassDownResultsById = createAction(moduleConstant('setPassDownResultsById'))

export const fetchActivePassDowns = () => (dispatch, getState) => {
  const state = getState()
  const regionId = selectCurrentRegion(state)

  return Store.LocationPassDown.findAll({
    orderBy: [['updatedAt', 'desc']],
    where: {
      active: true,
      regionId,
    },
  })
}

export const fetchPassDowns = ({ active = undefined, locationId, page = 1 } = {}) => (
  dispatch,
  getState,
) => {
  dispatch(setPassDownLoading(true))

  const where = {
    active,
    locationId,
  }

  return Store.LocationPassDown.findAll(
    {
      limit: PAGE_LIMIT,
      offset: page > 1 ? (page - 1) * PAGE_LIMIT : 0,
      orderBy: [['createdAt', 'desc']],
      where,
    },
    { headers: { count: true }, raw: true },
  )
    .then(response => {
      let count
      let passDown

      // when it's a cached response, it's an array of records
      if (Array.isArray(response)) {
        count = getState().Location.passDown.passDownResultsCount
        passDown = response
      } else {
        count = response.headers.count
        passDown = response.data
      }

      dispatch(setPassDownResultsById({ count, results: map(passDown, 'id') }))
      dispatch(setPassDownResultsPageCount(Math.ceil(count / PAGE_LIMIT)))
      dispatch(setPassDownLoading(false))

      return passDown
    })
    .catch(err => {
      console.error(`LocationPassDown.findAll error`, err)
      // todo show system toast err
      dispatch(setPassDownLoading(false))
    })
}

// reducer
const reducer = handleActions(
  {
    [clearPassDown]: () => ({
      ...initialState,
    }),
    [setPassDownLoading]: (state, action = {}) => ({
      ...state,
      passDownLoading: action.payload,
    }),

    [setPassDownPage]: (state, action = {}) => ({
      ...state,
      passDownPage: action.payload,
    }),

    [setPassDownResultsById]: (state, action = {}) => ({
      ...state,
      passDownResultsById: action.payload.results,
      passDownResultsCount: action.payload.count,
    }),

    [setPassDownResultsPageCount]: (state, action = {}) => ({
      ...state,
      passDownResultsPageCount: action.payload,
    }),

    [setPassDownSearchOptions]: (state, action = {}) => ({
      ...state,
      passDownSearchOptions: action.payload,
    }),
  },
  initialState,
)

export default reducer
