import React, { useCallback, useMemo, useState } from 'react'
// import PropTypes from 'prop-types'
import UserName from '../../User/shared/UserName'
import useScheduleTimelogsWithShift from '../hooks/useScheduleTimelogsWithShift'
import dayjs from 'dayjs'
import { humanizeMinutes } from '../../../utils/date'
import Conditional from '../../Auth/shared/Conditional'
import PositionName from '../components/Position/PositionName'
import ShiftAssignment from '../components/Shift/ShiftAssignment'
import DateTimeInput from '@components/Inputs/DateTime'
import SelectLocations from '../../Location/shared/Inputs/SelectLocations'
import SelectUsers from '../../User/shared/Inputs/SelectUsers'
import LayoverRelative from '@therms/web-common/components/Layover/Relative'
import Loading from '@components/Loading'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import { systemToast } from '../../../utils/toaster'
import { downloadScheduleTimelogsWithShift } from '../util'
import { ShiftTimelogFormModal } from '../components/Timelog/ShiftTimelogFormModal'
import { AddTimelogFormModal } from '../components/Timelog/AddTimelogForm/AddTimelogFormModal'

const ScheduleTimeLogWithShiftTable = () => {
  const [fromDate, setFromDate] = useState(() => dayjs().subtract(72, 'hours').toISOString())
  const [locationIds, setLocationIds] = useState([])
  const [toDate, setToDate] = useState(new Date().toISOString())
  const [userIds, setUserIds] = useState([])
  const [showTimelogFormModal, setShowTimelogFormModal] = useState()
  const [showAddTimelogModal, setShowAddTimelogModal] = useState(false)

  const {
    data: scheduleTimelogs,
    revalidate,
    validating,
  } = useScheduleTimelogsWithShift({
    fromDate,
    locationIds,
    toDate,
    userIds,
  })

  const handleDownload = useCallback(() => {
    systemToast('Downloading...')

    downloadScheduleTimelogsWithShift({
      fromDate,
      locationIds,
      toDate,
      userIds,
    })
  }, [fromDate, locationIds, toDate, userIds])

  const { totalTime } = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const totalTime = scheduleTimelogs.reduce((acc, _timelog) => {
      // eslint-disable-next-line no-param-reassign
      acc += dayjs
        .duration(dayjs(_timelog.clockOutTimestamp).diff(_timelog.clockInTimestamp))
        .asHours()

      return acc
    }, 0)

    return {
      totalTime: totalTime.toFixed(2),
    }
  }, [scheduleTimelogs])

  return (
    <div>
      <div className="d-flex">
        <DateTimeInput label="From" onChange={setFromDate} value={fromDate} />

        <div className="mr-1" />

        <DateTimeInput label="To" onChange={setToDate} value={toDate} />

        <div className="mr-1" />

        <SelectLocations label="Locations" multi onChange={setLocationIds} value={locationIds} />

        <div className="mr-1" />

        <SelectUsers
          label="Users"
          multi
          onChange={({ currentTarget: { value } }) => setUserIds(value)}
          value={userIds}
        />
      </div>

      <div className="d-flex mb-1">
        <div>
          <span className="text-muted">Total Clocked</span> <strong>{totalTime} hrs</strong>
        </div>

        {!!scheduleTimelogs.length && (
          <div className="ml-1">
            <button className="btn btn-short" onClick={handleDownload}>
              <FAIcon name="download" /> <Conditional isDesktop>Download</Conditional>
            </button>
          </div>
        )}

        <div className="ml-1">
          <button className="btn btn-short" onClick={() => setShowAddTimelogModal(true)}>
            <FAIcon name="plus" /> Add Timelog
          </button>
        </div>
      </div>

      <div className="position-relative table-response">
        {validating && (
          <LayoverRelative>
            <Loading />
          </LayoverRelative>
        )}
        <table className="table table-hover table-sm table-striped w-100">
          <thead>
            <tr>
              <th>Name</th>

              <th>Shift</th>

              <th>Clocked Time</th>

              <th>Break Time</th>

              <th>Assignment</th>

              <th>Position</th>
            </tr>
          </thead>

          <tbody>
            {scheduleTimelogs.map((timelog, i) => {
              const showUserName = scheduleTimelogs[i - 1]?.userId !== timelog.userId

              const showShiftInfo =
                !!timelog.scheduleShiftId &&
                scheduleTimelogs[i - 1]?.scheduleShiftId !== timelog.scheduleShiftId

              let shiftTotalTime = 0

              if (showShiftInfo) {
                const totalMin = scheduleTimelogs
                  .filter((_timelog) => _timelog.scheduleShiftId === timelog.scheduleShiftId)
                  .reduce((acc, _timelog) => {
                    // eslint-disable-next-line no-param-reassign
                    acc += dayjs
                      .duration(dayjs(_timelog.clockOutTimestamp).diff(_timelog.clockInTimestamp))
                      .asMinutes()

                    return acc
                  }, 0)

                shiftTotalTime = humanizeMinutes(totalMin)
              }

              return (
                <>
                  <tr key={timelog.id} onClick={() => setShowTimelogFormModal(timelog.id)}>
                    <td>{showUserName && <UserName id={timelog.userId} />}</td>

                    <td>
                      <Conditional isTrue={showShiftInfo}>
                        <span className="mr-1">{timelog.scheduleShift?.name}</span>
                        <div className="text-muted">
                          {dayjs(timelog.scheduleShift?.dateStart).format(`ddd, MMM Do YYYY`)}
                        </div>{' '}
                        {dayjs(timelog.scheduleShift?.dateStart).format(window.timeFormat)}-
                        {dayjs(timelog.scheduleShift?.dateEnd).format(window.timeFormat)}
                      </Conditional>
                    </td>

                    <td>
                      <Conditional isTrue={showShiftInfo}>
                        <div>Total: {shiftTotalTime}</div>
                      </Conditional>

                      <span className="text-muted">
                        {dayjs(timelog.clockInTimestamp).format(
                          `ddd, MMM Do YYYY ${window.timeFormat}`,
                        )}
                        -{dayjs(timelog.clockOutTimestamp).format(window.timeFormat)}
                      </span>

                      <div className="font-weight-bold ml-1">
                        {humanizeMinutes(
                          dayjs
                            .duration(
                              dayjs(timelog.clockOutTimestamp).diff(timelog.clockInTimestamp),
                            )
                            .asMinutes(),
                        )}
                      </div>
                    </td>

                    <td>
                      {!!timelog.breaklog?.length && (
                        <div>
                          {timelog.breaklog.map(({ action, timestamp }) => (
                            <div>
                              <span className="text-muted">{action.toUpperCase()}</span>{' '}
                              {dayjs(timestamp).format(window.timeFormat)}
                            </div>
                          ))}
                        </div>
                      )}
                    </td>

                    <td>
                      <Conditional isTrue={showShiftInfo}>
                        <ShiftAssignment scheduleShiftOrId={timelog.scheduleShift} />
                      </Conditional>
                    </td>

                    <td>
                      <Conditional isTrue={showShiftInfo}>
                        <PositionName id={timelog.scheduleShift?.schedulePositionId} />
                      </Conditional>
                    </td>
                  </tr>
                  {timelog.comment && (
                    <div className="mb-2 with-whitespace">
                      <FAIcon name="sticky-note" /> <em className="text-info">{timelog.comment}</em>
                    </div>
                  )}
                </>
              )
            })}
          </tbody>
        </table>

        {showTimelogFormModal && (
          <ShiftTimelogFormModal
            closeHandler={() => {
              setShowTimelogFormModal(false)
              revalidate()
            }}
            id={showTimelogFormModal}
          />
        )}

        {showAddTimelogModal && (
          <AddTimelogFormModal
            closeHandler={() => {
              setShowAddTimelogModal()
              revalidate()
            }}
          />
        )}
      </div>
    </div>
  )
}

ScheduleTimeLogWithShiftTable.propTypes = {}
ScheduleTimeLogWithShiftTable.defaultProps = {}

export default ScheduleTimeLogWithShiftTable
