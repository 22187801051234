import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

import FileCard from '../FileCard'
import filesApi from '../../../../../../services/filesApi'
import { systemToast } from '../../../../../../utils/toaster'

const CancelToken = axios.CancelToken

const FileCardUpload = ({ file, onDelete, onUploadComplete }) => {
  const local = useRef({ onUploadComplete, uploading: false })
  const [uploadError, setUploadError] = useState()
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [cancelSource] = useState(CancelToken.source())

  const handleDelete = useCallback(() => {
    cancelSource.cancel()
    onDelete()
  }, [onDelete])

  local.current.onUploadComplete = onUploadComplete

  useEffect(() => {
    if (!local.current.uploading) {
      // get signed url
      filesApi
        .post('/upload/get-signed-upload-url', { contentType: file.type, fileName: file.name })
        .then(async ({ data: { fileUrl, uploadUrl } }) => {
          local.current.uploading = true

          if (!file.type) {
            systemToast('Unable to upload corrupt file', { error: true })
            setUploadError('File does not contain "type" metadata')
            return
          }

          await axios
            .put(uploadUrl, file, {
              cancelToken: cancelSource.token,
              headers: {
                'content-type': file.type,
              },
              onUploadProgress({ loaded }) {
                setUploadPercentage(Math.floor((loaded / file.size) * 100))
              },
            })
            .catch((err) => {
              if (axios.isCancel(err)) console.info('upload cancelled')
              else {
                console.error(`There was a problem uploading to signed URL ${uploadUrl}`, err)
                Sentry.captureMessage(`Upload error - PUT [aws signed url] error: ${err.message}`)
              }
            })
            .finally(() => {
              local.current.onUploadComplete(file, fileUrl)
            })
        })
        .catch((e) => {
          console.error('filesApi POST /upload/get-signed-upload-url error', e)
          setUploadError('There was a problem getting a signed upload url')
          Sentry.captureMessage(`filesApi POST /upload/get-signed-upload-url error: ${e.message}`)
        })

      return () => {
        if (local.current.uploading) {
          cancelSource.cancel()
        }
      }
    }

    return undefined
  }, [])

  return (
    <FileCard
      blob={file}
      contentType={file.type}
      onDelete={handleDelete}
      size={file.size}
      statusText={uploadError ? 'Error Uploading' : ''}
      uploading
      uploadPercentage={uploadPercentage}
    />
  )
}

FileCardUpload.propTypes = {
  file: PropTypes.objectOf(File).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUploadComplete: PropTypes.func.isRequired,
}
FileCardUpload.defaultProps = {}

export default FileCardUpload
