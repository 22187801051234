import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { humanizeMinutes } from '../utils/date'

const DateTimeDiff = ({ format, render, time1, time2 }) => {
  const diff = useMemo(() => {
    const d = dayjs.duration(dayjs(time2).diff(dayjs(time1)))

    switch (format) {
      case 'human':
        return humanizeMinutes(d.asMinutes())
      case 'minutes':
        return d.asMinutes()
      case 'hours':
        return d.asHours()
      case 'days':
        return d.asDays()
      case 'months':
        return d.asMonths()
      default:
        return d.humanize()
    }
  }, [format, time1, time2])

  if (typeof render === 'function') {
    return render(diff)
  }

  return <>{diff}</>
}

DateTimeDiff.propTypes = {
  format: PropTypes.string,
  render: PropTypes.func,
  time1: PropTypes.string.isRequired,
  time2: PropTypes.string.isRequired,
}

export { DateTimeDiff }
