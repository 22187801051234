import React from 'react'
import PropTypes from 'prop-types'
import LocationName from '../../../Location/shared/LocationName'
import PatrolRouteName from '../../../Patrol/shared/PatrolRouteName'
import useScheduleShift from '../../hooks/useScheduleShift'

const ShiftAssignment = ({ scheduleShiftOrId, showNameOnly }) => {
  const { data: scheduleShift } = useScheduleShift(scheduleShiftOrId?.id ?? scheduleShiftOrId)

  if (!scheduleShift) return null

  if (scheduleShift.assignmentModuleName === 'location') {
    if (showNameOnly) return <LocationName id={scheduleShift.assignmentModuleId} />

    return (
      <div>
        <div className="display-label">Location</div>
        <LocationName id={scheduleShift.assignmentModuleId} />
      </div>
    )
  }

  if (scheduleShift.assignmentModuleName === 'patrolRoute') {
    if (showNameOnly) return <PatrolRouteName id={scheduleShift.assignmentModuleId} />

    return (
      <div>
        <div className="display-label">Patrol Route</div>
        <PatrolRouteName id={scheduleShift.assignmentModuleId} />
      </div>
    )
  }

  return null
}

ShiftAssignment.propTypes = {
  scheduleShiftOrId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  showNameOnly: PropTypes.bool,
}
ShiftAssignment.defaultProps = {
  showNameOnly: false,
}

export default ShiftAssignment
