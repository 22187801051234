/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState } from 'react'
// import PropTypes from 'prop-types'
import Store from 'services/store'
import dayjs from 'dayjs'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import TableCellDroppable from './components/TableCellDroppable'
import ShiftDraggable from './components/ShiftDraggable'
import { CustomDragLayer } from './components/CustomDragLayer'
import { useBuildScheduleData } from './hooks/useBuildScheduleData'
import PublishChangesControl from './components/PublishChangesControl'
import WeekDate from '../../shared/WeekDate'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import ShiftDetailsFormModal from './components/ShiftDetailsFormModal'
import { useScheduleAssignableModules } from './hooks/useScheduleAssignableModules'
import Loading from '@therms/web-common/components/Loading'
import LayoverRelative from '@therms/web-common/components/Layover/Relative'
import PatrolRouteName from '../../../Patrol/shared/PatrolRouteName'
import LocationName from '../../../Location/shared/LocationName'
import ServiceTypes from '../../../ServiceType/shared/ServiceTypes'
import useScheduleSettings from '../../hooks/useScheduleSettings'
import coreApi from '../../../../services/coreApi'
import { systemToast } from '../../../../utils/toaster'
import { UserScheduleContextProvider } from './components/UserScheduleContext'
import { UserScheduledHoursContextProvider } from './components/UserScheduledHoursContext'
import { ScheduleDetailsInfoRow } from './components/ScheduleDetailsInfoRow'

const BuildSchedule = () => {
  const [date, setDate] = useLocalStorage(`BuildSchedule_date`, new Date().toISOString())
  const [showShiftFormModal, setShowShiftFormModal] = useState()
  const [_loading, set_loading] = useState(false)
  const { loading, locations, patrolRoutes } = useScheduleAssignableModules()
  const { firstDayOfWeek } = useScheduleSettings() || {}

  const dayOfWeekByNumber = useMemo(() => {
    const labels = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]

    if (+firstDayOfWeek === 0) {
      return labels
    }

    labels.shift()

    return labels
  }, [firstDayOfWeek])

  const {
    dayOfWeekDateFormatted,
    // endOfWeek,
    hasUnpublishedScheduleShifts,
    hoursByUserId,
    revalidateScheduleShifts,
    scheduleShiftsUnassigned,
    scheduleShiftsByModuleNameByIdByDate,
    // startOfWeek,
    todayFormatted,
    totalHours,
    // validating,
    weekDatesList,
  } = useBuildScheduleData(date)

  const handleCopyPreviousWeek = useCallback(async () => {
    set_loading(true)

    try {
      await coreApi.post('/scheduleShift/copy-shifts-from-previous-week', { weekToCopyTo: date })

      systemToast('Copied previous week shifts', { info: true })

      revalidateScheduleShifts(true)
    } catch (e) {
      systemToast('Error copying previous week', { error: true })
    } finally {
      set_loading(false)
    }
  }, [date, revalidateScheduleShifts])

  const destroyAllUnpublished = useCallback(async () => {
    set_loading(true)

    try {
      await coreApi.post('/scheduleShift/destroy-unpublished-for-week', { week: date })

      Store.ScheduleShift.removeAll()

      systemToast('Reset week shifts', { info: true })

      revalidateScheduleShifts(true)
    } catch (e) {
      systemToast('Error resetting week', { error: true })
    } finally {
      set_loading(false)
    }
  }, [date, revalidateScheduleShifts])

  const resetChanges = useCallback(async () => {
    set_loading(true)

    try {
      await coreApi.post('/scheduleShift/reset-draft-changes', {
        weekDateToReset: date,
      })

      systemToast('Schedule Changes Reset')

      revalidateScheduleShifts(true)
    } catch (e) {
      systemToast('There was a problem resetting changes', { error: true })
    } finally {
      set_loading(false)
    }
  }, [revalidateScheduleShifts])

  const onClickDate = useCallback((e) => {
    const _date = e.currentTarget?.dataset?.date
    const assignmentModuleId = e.currentTarget?.dataset?.assignmentmoduleid
    const assignmentModuleName = e.currentTarget?.dataset?.assignmentmodulename

    setShowShiftFormModal({ assignmentModuleId, assignmentModuleName, date: _date })
  }, [])

  const onClickShift = useCallback((e) => {
    e.stopPropagation()
    setShowShiftFormModal({ shiftId: e.currentTarget?.dataset?.shiftid })
  }, [])

  return (
    <UserScheduledHoursContextProvider hoursByUserId={hoursByUserId} totalHoursScheduled={totalHours}>
      <UserScheduleContextProvider>
        {(loading || _loading) && (
          <LayoverRelative>
            <Loading />
          </LayoverRelative>
        )}

        <div>
          <div>
            <div className="d-inline mr-1">
              <WeekDate canDownload initialDate={date} onDateChange={setDate} />
            </div>

            <div className="btn-group">
              <button className="btn btn-info btn-sm" onClick={handleCopyPreviousWeek}>
                Copy Previous Week
              </button>

              <button className="btn btn-warning btn-sm" onClick={resetChanges}>
                Reset Draft Changes
              </button>

              <button className="btn btn-danger btn-sm" onClick={destroyAllUnpublished}>
                Delete Unpublished
              </button>
            </div>
          </div>

          <div className="bg-body mb-1 position-sticky" style={{ top: '1.75em', zIndex: 10 }}>
            <ScheduleDetailsInfoRow totalHoursScheduled={totalHours} />
          </div>

          <div className="table-responsive table-bordered">
            <DndProvider backend={HTML5Backend}>
              <CustomDragLayer />

              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <PublishChangesControl
                        hasUnpublishedScheduleShifts={hasUnpublishedScheduleShifts}
                        weekDateToPublish={date}
                      />
                    </th>

                    {new Array(7).fill(null).map((_, i) => (
                      <th
                        className={`text-center ${
                          dayOfWeekDateFormatted[i] === todayFormatted
                            ? 'bg-secondary-opacity-25'
                            : ''
                        }`}
                        key={`day_${i + _}`}
                      >
                        <div>
                          <span className="d-block font-small font-weight-bold text-info">
                            {dayOfWeekByNumber[i]}
                          </span>
                          <small className="font-weight-light text-muted">
                            {dayOfWeekDateFormatted[i]}
                          </small>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="max-width-300 min-width-200 text-muted">No Assignment</td>

                    {weekDatesList.map((_date, i) => (
                      <TableCellDroppable
                        className={
                          dayOfWeekDateFormatted[i] === todayFormatted
                            ? 'bg-secondary-opacity-25'
                            : ''
                        }
                        date={_date}
                        key={_date}
                        onClick={onClickDate}
                        // onDropComplete={console.log}
                        assignmentModuleId=""
                        assignmentModuleName=""
                      >
                        {scheduleShiftsUnassigned
                          .filter((ss) => dayjs(_date).isSame(ss.dateStart, 'day'))
                          .map((scheduleShift) => (
                            <div
                              data-shiftid={scheduleShift.id}
                              key={scheduleShift.id}
                              onClick={onClickShift}
                            >
                              <ShiftDraggable scheduleShift={scheduleShift} />
                            </div>
                          ))}
                      </TableCellDroppable>
                    ))}
                  </tr>

                  {patrolRoutes.map((patrolRoute) => (
                    <tr key={patrolRoute.id}>
                      <td className="max-width-300 min-width-200">
                        <div className="font-italic font-small text-muted">Patrol Route</div>
                        <PatrolRouteName id={patrolRoute.id} />
                      </td>

                      {weekDatesList.map((_date, i) => (
                        <TableCellDroppable
                          className={
                            dayOfWeekDateFormatted[i] === todayFormatted
                              ? 'bg-secondary-opacity-25'
                              : ''
                          }
                          date={_date}
                          key={_date}
                          onClick={onClickDate}
                          // onDropComplete={console.log}
                          assignmentModuleId={patrolRoute.id}
                          assignmentModuleName="patrolRoute"
                        >
                          {(
                            scheduleShiftsByModuleNameByIdByDate?.patrolRoute?.[patrolRoute.id]?.[
                              _date
                            ] || []
                          ).map((scheduleShift) => (
                            <div
                              data-shiftid={scheduleShift.id}
                              key={scheduleShift.id}
                              onClick={onClickShift}
                            >
                              <ShiftDraggable scheduleShift={scheduleShift} />
                            </div>
                          ))}
                        </TableCellDroppable>
                      ))}
                    </tr>
                  ))}

                  {locations.map((location) => (
                    <tr key={location.id}>
                      <td className="max-width-300 min-width-200">
                        <div className="font-weight-light text-light">
                          <LocationName id={location.id} />
                        </div>

                        <div className="font-small">
                          <ServiceTypes badge serviceTypeIds={location.serviceTypeIds} />
                        </div>
                      </td>

                      {weekDatesList.map((_date, i) => (
                        <TableCellDroppable
                          className={
                            dayOfWeekDateFormatted[i] === todayFormatted
                              ? 'bg-secondary-opacity-25'
                              : ''
                          }
                          date={_date}
                          key={_date}
                          onClick={onClickDate}
                          // onDropComplete={console.log}
                          assignmentModuleId={location.id}
                          assignmentModuleName="location"
                        >
                          {(
                            scheduleShiftsByModuleNameByIdByDate?.location?.[location.id]?.[
                              _date
                            ] || []
                          ).map((scheduleShift) => (
                            <div
                              data-shiftid={scheduleShift.id}
                              key={scheduleShift.id}
                              onClick={onClickShift}
                            >
                              <ShiftDraggable scheduleShift={scheduleShift} />
                            </div>
                          ))}
                        </TableCellDroppable>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </DndProvider>
          </div>
        </div>

        {showShiftFormModal && (
          <ShiftDetailsFormModal
            closeHandler={() => setShowShiftFormModal()}
            date={showShiftFormModal?.date}
            id={showShiftFormModal?.shiftId}
            assignmentModuleId={showShiftFormModal?.assignmentModuleId}
            assignmentModuleName={showShiftFormModal?.assignmentModuleName}
          />
        )}
      </UserScheduleContextProvider>
    </UserScheduledHoursContextProvider>
  )
}

BuildSchedule.propTypes = {}
BuildSchedule.defaultProps = {}

export default BuildSchedule
