import React from 'react'

import MainNavButton from 'modules/Layout/shared/MainNavButton/index'

const DashboardMainNavButton = () => (
  <MainNavButton iconClass="fa fa-users fa-2x" to="/roster">
    Roster
  </MainNavButton>
)
export default DashboardMainNavButton
