import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { locationByIdSelector } from '../../../state/ducks/location'
import getPriorityBsClass from '@therms/web-common/components/Priority/getPriorityBsClass'

function LocationActiveBoloCount({ badge, locationId, render }) {
  const location = useSelector(state => locationByIdSelector(state, locationId))

  const count = location.locationBolo?.count
  const priority = location.locationBolo?.priority

  if (!count) return null

  if (render) return render({ count, priority })

  return (
    <span
      className={classnames({
        [`badge badge-${getPriorityBsClass(priority) || 'secondary'}`]: badge,
      })}
    >
      {count}
    </span>
  )
}

LocationActiveBoloCount.propTypes = {
  badge: PropTypes.bool,
  locationId: PropTypes.string.isRequired,
  render: PropTypes.func,
}

LocationActiveBoloCount.defaultProps = {
  badge: false,
  render: undefined,
}

export default LocationActiveBoloCount
