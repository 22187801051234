import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'
import Store from '../../services/store'

const reducerName = 'userRecent'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'userRecents',
  reducerName,
  singular: 'userRecent',
  storeMapperName: 'userRecent',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const { userRecentByIdSelector, userRecentLoadingByIdSelector } = selectors

const selectUserRecents = state => state.userRecent.userRecents

const selectUserRecentByUserId = createSelector(
  (state, userId) => userId,
  selectUserRecents,
  (userId, userRecents) => userRecents.find(userRecent => userRecent.userId === userId)
)

export const userRecentByUserIdSelector = createSelector(
  (state, userId) => state.userRecent.userRecents.find(userRecent => userRecent.userId === userId),
  userRecent => userRecent,
)

export const userRecentRecordIdsByUserIdSelector = createSelector(
  selectUserRecentByUserId,
  userRecent => userRecent?.recordIds
)

// actions
export const { fetchUserRecent } = actions

export const addRecentRecord = (modelName, recordId) => {
  Store.UserRecent.addRecentRecord(modelName, recordId)
}

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
