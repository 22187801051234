/* eslint-disable no-use-before-define */
import { createAction, handleActions } from 'redux-actions'
import { all, put, select, take } from '@redux-saga/core/effects'

import reducerRegistry from '../reducerRegistry'
import { addSaga } from '../sagaRegistry'
import { clearCompletedQueriesCache } from '../../services/store'
import realtimeService from '../../services/realtime'
import stateStore from '../store'
import { types } from '../../modules/Auth/ducks'

const reducerName = 'app'

const initialState = {
  realtimeConnected: false,
}

const realtimeDisconnectedTasks = []
const realtimeOnReconnectTasks = []

// Selectors
export const realtimeConnectedSelector = state => state.app.realtimeConnected

export const realtimeConnectionChange = createAction('realtimeConnectionChange', connected =>
  Boolean(connected),
)

// Reducer
const reducer = handleActions(
  {
    [realtimeConnectionChange]: (state, action) => ({
      realtimeConnected: action.payload,
    }),
  },
  initialState,
)

reducerRegistry.register(reducerName, reducer)

// Sagas
function* appRun() {
  const authenticated = yield take(types.SET_AUTHENTICATED)

  if (authenticated) {
    yield all([realtimeDisconnected(), realtimeReconnected()])
  }
}

function* realtimeReconnected() {
  const initiallyConnected = yield select(realtimeConnectedSelector)

  // if not yet connected on app startup, wait until connected before continuing
  if (!initiallyConnected) yield take(realtimeConnectionChange)

  let lastConnectionStatus

  while (true) {
    const { payload: connected } = yield take(realtimeConnectionChange)
    if (connected && !lastConnectionStatus) {
      clearCompletedQueriesCache()

      for (const task of realtimeOnReconnectTasks) {
        const { func, taskName } = task
        console.info('realtimeOnReconnectTask: ', taskName)
        yield put(func())
      }
    }

    lastConnectionStatus = connected
  }
}

function* realtimeDisconnected() {
  yield
  // TODO: commented this out FEB 2020 because mobile devices were throwing a lot of errors due to
  //    the browser being in the background trying to load bundle chunks that had not been loaded yet
  //    as well as other issues - it's not a priority at this time to investigate further.
  // while (true) {
  //   yield delay(30000)
  //
  //   const connected = yield select(realtimeConnectedSelector)
  //
  //   if (!connected) {
  //     // todo: need to turn caching off in js-data datastore until the socket is connected (only if not offline)?
  //     //        how would that affect initial load times?
  //
  //     for (const task of realtimeDisconnectedTasks) {
  //       const { func, taskName } = task
  //       console.info('realtimeDisconnectedTask: ', taskName)
  //       yield put(func())
  //     }
  //   }
  // }
}

function* appSaga() {
  yield all([appRun()])
}

addSaga('app', appSaga)

// register a method to be dispatched while socket is disconnected at 30sec intervals
export function registerRealtimeDisconnectedTask(taskName, cb) {
  realtimeDisconnectedTasks.push({ func: cb, taskName })
}

// register a method to be invoked when realtime reconnects after being disconnected
export function registerRealtimeOnReconnectTask(taskName, cb) {
  realtimeOnReconnectTasks.push({ func: cb, taskName })
}

// register to dispatch to redux store when realtime connection changes
realtimeService.registerConnectionChangeListener(connected =>
  stateStore.dispatch(realtimeConnectionChange(connected)),
)
