import React from 'react'
import PropTypes from 'prop-types'

import { REGEX_VALID_RASTER_IMAGE_FORMATS } from '../../../constants'

const ImageThumbnail = ({ contentType, onError, onClick, thumbnailUrl, url }) => (
  <div
    className="file-card-container bg-secondary-opacity-25 onhover-highlight"
    onClick={onClick}
  >
    <img
      alt=""
      className="file-card-image-thumbnail"
      crossOrigin="anonymous"
      onError={onError}
      src={REGEX_VALID_RASTER_IMAGE_FORMATS.test(contentType) ? thumbnailUrl : url}
    />
  </div>
  )

ImageThumbnail.propTypes = {
  contentType: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onClick: PropTypes.func,
  thumbnailUrl: PropTypes.string,
  url: PropTypes.string.isRequired,
}

ImageThumbnail.defaultProps = {
  onError: () => {},
  onClick: () => {},
  thumbnailUrl: undefined,
}

export default ImageThumbnail
