import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { scheduleTimelogApi } from '../../../../services/apiCore'
import { systemToast } from '../../../../../../utils/toaster'
import LoadingStateText from '@therms/web-common/components/LoadingStateText'
import UserName from '../../../../../User/shared/UserName'
import Conditional from '../../../../../Auth/shared/Conditional'
import Checkbox from '@therms/web-common/components/Inputs/Checkbox'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import dayjs from 'dayjs'
import DateTime from '@components/DateTime'
import useRegionMetadata from '../../../../../Auth/shared/hooks/useRegionMetadata'

const TimeLogControlsClockIn = ({ scheduleShift }) => {
  const [showClockInForUserBtn, setShowClockInForUserBtn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tooEarlyForClockIn, setTooEarlyForClockIn] = useState(false)
  const [showTooEarlyForClockIn, setShowTooEarlyForClockIn] = useState(false)
  const regionMetadata = useRegionMetadata()

  useEffect(() => {
    if (regionMetadata.data) {
      const { settings } = regionMetadata.data

      const earlyClockInMin = settings.module.schedule.earlyClockInThresholdMin || 0

      const clockInThresholdTime = dayjs(scheduleShift.dateStart)
        .subtract(earlyClockInMin, 'minute')
        .toISOString()

      setTooEarlyForClockIn(
        clockInThresholdTime > new Date().toISOString() ? clockInThresholdTime : false,
      )
    }
  }, [scheduleShift, regionMetadata])

  const handleClockIn = useCallback(async () => {
    if (tooEarlyForClockIn) {
      setShowTooEarlyForClockIn(true)
    } else {
      setLoading(true)

      try {
        await scheduleTimelogApi.clockIn({ scheduleShiftId: scheduleShift.id })
      } catch (e) {
        console.log('handleClockIn() error', e)

        systemToast('There was a problem clocking-in.', {
          error: true,
        })
      } finally {
        setLoading(false)
      }
    }
  }, [scheduleShift, tooEarlyForClockIn])

  const clockInBtn = useMemo(
    () => (
      <button
        className="btn btn-block btn-success"
        disabled={loading}
        onClick={handleClockIn}
        type="button"
      >
        <FAIcon name="sign-in-alt" />{' '}
        <LoadingStateText isLoading={loading} loadingText="Clock-In" text="Clock-In" />
      </button>
    ),
    [handleClockIn, loading],
  )

  if (tooEarlyForClockIn && showTooEarlyForClockIn) {
    return (
      <div className="alert alert-warning w-100">
        <FAIcon name="exclamation-triangle" /> It is too early to clock-in. Please wait{' '}
        <DateTime relativeTime timestamp={tooEarlyForClockIn} />
      </div>
    )
  }

  return (
    <div>
      <Conditional
        isCurrentUser={scheduleShift.userId}
        renderOnFail={() => (
          <div>
            <div className="alert alert-info text-center">
              <div>
                This shift is assigned to <UserName id={scheduleShift.userId} />
              </div>
              <div className="mt-2">
                <Checkbox
                  label="Clock-in for this User"
                  onChange={(checked) => setShowClockInForUserBtn(checked)}
                />
              </div>
            </div>

            {showClockInForUserBtn && clockInBtn}
          </div>
        )}
      >
        {clockInBtn}
      </Conditional>
    </div>
  )
}

TimeLogControlsClockIn.propTypes = {
  scheduleShift: PropTypes.object.isRequired,
}
TimeLogControlsClockIn.defaultProps = {}

export default TimeLogControlsClockIn
