import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import FileCard from './components/FileCard'
import PropTypes from 'prop-types'

import { buildFilesFromUrls, FileTemplate } from './utils'
import FileInput from './components/FileInput'
import FileCardUpload from './components/FileCardUpload'
import resizeImage from '../../utils/resize-image'
import { usePreviousValue } from 'beautiful-react-hooks'
import { getImageThumbnailUrl } from 'modules/Files/utils/imageSizeUrls'
import {
  REGEX_VALID_IMAGE_FORMATS,
  REGEX_VALID_RASTER_IMAGE_FORMATS,
} from 'modules/Files/constants'

import './index.scss'

/**
 * TODO:
 *
 *  Refactor Uses After Component Ready:
 *  - refactor "initialPrimaryImageId" to new 'primaryImageFileUrl' prop
 *  - refactor prop usage getPrimaryImageFileId => getPrimaryImageFileUrl
 *  - refactor prop usage getFiles => getFileUrls
 *
 */

const FilePicker = forwardRef(
  (
    {
      canSelectPrimaryImage,
      canSelectMultiple,
      disabled,
      imageOnly,
      label,
      onFileCountChange,
      onFileUrlsChange,
      onUploadingStatusChange,
      previouslyUploadedFileUrls,
      primaryImageFileUrl,
    },
    ref,
  ) => {
    const [primaryImageUrl, setPrimaryImageUrl] = useState(primaryImageFileUrl)
    const [files, setFiles] = useState([])
    const prevFiles = usePreviousValue(files)
    const [uploading, setUploading] = useState([])
    const prevUploading = usePreviousValue(uploading)
    const [resizingImages, setResizingImages] = useState(false)

    const fileUrls = useMemo(() => {
      if (canSelectMultiple) return files.map(({ url }) => url)
      if (files.length) return files[0].url
      return undefined
    }, [canSelectMultiple, files])

    // copy of state in ref to avoid closures
    const local = useRef({ files, uploading })
    local.current.files = files
    local.current.uploading = uploading

    const handleFilesSelected = useCallback(
      (fileList) => {
        if (canSelectMultiple) setUploading([...uploading, ...Array.from(fileList)])
        else {
          setUploading([fileList[0]])
          setFiles([])
        }
      },
      [uploading],
    )

    const handleFileUploadComplete = useCallback(
      (file, fileUrl) => {
        // file is a file object from the <input /> element
        if (!file)
          throw new Error('handleFileUploadComplete(file, fileUrl) no "file" param provided')

        if (!fileUrl)
          throw new Error('handleFileUploadComplete(file, fileUrl) no "fileUrl" param provided')

        const isImage = REGEX_VALID_IMAGE_FORMATS.test(file.type)
        const isRasterImage = REGEX_VALID_RASTER_IMAGE_FORMATS.test(file.type)

        // out file server/host creates thumbnails for images
        const thumbnailUrl = isRasterImage ? getImageThumbnailUrl(fileUrl) : undefined

        const newFile = {
          size: file.size,
          statusText: new Date().toString(),
          thumbnailUrl,
          type: file.type,
          url: fileUrl,
        }

        // order matters in updating state because of the onUploadingStatusChange call from useEffect
        setUploading(uploading.filter((uploadingFile) => file !== uploadingFile))
        setFiles([...files, newFile])
        if (isImage && canSelectPrimaryImage && !primaryImageUrl) setPrimaryImageUrl(fileUrl)
      },
      [files, primaryImageUrl, setFiles, setUploading, uploading],
    )

    const removeFileAtIndex = useCallback(
      (index) => {
        const clonedFiles = Array.from(files)

        if (canSelectPrimaryImage && files[index].url === primaryImageUrl) setPrimaryImageUrl('')

        clonedFiles.splice(index, 1)
        setFiles(clonedFiles)
      },
      [files],
    )

    const removeUploadingFileAtIndex = useCallback(
      (index) => {
        const clonedUploadingFiles = Array.from(uploading)
        clonedUploadingFiles.splice(index, 1)
        setUploading(clonedUploadingFiles)
      },
      [uploading],
    )

    useImperativeHandle(ref, () => ({
      /**
       * Get the file urls for files that have already been uploaded
       * @returns {Array<String> | String}
       */
      getFileUrls: () => fileUrls,
      /**
       * Get the files and their metadata
       * @returns {Array<{
       *   size: number,
       *   statusText: string,
       *   thumbnailUrl: string|undefined,
       *   type: string,
       *   url: string,
       * }>}
       */
      getFilesWithMetadata: () => files,

      isUploading: () => !!local.current.uploading.length,

      reset: () => {
        setUploading([])
        setPrimaryImageUrl(primaryImageFileUrl)
      },

      /**
       * Returns the currently selected primaryImageUrl
       *
       * @returns {String | undefined }
       */
      getPrimaryImageUrl: () => primaryImageUrl,

      /**
       * Can be called on a ref of this component  - if `canSelectMultiple` then will callback with
       * an array of fileUrls otherwise just a single fileUrl.
       *
       * @returns {Promise<Array<String> | String>}
       */
      startUpload: () => 
        // we do fancy stuff to wait for any pending/inprogress uploads then callback with uploaded file urls
         new Promise((resolve) => {
          function callback() {
            if (canSelectMultiple) resolve(local.current.files.map(({ url }) => url))
            else resolve(local.current.files[0]?.url)
          }

          function wait() {
            setTimeout(() => {
              if (local.current.uploading.length > 0) wait()
              else callback()
            }, 250)
          }

          if (local.current.uploading.length > 0) wait()
          else callback()
        })
      ,
    }))

    useEffect(() => {
      const previousUrls =
        typeof previouslyUploadedFileUrls === 'string'
          ? [previouslyUploadedFileUrls]
          : previouslyUploadedFileUrls

      if (Array.isArray(previousUrls)) {
        const filteredUrls = previousUrls.filter(
          (fileUrl) => !files.find(({ url }) => url === fileUrl),
        )

        // set urls immediately in case of a slow resolve from buildFilesFromUrls()
        setFiles(filteredUrls.map((url) => new FileTemplate({ url })))

        buildFilesFromUrls(filteredUrls).then(setFiles)
      }
    }, [])

    useEffect(() => {
      if (onFileCountChange) {
        if (prevFiles.length !== undefined && files.length !== prevFiles.length)
          onFileCountChange(files.length)
      }

      if (onFileUrlsChange) {
        if (prevFiles !== files)
          onFileUrlsChange(fileUrls)
      }

      if (onUploadingStatusChange) {
        if (prevUploading !== uploading)
          onUploadingStatusChange({
            uploading: !!uploading.length,
          })
      }
    }, [files, prevFiles, prevUploading, uploading])

    return (
      <div className="file-picker-container">
        {label && <div className="mb-2">{label}</div>}

        {!disabled && !resizingImages && (
          <div>
            <FileInput
              multiple={canSelectMultiple}
              imageOnly={imageOnly}
              onFilesSelected={async (fileList) => {
                const _fileList = []

                setResizingImages(true)

                // give the browser 1sec to digest React updates before blocking in the resizing call
                if (Array.from(fileList).length > 3) await new Promise((r) => setTimeout(r, 500))

                for await (const file of Array.from(fileList)) {
                  if (REGEX_VALID_RASTER_IMAGE_FORMATS.test(file.type)) {
                    const resizedImage = await resizeImage(file)
                    _fileList.push(resizedImage)
                  } else {
                    _fileList.push(file)
                  }
                }

                handleFilesSelected(_fileList)
                setResizingImages(false)
              }}
            />
          </div>
        )}

        {resizingImages && (
          <div className="alert alert-secondary my-1 text-center w-100">Loading files...</div>
        )}

        <div className="file-cards-container">
          {files.map((file, index) => (
            <div className="m-1" key={file.url}>
              <FileCard
                disabled={disabled}
                contentType={file.type}
                isPrimaryImage={primaryImageUrl === file.url}
                onDelete={() => removeFileAtIndex(index)}
                onMakePrimaryImage={
                  canSelectPrimaryImage && REGEX_VALID_IMAGE_FORMATS.test(file.type)
                    ? () => setPrimaryImageUrl(file.url)
                    : undefined
                }
                size={+file.size}
                statusText={file.statusText}
                thumbnailUrl={file.thumbnailUrl}
                url={file.url}
              />
            </div>
          ))}

          {uploading.map((file, index) => (
            <div className="m-1" key={`${file.name}-${file.lastModified}`}>
              <FileCardUpload
                file={file}
                onDelete={() => removeUploadingFileAtIndex(index)}
                onUploadComplete={(uploadedFile, fileUrl) => {
                  handleFileUploadComplete(uploadedFile, fileUrl)
                }}
              />
            </div>
          ))}
        </div>
      </div>
    )
  },
)

FilePicker.propTypes = {
  canSelectPrimaryImage: PropTypes.bool,
  canSelectMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  imageOnly: PropTypes.bool,
  label: PropTypes.any,
  onFileCountChange: PropTypes.func,
  onFileUrlsChange: PropTypes.func,
  onUploadingStatusChange: PropTypes.func,
  // ie: https://therms-files.s3.amazonaws.com/5facb1c3e533f404374fa113.jpg
  previouslyUploadedFileUrls: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  primaryImageFileUrl: PropTypes.string,
}
FilePicker.defaultProps = {
  canSelectPrimaryImage: false,
  canSelectMultiple: true,
  disabled: false,
  imageOnly: false,
  label: undefined,
  onFileCountChange: undefined,
  onFileUrlsChange: undefined,
  onUploadingStatusChange: undefined,
  previouslyUploadedFileUrls: [],
  primaryImageFileUrl: undefined,
}

export default FilePicker
