import { useEffect, useState } from 'react'
import Store from 'services/store'

export default function useLocation(locationId) {
  const [location, setLocation] = useState(Store.Location.get(locationId))

  useEffect(() => {
    if (locationId)
      Store.Location.find(locationId).then(setLocation)
  }, [locationId])

  return location
}
