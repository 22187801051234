import { configHttp, store } from '@therms/models/stores/browser'

import { isProdEnv } from 'config/env'
import coreApi from './coreApi'
import realtime from './realtime'

configHttp({ httpClient: coreApi })

const changeListeners = {}

const createStoreEventHandlerFactory = event => (mapperName, payload) => {
  const records = Array.isArray(payload) ? payload : [payload]

  if (!records || (Array.isArray(records) && !records.length)) return

  // we can get back record class instances, so convert to POJO
  const data = records.map(r => (r.toJSON ? r.toJSON() : r))

  const name = mapperName.toLowerCase()

  if (changeListeners[name]) {
    changeListeners[name].forEach(func => func.call(store, { event, payload: data }))
  }
}

store.on('add', createStoreEventHandlerFactory('add'))
store.on('remove', createStoreEventHandlerFactory('remove'))

realtime.registerListener('model:add', ({ model, record }) => {
  if (!isProdEnv || window.__DEBUG) console.info('io msg "model:add"', model, record)

  if (!Object.hasOwnProperty.call(store._mappers, model))
    throw new Error(`realtime event "model:add" for model "${model}" does not exist.`)

  store.add(model, record)
})

realtime.registerListener('model:remove', ({ model, record, recordId }) => {
  if (!isProdEnv || window.__DEBUG)
    console.info('io msg "model:remove"', model, record ? record.id : recordId)

  if (!Object.hasOwnProperty.call(store._mappers, model))
    throw new Error(`realtime event "model:remove" for model "${model}" does not exist.`)

  store.remove(model, record ? record.id : recordId)
})

/**
 * Remove all cached queries from datastore to force a refetch on the next query
 */
export const clearCompletedQueriesCache = () => {
  Object.keys(store._mappers).forEach(mapper => {
    store._completedQueries[mapper] = {}
  })
}

// callback example: cb({ event: add|remove, payload: [...] })
export const registerStoreChangeHandler = function registerChangeHandler(mapperName, cb) {
  const name = mapperName.toLowerCase()
  if (!changeListeners[name]) changeListeners[name] = []
  changeListeners[name].push(cb)
}

export const unregisterStoreChangeHandler = function registerChangeHandler(mapperName, cb) {
  const name = mapperName.toLowerCase()
  const index = changeListeners[name].indexOf(cb)
  if (index !== -1) delete changeListeners[name][index]
}

export default store
