import React, { useEffect, useState } from 'react'
import DateTime from '@therms/web-common/components/DateTime'
// import PropTypes from 'prop-types'

const Clock = () => {
  const [time, setTime] = useState()

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().toISOString())
    }, 15000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="align-items-center d-inline-flex flex-column-center">
      <span className="clock-time font-weight-bold font-lg mb-1 text-light">
        <DateTime date={false} timestamp={time} />
      </span>

      <span className="clock-date font-small text-muted">
        <DateTime time={false} timestamp={time} />
      </span>
    </div>
  )
}

Clock.propTypes = {}
Clock.defaultProps = {}

export default Clock
