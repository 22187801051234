import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'

import { handleAuthFailed } from './auth'
import cookies from 'js-cookie'
import url from "url"
import ENV from 'config/env'

const parsedUrl = url.parse(ENV.FILE_SERVER)
const FILE_SERVER_HOST = parsedUrl.host || window.location.host
const FILE_SERVER_PATH = parsedUrl.path

const axiosConfig = {
  // todo: we want to cache file requests, but this needs fleshing out
  //      it's a huge performance gain to cache in localStorage also if we can
  //      this might require serviceWorker.js help :thinking:
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)),

  baseURL: url.format({ host: FILE_SERVER_HOST, pathname: FILE_SERVER_PATH }),

  // `onUploadProgress` allows handling of progress events for uploads
  // onUploadProgress(progressEvent) {
  // Do whatever you want with the native progress event
  // },

  // `onDownloadProgress` allows handling of progress events for downloads
  // onDownloadProgress(progressEvent) {
  // Do whatever you want with the native progress event
  // },

  timeout: 1000 * 60 * 10, // 10 min because we might have long uploads, ie: 3g connection
}

const filesApi = axios.create(axiosConfig)

filesApi.interceptors.request.use(
  (config) => {
    // add jwt to req header
    // eslint-disable-next-line no-param-reassign
    config.headers[process.env.JWT_HEADER_KEY] = cookies.get(
      process.env.AUTH_COOKIE_NAME,
    )
    return config
  },
  (error) => 
    // Do something with request error
     Promise.reject(error)
  ,
)

// Add a response interceptor
filesApi.interceptors.response.use(
  response =>
    // Do something with response data
    // console.log('ok', response.status)
    response,
  error => {
    console.log('error', error.response)

    if (error && error.response && error.response.status === 401) handleAuthFailed('fileApi 401')

    return Promise.reject(error)
  },
)

export default filesApi
