import React from 'react'
import PropTypes from 'prop-types'
import usePatrolRoute from './usePatrolRoute'

const PatrolRouteName = ({ id }) => {
  const { data, validating } = usePatrolRoute(id)

  return <span className={validating ? 'loading' : ''}>{data?.name}</span>
}

PatrolRouteName.propTypes = {
  id: PropTypes.string.isRequired,
}
PatrolRouteName.defaultProps = {}

export default PatrolRouteName
