import { handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'
import reducerRegistry from '../reducerRegistry'

const reducerName = 'reportMetadata'

const {
  actions,
  actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'reportMetadatas',
  reducerName,
  singular: 'reportMetadata',
  storeMapperName: 'reportMetadata',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
const selectReportMetadataByReportId = (state, reportId) =>
  state.reportMetadata.reportMetadatas.find(
    reportMetadata => reportMetadata.reportId === reportId,
  )

export const {
  reportMetadataByIdSelector,
  reportMetadataHasFetchedByIdSelector,
  reportMetadataLoadingByIdSelector,
} = selectors

export const reportMetadataByReportIdSelector = createSelector(
  selectReportMetadataByReportId,
  reportMetadata => reportMetadata,
)

// actions
export { actionCreators }
export const { fetchReportMetadata, fetchReportMetadatas } = actions

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
