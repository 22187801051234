import React from 'react'
import DropdownMenuContainer, { DropdownItem } from '@components/DropdownMenu/index'
import Conditional from '../../Auth/shared/Conditional'
// import PropTypes from 'prop-types'

const ScheduleModuleDropdownMenu = () => (
  <Conditional permission="schedule.canChangeModuleSettings">
    <DropdownMenuContainer positionTopRight>
      <DropdownItem to="/schedule/settings">
        <i className="fa fa-cogs" /> Settings
      </DropdownItem>
    </DropdownMenuContainer>
  </Conditional>
)

ScheduleModuleDropdownMenu.propTypes = {}
ScheduleModuleDropdownMenu.defaultProps = {}

export default ScheduleModuleDropdownMenu
