import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { escapeRegExp } from 'lodash-es'

import {
  allUsersSelector,
  activeRegionUsersSelector,
  fetchUsersForAllRegionIds,
} from '../../../Roster/ducks'
import { fetchUsers } from 'state/ducks/user'
import formatUserName from '../utils/formatUserName'

import Select from '@components/Inputs/Select'
import { components } from 'react-select'
import Conditional from '../../../Auth/shared/Conditional'
import RegionName from '../../../Region/shared/RegionName'
import StatusBadge from '../StatusBadge'

// todo: default to closeOnSelect - it's more common to only select 1 user
//       than multiple, as opposed to SelectActivityTypes
class SelectUsers extends React.Component {
  static propTypes = {
    // show all region users or only users who have access to the current region
    allRegionUsers: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    fetchUsers: PropTypes.func.isRequired,
    fetchUsersForAllRegionIds: PropTypes.func.isRequired,
    input: PropTypes.object,
    isClearable: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.elementType,
      PropTypes.node,
      PropTypes.string,
    ]),
    loadingUsers: PropTypes.bool.isRequired,
    meta: PropTypes.object,
    multi: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    regionId: PropTypes.string.isRequired,
    showRegionName: PropTypes.bool,
  }

  static defaultProps = {
    allRegionUsers: false,
    defaultValue: undefined,
    input: {},
    isClearable: true,
    label: '',
    meta: {},
    multi: false,
    onChange: undefined,
    placeholder: undefined,
    showRegionName: false,
  }

  componentDidMount() {
    if (this.props.allRegionUsers) this.props.fetchUsersForAllRegionIds()
    else this.props.fetchUsers({ regionId: this.props.regionId })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.regionId !== this.props.regionId) {
      this.props.fetchUsers({ regionId: this.props.regionId })
    }
  }

  filterOption = ({ data, label }, input) => {
    const _input = input.replace(/[^a-zA-Z0-9] /gi, '')
    const rgx = new RegExp(escapeRegExp(_input), 'gi')

    if (_input) return rgx.test(JSON.stringify({ ...data, label }))

    return true
  }

  getPlaceholder = () => {
    if (this.props.multi) {
      return 'Users'
    }

    return 'User'
  }

  renderLabel = () => {
    const { label, multi } = this.props
    let customLabel

    if (!label) return null
    if (React.isValidElement(label)) return label
    if (typeof label === 'string') customLabel = label
    else if (multi) customLabel = 'Users'
    else customLabel = 'User'

    return <label>{customLabel}</label>
  }

  render() {
    const {
      defaultValue,
      input,
      isClearable,
      loadingUsers,
      meta,
      multi,
      placeholder,
      users,
      ...rest
    } = this.props

    const options = users.map((user) => ({
      badgeNumber: user.badgeNumber,
      defaultRegionId: user.defaultRegionId,
      value: user.id,
      label: formatUserName(user),
      phone: user.phone,
      title: user.title,
    }))

    return (
      <div className="min-width-200">
        <Select
          defaultValue={defaultValue}
          filterOption={this.filterOption}
          isClearable={isClearable}
          isLoading={loadingUsers}
          label={this.renderLabel()}
          multi={multi}
          name="region"
          options={options}
          placeholder={placeholder || this.getPlaceholder()}
          input={input}
          meta={meta}
          {...rest}
          reactSelectCustomComponents={{
            MultiValueLabel: (props) => (
              <components.MultiValueLabel {...props}>
                <div>
                  <div>
                    {props.data.badgeNumber ? (
                      <span className="font-small text-secondary">{props.data.badgeNumber} </span>
                    ) : (
                      ''
                    )}
                    <span className="font-weight-bold">
                      {props.data.label}{' '}
                      <Conditional isTrue={this.props.showRegionName} module="region">
                        <span className="font-xs text-muted">
                          <RegionName id={props.data.defaultRegionId} />
                        </span>
                      </Conditional>
                    </span>
                    {props.data.title && (
                      <div className="font-small text-secondary">{props.data.title}</div>
                    )}
                  </div>
                </div>
              </components.MultiValueLabel>
            ),
            Option: (_props) => {
              const {
                data: { badgeNumber, label, title, value },
                innerProps,
                innerRef,
              } = _props

              return (
                <article
                  ref={innerRef}
                  {...innerProps}
                  className={`${_props.isFocused && 'bg-primary'}`}
                >
                  <div className="cursor p-1">
                    {badgeNumber ? (
                      <span className="font-small text-secondary">{badgeNumber}</span>
                    ) : (
                      ''
                    )}
                    <span className="font-weight-bold">
                      {label} <StatusBadge className="ml-1" userId={value} />{' '}
                      <Conditional isTrue={this.props.showRegionName} module="region">
                        <span className="font-xs text-muted">
                          <RegionName id={_props.data.defaultRegionId} />
                        </span>
                      </Conditional>
                    </span>
                    {title && <div className="font-small text-secondary">{title}</div>}
                  </div>
                </article>
              )
            },
            SingleValue: (props) => (
              <components.SingleValue {...props}>
                <div>
                  {props.data.badgeNumber ? (
                    <span className="font-small text-secondary">{props.data.badgeNumber} </span>
                  ) : (
                    ''
                  )}
                  <span className="font-weight-bold">
                    {props.data.label} <StatusBadge className="ml-1" userId={props.data.value} />{' '}
                    <Conditional isTrue={this.props.showRegionName} module="region">
                      <span className="font-xs text-muted">
                        <RegionName id={props.data.defaultRegionId} />
                      </span>
                    </Conditional>
                  </span>

                  {props.data.title && (
                    <span className="font-small text-secondary"> {props.data.title}</span>
                  )}
                </div>
              </components.SingleValue>
            ),
          }}
        />
      </div>
    )
  }
}

export default connect(
  (state, props) => ({
    loadingUsers: state.user.loadingUsers,
    regionId: props.regionId || state.Auth.region.id,
    users: props.allRegionUsers ? allUsersSelector(state) : activeRegionUsersSelector(state),
  }),
  { fetchUsers, fetchUsersForAllRegionIds },
)(SelectUsers)
