import axios from 'axios'

import { handleAuthFailed } from './auth'
import cookies from 'js-cookie'
import url from "url"
import ENV from 'config/env'

const parsedUrl = url.parse(ENV.AUTH_SERVER)
const AUTH_SERVER_HOST = parsedUrl.host || window.location.host
const AUTH_SERVER_PATH = parsedUrl.path

const axiosConfig = {
  baseURL: url.format({ host: AUTH_SERVER_HOST, pathname: AUTH_SERVER_PATH }),
}

const authApi = axios.create(axiosConfig)

authApi.interceptors.request.use(
  (config) => {
    // add jwt to req header
    // eslint-disable-next-line no-param-reassign
    config.headers[process.env.JWT_HEADER_KEY] = cookies.get(
      process.env.AUTH_COOKIE_NAME,
    )
    return config
  },
  (error) => 
    // Do something with request error
     Promise.reject(error)
  ,
)

// Add a response interceptor
authApi.interceptors.response.use(
  response =>
    // Do something with response data
    // console.log('ok', response.status)
    response,
  error => {
    console.log('error', error.response)

    if (error && error.response && error.response.status === 401) handleAuthFailed('authApi 401')

    return Promise.reject(error)
  },
)

export default authApi
