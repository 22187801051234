/* eslint-disable no-use-before-define */
import { createSelector } from 'reselect'
import { createAction } from 'redux-actions'

import coreApi from 'services/coreApi'
import { createHashByKey } from '@utils'
import reducerRegistry from 'state/reducerRegistry'
import Store from 'services/store'

const initialState = {
  accessLevel: 1,
  allowedLocationIds: null,
  authenticated: false,
  loadingCurrentUser: false,
  modules: [], // available modules for current organization
  organization: undefined, // current organization
  organizationMetadata: undefined, // current organization's metadata
  region: undefined, // current region
  regionMetadata: undefined, // current region's metadata
  regions: undefined, // all regions user has access to
  token: undefined,
  user: undefined, // current user
  userAccount: undefined, // current user's account
  userMetadata: undefined, // current user's metadata
  userRecent: undefined, // current user's userRecent model record
  userStatus: undefined, // current user's userStatus model record
}

const moduleName = 'Auth'

const moduleConstant = (name) => `${moduleName}/${name}`

// selectors
export const selectAllowedLocationIds = (state) => state.Auth.allowedLocationIds

export const selectAuth = (state) => state.Auth

export const selectCurrentUserAccessLevel = (state) =>
  state.Auth.userMetadata.admin ? 3 : state.Auth.userMetadata.accessLevel

export const selectCurrentUser = (state) => state.Auth.user

export const selectCurrentUserRegionIds = (state) => state.Auth.user.regionIds

export const selectModules = (state) => state.Auth.modules

export const selectOrganization = (state) => state.Auth.organization

export const selectOrganizationMetadata = (state) => state.Auth.organizationMetadata

const selectRegions = (state) => state.Auth.regions

export const selectRegionSettings = (state) => state.Auth.regionMetadata.settings

export const selectOrgSettings = (state) => state.Auth.organizationMetadata.settings

export const selectUserInfoAccessLevel = (state) =>
  state.Auth.organizationMetadata.settings?.module?.roster?.userInfoAccessLevel

export const currentUserSelector = createSelector(
  (state) => state.Auth,
  (Auth) => ({
    ...Auth.user,
    userAccount: Auth.userAccount,
    userMetadata: Auth.userMetadata,
    userRecent: Auth.userRecent,
    userStatus: Auth.userStatus,
  }),
)

export const modulesByIdentitySelector = createSelector(selectModules, (modules) =>
  modules.reduce((acc, module) => {
    acc[module.identityName] = {
      ...module,
      allowedPermissionsByIdentity: module.allowedPermissions.reduce((acc2, permission) => {
        // eslint-disable-next-line no-param-reassign
        acc2[permission.identityName] = permission

        return acc2
      }, {}),
    }

    return acc
  }, {}),
)

export const regionsByIdSelector = createSelector(selectRegions, (regionList) =>
  createHashByKey(regionList, 'id'),
)

// types
export const types = {
  SET_ACCESS_LEVEL: moduleConstant('SET_ACCESS_LEVEL'),
  SET_ALLOWED_LOCATION_IDS: moduleConstant('SET_ALLOWED_LOCATION_IDS'),
  SET_AUTHENTICATED: moduleConstant('SET_AUTHENTICATED'),
  SET_LOADING_CURRENT_USER: moduleConstant('SET_LOADING_CURRENT_USER'),
  SET_MODULES: moduleConstant('SET_MODULES'),
  SET_ORGANIZATION: moduleConstant('SET_ORGANIZATION'),
  SET_ORGANIZATION_METADATA: moduleConstant('SET_ORGANIZATION_METADATA'),
  SET_REGION: moduleConstant('SET_REGION'),
  SET_REGION_METADATA: moduleConstant('SET_REGION_METADATA'),
  SET_REGIONS: moduleConstant('SET_REGIONS'),
  SET_TOKEN: moduleConstant('SET_TOKEN'),
  SET_USER: moduleConstant('SET_USER'),
  SET_USER_ACCOUNT: moduleConstant('SET_USER_ACCOUNT'),
  SET_USER_METADATA: moduleConstant('SET_USER_METADATA'),
  SET_USERRECENT: moduleConstant('SET_USERRECENT'),
  SET_USERSTATUS: moduleConstant('SET_USERSTATUS'),
}

// actions
export const authenticate = createAction('authenticate')
export const changeActiveRegion = createAction('change active region')
export const logout = createAction('logout')
export const setAccessLevel = createAction(types.SET_ACCESS_LEVEL)
export const setAllowedLocationIds = createAction(types.SET_ALLOWED_LOCATION_IDS)
export const setLoadingCurrentUser = createAction(types.SET_LOADING_CURRENT_USER)
export const setModules = createAction(types.SET_MODULES)
export const setUser = createAction(types.SET_USER)
export const setUserAccount = createAction(types.SET_USER_ACCOUNT)
export const setUserMetadata = createAction(types.SET_USER_METADATA)
export const setUserRecent = createAction(types.SET_USERRECENT)
export const setUserStatus = createAction(types.SET_USERSTATUS)

export const fetchCurrentUser = () => (dispatch, getState) => {
  const user = getState().Auth.user

  dispatch(setLoadingCurrentUser(true))

  Promise.all([
    Store.User.find(user.id).then((u) => dispatch(setUser(u))),
    Store.UserAccount.find(user.userAccountId).then((userAccount) =>
      dispatch(setUserAccount(userAccount)),
    ),
    Store.UserMetadata.find(user.userMetadataId).then((userMetadata) => {
      dispatch(setUserMetadata(userMetadata))
      dispatch(setAccessLevel(userMetadata.accessLevel))
    }),
    Store.UserRecent.find().then((userRecent) => dispatch(setUserRecent(userRecent))),
    Store.UserStatus.findOne({ userId: user.id }).then((userStatus) =>
      dispatch(setUserStatus(userStatus)),
    ),
  ])
    .catch((err) => {
      console.error('Auth fetchCurrentUser() err', err)
    })
    .finally(() => {
      dispatch(setLoadingCurrentUser(false))
    })
}

export const fetchCurrentUserStatus = () => (dispatch, getState) => {
  const user = getState().Auth.user

  return Store.UserStatus.findOne({ userId: user.id })
    .then((userStatus) => dispatch(setUserStatus(userStatus)))
    .catch((err) => {
      console.error('Auth fetchCurrentUserStatus() err', err)
    })
}

export const fetchModules = () => (dispatch) =>
  coreApi.get('/module').then(({ data }) => {
    // sort alpha by name
    dispatch(setModules(data.sort((a, b) => (a.name > b.name ? 1 : -1))))
  })

export const updatePassword =
  ({ currentPassword, newPassword }) =>
  (dispatch) =>
    coreApi
      .put('/userAccount/updatePassword', {
        currentPassword,
        newPassword,
      })
      .then(({ data }) => {
        dispatch({ type: types.SET_USER_ACCOUNT, payload: data })
        return data
      })

export const fetchCurrentRegionUserRecent = () => Store.UserRecent.find()

// reducer
function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_ALLOWED_LOCATION_IDS:
      return {
        ...state,
        allowedLocationIds: action.payload,
      }
    case types.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: Boolean(action.payload),
      }
    case types.SET_LOADING_CURRENT_USER:
      return {
        ...state,
        loadingCurrentUser: Boolean(action.payload),
      }
    case types.SET_MODULES:
      return {
        ...state,
        modules: action.payload,
      }
    case types.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      }
    case types.SET_ORGANIZATION_METADATA:
      return {
        ...state,
        organizationMetadata: action.payload,
      }
    case types.SET_REGION:
      return {
        ...state,
        region: action.payload,
      }
    case types.SET_REGION_METADATA:
      return {
        ...state,
        regionMetadata: action.payload,
      }
    case types.SET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      }
    case types.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case types.SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case types.SET_USER_ACCOUNT:
      return {
        ...state,
        userAccount: action.payload,
      }
    case types.SET_USER_METADATA:
      return {
        ...state,
        userMetadata: action.payload,
      }
    case types.SET_USERRECENT:
      return {
        ...state,
        userRecent: action.payload,
      }
    case types.SET_USERSTATUS:
      return {
        ...state,
        userStatus: action.payload,
      }
    default:
      return state
  }
}

export default reducer

reducerRegistry.register(moduleName, reducer)
