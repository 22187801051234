import React from 'react'
import { toast } from 'react-toastify'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'

export const systemToast = (textOrComponent, options = {}) => {
  const defaultOptions = {
    autoClose: 3000,
    center: true,
    className: 'alert alert-secondary',
    hideProgressBar: true,
    // onClose: props => console.log(props),
    // onOpen: props => console.log(props),
    closeButton: <></>, // for system msgs we don't want to show a close
    position: toast.POSITION.BOTTOM_CENTER,
    pauseOnHover: false,
    // type: toast.TYPE.INFO,
    pauseOnFocusLoss: true,
  }

  const opts = {
    ...defaultOptions,
    ...options,
  }

  // show the error background
  if (options.error) {
    opts.className = 'alert alert-danger'
  } else if (options.info) {
    opts.className = 'alert alert-info'
  } else if (options.warn) {
    opts.className = 'alert alert-warning'
  } else if (options.success) {
    opts.className = 'alert alert-success'
  }

  // show the toaster for 1.5sec
  if (options.quick) {
    opts.autoClose = 1500
  }

  const msg = (
    <div className={`${opts.center ? 'align-items-center d-flex justify-content-center' : ''}`}>
      {textOrComponent}
    </div>
  )

  return toast(msg, opts)
}

let reloadPageTimeout

export const updateAppReloadToast = () => {
  reloadPageTimeout = setTimeout(() => {
    window.location.reload()
  }, 299000)

  const opts = {
    autoClose: 300000, // 5 min
    onClose: () => {
      clearTimeout(reloadPageTimeout)
    },
    className: 'alert bg-primary',
    position: toast.POSITION.BOTTOM_CENTER,
    pauseOnHover: true,
    type: toast.TYPE.INFO,
  }

  const msg = (
    <div className="align-items-center d-flex flex-column justify-content-center brand">
      <div className="font-weight-bold text-center">
        There is an update available, please reload
      </div>

      <div className="font-italic text-center text-muted">
        App will reload automatically if no action is taken
      </div>

      <div className="my-2">
        <button className="btn btn-outline-info" onClick={() => window.location.reload()}>
          <FAIcon name="sync" /> Reload Now
        </button>
      </div>
    </div>
  )

  return toast(msg, opts)
}

window.updateAppReloadToast = updateAppReloadToast
