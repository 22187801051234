import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Conditional from '../../Auth/shared/Conditional'
import RegionName from '../../Region/shared/RegionName'
import Tooltip from '@components/Tooltip'
import UserCard from './UserCard'
import formatUserName from './utils/formatUserName'
import useCurrentUser from '../../Auth/shared/hooks/useCurrentUser'
import useDetectDevice from 'use-mobile-detect-hook'
import useOrganization from '../../Auth/shared/hooks/useOrganization'
import useUser from './hooks/useUser'
import useUserStatus from 'modules/User/shared/hooks/useUserStatus'
import { Portal } from 'react-portal'
import { get } from 'lodash-es'
import { onlineRecordsByUserIdSelector } from '../../Online/ducks'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import './UserName.scss'

const UserName = ({ id, noCard, noDutyStatus, noLink, noOnlineStatus, showRegionName }) => {
  const detectDevice = useDetectDevice()
  const onlineRecordsByUserId = useSelector(onlineRecordsByUserIdSelector)

  const { data, validating } = useUser(id, { preferCache: true })
  const currentUser = useCurrentUser()
  const userStatus = useUserStatus(id)
  const history = useHistory()
  const organization = useOrganization()

  const userCardRef = useRef()
  const userNameRef = useRef()

  const [mouseEnterTimer, setMouseEnterTimer] = useState()
  const [mouseOutTimer, setMouseOutTimer] = useState()
  const [showCard, setShowCard] = useState(false)

  const user = data || currentUser
  const formattedUserName = useMemo(
    () => formatUserName(user, organization?.organizationMetadata?.settings?.userNameFormat),
    [organization, user],
  )
  const isMobile = useMemo(() => detectDevice.isMobile(), [])
  const isOnline = useMemo(
    () => onlineRecordsByUserId[id || currentUser.id],
    [currentUser.id, id, onlineRecordsByUserId],
  )

  const onMouseEnter = useCallback(() => {
    if ((isMobile && noLink) || noCard) return
    if (showCard) return

    clearTimeout(mouseOutTimer)

    const timeoutId = setTimeout(() => {
      setShowCard(true)
    }, 500)

    setMouseEnterTimer(timeoutId)
  }, [userNameRef])

  const onMouseLeave = useCallback(() => {
    clearTimeout(mouseEnterTimer)

    if (isMobile && noLink) return
    if (!showCard) return

    const mouseOutTimerId = setTimeout(() => {
      setShowCard(false)
    }, 100)

    setMouseOutTimer(mouseOutTimerId)
  }, [mouseEnterTimer, showCard])

  useEffect(() => {
    if (!showCard) return

    const userNameElPosition = userNameRef.current?.getBoundingClientRect()
    let { x } = userNameElPosition
    const { y } = userNameElPosition

    if (x + userCardRef.current.clientWidth > window.innerWidth)
      x = window.innerWidth - userCardRef.current.clientWidth

    let top = y

    try {
      const userNameRects = get(userNameRef.current.getClientRects(), '0')

      if (userNameRects) top = userNameRects.top + userNameRects.height
    } catch (err) {
      console.log('<UserName /> hover card err', err)
    }

    userCardRef.current.style.left = `${x}px`
    userCardRef.current.style.top = `${top}px`
  }, [showCard])

  const onlineStatusClasses = useMemo(
    () => (isOnline ? 'username-online' : 'username-offline'),
    [id, isOnline],
  )

  if (id && (!data || validating)) return <span className="loading" />

  return (
    <span
      className={classnames({ 'position-relative': showCard }, 'username-container')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={userNameRef}
    >
      <span
        className={classnames(`username ${!noOnlineStatus ? onlineStatusClasses : ''}`, {
          cursor: !noLink,
          'onhover-underline': !noLink,
        })}
        onClick={(e) => {
          if (noLink) return

          e.preventDefault()
          e.stopPropagation()

          history.push(`/roster/profile/${user.id}`)
        }}
      >
        {!noDutyStatus && !!userStatus?.status?.title ? (
          <Tooltip className="d-flex align-items-center" title={userStatus.status.title}>
            <span className="status-icon-container font-small">
              <i
                className="fas fa-circle status-icon-background"
                style={{ color: userStatus?.status?.color }}
              />
              <i className="fas fa-circle status-icon" />
            </span>
          </Tooltip>
        ) : (
          !noOnlineStatus &&
          isOnline && (
            <span className="online-icon font-small">
              <i className="far fa-dot-circle" />
            </span>
          )
        )}

        {formattedUserName}

        {!user.active && <span className="font-weight-lighter text-danger">*</span>}

        {showRegionName && (
          <Conditional module="region">
            <span className="ml-1">
              <span className="badge badge-secondary font-xs max-width-100 text-truncate">
                <RegionName id={user.defaultRegionId} />
              </span>
            </span>
          </Conditional>
        )}
      </span>

      {showCard && (
        <Portal>
          <div
            className="position-absolute username-usercard-container"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            onMouseEnter={() => {
              if (mouseOutTimer) clearTimeout(mouseOutTimer)
            }}
            ref={userCardRef}
          >
            <UserCard user={user} />
          </div>
        </Portal>
      )}
    </span>
  )
}

UserName.propTypes = {
  id: PropTypes.string,
  noCard: PropTypes.bool,
  noDutyStatus: PropTypes.bool,
  noLink: PropTypes.bool,
  noOnlineStatus: PropTypes.bool,
  showRegionName: PropTypes.bool,
}
UserName.defaultProps = {
  id: undefined,
  noCard: false,
  noDutyStatus: false,
  noLink: false,
  noOnlineStatus: false,
  showRegionName: false,
}

export default UserName
