import React from 'react'

import LateShiftNotifications from './components/LateShiftNotifications'
import Conditional from '../Auth/shared/Conditional'

const ScheduleMount = () => (
  <Conditional module="schedule">
    <LateShiftNotifications />
  </Conditional>
  )

ScheduleMount.propTypes = {}
ScheduleMount.defaultProps = {}

export default ScheduleMount
