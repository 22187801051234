import React from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import Checkbox from '@therms/web-common/components/Inputs/Checkbox'
import SelectLocationsList from '../../../../Location/shared/SelectLocationsList'
import SelectPatrolRoutesList from '../../../../Location/shared/SelectPatrolRoutesList'
// import PropTypes from 'prop-types'

/*
    Below the checkbox for "all locations" removes the field
    `regionMetadata.settings.module.schedule.assignableModules.locationIds` - when this field is set
    it means that only some locationIds can be assigned in the schedule module/feature
 */

const SelectAssignableModuleFields = () => {
  const form = useForm()
  const { values } = useFormState()

  return (
    <>
      <h5>
        Assignable Modules for Scheduling
        <small className="d-block text-muted">
          Select which modules will be displayed for assigning shifts when building the schedule
        </small>
      </h5>

      <div className="my-2">
        <Field
          component={Checkbox}
          label="Locations"
          name="assignableModules.locations"
          type="checkbox"
        />

        {values?.assignableModules?.locations && (
          <div className="ml-4">
            <Checkbox
              checked={!values.assignableModules.locationIds}
              label="All Locations"
              onChange={(checked) => {
                form.change('assignableModules.locationIds', checked ? null : [])
              }}
            />

            {values?.assignableModules?.locationIds && (
              <SelectLocationsList
                onChange={(ids) => {
                  form.change('assignableModules.locationIds', ids)
                }}
                value={values?.assignableModules?.locationIds || []}
              />
            )}
          </div>
        )}
      </div>

      <div className="my-2">
        <Field
          component={Checkbox}
          label="Patrol Routes"
          name="assignableModules.patrolRoutes"
          type="checkbox"
        />

        {values?.assignableModules?.patrolRoutes && (
          <div className="ml-4">
            <Checkbox
              checked={!values.assignableModules.patrolRouteIds}
              label="All Patrol Routes"
              onChange={(checked) => {
                form.change('assignableModules.patrolRouteIds', checked ? null : [])
              }}
            />

            {values?.assignableModules?.patrolRouteIds && (
              <SelectPatrolRoutesList
                onChange={(ids) => form.change('assignableModules.patrolRouteIds', ids)}
                value={values?.assignableModules?.patrolRouteIds || []}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

SelectAssignableModuleFields.propTypes = {}
SelectAssignableModuleFields.defaultProps = {}

export default SelectAssignableModuleFields
