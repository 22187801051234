import React from 'react'
import PropTypes from 'prop-types'

import ConditionalShowScheduleTimelogControls from '../components/ConditionalShowScheduleTimelogControls'
import ShiftDetails from './ShiftDetails'

import './ShiftDetails.scss'
import ShiftTimelogControls from '../components/Timelog/ShiftTimelogControls'
import ScheduleTimeLog from './ScheduleTimeLog'

const ShiftDetailsWithControls = ({ scheduleShiftId }) => (
  <>
    <ShiftDetails scheduleShiftId={scheduleShiftId} />

    <ConditionalShowScheduleTimelogControls scheduleShiftId={scheduleShiftId}>
      <div className="mt-5">
        <ShiftTimelogControls scheduleShiftId={scheduleShiftId} />
      </div>
    </ConditionalShowScheduleTimelogControls>

    <div className="bg-secondary-opacity-25 border-radius my-2 p-1">
      <p className="text-center">
        <strong>Shift Timelog</strong>
      </p>

      <ScheduleTimeLog scheduleShiftId={scheduleShiftId} timelogDesc />
    </div>
  </>
)

ShiftDetailsWithControls.propTypes = {
  scheduleShiftId: PropTypes.string.isRequired,
}
ShiftDetailsWithControls.defaultProps = {}

export default ShiftDetailsWithControls
