import React from 'react'
import PropTypes from 'prop-types'
import Phone from '@therms/web-common/components/Phone'

const UserPhone = ({ phone }) => Boolean(phone) && (
  <Phone number={phone} />
)

UserPhone.propTypes = {
  phone: PropTypes.string,
}
UserPhone.defaultProps = {
  phone: undefined,
}

export default UserPhone
