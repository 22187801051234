import React from 'react'
// import PropTypes from 'prop-types'

import Header from './Header/index'
import LeftMenu from './LeftMenu/index'
import MainScene from './MainScene/index'
// import RightMenu from '..//RightMenu'
// import Footer from '../Footer'

import './index.scss'

const Desktop = () => (
  <div className="layout-container">
    <Header />

    <div className="layout-main-container">
      <LeftMenu />

      <div id="aside-left" />

      <MainScene />

      {/* <RightMenu /> */}
    </div>

    {/* <Footer /> */}
  </div>
)

Desktop.propTypes = {}
Desktop.defaultProps = {}

export default Desktop
