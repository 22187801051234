import React from 'react'
import PropTypes from 'prop-types'
import TimeAgo from 'react-timeago'

import logo from '@images/logo_icon_red_153x154.png'

import ErrorBoundary from './index'

const FallbackComponent = ({ error, errorInfo, eventId, refreshDate }) => (
  <div className="bg-dark flex-column-center h-100 text-light w-100">
    <div className="my-3">
      <img alt=" " src={logo} />
    </div>

    <h2>There was an Error</h2>

    <details style={{ maxHeight: '200px', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
      {error && error.toString().substr(0, 200)}

      <br />

      {errorInfo.componentStack}
    </details>

    <div className="max-width-500 my-3 text-center">
      <i>
        This error has been reported and will be corrected ASAP. If it continues to happen, you can
        attempt to reload the app or contact us{' '}
        <a
          className="badge badge-info"
          href={`mailto:support@therms.io?subject=Error # ${eventId}&&body=[Pleae explain what you were doing or clicked on when you received this error]`.replace(
            ' ',
            '%20',
          )}
        >
          support@therms.io
        </a>{' '}
        and reference <strong>error # {eventId}</strong>
      </i>

      {/* <a */}
      {/*  className="btn btn-danger" */}
      {/*  onClick={() => */}
      {/*    Sentry.showReportDialog({ eventId: this.state.eventId }) */}
      {/*  } */}
      {/* > */}
      {/*  Report feedback */}
      {/* </a> */}

      {Boolean(refreshDate) && (
        <div className="font-small my-3 text-muted">
          App will reload automatically in <TimeAgo date={refreshDate} />
        </div>
      )}

      <div className="my-2">
        <button className="btn btn-secondary" onClick={() => window.location.reload()}>
          Reload
        </button>
      </div>
    </div>
  </div>
)

FallbackComponent.propTypes = {
  error: PropTypes.any.isRequired,
  errorInfo: PropTypes.any.isRequired,
  eventId: PropTypes.string.isRequired,
  refreshDate: PropTypes.string.isRequired,
}
FallbackComponent.defaultProps = {}

const ErrorBoundaryWithDetails = ({ autoRefresh, children }) => (
  <ErrorBoundary autoRefresh={autoRefresh} FallbackComponent={FallbackComponent}>
    {children}
  </ErrorBoundary>
)

ErrorBoundaryWithDetails.propTypes = {
  autoRefresh: PropTypes.bool,
  children: PropTypes.any.isRequired,
}

ErrorBoundaryWithDetails.defaultProps = {
  autoRefresh: false,
}

export default ErrorBoundaryWithDetails
