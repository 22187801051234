import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { userAccountByUserIdSelector } from '../../../state/ducks/userAccount'

import { fetchUserAccount } from 'state/ducks/userAccount'

import { Container } from '@components/Card'
import BadgeID from './BadgeID'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import Email from './Email'
import Phone from './Phone'
import Title from './Title'
import UserOnlineLatestStatus from './UserOnlineLatestStatus'

import './UserCard.scss'
import Conditional from '../../Auth/shared/Conditional'
import { getImageSmallUrl } from 'modules/Files/utils/imageSizeUrls'
import { selectUserInfoAccessLevel } from '../../Auth/ducks'
import StatusBadge from 'modules/User/shared/StatusBadge'
import UserName from 'modules/User/shared/UserName'

const UserCard = ({ user }) => {
  const userAccount = useSelector((state) => userAccountByUserIdSelector(state, user.id))
  const userInfoAccessLevel = useSelector(selectUserInfoAccessLevel)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!userAccount) dispatch(fetchUserAccount(user.userAccountId))
  }, [user.userAccountId, userAccount])

  return (
    <div className="user-card-container">
      <Container>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-between p-2 w-100">
            <div>
              {!user.active && (
                <div className="text-danger">
                  <FAIcon name="ban" /> Inactive
                </div>
              )}

              <div className="align-items-start d-flex justify-content-between">
                <UserName id={user.id} noCard noDutyStatus noLink noOnlineStatus showRegionName />
                <StatusBadge userId={user.id} />
              </div>

              <div className="font-small font-weight-bold">
                <span className="font-weight-bold mr-1 text-muted">
                  <BadgeID badgeNumber={user.badgeNumber} />
                </span>
                <Title title={user.title} />
              </div>

              <Conditional accessLevel={userInfoAccessLevel}>
                <div className="contact-info">
                  <div>
                    <FAIcon name="phone-alt" className="text-muted mr-1" />
                    <Phone phone={user.phone} />
                  </div>

                  {userAccount && (
                    <div>
                      <FAIcon name="envelope" className="text-muted mr-1" />
                      <Email email={userAccount.email} />
                    </div>
                  )}
                </div>
              </Conditional>
            </div>

            <div>
              <UserOnlineLatestStatus userId={user.id} />
            </div>
          </div>

          {user.primaryImageUrl ? (
            <img
              alt=""
              className="border-radius-right-card user-profile-picture"
              crossOrigin="anonymous"
              height="150"
              src={getImageSmallUrl(user.primaryImageUrl)}
              width="150"
            />
          ) : (
            <div className="flex-column-center h-100 min-height-150 min-width-150">
              <div className="font-400">
                <FAIcon name="user" />
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
}
UserCard.defaultProps = {}

export default UserCard
