import React from 'react'
import PropTypes from 'prop-types'

import DateTime from '@therms/web-common/components/DateTime'
import Read from './Read'
import UserName from 'modules/User/shared/UserName'
import UserNames from '../../User/shared/UserNames'
import Tooltip from '@therms/web-common/components/Tooltip'
import FAIcon from '@therms/web-common/components/Icon/FAIcon'
import useOrganizationIsMultiRegion from '../../Auth/shared/hooks/useOrganizationIsMultiRegion'

import './MailHeaders.scss'

const MailHeaders = ({ mail, showRead }) => {
  let toGroup = null
  const isMultiRegion = useOrganizationIsMultiRegion()

  if ((mail.receiverIds || []).length > 1)
    toGroup = (
      <Tooltip
        content={<UserNames ids={mail.receiverIds} showRegionName={isMultiRegion} />}
      >
        <span className="badge">and {mail.receiverIds.length - 1} more users</span>
      </Tooltip>
    )

  return (
    <div className="d-flex flex-column align-items-start justify-content-between h-100">
      <table className="mb-1">
        <tbody>
          <tr>
            <th className="mailheader-label">From</th>
            <td>
              <UserName id={mail.senderId} showRegionName />
            </td>
          </tr>

          <tr>
            <th className="mailheader-label">To</th>
            <td>
              <UserName id={mail.receiverId} showRegionName /> {toGroup}
            </td>
          </tr>

          <tr>
            <th className="mailheader-label">Date</th>
            <td>
              <DateTime timestamp={mail.createdAt} />
            </td>
          </tr>

          <tr>
            <th className="mailheader-label">Subject</th>
            <td>{mail.subject}</td>
          </tr>
        </tbody>
      </table>

      <div className="d-flex">
        {mail.urgent && (
          <span className="badge badge-danger mr-2">
            <FAIcon name="exclamation" /> Urgent
          </span>
        )}

        {showRead && <Read mail={mail} showReadTime />}
      </div>
    </div>
  )
}

MailHeaders.propTypes = {
  mail: PropTypes.object.isRequired,
  showRead: PropTypes.bool,
}
MailHeaders.defaultProps = {
  showRead: true,
}

export default MailHeaders
