import React from 'react'
import Modal from '@therms/web-common/components/Modal'
import PropTypes from 'prop-types'
import ShiftTimelogForm from './ShiftTimelogForm'
import Conditional from '../../../Auth/shared/Conditional'

const ShiftTimelogFormModal = ({ closeHandler, id }) => (
  <Modal closeHandler={closeHandler} title="Shift Timelog">
    <Conditional
      permission="schedule.canAdjustShiftClockInOutTimes"
      renderOnFail={() => (
        <div className="text-danger">
          Invalid Permission - You need the permission, "Can adjust other User's clock in/out
          times".
        </div>
      )}
    >
      <ShiftTimelogForm onCancel={closeHandler} id={id} onComplete={closeHandler} />
    </Conditional>
  </Modal>
)

ShiftTimelogFormModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export { ShiftTimelogFormModal }
