import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useMail from '../hooks/useMail'

import Files from 'modules/Files/shared/Files'
import MailHeaders from './MailHeaders'
import MailBody from './MailBody'

const PreviousMail = ({ collapsedOnRender, previsousMailId }) => {
  const { data: mail, validating } = useMail(previsousMailId)
  const [show, setShow] = useState(!collapsedOnRender)

  if (!previsousMailId || validating || !mail) return null

  if (!show)
    return (
      <div className="card">
        <button
          className="border-0 btn btn-outline-secondary"
          onClick={() => setShow(true)}
          type="button"
        >
          Show Previous Mail
        </button>
      </div>
    )

  return (
    <div>
      <div className="card mb-2 p-3">
        <MailHeaders mail={mail} showRead={false} />
        <hr />
        <div>
          <div className="p-3">
            <MailBody mail={mail} />
          </div>

          <Files fileUrls={mail.files} />
        </div>
      </div>

      <PreviousMail previsousMailId={mail.previsousMailId} collapsedOnRender />
    </div>
  )
}

PreviousMail.propTypes = {
  collapsedOnRender: PropTypes.bool,
  previsousMailId: PropTypes.string,
}
PreviousMail.defaultProps = {
  collapsedOnRender: true,
  previsousMailId: undefined,
}

export default PreviousMail
