import React from 'react'
import { useUserScheduleContext } from './UserScheduleContext'
import { useUserScheduledHoursContext } from './UserScheduledHoursContext'
import UserName from '../../../../User/shared/UserName'
import PropTypes from 'prop-types'
import FAIcon from '@components/Icon/FAIcon'

const ScheduleDetailsInfoRow = ({ totalHoursScheduled }) => {
  const { highlightedUserId, isMouseOverShift } = useUserScheduleContext()
  const { hoursByUserId } = useUserScheduledHoursContext()

  return (
    <div className="d-flex">
      <div>
        <span className="text-muted">Total Hours Schedule </span>
        {totalHoursScheduled} hrs
      </div>

      <div className="d-flex">
        {highlightedUserId && (
          <div className="bg-dark border-radius px-1 text-light ml-1">
            <span className="mr-1 text-muted">
              Total Hours for{' '}
              <UserName id={highlightedUserId} noCard noLink noDutyStatus noOnlineStatus />:
            </span>
            {(hoursByUserId[highlightedUserId] || 0).toFixed(2)} hrs
          </div>
        )}

        {isMouseOverShift && (
          <div className="px-1 text-light ml-2">
            <small>
              <FAIcon name="info-circle" /> <em>Hold "Alt" key to clone Shift</em>
            </small>
          </div>
        )}
      </div>
    </div>
  )
}

ScheduleDetailsInfoRow.propTypes = {
  totalHoursScheduled: PropTypes.number.isRequired,
}

export { ScheduleDetailsInfoRow }
