import React from 'react'
import PropTypes from 'prop-types'

const FileStatus = ({ statusText, uploadPercentage }) => (
  <div className="file-status position-relative">
    {statusText && (
    <div className="font-xs position-absolute text-center text-muted text-truncate w-100">
      {statusText}
    </div>
      )}

    {uploadPercentage > 0 && (
    <progress className="w-100" value={uploadPercentage} max="100">
      {' '}
      {uploadPercentage}%{' '}
    </progress>
      )}
  </div>
  )

FileStatus.propTypes = {
  statusText: PropTypes.string,
  uploadPercentage: PropTypes.number,
}
FileStatus.defaultProps = {
  statusText: undefined,
  uploadPercentage: 0,
}

export default FileStatus
