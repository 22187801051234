import { handleActions } from 'redux-actions'
import reducerRegistry from '../reducerRegistry'

import duckBoilerplateFactory from '../utils/duckBoilerplateFactory'

const reducerName = 'serviceType'

const {
  actions,
  // actionCreators,
  // createActionName,
  handleActionsBoilerplate,
  initialStateBoilerplate,
  registerStoreChangeListenerForBoilerplate,
  // selects,
  selectors,
} = duckBoilerplateFactory({
  plural: 'serviceTypes',
  reducerName,
  singular: 'serviceType',
  storeMapperName: 'serviceType',
})

const initialState = {
  ...initialStateBoilerplate,
}

// selectors
export const {
  serviceTypeByIdSelector,
  serviceTypeLoadingByIdSelector,
} = selectors

// actions
export const { fetchServiceType, fetchServiceTypes } = actions

// reducer
const reducer = handleActions(
  {
    ...handleActionsBoilerplate,
  },
  initialState,
)

export default reducer

reducerRegistry.register(reducerName, reducer)

registerStoreChangeListenerForBoilerplate()
