import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import useCurrentUser from '../../Auth/shared/hooks/useCurrentUser'
import useMail from '../hooks/useMail'

import FAIcon from '@therms/web-common/components/Icon/FAIcon'

import './MailControls.scss'

const MailControls = ({ mailId, onDelete, onFwd, onReply, onReplyAll }) => {
  const [confirmDelete, setConfirmDelete] = useState()
  const { data: mail, markAsUnread } = useMail(mailId)
  const { id: currentUserId } = useCurrentUser()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (confirmDelete) {
      const t = setTimeout(() => setConfirmDelete(false), 3000)
      return () => clearTimeout(t)
    }
  }, [confirmDelete])

  const isMailRead = useMemo(() => mail.read && mail.receiverId === currentUserId, [mail.read, mail.receiverId, currentUserId])

  function handleDelete() {
    onDelete({})
  }

  function handleFwd() {
    onFwd({
      previsousMailId: mail.id,
      subject: `FWD: ${mail.subject.trim()}`,
    })
  }

  function handleMarkUnread() {
    markAsUnread()
  }

  function handleReply() {
    onReply({
      groupingMessageId: mail.groupingMessageId,
      previsousMailId: mail.id,
      receiverIds: mail.senderId,
      subject: `RE: ${mail.subject.replace('RE:', '').trim()}`,
    })
  }

  function handleReplyAll() {
    const toUserIds = mail.receiverIds.filter(userId => userId !== currentUserId)

    toUserIds.push(mail.senderId)

    onReplyAll({
      groupingMessageId: mail.groupingMessageId,
      previsousMailId: mail.id,
      receiverIds: toUserIds,
      subject: `RE: ${mail.subject.replace('RE:', '').trim()}`,
    })
  }

  return (
    <div className="mail-controls-container">
      <button className="mail-control btn btn-secondary btn-sm" onClick={handleReply} type="button">
        <FAIcon name="reply" /> Reply
      </button>

      {(mail.receiverIds || []).length > 1 && (
        <button
          className="mail-control btn btn-outline-secondary btn-sm"
          onClick={handleReplyAll}
          type="button"
        >
          <FAIcon name="reply-all" /> Reply All
        </button>
      )}

      <button
        className="mail-control btn btn-outline-secondary btn-sm"
        onClick={handleFwd}
        type="button"
      >
        <FAIcon name="share" /> Fwd
      </button>

      {isMailRead && (
        <button
          className="mail-control btn btn-outline-secondary btn-sm"
          onClick={handleMarkUnread}
          type="button"
        >
          <FAIcon name="eye" /> Mark Unread
        </button>
      )}

      <button
        className="mail-control border-0 btn btn-outline-danger btn-short"
        onClick={confirmDelete ? handleDelete : () => setConfirmDelete(true)}
        type="button"
      >
        {confirmDelete ? (
          'Confirm'
        ) : (
          <>
            <FAIcon name="trash" /> Delete
          </>
        )}
      </button>
    </div>
  )
}

MailControls.propTypes = {
  mailId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFwd: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  onReplyAll: PropTypes.func.isRequired,
}
MailControls.defaultProps = {}

export default MailControls
