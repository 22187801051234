import { all, delay, put, select, takeLeading } from 'redux-saga/effects'
import cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { getDeviceInfo } from '@therms/web-common/utils/device'

import { getDataStoreMemoryInfo, getStateMemoryInfo, getDeviceMemoryInfo } from 'services/debugging'

import startup from 'modules/startup'

import {
  getLastAuthUrlPath,
  handleAuthFailed,
  handleLogout,
  removeAuthCookie,
  resetLastAuthUrlPath,
  setAuthCookie,
} from 'services/auth'
import { history } from 'services/browserHistory'
import { isProdEnv } from 'config/env'
import authApi from 'services/authApi'
import ENV from '../../../config/env'
import Store from 'services/store'

import { addSaga } from 'state/sagaRegistry'
import {
  currentUserSelector,
  types,
  authenticate,
  changeActiveRegion,
  logout,
  fetchCurrentUserStatus,
  setUserMetadata,
  setAllowedLocationIds,
} from './index'
import { turnOnListeners } from './listener'
import formatUserName from '../../User/shared/utils/formatUserName'
import { models } from '@therms/models'

function* doAuthenticate() {
  yield put({ type: types.SET_AUTHENTICATED, payload: false })

  const authToken = cookies.get(ENV.AUTH_COOKIE_NAME)

  if (!authToken) {
    console.log('no auth token')
    handleAuthFailed('doAuthenticate() no auth token')
    return
  }

  let validateResponse

  try {
    // send token to back-end for valdiation and our initial payload:
    validateResponse = yield authApi.post('/token/validate')
  } catch (e) {
    console.log('POST /token/validate error', e)
    handleAuthFailed('doAuthenticate() POST /token/validate error')
    return
  }

  const { data } = validateResponse

  if (!isProdEnv) {
    console.log('token validated, data:', data)
  }

  // if validation fails, show login overlay
  if (!data) {
    handleAuthFailed('doAuthenticate() no response data in POST /token/validate')
    return
  }

  const userRecent = yield Store.UserRecent.find()

  Store.User.add(data.user)
  Store.Organization.add(data.organization)
  Store.OrganizationMetadata.add(data.organization.organizationMetadata)
  Store.Region.add(data.regions)

  yield put({ type: types.SET_ORGANIZATION, payload: data.organization })
  yield put({
    type: types.SET_ORGANIZATION_METADATA,
    payload: data.organization.organizationMetadata,
  })
  yield put({ type: types.SET_REGION, payload: data.region })
  yield put({
    type: types.SET_REGION_METADATA,
    payload: data.region.regionMetadata,
  })
  yield put({ type: types.SET_REGIONS, payload: data.regions })
  yield put({ type: types.SET_TOKEN, payload: authToken })
  yield put({ type: types.SET_USER, payload: data.user })
  yield put({ type: types.SET_USER_ACCOUNT, payload: data.user.userAccount })
  yield put({ type: types.SET_USER_METADATA, payload: data.user.userMetadata })
  yield put({ type: types.SET_USERRECENT, payload: userRecent })

  // call startup scripts before setting `authenticated=true` so no components render before
  // the startup scripts run - there are components that rely on configs in startup scripts to config
  // certain settings, ie: dayjs.Ls.en.weekStart = organizationsettings.firstDayofWeek
  startup()

  yield put({ type: types.SET_AUTHENTICATED, payload: true })

  yield put(fetchCurrentUserStatus())

  Sentry.configureScope((scope) => {
    scope.setUser({ email: data.user.userAccount.email, id: data.user.id })
    scope.setTag('organization', data.organization.name)
    scope.setTag('organizationId', data.organization.id)
    scope.setTag('region', data.region.name)
    scope.setTag('regionId', data.region.id)
  })

  turnOnListeners()
}

function* doChangeActiveRegion({ payload: regionId }) {
  yield put({
    type: types.SET_AUTHENTICATED,
    payload: false,
  })

  const state = yield select()
  const region = state.Auth.regions.find(({ id }) => id === regionId)

  if (!region) {
    // todo: simulate this case and refactor how it's handled for a good ux
    //    ie: show a re-login modal or a useful message
    handleAuthFailed('doChangeActiveRegion() no region data')
    yield put({
      type: types.SET_AUTHENTICATED,
      payload: true,
    })
  }

  try {
    yield authApi.post('/token/switchRegion', { regionId }).then(({ data }) => {
      if (!data.authToken) throw new Error('failed to switch regions, new authToken not provided')
      setAuthCookie(data.authToken)
    })

    try {
      localStorage.clear()
    } catch (e) {
      console.error('failed to clear localStorage', e)
    }

    window.location.reload()
    window.location.href = '/'
  } catch (e) {
    // todo: show system toast
    yield put({
      type: types.SET_AUTHENTICATED,
      payload: true,
    })
  }
}

function* doLogout() {
  try {
    yield authApi.post('/logout')

    try {
      localStorage.clear()
    } catch (e) {
      console.error('failed to clear localStorage', e)
    }

    removeAuthCookie()
  } catch (e) {
    console.error('authApi /logout error', e)
  }

  yield handleLogout()
}

/**
 * Reload any state from the authenticated user's last session.
 * This can be anything that's short-lived and improves UX in a situation
 * like a user's session/auth expiring and having to log back in, they
 * would expect to be returned to a specific state
 *
 * ie: last url, settings, etc.
 *
 * @returns {IterableIterator<*>}
 */
function* doReloadLastAuthState() {
  const lastAuthUrlPath = getLastAuthUrlPath()

  if (lastAuthUrlPath) {
    resetLastAuthUrlPath()
    history.push(lastAuthUrlPath)
  }

  yield true
}

function* doSendPerformanceDebuggingInfo() {
  while (true) {
    yield delay(5 * 1000 * 60)

    const currentUser = yield select(currentUserSelector)

    if (currentUser?.userMetadata?.settings?.debugPerformance) {
      const data = {
        user: {
          id: currentUser.id,
          name: `${formatUserName(currentUser)}`,
          title: currentUser.title,
        },
        device: getDeviceInfo(),
        dataStore: getDataStoreMemoryInfo(),
        memory: getDeviceMemoryInfo(),
        state: getStateMemoryInfo(),
      }

      data.totalObjects = 0

      Object.values(data.dataStore).forEach((n) => (data.totalObjects += n))
      Object.values(data.state).forEach((n) => (data.totalObjects += n))

      console.log('debug performance "totalObjects": ', data.totalObjects)

      axios.post('https://admin.therms.io/api/admin/public/performance-debugging-report', data)
    }
  }
}

function* doSetUserAccess() {
  const {
    userMetadata: { access },
  } = yield select(currentUserSelector)

  if (!access || (!access.locationIds && !access.locationGroupIds)) return

  const locationIds = access.locationIds || []
  const locationGroupIds = access.locationGroupIds || []

  if (locationGroupIds) {
    const locations = yield models.location.utils.getLocationsFromLocationGroupIds(
      locationGroupIds,
      Store,
    )

    locationIds.push(...locations.map(({ id }) => id))
  }

  yield put(setAllowedLocationIds(locationIds))
}

function* authSaga() {
  yield all([
    doSendPerformanceDebuggingInfo(),
    takeLeading(authenticate, doAuthenticate),
    takeLeading(changeActiveRegion, doChangeActiveRegion),
    takeLeading(logout, doLogout),
    takeLeading(setUserMetadata, doSetUserAccess),
    takeLeading(types.SET_AUTHENTICATED, doReloadLastAuthState),
  ])
}

addSaga('Auth', authSaga)
